import React, { Component } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  LinearProgress,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WithProps } from 'react-waterfall';
import { DropzoneState } from 'react-dropzone';
import { cloneDeep } from 'lodash';

import { actions, connect, StateType } from '../../../../../../core/store';
import {
  CREATE_SELF_STUDY_ASSESSMENT,
  CREATE_SELF_STUDY_QUESTION_SUCCESS,
  CREATE_SELF_STUDY_VIDEO_SUCCESS
} from '../../../../../../constants';
import { promiseEach, upload } from '../../../../../../utilities';

import AddFinalAssessmentPresenter from './add-final-assessment-presenter';
import AddQuestionPresenter from './add-question-presenter';

let Dropzone = require('react-dropzone');
if ('default' in Dropzone) {
  Dropzone = Dropzone.default;
}

const styles = createStyles({
  addVideo: {
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    width: 250,
    height: 141,
    marginRight: 15
  },
  addVideoButton: {
    width: '100%',
    height: '100%'
  },
  container: {
    display: 'inline-flex',
    verticalAlign: 'top'
  },
  dropzone: {
    textAlign: 'center',
    border: '1px dotted',
    cursor: 'pointer',
    width: '100%'
  },
  dropzoneContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  panels: {
    marginTop: 10
  },
  progressContainer: {
    flexGrow: 1
  }
});

const mapStateToProps = ({ error, message }: StateType) => ({
  error,
  message
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  containerState: any;
  error: string;
  message: string;
  newPos: number;
  setContainerState: Function;
}

interface State {
  expanded?: string;
  assessment: any;
  question: any;
  open: boolean;
  submitting: boolean;
  videoFile?: File;
}

const resetQuestion = (newPos: number) => ({
  answers: ['', ''],
  feedback: ['', ''],
  question: '',
  position: newPos
});

const resetAssessment = () => ({
  questions: [
    {
      answers: ['', ''],
      question: ''
    }
  ]
});

class AddSelfStudyDialogBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      assessment: resetAssessment(),
      open: false,
      question: resetQuestion(props.newPos),
      submitting: false
    };
  }

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    const { message } = newProps;

    if (
      prevState.open &&
      (message === CREATE_SELF_STUDY_VIDEO_SUCCESS ||
        message === CREATE_SELF_STUDY_QUESTION_SUCCESS ||
        message === CREATE_SELF_STUDY_ASSESSMENT)
    ) {
      return {
        assessment: resetAssessment(),
        expanded: undefined,
        open: false,
        question: resetQuestion(newProps.newPos),
        submitting: false,
        videoFile: undefined
      };
    } else if (prevState.submitting && newProps.error) {
      return {
        submitting: false
      };
    }
    return {};
  }

  handleAddAssessmentQuestion = () => {
    const { assessment } = this.state;
    const { questions } = assessment;
    const question = {
      answers: ['', ''],
      question: ''
    };
    this.setState({
      assessment: {
        questions: [...questions, question]
      }
    });
  };

  handleAddAssessmentAnswer = (questionIndex: number) => {
    const { assessment } = this.state;
    const { questions } = assessment;
    const clonedQuestions = cloneDeep(questions);
    clonedQuestions[questionIndex].answers.push('');
    this.setState({
      assessment: {
        questions: clonedQuestions
      }
    });
  };

  handleAddAnswer = () => {
    const { question } = this.state;
    const { answers, feedback } = question;
    this.setState({
      question: {
        ...question,
        answers: [...answers, ''],
        feedback: [...feedback, '']
      }
    });
  };

  handleAssessmentArrayChange = (questionIndex: number, prop: string, index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { assessment } = this.state;
    const { questions } = assessment;
    const clonedQuestions = cloneDeep(questions);
    clonedQuestions[questionIndex][prop][index] = event.target.value;

    this.setState({
      assessment: {
        questions: clonedQuestions
      }
    });
  };

  handleArrayChange = (prop: string, index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const values = this.state.question[prop];
    values[index] = event.target.value;

    this.setState(state => ({
      question: {
        ...state.question,
        [prop]: values
      }
    }));
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAssessmentChange = (questionIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    const key = event.target.id || event.target.name;
    const { assessment } = this.state;
    const { questions } = assessment;
    const question = questions[questionIndex];
    question[key] = event.target.value;
    this.setState({
      assessment: {
        questions: [...questions]
      }
    });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    const key = event.target.id || event.target.name;
    this.setState(state => ({
      question: {
        ...state.question,
        [key]: event.target.value
      }
    }));
  };

  handleSave = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    const { containerState, newPos } = this.props;
    const { assessment, expanded, question, videoFile } = this.state;
    const { form } = containerState;

    this.setState(
      {
        submitting: true
      },
      async () => {
        if (expanded === 'video' && videoFile) {
          const url = await upload(videoFile);
          const { name } = videoFile;
          actions.createSelfStudyVideo(form.id, {
            position: newPos,
            title: name.substring(0, name.lastIndexOf('.')),
            url
          });
        } else if (expanded === 'assessment') {
          const { questions } = assessment;
          promiseEach(questions, q => actions.createSelfStudyAssessment(form.id, q));
        } else if (expanded === 'question') {
          actions.createSelfStudyQuestion(form.id, {
            ...question,
            position: newPos
          });
        }
      }
    );
  };

  handleRemoveAnswer = (answerIndex: number) => {
    const { question } = this.state;
    const { answers, feedback } = question;

    answers.splice(answerIndex, 1);
    feedback.splice(answerIndex, 1);

    this.setState({
      question: {
        ...question,
        answers: [...answers],
        feedback: [...feedback]
      }
    });
  };

  handleRemoveAssessmentAnswer = (questionIndex: number, answerIndex: number) => {
    const { assessment } = this.state;
    const { questions } = assessment;
    const question = questions[questionIndex];
    const { answers } = question;

    answers.splice(answerIndex, 1);

    this.setState({
      assessment: {
        questions: [...questions]
      }
    });
  };

  handleRemoveAssessmentQuestion = (questionIndex: number) => {
    const { assessment } = this.state;
    const { questions } = assessment;

    questions.splice(questionIndex, 1);

    this.setState({
      assessment: {
        questions: [...questions]
      }
    });
  };

  handlePanelChange = (panel: string) => (event: React.ChangeEvent, expanded: boolean) => {
    this.setState({
      assessment: resetAssessment(),
      question: resetQuestion(this.props.newPos),
      expanded: expanded ? panel : undefined,
      videoFile: undefined
    });
  };

  onDropVideo = (videoFiles: File[]) => {
    const [videoFile] = videoFiles;
    this.setState(
      {
        videoFile
      },
      this.handleSave
    );
  };

  render() {
    const { classes } = this.props;
    const { assessment, expanded, question, open, submitting, videoFile } = this.state;
    let disabled = submitting || !expanded;

    if (expanded === 'video' && !videoFile) {
      disabled = true;
    } else if (
      expanded === 'question' &&
      (question.correctAnswer === undefined ||
        !question.question ||
        !question.answers[0] ||
        !question.answers[1] ||
        !question.feedback[0] ||
        !question.feedback[1])
    ) {
      disabled = true;
    } else if (
      expanded === 'assessment' &&
      (assessment.questions.length === 0 ||
        assessment.questions.some((q: any) => !q.question || q.correctAnswer === undefined || !q.answers[0] || !q.answers[1]))
    ) {
      disabled = true;
    }

    return (
      <div className={classes.container}>
        <div className={classes.addVideo}>
          <Button className={classes.addVideoButton} onClick={this.handleOpen}>
            <Icon style={{ fontSize: 60 }}>add_circle</Icon>
          </Button>
        </div>
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="upload-options" fullWidth={true} maxWidth="md">
          <form onSubmit={this.handleSave}>
            <DialogTitle id="upload-options">Upload Options</DialogTitle>
            <DialogContent>
              <div className={classes.panels}>
                <ExpansionPanel expanded={expanded === 'video'} onChange={this.handlePanelChange('video')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Add Video</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className={classes.dropzoneContainer}>
                      <Dropzone onDrop={this.onDropVideo} accept="video/*,.mp4" multiple={false}>
                        {({
                          getRootProps,
                          getInputProps
                        }: {
                          getRootProps: DropzoneState['getRootProps'];
                          getInputProps: DropzoneState['getInputProps'];
                        }) => (
                          <section>
                            <div className={classes.dropzone} {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>Drop video here, or click to select one to upload.</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      {videoFile && (
                        <ul>
                          <li>{videoFile.name}</li>
                        </ul>
                      )}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expanded === 'question'} onChange={this.handlePanelChange('question')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Add Question</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <AddQuestionPresenter
                      expanded={expanded}
                      handleChange={this.handleChange}
                      handleArrayChange={this.handleArrayChange}
                      handleAddAnswer={this.handleAddAnswer}
                      handleRemoveAnswer={this.handleRemoveAnswer}
                      question={question}
                      submitting={submitting}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={expanded === 'assessment'} onChange={this.handlePanelChange('assessment')}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Add Final Assessment</Typography>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails>
                    <AddFinalAssessmentPresenter
                      assessment={assessment}
                      expanded={expanded}
                      handleChange={this.handleAssessmentChange}
                      handleArrayChange={this.handleAssessmentArrayChange}
                      handleAddAnswer={this.handleAddAssessmentAnswer}
                      handleAddQuestion={this.handleAddAssessmentQuestion}
                      handleRemoveAnswer={this.handleRemoveAssessmentAnswer}
                      handleRemoveQuestion={this.handleRemoveAssessmentQuestion}
                      submitting={submitting}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </DialogContent>
            {submitting && (
              <div className={classes.progressContainer}>
                <LinearProgress variant="query" />
              </div>
            )}
            <DialogActions>
              <Button disabled={submitting} onClick={this.handleClose} variant="outlined">
                Cancel
              </Button>
              <Button disabled={disabled} type="submit" color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export const AddSelfStudyDialog = withStyles(styles)(connect(mapStateToProps)(AddSelfStudyDialogBase));
