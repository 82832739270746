import React, { Component } from 'react';
import { AppBar, createStyles, Tab, Tabs, WithStyles, withStyles } from '@material-ui/core';
import { Link, match, Route } from 'react-router-dom';
import { WithProps } from 'react-waterfall';

import { connect, StateType } from '../core/store';

import {
  AdminList,
  CertificateList,
  CourseCreate,
  CourseList,
  InstructorEdit,
  InstructorList,
  LearnerList,
  PollingQuestionEdit,
  PollingQuestionList,
  WebinarList
} from './routes';

const tabs = [
  { to: '/admin', label: 'Admins' },
  { to: '/admin/learners', label: 'Learners' },
  { to: '/admin/instructors', label: 'Instructors' },
  { to: '/admin/courses', label: 'Courses' },
  { to: '/admin/webinars', label: 'Webinars' },
  { to: '/admin/polling-questions', label: 'Polling Questions' },
  { to: '/admin/certificates', label: 'Certificates' }
];

const styles = createStyles({
  container: {
    margin: 10
  }
});

const mapStateToProps = ({ user }: StateType) => ({ user });

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  match: match;
  history: any;
}

const AdminRouterBase = class Admin extends Component<Props> {
  getTab = () => {
    const { history } = this.props;
    for (let i = tabs.length - 1; i >= 0; --i) {
      const tab = tabs[i];
      if (history.location.pathname.startsWith(tab.to)) {
        return i;
      }
    }
    return -1;
  };

  handleTabChange = (event: React.ChangeEvent, value: any) => {
    const { history } = this.props;
    history.push(value);
  };

  render() {
    const { classes, match, user } = this.props;

    if (!user) {
      return null;
    }

    if (user.role !== 'admin') {
      window.location.href = '/';
      return null;
    }

    return (
      <div className={classes.container}>
        <AppBar position="static" color="default">
          <Tabs variant="scrollable" value={this.getTab()} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
            {tabs.map((t, index) => (
              <Tab key={index} label={t.label} component={Link} to={t.to} />
            ))}
          </Tabs>
        </AppBar>
        <Route path={`${match.url}`} component={AdminList} exact />
        <Route path={`${match.url}/certificates`} component={CertificateList} exact />
        <Route path={`${match.url}/courses`} component={CourseList} exact />
        <Route path={`${match.url}/courses/create`} component={CourseCreate} exact />
        <Route path={`${match.url}/courses/:courseId([0-9]+)`} component={CourseCreate} />
        <Route path={`${match.url}/learners`} component={LearnerList} />
        <Route path={`${match.url}/instructors/:instructorId`} component={InstructorEdit} />
        <Route path={`${match.url}/instructors`} component={InstructorList} exact />
        <Route path={`${match.url}/polling-questions/:questionId`} component={PollingQuestionEdit} />
        <Route path={`${match.url}/polling-questions`} component={PollingQuestionList} exact />
        <Route path={`${match.url}/webinars`} component={WebinarList} />
      </div>
    );
  }
};

export const AdminRouter = connect(mapStateToProps)(withStyles(styles)(AdminRouterBase));
