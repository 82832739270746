import { hexToRGB } from '../index';

/**
 * Convert field of study object into gradient.  Fallback to purple gradient.
 */
export default function studyToGradient(fieldOfStudy?: any): any {
  let gradientStyle = {
    background: 'linear-gradient(135deg, rgba(52,37,175,1) 0%, rgba(190,105,213,1) 100%)'
  };

  if (fieldOfStudy) {
    const gradientStart = hexToRGB(fieldOfStudy.gradientStart);
    const gradientEnd = hexToRGB(fieldOfStudy.gradientEnd);

    if (gradientStart && gradientEnd) {
      gradientStyle = {
        background: `linear-gradient(135deg, rgba(${gradientStart.r},${gradientStart.g},${gradientStart.b},1) 0%, rgba(${gradientEnd.r},${gradientEnd.g},${gradientEnd.b},1) 100%)`
      };
    }
  }

  return gradientStyle;
}
