/* eslint-disable import/no-duplicates */

import {
  addDays,
  addMonths,
  addYears,
  differenceInMilliseconds,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getHours,
  getSeconds,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isValid,
  parse,
  setHours,
  setMinutes,
  setSeconds,
  setYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns';
import { enUS } from 'date-fns/locale';

/**
 * Ported for date-fns.2.0alpha.
 * @TODO switch back to module version once it's updated for 2.0.
 */

export default class DateFnsUtils {
  locale: Locale;

  constructor({ locale } = { locale: enUS }) {
    this.locale = locale;
  }

  date(value: any) {
    if (typeof value === 'undefined') {
      return new Date();
    }

    if (value === null) {
      return null;
    }

    return new Date(value);
  }

  parse(value: any, formatString: string) {
    if (value === '') {
      return null;
    }

    return parse(value, formatString, new Date());
  }

  format(date: any, formatString: string) {
    return format(date, formatString, { locale: this.locale });
  }

  isEqual(date: any, comparing: any) {
    if (date === null && comparing === null) {
      return true;
    }

    return isEqual(date, comparing);
  }

  addDays = addDays;

  isValid = isValid;

  getDiff = differenceInMilliseconds;

  isNull(date: any) {
    return date === null;
  }

  isAfter = isAfter;

  isBefore = isBefore;

  isAfterDay(date: any, value: any) {
    return isAfter(date, endOfDay(value));
  }

  isBeforeDay(date: any, value: any) {
    return isBefore(date, startOfDay(value));
  }

  isBeforeYear(date: any, value: any) {
    return isBefore(date, startOfYear(value));
  }

  isAfterYear(date: any, value: any) {
    return isAfter(date, endOfYear(value));
  }

  startOfDay = startOfDay;

  endOfDay = endOfDay;

  formatNumber(num: any) {
    return num;
  }

  getHours = getHours;

  setHours = setHours;

  getMinutes(date: any) {
    return date.getMinutes();
  }

  setMinutes = setMinutes;

  getSeconds = getSeconds;

  setSeconds = setSeconds;

  getMonth(date: any) {
    return date.getMonth();
  }

  isSameDay = isSameDay;

  getMeridiemText(ampm: any) {
    return ampm === 'am' ? 'AM' : 'PM';
  }

  getStartOfMonth = startOfMonth;

  getNextMonth(date: any) {
    return addMonths(date, 1);
  }

  getPreviousMonth(date: any) {
    return addMonths(date, -1);
  }

  getYear = getYear;

  setYear = setYear;

  getWeekdays() {
    const now = new Date();
    return eachDayOfInterval(
      {
        start: startOfWeek(now, { locale: this.locale }),
        end: endOfWeek(now, { locale: this.locale })
      },
      { locale: this.locale } as any // TODO: possibly bad typing defs check
    ).map(day => format(day, 'dd', { locale: this.locale }));
  }

  getWeekArray(date: any) {
    const start = startOfWeek(startOfMonth(date), { locale: this.locale });
    const end = endOfWeek(endOfMonth(date), { locale: this.locale });

    const nestedWeeks: any[] = [];
    let count = 0;
    let current = start;
    while (isBefore(current, end)) {
      const weekNumber = Math.floor(count / 7);
      nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
      nestedWeeks[weekNumber].push(current);
      current = addDays(current, 1);
      count += 1;
    }

    return nestedWeeks;
  }

  getYearRange(start: any, end: any) {
    const startDate = startOfYear(new Date(start));
    const endDate = endOfYear(new Date(end));
    const years = [];

    let current = startDate;
    while (isBefore(current, endDate)) {
      years.push(current);
      current = addYears(current, 1);
    }

    return years;
  }

  getCalendarHeaderText(date: any) {
    return format(date, 'MMMM yyyy', { locale: this.locale });
  }

  getYearText(date: any) {
    return format(date, 'yyyy', { locale: this.locale });
  }

  getDatePickerHeaderText(date: any) {
    return format(date, 'EEE, MMM d', { locale: this.locale });
  }

  getDateTimePickerHeaderText(date: any) {
    return format(date, 'MMM d ', { locale: this.locale });
  }

  getDayText(date: any) {
    return format(date, 'd', { locale: this.locale });
  }

  getHourText(date: any, ampm: any) {
    return format(date, ampm ? 'hh' : 'HH', { locale: this.locale });
  }

  getMinuteText(date: any) {
    return format(date, 'mm', { locale: this.locale });
  }

  getSecondText(date: any) {
    return format(date, 'ss', { locale: this.locale });
  }
}
