import React from 'react';
import MaskedInput from 'react-text-mask';

export interface Props {
  inputRef: (ref: HTMLInputElement | null) => void;
}

export const PtinInput: React.FunctionComponent<Props> = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...other}
      mask={['P', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      showMask
    />
  );
};
