/**
 * Captialize each word.
 *
 * @param {string} input
 * @return {string} Title Case Word
 */
export default function toTitleCase(input: string) {
  if (typeof input !== 'string') {
    return input;
  }
  return input.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}
