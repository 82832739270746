import React, { Component } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  withWidth
} from '@material-ui/core';
import { isWidthDown, WithWidthProps } from '@material-ui/core/withWidth';

import { actions } from '../../core/store';
import { POLLING_QUESTION_DISPLAY_DURATION } from '../../constants';
import { PollingQuestionType } from '../../types';

const styles = (theme: Theme) =>
  createStyles({
    answerLabel: {
      '&:not(:last-child)': {
        marginBottom: 12
      }
    },
    barColorPrimary: {
      backgroundColor: 'white'
    },
    colorPrimary: {
      backgroundColor: theme.palette.secondary.main
    },
    formControl: {
      width: '100%'
    },
    modalActions: {
      justifyContent: 'flex-start'
    },
    progressRoot: {
      height: 2
    }
  });

interface Props extends WithStyles<typeof styles>, WithWidthProps {
  onClose: () => void;
  open: boolean;
  pollingQuestion: PollingQuestionType;
  preview?: boolean;
  registeredWebinar?: any;
}

interface State {
  answer: string;
  completed: number;
}

class PollingQuestionDialogBase extends Component<Props, State> {
  questionTimer?: number;
  answerSubmitted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      answer: '',
      completed: 0
    };
  }

  componentDidMount(): void {
    this.setupInterval();
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  componentDidUpdate(prevProps: Props) {
    const { open } = this.props;
    if (prevProps.open !== open) {
      this.setState({
        completed: 0
      });

      if (!open) {
        this.clearInterval();
        this.answerSubmitted = false;
      } else {
        this.setupInterval();
      }
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ answer: event.target.value });
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await this.sendQuestion();
  };

  async sendQuestion() {
    const { answer } = this.state;
    const { onClose, pollingQuestion, registeredWebinar } = this.props;

    if (!registeredWebinar || !answer || this.answerSubmitted) {
      return;
    }

    this.answerSubmitted = true;
    await actions.answerPollingQuestion(pollingQuestion.id, {
      answer,
      webinarId: registeredWebinar.webinarId
    });
    this.setState({ answer: '' }, onClose);
  }

  progress = () => {
    const { preview } = this.props;
    const { completed } = this.state;

    if (completed === POLLING_QUESTION_DISPLAY_DURATION) {
      this.setState({ completed: 0 });

      // don't close when in admin preview
      if (!preview) {
        this.sendQuestion();
      }
    } else {
      this.setState({ completed: Math.min(completed + 1, POLLING_QUESTION_DISPLAY_DURATION) });
    }
  };

  close = (event: React.SyntheticEvent) => {
    const { onClose } = this.props;
    onClose();

    event.stopPropagation();
  };

  render() {
    const { classes, pollingQuestion, preview = false, open, width } = this.props;
    const { question, answers } = pollingQuestion;
    const { completed, answer } = this.state;
    const normaliseProgress = (v: number) => ((v - 1) * 100) / (POLLING_QUESTION_DISPLAY_DURATION - 1);

    const fullScreen = isWidthDown('sm', width!);

    return (
      <Dialog
        disableBackdropClick={!preview}
        disableEscapeKeyDown={!preview}
        open={open}
        fullScreen={fullScreen}
        maxWidth={fullScreen ? false : 'md'}
        onClose={this.close}>
        <DialogTitle>Poll Question</DialogTitle>
        <form onSubmit={this.handleSubmit}>
          <DialogContent>
            <Grid container spacing={0}>
              <Grid container justify="flex-end">
                <Grid item xs={12}>
                  <LinearProgress
                    classes={{
                      colorPrimary: classes.colorPrimary,
                      barColorPrimary: classes.barColorPrimary,
                      root: classes.progressRoot
                    }}
                    color="primary"
                    variant="determinate"
                    value={normaliseProgress(completed)}
                  />
                </Grid>
                <Grid item>{POLLING_QUESTION_DISPLAY_DURATION - completed} seconds</Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {question}
                </Typography>
                <FormControl component={'fieldset'} className={classes.formControl} required>
                  <RadioGroup aria-label="answers" name="answers" onChange={this.handleChange} value={answer}>
                    {answers.map((a, i: number) => (
                      <FormControlLabel
                        key={i}
                        value={a}
                        className={classes.answerLabel}
                        control={
                          <Radio
                            inputProps={{
                              required: true
                            }}
                          />
                        }
                        label={a}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions classes={{ root: classes.modalActions }}>
            <Button disabled={preview || !answer} type="submit" color="secondary" variant="contained" size="large">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  private setupInterval(): void {
    this.questionTimer = window.setInterval(this.progress, 1000);
  }

  private clearInterval(): void {
    window.clearInterval(this.questionTimer);
    this.questionTimer = undefined;
  }
}

export const PollingQuestionDialog = withStyles(styles)(withWidth()(PollingQuestionDialogBase));
