import React, { Component, forwardRef } from 'react';
import { WithProps } from 'react-waterfall';
import { AppBar, Button, createStyles, Fade, Hidden, IconButton, Popper, Toolbar, withStyles, WithStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Menu as MenuIcon, Notifications as NotificationIcon } from '@material-ui/icons';

import { connect, StateType } from '../../store';
import { UserAvatar } from '../../../shared';

import { NotificationsPanel } from './notifications-panel';
import { MenuAuthButton } from './menu-auth-button';

const logo = require('../../../../images/logo.svg');

const styles = createStyles({
  appBar: {
    minHeight: 84
  },
  appBarButton: {
    fontWeight: 600,
    fontSize: '1rem',
    textTransform: 'none'
  },
  bannerContainer: {
    backgroundColor: '#FFF',
    zIndex: 10,
    position: 'relative'
  },
  bellActive: {
    color: '#F9445A'
  },
  bellInactive: {},
  canopyLogo: {
    display: 'block',
    height: 23,
    position: 'relative',
    top: 3
  },
  canopyLogoAnchor: {
    display: 'block'
  },
  canopyBanner: {
    backgroundColor: '#FFF',
    padding: '13px 20px'
  },
  menuButton: {
    color: '#333'
  },
  navbarLinks: {
    margin: '0 auto'
  },
  popper: {
    zIndex: 1
  },
  root: {
    flexGrow: 1
  },
  title: {},
  toolbar: {
    backgroundColor: '#F3F3F3'
  }
});

function linkGenerator(route: string) {
  return forwardRef((props: any, ref) => <Link innerRef={ref} to={route} {...props} />);
}

const mapStateToProps = ({ notifications, user }: StateType) => ({
  user,
  notifications
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  onMenuIconClick: (event: React.MouseEvent) => void;
}

interface State {
  drawer: boolean;
  notificationsAnchorEl: any;
  notificationsOpen: boolean;
}

class MenuBase extends Component<Props, State> {
  rootLink = linkGenerator('/');
  coursesLink = linkGenerator('/my/courses');
  certificatesLink = linkGenerator('/my/certificates');
  profileLink = linkGenerator('/profile');

  constructor(props: Props) {
    super(props);
    this.state = {
      drawer: false,
      notificationsAnchorEl: null,
      notificationsOpen: false
    };
  }

  toggleNotifications = (event: React.MouseEvent) => {
    const { currentTarget } = event;
    this.setState(state => ({
      notificationsAnchorEl: currentTarget,
      notificationsOpen: !state.notificationsOpen
    }));
  };

  handleCloseNotifications = () => {
    this.setState(() => ({
      notificationsAnchorEl: null,
      notificationsOpen: false
    }));
  };

  render() {
    const { classes, user, notifications, onMenuIconClick } = this.props;
    const { notificationsAnchorEl, notificationsOpen } = this.state;
    const id = notificationsOpen ? 'notifications-popper' : undefined;
    const bellClasses = notifications.length === 0 ? classes.bellInactive : classes.bellActive;

    return (
      <div className={classes.root}>
        <div className={classes.bannerContainer}>
          <Hidden smDown>
            <div className={classes.canopyBanner}>
              <a href="https://www.canopytax.com/" className={classes.canopyLogoAnchor}>
                <img className={classes.canopyLogo} src={logo} />
              </a>
            </div>
          </Hidden>
        </div>
        <AppBar position="static">
          <Toolbar className={classes.toolbar}>
            <Hidden mdUp>
              <IconButton edge="start" color="inherit" aria-label="Menu" className={classes.menuButton} onClick={onMenuIconClick}>
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Button component={this.rootLink} className={classes.title}>
              CPE & CE COURSES
            </Button>

            <Hidden smDown>
              <div className={classes.navbarLinks}>
                <Button component={this.rootLink}>Discover Courses</Button>
                <Button component={this.coursesLink}>My Courses</Button>
                <Button component={this.certificatesLink}>Certificates</Button>
              </div>
              {user && (
                <>
                  <Button component={this.profileLink}>
                    <Hidden smDown>
                      {user.firstName} {user.lastName}&nbsp;&nbsp;&nbsp;&nbsp;
                    </Hidden>
                    <UserAvatar height={27} width={27} url={user.avatarUrl} />
                  </Button>
                  <IconButton className={bellClasses} aria-describedby={id} onClick={this.toggleNotifications}>
                    <NotificationIcon />
                  </IconButton>
                  <MenuAuthButton />
                </>
              )}
            </Hidden>
          </Toolbar>
        </AppBar>
        {user && (
          <Popper
            id={id}
            open={notificationsOpen}
            anchorEl={notificationsAnchorEl}
            placement="bottom-end"
            className={classes.popper}
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <NotificationsPanel onClose={this.handleCloseNotifications} />
              </Fade>
            )}
          </Popper>
        )}
      </div>
    );
  }
}

export const Menu = withStyles(styles)(connect(mapStateToProps)(MenuBase));
