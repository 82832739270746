import React, { Component } from 'react';

interface Props {
  active: boolean;
  label: string;
  onToggle: Function;
  style: string;
}

export class RichTextEditorButton extends Component<Props> {
  onToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props.onToggle(this.props.style);
  };

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}
