import React, { Component } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../../../core/store';
import { Select, SelfStudyQuestion } from '../../../../../shared';

const styles = createStyles({
  actionButton: {
    minWidth: '48px !important'
  },
  container: {
    display: 'inline-flex',
    verticalAlign: 'top'
  },
  formControl: {
    width: '100%'
  }
});

const mapStateToProps = ({ error }: StateType) => ({
  error
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  courseId?: number;
  question?: any;
}

interface State {
  open: boolean;
  previewQuestion: any | undefined;
  question: any;
  submitting: boolean;
}

class EditQuestionDialogBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      question: {} as any,
      submitting: false,
      previewQuestion: undefined
    };
  }

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    const { question = {} } = newProps;

    if (question.updated !== prevState.question.updated) {
      return { question };
    }
    if (prevState.submitting && newProps.error) {
      return {
        submitting: false
      };
    }

    return {};
  }

  handleAddAnswer = () => {
    const { question } = this.state;
    const { answers, feedback } = question;
    this.setState({
      question: {
        ...question,
        answers: [...answers, ''],
        feedback: [...feedback, '']
      }
    });
  };

  handleArrayChange = (prop: string, index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const values = this.state.question[prop];
    values[index] = event.target.value;

    this.setState(state => ({
      question: {
        ...state.question,
        [prop]: values
      }
    }));
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleFormChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    const key = event.target.id || event.target.name;
    this.setState(state => ({
      question: {
        ...state.question,
        [key]: event.target.value
      }
    }));
  };

  handlePreviewQuestion = () => {
    const { question } = this.state;
    this.setState({
      previewQuestion: question
    });
  };

  handleClosePreviewQuestion = () => {
    this.setState({
      previewQuestion: undefined
    });
  };

  handleRemoveAnswer = (answerIndex: number) => () => {
    const { question } = this.state;
    const { answers, feedback } = question;

    answers.splice(answerIndex, 1);
    feedback.splice(answerIndex, 1);

    this.setState({
      question: {
        ...question,
        answers: [...answers],
        feedback: [...feedback]
      }
    });
  };

  handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { courseId } = this.props;
    const { question: newQuestion } = this.state;
    const { id: questionId, answers, correctAnswer, feedback, question } = newQuestion;

    this.setState({ submitting: true }, () => {
      actions.patchSelfStudyQuestion(courseId, questionId, {
        answers,
        feedback,
        question,
        correctAnswer
      });
    });
  };

  render() {
    const { classes } = this.props;
    const { previewQuestion, question, submitting } = this.state;

    if (!question.id) {
      return null;
    }

    const { answers = [], correctAnswer, feedback = [] } = question;

    const disabled =
      submitting ||
      correctAnswer === undefined ||
      !question.question ||
      !question.answers[0] ||
      !question.answers[1] ||
      !question.feedback[0] ||
      !question.feedback[1];

    return (
      <div className={classes.container}>
        <Button size="small" className={classes.actionButton} onClick={this.handleOpen}>
          <Icon>edit</Icon>
        </Button>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md">
          <form onSubmit={this.handleSave}>
            <DialogTitle id="form-dialog-title">Edit Self Study Question</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div>
                    <TextField
                      id="question"
                      defaultValue={question.question}
                      disabled={submitting}
                      fullWidth
                      label="Question"
                      margin="none"
                      onChange={this.handleFormChange}
                      required={true}
                      type="text"
                    />
                  </div>

                  <Select
                    onChange={this.handleFormChange}
                    label="Correct Answer"
                    name="correctAnswer"
                    value={correctAnswer}
                    options={answers.map((_: any, i: number) => ({
                      id: i,
                      name: i + 1
                    }))}
                    className={classes.formControl}
                  />
                </Grid>
                <Grid item xs={4}>
                  {answers.map((_: any, index: number) => (
                    <div key={index}>
                      <TextField
                        id="answers"
                        defaultValue={question.answers[index]}
                        disabled={submitting}
                        fullWidth
                        label={`Answer ${index + 1}`}
                        margin="none"
                        onChange={this.handleArrayChange('answers', index)}
                        type="text"
                        required={index < 2}
                        InputProps={
                          index < 2
                            ? {}
                            : {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={this.handleRemoveAnswer(index)}>
                                      <Icon>delete</Icon>
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }
                        }
                      />
                    </div>
                  ))}

                  <br />
                  <Button variant="outlined" onClick={this.handleAddAnswer}>
                    Add Answer
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  {feedback.map((_: any, index: number) => (
                    <div key={index}>
                      <TextField
                        id="feedback"
                        defaultValue={question.feedback[index]}
                        disabled={submitting}
                        fullWidth
                        label={`Feedback ${index + 1}`}
                        margin="none"
                        onChange={this.handleArrayChange('feedback', index)}
                        type="text"
                        required={index < 2 || !!answers[index]}
                      />
                    </div>
                  ))}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={submitting} variant="outlined" onClick={this.handleClose}>
                Cancel
              </Button>
              <Button disabled={submitting} variant="outlined" onClick={this.handlePreviewQuestion}>
                Preview
              </Button>
              <Button disabled={disabled} type="submit" color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <SelfStudyQuestion preview={previewQuestion} onClose={this.handleClosePreviewQuestion} />
      </div>
    );
  }
}

export const EditQuestionDialog = connect(mapStateToProps)(withStyles(styles)(EditQuestionDialogBase));
