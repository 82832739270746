import { sortBy } from 'lodash';

import { request } from '../../utilities';
import { parseCourse, parseWebinars } from '../../parse';
import { CREATE_WEBINAR_VIDEO_SUCCESS, REGISTER_WEBINAR_SUCCESS } from '../../constants';
import { StateType } from '../store';

function createErrorState(error: Error) {
  return { error: error.message };
}

/**
 * Create webinar scheduled date.
 *
 */
export async function createWebinarScheduled(state: StateType, _a: any, courseId: number, body: any) {
  const { data, error } = await request('post', `/webinars/${courseId}`, body);
  if (error) {
    return createErrorState(error);
  }

  const { course: { webinarScheduled: prevWebinarScheduled = [] } = {} } = state;
  const webinarScheduled = sortBy([...prevWebinarScheduled, data], 'scheduledOn');
  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    webinarScheduled
  });
  return {
    course
  };
}

/**
 * Create webinar video.
 *
 */
export async function createWebinarVideo(state: StateType, _a: any, courseId: number, url: string) {
  const { data, error } = await request('post', `/webinars/${courseId}/videos`, { url });
  if (error) {
    return createErrorState(error);
  }

  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    webinarVideos: [data]
  });

  return {
    message: CREATE_WEBINAR_VIDEO_SUCCESS,
    course
  };
}

/**
 * Delete webinar scheduled date.
 *
 */
export async function deleteWebinarScheduled(state: StateType, _a: any, courseId: number, webinarId: number) {
  const { error } = await request('delete', `/webinars/${courseId}/webinars/${webinarId}`);
  if (error) {
    return createErrorState(error);
  }

  const { course: { webinarScheduled = [] } = {} } = state;
  const scheduledIndex = webinarScheduled.findIndex(s => s.id === webinarId);
  if (scheduledIndex !== -1) {
    webinarScheduled.splice(scheduledIndex, 1);
  }

  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    webinarScheduled: [...webinarScheduled]
  });

  return {
    course
  };
}

/**
 * Delete webinar video.
 *
 */
export async function deleteWebinarVideo(state: StateType, _a: any, courseId: number) {
  const { error } = await request('delete', `/webinars/${courseId}/videos`);
  if (error) {
    return createErrorState(error);
  }

  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    webinarVideos: []
  });

  return {
    message: 'Webinar video deleted!',
    course
  };
}

/**
 * Search webinars.
 *
 */
export async function getWebinars(_s: StateType, _a: any, params: any) {
  const { data, error, headers } = await request('get', '/webinars', params);
  if (error) {
    return createErrorState(error);
  }
  const webinarsTotal = parseInt(headers['x-count'], 10);
  const webinars = parseWebinars(data);
  return { webinars, webinarsTotal };
}

/**
 * Join webinar.
 *
 */
export async function joinWebinar(_s: StateType, _a: any, courseId: number, webinarId: number) {
  const { error } = await request('post', `/webinars/${courseId}/webinars/${webinarId}/join`, {});
  if (error) {
    return createErrorState(error);
  }

  return {};
}

/**
 * Register for webinar.
 *
 */
export async function registerWebinar(_s: StateType, _a: any, courseId: number, webinarId: number) {
  const { data, error } = await request('post', `/webinars/${courseId}/webinars/${webinarId}/register`, {});

  if (error) {
    return createErrorState(error);
  }

  return {
    message: REGISTER_WEBINAR_SUCCESS,
    googleCalendarLink: data.googleCalendarLink,
    icalAttachmentContent: data.icalAttachmentContent
  };
}

/**
 * Unregister for webinar.
 *
 */
export async function unregisterWebinar(state: StateType, _a: any, courseId: number, webinarId: number, returnMessage = true) {
  const { error } = await request('delete', `/webinars/${courseId}/webinars/${webinarId}/register`, {});

  if (error) {
    return createErrorState(error);
  }

  const { userWebinars = [] } = state;
  const userWebinarIndex = userWebinars.findIndex(web => web.courseId === courseId);

  if (userWebinarIndex >= 0) {
    userWebinars.splice(userWebinarIndex, 1);
  }

  if (returnMessage) {
    return {
      message: "You've successfully unregistered for this webinar.",
      userWebinars: [...userWebinars]
    };
  }

  return {
    userWebinars: [...userWebinars]
  };
}
