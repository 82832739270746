import React, { Component } from 'react';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../core/store';
import { TableGrid } from '../../shared';

import PollingQuestionCreate from './polling-question-create';

const selectActions = () => <PollingQuestionCreate />;

const config = {
  columns: [
    { name: 'question', title: 'Question' },
    { name: 'status', title: 'Status' },
    { name: 'published', title: 'Published' },
    { name: 'publishedByName', title: 'Published By' },
    { name: 'updated', title: 'Last Modified' },
    { name: 'updatedByName', title: 'Last Modified By' },
    { name: 'position', title: 'Position' },
    { name: 'totalResponses', title: 'Total Responses' }
  ],
  columnExtensions: [
    { columnName: 'question', width: 420 },
    { columnName: 'published', width: 180 },
    { columnName: 'publishedByName', width: 200 },
    { columnName: 'updated', width: 180 },
    { columnName: 'updatedByName', width: 200 },
    { columnName: 'status', width: 100 },
    { columnName: 'position', width: 100 },
    { columnName: 'totalResponses', width: 170 }
  ],
  dateColumns: ['published', 'updated'],
  filteringStateColumnExtensions: [{ columnName: 'totalResponses', filteringEnabled: false }],
  selectActions,
  sortingStateColumnExtensions: [{ columnName: 'totalResponses', sortingEnabled: false }],
  urlColumns: ['question'],
  urlColumnsBase: '/admin/polling-questions'
};

const mapStateToProps = ({ pollingQuestions, pollingQuestionsTotal: totalCount, adminColumns }: StateType) => ({
  pollingQuestions,
  totalCount,
  adminColumns
});

type Props = WithProps<typeof mapStateToProps>;

class PollingQuestionListBase extends Component<Props> {
  componentDidMount(): void {
    actions.getAdminColumns();
  }

  exportRows = (method: string, params: any = {}) => {
    actions.exportPollingQuestions(method, params);
  };

  loadRows = (params: any) => {
    actions.getPollingQuestions(params);
  };

  render() {
    const { pollingQuestions, totalCount, adminColumns } = this.props;
    return (
      <TableGrid
        actions={actions}
        adminColumns={adminColumns}
        config={config}
        exportRows={this.exportRows}
        model="pollingQuestions"
        rows={pollingQuestions}
        totalCount={totalCount}
        loadRows={this.loadRows}
      />
    );
  }
}

export const PollingQuestionList = connect(mapStateToProps)(PollingQuestionListBase);
