import { request } from '../../utilities';
import { parseCourse } from '../../parse';
import {
  CREATE_SELF_STUDY_ASSESSMENT,
  CREATE_SELF_STUDY_QUESTION_SUCCESS,
  CREATE_SELF_STUDY_VIDEO_SUCCESS,
  REGISTER_SELF_STUDY_SUCCESS
} from '../../constants';
import { StateType } from '../store';

function createErrorState(error: Error) {
  return { error: error.message };
}

/**
 * Create self study assessment.
 */
export async function createSelfStudyAssessment(state: StateType, _a: any, courseId: number, params: any) {
  const { data, error } = await request('post', `/self-study/${courseId}/assessment`, params);
  if (error) {
    return createErrorState(error);
  }

  const { course: { selfStudyAssessments = [] } = {} } = state;

  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    selfStudyAssessments: [...selfStudyAssessments, data]
  });

  return {
    message: CREATE_SELF_STUDY_ASSESSMENT,
    course
  };
}

/**
 * Create self study question.
 *
 */
export async function createSelfStudyQuestion(state: StateType, _a: any, courseId: number, question: any) {
  const { data, error } = await request('post', `/self-study/${courseId}/questions`, question);
  if (error) {
    return createErrorState(error);
  }

  const { course: { selfStudyQuestions = [] } = {} } = state;
  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    selfStudyQuestions: [...selfStudyQuestions, data]
  });

  return {
    message: CREATE_SELF_STUDY_QUESTION_SUCCESS,
    course
  };
}

/**
 * Create self study video.
 *
 */
export async function createSelfStudyVideo(state: StateType, _a: any, courseId: number, video: any) {
  const { data, error } = await request('post', `/self-study/${courseId}/videos`, video);
  if (error) {
    return createErrorState(error);
  }

  const { course: { selfStudyVideos = [] } = {} } = state;
  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    selfStudyVideos: [...selfStudyVideos, data]
  });

  return {
    message: CREATE_SELF_STUDY_VIDEO_SUCCESS,
    course
  };
}

/**
 * Delete self study assessment.
 *
 */
export async function deleteSelfStudyAssessment(state: StateType, _a: any, courseId: number, questionId: number) {
  const { error } = await request('delete', `/self-study/${courseId}/assessment/${questionId}`, {});
  if (error) {
    return createErrorState(error);
  }

  const { course: { selfStudyAssessments = [] } = {} } = state;
  const questionIndex = selfStudyAssessments.findIndex(s => s.id === questionId);
  if (questionIndex !== -1) {
    selfStudyAssessments.splice(questionIndex, 1);
  }

  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    selfStudyAssessments: [...selfStudyAssessments]
  });

  return {
    message: 'Self study assessment deleted!',
    course
  };
}

/**
 * Delete self study assessment answers.
 *
 */
export async function deleteSelfStudyAssessmentAnswers(state: StateType, _a: any, userId: number, courseId: number) {
  const status = 'pending';
  const { error } = await request('delete', `/users/${userId}/self-study/${courseId}/assessment`, {});

  if (error) {
    return createErrorState(error);
  }

  const { userSelfStudies = [] } = state;
  const userSelfStudy = userSelfStudies.find(selfStudy => selfStudy.courseId === courseId);

  if (userSelfStudy) {
    userSelfStudy.status = status;
    userSelfStudy.answers = [];
    return {
      message: 'You can now retake the self assessment.',
      userSelfStudies: [...userSelfStudies]
    };
  }

  return {};
}

/**
 * Delete self study question.
 *
 */
export async function deleteSelfStudyQuestion(state: StateType, _a: any, courseId: number, questionId: number) {
  const { error } = await request('delete', `/self-study/${courseId}/questions/${questionId}`);
  if (error) {
    return createErrorState(error);
  }

  const { course: { selfStudyQuestions = [] } = {} } = state;
  const questionIndex = selfStudyQuestions.findIndex(s => s.id === questionId);
  if (questionIndex !== -1) {
    selfStudyQuestions.splice(questionIndex, 1);
  }

  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    selfStudyQuestions: [...selfStudyQuestions]
  });

  return {
    message: 'Self study question deleted!',
    course
  };
}

/**
 * Delete self study video.
 *
 */
export async function deleteSelfStudyVideo(state: StateType, _a: any, courseId: number, videoId: number) {
  const { error } = await request('delete', `/self-study/${courseId}/videos/${videoId}`);
  if (error) {
    return createErrorState(error);
  }

  const { course: { selfStudyVideos = [] } = {} } = state;
  const videoIndex = selfStudyVideos.findIndex(s => s.id === videoId);
  if (videoIndex !== -1) {
    selfStudyVideos.splice(videoIndex, 1);
  }

  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    selfStudyVideos: [...selfStudyVideos]
  });

  return {
    message: 'Self study video deleted!',
    course
  };
}

/**
 * Patch self study assessment.
 *
 */
export async function patchSelfStudyAssessment(state: StateType, _a: any, courseId: number, questionId: number, params: any) {
  const { data, error } = await request('patch', `/self-study/${courseId}/assessment/${questionId}`, params);
  if (error) {
    return createErrorState(error);
  }

  const { course: { selfStudyAssessments = [] } = {} } = state;
  const selfStudyAssessmentIndex = selfStudyAssessments.findIndex(v => v.id === questionId);

  if (selfStudyAssessmentIndex !== -1) {
    selfStudyAssessments.splice(selfStudyAssessmentIndex, 1, data);
  }

  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    selfStudyAssessments: [...selfStudyAssessments]
  });

  return {
    course,
    message: 'Self study assessment updated!'
  };
}

/**
 * Patch self study question.
 *
 */
export async function patchSelfStudyQuestion(state: StateType, _a: any, courseId: number, questionId: number, params: any) {
  const { data, error } = await request('patch', `/self-study/${courseId}/questions/${questionId}`, params);
  if (error) {
    return createErrorState(error);
  }

  const { course: { selfStudyQuestions = [] } = {} } = state;
  const selfStudyQuestionIndex = selfStudyQuestions.findIndex(v => v.id === questionId);

  if (selfStudyQuestionIndex !== -1) {
    selfStudyQuestions.splice(selfStudyQuestionIndex, 1, data);
  }

  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    selfStudyQuestions
  });

  return {
    course,
    message: 'Self study question updated!'
  };
}

/**
 * Patch self study video.
 *
 */
export async function patchSelfStudyVideo(state: StateType, _a: any, courseId: number, videoId: number, params: any) {
  const { data, error } = await request('patch', `/self-study/${courseId}/videos/${videoId}`, params);
  if (error) {
    return createErrorState(error);
  }

  const { course: { selfStudyVideos = [] } = {} } = state;
  const selfStudyVideoIndex = selfStudyVideos.findIndex(v => v.id === videoId);

  if (selfStudyVideoIndex !== -1) {
    selfStudyVideos.splice(selfStudyVideoIndex, 1, data);
  }

  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    selfStudyVideos
  });

  return {
    course,
    message: 'Self study video updated!'
  };
}

/**
 * Register in self study.
 *
 */
export async function registerSelfStudy(_s: StateType, _a: any, courseId: number) {
  const { error } = await request('post', `/self-study/${courseId}/register`, {});

  if (error) {
    return createErrorState(error);
  }

  return {
    message: REGISTER_SELF_STUDY_SUCCESS
  };
}

/**
 * Unregister from self study.
 *
 */
export async function unregisterSelfStudy(state: StateType, _a: any, courseId: number, showMessage = true) {
  const { error } = await request('delete', `/self-study/${courseId}/register`, {});

  if (error) {
    return createErrorState(error);
  }

  const { userSelfStudies = [] } = state;
  const userSelfStudyIndex = userSelfStudies.findIndex(study => study.courseId === courseId);

  if (userSelfStudyIndex >= 0) {
    userSelfStudies.splice(userSelfStudyIndex, 1);
  }

  if (showMessage) {
    return {
      message: "You've successfully unregistered for this self study.",
      userSelfStudies: [...userSelfStudies]
    };
  }

  return {
    userSelfStudies: [...userSelfStudies]
  };
}
