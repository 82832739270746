import React, { forwardRef } from 'react';
import { addMinutes, format, isAfter } from 'date-fns';
import { Link } from 'react-router-dom';
import { Button, Icon, Tooltip, WithStyles, withStyles } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { connect, StateType } from '../core/store';
import { studyToGradient } from '../utilities';
import { UserWebinarType } from '../types';
import styles from '../shared/components/course-panel/course-panel.style';

const webinarIcon = require('../../images/icons/webinar.png');

const mapStateToProps = ({ fieldOfStudies }: StateType) => ({
  fieldOfStudies
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  userWebinar: UserWebinarType;
  onRemove: () => void;
}

const WebinarPanelBase: React.FunctionComponent<Props> = ({ classes, fieldOfStudies, userWebinar, onRemove }) => {
  const { course } = userWebinar;
  if (!course) {
    return null;
  }

  const fieldOfStudy = fieldOfStudies.find(s => s.id === course.fieldOfStudyId);
  if (!fieldOfStudy) {
    return null;
  }

  const gradientStyle = studyToGradient(fieldOfStudy);
  const canJoin = userWebinar ? isAfter(addMinutes(new Date(), 10), new Date(userWebinar.scheduledOn)) : false;
  const webinarUrl = canJoin ? `/courses/${course.id}/webinar` : '#';
  const webinarLink = forwardRef((props: any, ref) => <Link innerRef={ref} to={webinarUrl} {...props} />);
  const joinButton = canJoin ? (
    <Button component={webinarLink} disabled={!canJoin} className={classes.courseDetailsButton} color="primary" variant="contained">
      Start Webinar
    </Button>
  ) : (
    <Tooltip placement="top" title="Please try to join again within 10 minutes of the webinar scheduled time.">
      <div>
        <Button component={webinarLink} disabled={!canJoin} className={classes.courseDetailsButton} color="primary" variant="contained">
          Start Webinar
        </Button>
      </div>
    </Tooltip>
  );

  return (
    <div className={classes.panel}>
      <div className={`${classes.panelHeader}`} style={gradientStyle}>
        <Icon className={classes.closeIcon} onClick={onRemove}>
          close
        </Icon>
        {fieldOfStudy && (
          <div>
            {canJoin && (
              <Link className={classes.fieldOfStudy} to={webinarUrl}>
                {fieldOfStudy.name}
              </Link>
            )}
            {!canJoin && <span className={classes.fieldOfStudy}>{fieldOfStudy.name}</span>}
          </div>
        )}
        <div>
          {canJoin && (
            <Link className={classes.headerLink} to={webinarUrl}>
              {course.name}
            </Link>
          )}
          {!canJoin && <span className={classes.headerLink}>{course.name}</span>}
        </div>
        {course.courseSeriesId && (
          <div className={classes.part}>
            Part {course.courseSeriesPart} of {course.seriesPartsCount}
          </div>
        )}
      </div>
      <div className={classes.panelBody}>
        <p className={classes.scheduledOn}>
          <b>{format(new Date(userWebinar.scheduledOn), 'EEE, MMMM d, h:mm a')}</b>
        </p>
        <Link className={classes.changeRegistration} to={`/courses/${course.id}`}>
          Change Course Registration
        </Link>

        <p className={classes.creditType}>
          {course.credittype.join(' & ')} | {course.credits} Hour{course.credits > 1 && <span>s</span>}
        </p>

        {joinButton}

        <div className={classes.linkContainer}>
          {canJoin && (
            <Link className={classes.link} to={webinarUrl}>
              <img src={webinarIcon} />
              <br />
              Webinar
            </Link>
          )}
          {!canJoin && (
            <span className={classes.link}>
              <img src={webinarIcon} />
              <br />
              Webinar
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export const WebinarPanel = connect(mapStateToProps)(withStyles(styles)(WebinarPanelBase));
