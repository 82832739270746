/**
 * Convert duration in seconds to pretty time HH:MM:SS or MM:SS.
 *
 * @param {number} duration
 * @return {string} Duration in HH::MM:SS or MM:SS
 */
export default function prettyTime(duration: string) {
  const secs = parseInt(duration, 10);
  const hours = Math.floor(secs / 3600) % 24;
  const minutes = Math.floor(secs / 60) % 60;
  const seconds = secs % 60;
  return [hours, minutes, seconds]
    .map(v => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
}
