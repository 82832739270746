import React from 'react';
import { createStyles, Grid, Icon, Paper, Theme, WithStyles, withStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { UserAvatar } from '../../shared';

const styles = (theme: Theme) =>
  createStyles({
    avatarContainer: {
      textAlign: 'center'
    },
    biography: {
      lineHeight: 1.8,
      fontSize: 21,
      width: '70%'
    },
    cancel: {
      color: theme.palette.common.white,
      fontSize: 48
    },
    cancelContainer: {
      textAlign: 'right',
      marginBottom: 40
    },
    container: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      margin: 40,
      padding: 15
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 5,
      marginTop: 30,
      marginBottom: 30,
      width: 180
    },
    grid: {
      marginBottom: 40
    }
  });

interface Props extends WithStyles<typeof styles> {
  course: any;
}

const CourseInstructorBase: React.FunctionComponent<Props> = ({ classes, course }) => {
  const { instructor } = course;

  if (!instructor) {
    return null;
  }

  const title = `${instructor.firstName} ${instructor.lastName} - ${course.name}`;

  return (
    <Paper className={classes.container}>
      <Helmet title={title} />
      <div className={classes.cancelContainer}>
        <Link to={`/courses/${course.id}`}>
          <Icon className={classes.cancel}>close</Icon>
        </Link>
      </div>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={4}>
          <div className={classes.avatarContainer}>
            <UserAvatar height={240} width={240} url={instructor.avatarUrl} />
          </div>
        </Grid>
        <Grid item xs={8}>
          <h1>
            {instructor.firstName} {instructor.lastName}
          </h1>
          <div className={classes.divider} />
          <p className={classes.biography}>
            {instructor.biography.split('\n').map((item: string, key: number) => (
              <span key={key}>
                {item}
                <br />
              </span>
            ))}
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const CourseInstructor = withStyles(styles)(CourseInstructorBase);
