import React, { Component } from 'react';
import { format } from 'date-fns';
import { reduce } from 'lodash';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Grid,
  Radio,
  TextField,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  withWidth
} from '@material-ui/core';
import { isWidthDown, WithWidthProps } from '@material-ui/core/withWidth';

import { actions } from '../../../../core/store';
import { CourseType, UserSelfStudyType, UserWebinarType } from '../../../../types';

const styles = (theme: Theme) =>
  createStyles({
    courseName: {
      fontWeight: 700
    },
    dialogForm: {
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column'
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 3,
      margin: '10px 0',
      width: 112
    },
    feedbackHeader: {
      marginTop: 18
    },
    modalActions: {
      justifyContent: 'flex-start'
    },
    question: {
      padding: '10px 0',
      '&:not(:last-child)': {
        borderBottom: '2px #D0D0D0 solid'
      }
    },
    textField: {
      marginTop: 0
    },
    radioContainer: {
      textAlign: 'center',
      marginRight: 32
    }
  });

interface Props extends WithStyles<typeof styles>, WithWidthProps {
  course: CourseType;
  deliveryMethod: string;
  registration: UserSelfStudyType | UserWebinarType;
  onClose: () => void;
  open: boolean;
}

interface State {
  additionalFeedback: string | undefined;
  audioVideoEffective: string | undefined;
  courseRating: string | undefined;
  handoutMaterialsSatisfactory: string | undefined;
  facilitiesAppropriate: string | undefined;
  instructorsEffective: string | undefined;
  learningObjectivesMet: string | undefined;
  numberOfHours: string | undefined;
  programMaterialsRelevant: string | undefined;
  requirementsAppropriate: string | undefined;
  timeAllotedAdequate: string | undefined;
}

class CourseFeedbackDialogBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      additionalFeedback: '',
      audioVideoEffective: undefined,
      courseRating: undefined,
      handoutMaterialsSatisfactory: undefined,
      facilitiesAppropriate: undefined,
      instructorsEffective: undefined,
      learningObjectivesMet: undefined,
      numberOfHours: undefined,
      programMaterialsRelevant: undefined,
      requirementsAppropriate: undefined,
      timeAllotedAdequate: undefined
    };
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as any);
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { onClose } = this.props;

    this.submitCourseRating();
    this.submitCourseFeedback();

    onClose();
  };

  private submitCourseRating() {
    const { deliveryMethod, course } = this.props;
    if (!this.state.courseRating) {
      return;
    }
    const courseRating = parseInt(this.state.courseRating, 10);

    if (deliveryMethod === 'self-study') {
      actions.createSelfStudyRating(course.id, courseRating);
    } else if (deliveryMethod === 'webinar') {
      actions.createWebinarRating(course.id, courseRating);
    }
  }

  private submitCourseFeedback() {
    const { course } = this.props;
    const whitelist = [
      'audioVideoEffective',
      'handoutMaterialsSatisfactory',
      'facilitiesAppropriate',
      'instructorsEffective',
      'learningObjectivesMet',
      'programMaterialsRelevant',
      'requirementsAppropriate',
      'timeAllotedAdequate'
    ];

    const { additionalFeedback, numberOfHours } = this.state;
    const feedback: Record<string, any> = { additionalFeedback, numberOfHours };
    whitelist.forEach(prop => {
      // @ts-ignore
      if (this.state[prop]) {
        // @ts-ignore
        feedback[prop] = parseInt(this.state[prop], 10);
      }
    });

    const feedbackEmpty = reduce(
      feedback,
      (result, value) => {
        if (!result) {
          return result;
        }
        return !value;
      },
      true
    );

    if (feedbackEmpty) {
      return;
    }
    actions.createCourseFeedback(course.id, feedback);
  }

  render() {
    const { classes, course, deliveryMethod, onClose, open, registration, width } = this.props;
    const ratings = [
      { rating: '1', label: '1' },
      { rating: '2', label: '2' },
      { rating: '3', label: '3' },
      { rating: '4', label: '4' },
      { rating: '5', label: '5' },
      { rating: '0', label: 'NA' }
    ];
    const {
      additionalFeedback,
      audioVideoEffective,
      courseRating,
      handoutMaterialsSatisfactory,
      facilitiesAppropriate,
      instructorsEffective,
      learningObjectivesMet,
      programMaterialsRelevant,
      numberOfHours,
      requirementsAppropriate,
      timeAllotedAdequate
    } = this.state;

    const disabled =
      !additionalFeedback &&
      !audioVideoEffective &&
      !courseRating &&
      !handoutMaterialsSatisfactory &&
      !facilitiesAppropriate &&
      !instructorsEffective &&
      !learningObjectivesMet &&
      !programMaterialsRelevant &&
      !numberOfHours &&
      !requirementsAppropriate &&
      !timeAllotedAdequate;

    const fullScreen = isWidthDown('sm', width!);

    return (
      <Dialog open={open} onClose={onClose} fullScreen={fullScreen} maxWidth={fullScreen ? false : 'sm'}>
        <DialogTitle>Continuing Education Feedback Form</DialogTitle>
        <form onSubmit={this.handleSubmit} className={classes.dialogForm}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Typography variant="body2" gutterBottom>
                  CE Provider: Canopy Tax
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Course Name: {course.name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  IRS Course: {course.irsId}
                </Typography>
                <Typography variant="body2">Date of Completion: {format(new Date(), 'MMM, d, yyyy')}</Typography>
                <div className={classes.divider} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.courseName} gutterBottom>
                  {course.name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Thank you for participating in this program! Please help us know what we&#39;re doing well and how we can improve your
                  continuing education experience by offering your feedback below.
                </Typography>

                <Typography variant="h5" className={classes.feedbackHeader}>
                  Feedback on the Presenter & Course Content
                </Typography>

                <Typography variant="body2">(Please rate on a scale from 1-5, with 5 being the highest)</Typography>

                {!registration.rated && (
                  <div className={classes.question}>
                    <Typography variant="h6">Course overall</Typography>
                    <FormGroup row>
                      {ratings.map(r => (
                        <div className={classes.radioContainer} key={r.rating}>
                          <Radio checked={courseRating === r.rating} onChange={this.handleChange} value={r.rating} name="courseRating" />
                          <br />
                          {r.label}
                        </div>
                      ))}
                    </FormGroup>
                  </div>
                )}
                {!registration.feedback && (
                  <>
                    <div className={classes.question}>
                      <Typography variant="h6">Were individual instructors knowledgeable and effective?</Typography>
                      <FormGroup row>
                        {ratings.map(r => (
                          <div className={classes.radioContainer} key={r.rating}>
                            <Radio
                              checked={instructorsEffective === r.rating}
                              onChange={this.handleChange}
                              value={r.rating}
                              name="instructorsEffective"
                            />
                            <br />
                            {r.label}
                          </div>
                        ))}
                      </FormGroup>
                    </div>
                    <div className={classes.question}>
                      <Typography variant="h6">Were stated learning objectives met?</Typography>
                      <FormGroup row>
                        {ratings.map(r => (
                          <div className={classes.radioContainer} key={r.rating}>
                            <Radio
                              checked={learningObjectivesMet === r.rating}
                              onChange={this.handleChange}
                              value={r.rating}
                              name="learningObjectivesMet"
                            />
                            <br />
                            {r.label}
                          </div>
                        ))}
                      </FormGroup>
                    </div>
                    <div className={classes.question}>
                      <Typography variant="h6">Were the prerequisite requirements appropriate and sufficient?</Typography>
                      <FormGroup row>
                        {ratings.map(r => (
                          <div className={classes.radioContainer} key={r.rating}>
                            <Radio
                              checked={requirementsAppropriate === r.rating}
                              onChange={this.handleChange}
                              value={r.rating}
                              name="requirementsAppropriate"
                            />
                            <br />
                            {r.label}
                          </div>
                        ))}
                      </FormGroup>
                    </div>
                    <div className={classes.question}>
                      <Typography variant="h6">Was the time allowed to learning adequate?</Typography>
                      <FormGroup row>
                        {ratings.map(r => (
                          <div className={classes.radioContainer} key={r.rating}>
                            <Radio
                              checked={timeAllotedAdequate === r.rating}
                              onChange={this.handleChange}
                              value={r.rating}
                              name="timeAllotedAdequate"
                            />
                            <br />
                            {r.label}
                          </div>
                        ))}
                      </FormGroup>
                    </div>
                    <div className={classes.question}>
                      <Typography variant="h6">
                        Were program materials accurate, relevant and did they contribute to the achievement of the learning objectives?
                      </Typography>
                      <FormGroup row>
                        {ratings.map(r => (
                          <div className={classes.radioContainer} key={r.rating}>
                            <Radio
                              checked={programMaterialsRelevant === r.rating}
                              onChange={this.handleChange}
                              value={r.rating}
                              name="programMaterialsRelevant"
                            />
                            <br />
                            {r.label}
                          </div>
                        ))}
                      </FormGroup>
                    </div>
                    <div className={classes.question}>
                      <Typography variant="h6">Were the facilities / equipment appropriate?</Typography>
                      <FormGroup row>
                        {ratings.map(r => (
                          <div className={classes.radioContainer} key={r.rating}>
                            <Radio
                              checked={facilitiesAppropriate === r.rating}
                              onChange={this.handleChange}
                              value={r.rating}
                              name="facilitiesAppropriate"
                            />
                            <br />
                            {r.label}
                          </div>
                        ))}
                      </FormGroup>
                    </div>
                    <div className={classes.question}>
                      <Typography variant="h6">Were the audio and video materials effective?</Typography>
                      <FormGroup row>
                        {ratings.map(r => (
                          <div className={classes.radioContainer} key={r.rating}>
                            <Radio
                              checked={audioVideoEffective === r.rating}
                              onChange={this.handleChange}
                              value={r.rating}
                              name="audioVideoEffective"
                            />
                            <br />
                            {r.label}
                          </div>
                        ))}
                      </FormGroup>
                    </div>
                    <div className={classes.question}>
                      <Typography variant="h6">Were the handout materials satisfactory?</Typography>
                      <FormGroup row>
                        {ratings.map(r => (
                          <div className={classes.radioContainer} key={r.rating}>
                            <Radio
                              checked={handoutMaterialsSatisfactory === r.rating}
                              onChange={this.handleChange}
                              value={r.rating}
                              name="handoutMaterialsSatisfactory"
                            />
                            <br />
                            {r.label}
                          </div>
                        ))}
                      </FormGroup>
                    </div>
                    {deliveryMethod === 'self-study' && (
                      <div className={classes.question}>
                        <Typography variant="h6">Number of hours to complete course?</Typography>
                        <TextField
                          className={classes.textField}
                          id="numberOfHours"
                          type="number"
                          margin="normal"
                          name="numberOfHours"
                          placeholder="Number of Hours"
                          onChange={this.handleChange}
                        />
                      </div>
                    )}
                    <div className={classes.question}>
                      <Typography variant="h6">
                        Please provide any additional feedback about the program strengths/weaknesses, and areas we can improve
                      </Typography>
                      <TextField
                        className={classes.textField}
                        id="additionalFeedback"
                        fullWidth={true}
                        margin="normal"
                        multiline
                        name="additionalFeedback"
                        onChange={this.handleChange}
                        placeholder="Your Answer"
                        rows={10}
                      />
                      <br />
                      <br />
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions classes={{ root: classes.modalActions }}>
            <Button color="primary" disabled={disabled} size="large" type="submit" variant="contained">
              Submit
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export const CourseFeedbackDialog = withStyles(styles)(withWidth()(CourseFeedbackDialogBase));
