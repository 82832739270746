/**
 * Application constants.
 */

export const CREATE_COURSE_SUCCESS = 'Course created!';

export const CREATE_SELF_STUDY_VIDEO_SUCCESS = 'Self study video created!';

export const CREATE_SELF_STUDY_QUESTION_SUCCESS = 'Self study question created!';

export const CREATE_SELF_STUDY_ASSESSMENT = 'Self study assessment created';

export const CREATE_WEBINAR_VIDEO_SUCCESS = 'Webinar video created!';

export const SAVE_COURSE_SERIES_SUCCESS = 'Course series saved!';

export const CREDIT_TYPES = ['CE', 'CPE'];

export const DELIVERY_METHODS = [
  {
    id: 'self-study',
    name: 'Self Study'
  },
  {
    id: 'webinar',
    name: 'Webinar'
  }
];

export const IRS_STATUSES = [
  {
    id: 'pending',
    name: 'Pending'
  },
  {
    id: 'success',
    name: 'Success'
  },
  {
    id: 'failed',
    name: 'Failed'
  }
];

export const LEVELS = [
  {
    id: 'beginner',
    name: 'Beginner'
  },
  {
    id: 'intermediate',
    name: 'Intermediate'
  },
  {
    id: 'advanced',
    name: 'Advanced'
  }
];

export const LOGIN_URL = 'https://www.canopytax.com/courses/login';

export const LOGOUT_URL = 'https://sso.canopytax.com/logout';

export const PATCH_COURSE_SUCCESS = 'Course updated!';

export const POLLING_QUESTION_DISPLAY_DURATION = 30;

export const REGISTER_WEBINAR_SUCCESS = 'Webinar registration successful!';

export const REGISTER_SELF_STUDY_SUCCESS = 'Self study registration successful!';

export const LEARNER_SAVE_COURSE_SUCCESS = 'Course saved succesfully!';
export const LEARNER_UNSAVE_COURSE_SUCCESS = 'Course removed from saved courses list succesfully!';

export const UPDATE_POLLING_QUESTION_SUCCESS = 'The polling question has been successfully updated.';

export const LEAVE_COURSE_EDIT_MESSAGE = 'Unsaved changes will be discarded.';

export const WEBINAR_MAX_MISSED_SECONDS_PER_CREDIT = 300;

export const WEBINAR_MAX_AUTOSCHEDULE_DAYS = 28;
