import React, { Component } from 'react';
import { Button, createStyles, Dialog, Grid, Icon, WithStyles, withStyles } from '@material-ui/core';

import { Course, SelfStudy, Webinar } from '../../../../../course';
import { CoursePanel } from '../../../../../shared';
import { CourseType } from '../../../../../types';

const styles = createStyles({
  button: {
    marginRight: 20
  },
  container: {
    display: 'inline-flex'
  },
  closeContainer: {
    textAlign: 'right'
  },
  coursePanelContainer: {
    margin: '0px 40px'
  }
});

interface Props extends WithStyles<typeof styles> {
  course: CourseType;
  disabled: boolean;
  preview: string;
}

interface State {
  open: boolean;
}

class Preview extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, course, disabled, preview } = this.props;
    const { open } = this.state;

    return (
      <span>
        <Button className={classes.button} variant="outlined" disabled={disabled} onClick={this.handleOpen}>
          Preview
        </Button>
        <Dialog fullScreen open={open} onClose={this.handleClose}>
          <div className={classes.closeContainer}>
            <Button onClick={this.handleClose}>
              <Icon>close</Icon>
            </Button>
          </div>

          {preview === 'course.component.tsx' && (
            <div>
              <Course course={course} preview={true} />
              <br />
              <br />
              <div className={classes.coursePanelContainer}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <CoursePanel course={course} preview={true} />
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
          {preview === 'webinar' && <Webinar course={course} preview={true} />}
          {preview === 'self-study' && <SelfStudy course={course} preview={true} />}
        </Dialog>
      </span>
    );
  }
}

export default withStyles(styles)(Preview);
