import { UserAnswerType, UserSavedCourseType, UserSelfStudyType, UserType, UserWebinarType } from '../types';
import { array, boolean, number, string } from '../utilities/parse';

import { parseCourse } from './course';

/**
 * User related parsing functions for API responses.
 */
export function parseUser(input: any): UserType {
  return {
    id: number(input, 'id'),
    name: string(input, 'name'), // TODO: fix - this has to be cast because url is defined on UserType
    avatarUrl: string(input, 'avatarUrl'),
    certificatesGranted: number(input, 'certificatesGranted'),
    created: string(input, 'created'),
    email: string(input, 'email'),
    firstName: string(input, 'firstName'),
    firstNameOnCertificate: string(input, 'firstNameOnCertificate'),
    hubspotUserId: number(input, 'hubspotUserId'),
    irsEnabled: boolean(input, 'irsEnabled'),
    lastName: string(input, 'lastName'),
    lastNameOnCertificate: string(input, 'lastNameOnCertificate'),
    lastLogin: string(input, 'lastLogin'),
    ptin: number(input, 'ptin'),
    registrations: number(input, 'registrations'),
    role: string(input, 'role'),
    updated: string(input, 'updated'),
    url: `/profile/${input.id}`
  } as any;
}

export function parseUserSelfStudyAnswer(input: any): UserAnswerType {
  return {
    id: number(input, 'id'),
    position: number(input, 'position'),
    selfStudyQuestionId: number(input, 'selfStudyQuestionId')
  };
}

export function parseUserSelfStudyAnswers(input: any[]): UserAnswerType[] {
  return input.map(row => parseUserSelfStudyAnswer(row));
}

export function parseUsers(input: Record<string, any>[]): UserType[] {
  return input.map(row => parseUser(row));
}

export function parseUserSelfStudy(input: any): UserSelfStudyType {
  return {
    id: number(input, 'id'),
    answers: array(input, 'answers', parseUserSelfStudyAnswer), // TODO: fix - this has to be cast because url is defined on UserSelfStudyType
    course: input.course ? parseCourse(input.course) : undefined,
    courseId: number(input, 'courseId'),
    feedback: boolean(input, 'feedback'),
    score: number(input, 'score'),
    status: string(input, 'status'),
    rated: boolean(input, 'rated'),
    userId: number(input, 'userId')
  } as any;
}

export function parseUserSelfStudies(input: Record<string, any>[]): UserSelfStudyType[] {
  return input.map(row => parseUserSelfStudy(row));
}

export function parseUserWebinar(input: any): UserWebinarType {
  return {
    id: number(input, 'id'),
    course: input.course ? parseCourse(input.course) : undefined,
    courseId: number(input, 'courseId'),
    feedback: boolean(input, 'feedback'),
    joined: string(input, 'joined'),
    rated: boolean(input, 'rated'),
    scheduledOn: string(input, 'scheduledOn'),
    status: string(input, 'status'),
    webinarId: number(input, 'webinarId'),
    userId: number(input, 'userId')
  };
}

export function parseUserWebinars(input: any[]): UserWebinarType[] {
  return input.map(row => parseUserWebinar(row));
}

export function parseUserSavedCourse(input: any): UserSavedCourseType {
  return {
    id: number(input, 'id'),
    userId: number(input, 'userId'),
    courseId: number(input, 'courseId'),
    course: input.course ? parseCourse(input.course) : undefined
  };
}

export function parseUserSavedCourses(input: any[]): UserSavedCourseType[] {
  return input.map(row => parseUserSavedCourse(row));
}
