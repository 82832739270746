import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const CourseInstructionsDialog: React.FunctionComponent<Props> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} scroll="paper" aria-labelledby="instructions-dialog-title">
      <DialogTitle id="instructions-dialog-title">Course Instructions</DialogTitle>
      <DialogContent>
        <h4>Requirements for Credit</h4>
        <p>
          In order to receive full credit for this course, you must complete the final assessment with a minimum passing score of at least
          70 percent. You may retake the final assessment if needed.
        </p>
        <p>
          This course also includes several other review questions that will help reinforce the learning material. In order to receive
          credit, this course must be completed within one year of the date of enrollment. Your certificate of completion will be delivered
          after successful completion of the final assessment.
        </p>
        <p>If you have additional questions about this, please see our terms and conditions, and privacy policies.</p>
        <h4>Navigating this Course</h4>
        <p>
          On the left side of the screen, you will see the course navigation, or course index, which gives an overview of the entire course,
          broken up into sections. At the end of each section is a review question that will give you feedback on the material, as well as
          informing you of the correct selection. As you complete a section and review question, the next will become accessible. You can
          navigate back to each section with the left-hand menu.
        </p>
        <p>
          After each section and review question, select the next section on the left. Continue this process until you come to the final
          assessment. When taking the final assessment, select an answer and choose “Next” to move to the next question. After all questions
          are answered, you will receive a score. If you have scored above 70%, you will pass and complete the course.
        </p>
        <p>Upon completion, you will have an opportunity to rate the course and provide feedback.</p>
        <h4>Other Resources</h4>
        <p>
          A link to our glossary of terms is located under the “Resources” section, as well as other supporting materials (including the
          slide deck).
        </p>
        <p>
          You can ask questions at the bottom of your screen, or head directly over to the Canopy Community to talk with other
          practitioners/instructors.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
