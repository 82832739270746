import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../core/store';
import { TableGrid } from '../shared';
import { CertificateType } from '../../types';

const config = {
  columns: [
    { name: 'id', title: 'ID' },
    {
      name: 'firstName',
      title: 'First Name',
      getCellValue: (row: CertificateType) => (row.user ? row.user.firstName : undefined)
    },
    {
      name: 'lastName',
      title: 'Last Name',
      getCellValue: (row: CertificateType) => (row.user ? row.user.lastName : undefined)
    },
    {
      name: 'firstNameOnCertificate',
      title: 'First Name on Cert',
      getCellValue: (row: CertificateType) => (row.user ? row.user.firstNameOnCertificate : undefined)
    },
    {
      name: 'lastNameOnCertificate',
      title: 'Last Name on Cert',
      getCellValue: (row: CertificateType) => (row.user ? row.user.lastNameOnCertificate : undefined)
    },
    {
      name: 'email',
      title: 'Email',
      getCellValue: (row: CertificateType) => (row.user ? row.user.email : undefined)
    },
    {
      name: 'ptin',
      title: 'PTIN',
      getCellValue: (row: CertificateType) => (row.user ? row.user.ptin : undefined)
    },
    { name: 'created', title: 'Date Granted' },
    {
      name: 'name',
      title: 'Course Name',
      getCellValue: (row: CertificateType) => (row.course ? row.course.name : undefined)
    },
    {
      name: 'courseSeriesPart',
      title: 'Series',
      getCellValue: (row: CertificateType) => (row.course && row.course.courseSeriesId ? row.course.courseSeriesPart : null)
    },
    { name: 'deliveryMethod', title: 'Delivery Method' },
    {
      name: 'credittype',
      title: 'Credit Type',
      getCellValue: (row: CertificateType) => (row.course ? row.course.credittype : undefined)
    },
    {
      name: 'credits',
      title: 'Credits',
      getCellValue: (row: CertificateType) => (row.course ? row.course.credits : undefined)
    },
    {
      name: 'irsId',
      title: 'IRS ID',
      getCellValue: (row: CertificateType) => (row.course ? row.course.irsId : undefined)
    },
    { name: 'irsStatus', title: 'IRS Status' },
    { name: 'irsStatusMessage', title: 'Status Error' },
    {
      name: 'ctecId',
      title: 'CTEC ID',
      getCellValue: (row: CertificateType) => (row.course ? row.course.ctecId : undefined)
    },
    {
      name: 'instructor',
      title: 'Instructor',
      getCellValue: (row: CertificateType) => (row.course && row.course.instructor ? row.course.instructor : undefined)
    },
    {
      name: 'courseId',
      title: 'Course ID',
      getCellValue: (row: CertificateType) => (row.course ? row.course.id : undefined)
    },
    {
      name: 'fieldOfStudyId',
      title: 'Field of Study',
      getCellValue: (row: CertificateType) => (row.course ? row.course.fieldOfStudyId : undefined)
    },
    {
      name: 'categoryId',
      title: 'Category',
      getCellValue: (row: CertificateType) => (row.course ? row.course.categoryId : undefined)
    }
  ],

  columnExtensions: [
    { columnName: 'categoryId', width: 180 },
    { columnName: 'courseId', width: 100 },
    { columnName: 'created', width: 180 },
    { columnName: 'credits', width: 90 },
    { columnName: 'credittype', width: 120 },
    { columnName: 'ctecId', width: 170 },
    { columnName: 'deliveryMethod', width: 120 },
    { columnName: 'email', width: 320 },
    { columnName: 'fieldOfStudyId', width: 200 },
    { columnName: 'firstName', width: 170 },
    { columnName: 'lastName', width: 170 },
    { columnName: 'id', width: 100 },
    { columnName: 'instructor', width: 200 },
    { columnName: 'irsId', width: 170 },
    { columnName: 'irsStatus', width: 120 },
    { columnName: 'irsStatusMessage', width: 200 },
    { columnName: 'name', width: 280 },
    { columnName: 'firstNameOnCertificate', width: 120 },
    { columnName: 'lastNameOnCertificate', width: 120 },
    { columnName: 'ptin', width: 100 }
  ],
  dateColumns: ['created'],
  filteringStateColumnExtensions: [
    { columnName: 'instructor', filteringEnabled: false },
    { columnName: 'courseSeriesPart', filteringEnabled: false }
  ],
  sortingStateColumnExtensions: [
    { columnName: 'instructor', sortingEnabled: false },
    { columnName: 'courseSeriesPart', sortingEnabled: false }
  ]
};

const mapStateToProps = ({ adminColumns, certificates, certificatesTotal: totalCount }: StateType) => ({
  adminColumns,
  certificates,
  totalCount
});

type Props = WithProps<typeof mapStateToProps>;

class CertificateListBase extends Component<Props> {
  componentDidMount(): void {
    actions.getAdminColumns();
  }

  exportRows = (method: string, params: Record<string, any> = {}) => {
    actions.exportCertificates(method, params);
  };

  loadRows = (params: Record<string, any>) => {
    actions.getCertificates(params);
  };

  render() {
    const { certificates, totalCount, adminColumns } = this.props;
    return (
      <div>
        <Helmet title="Webinar List" />
        <TableGrid
          actions={actions}
          adminColumns={adminColumns}
          config={config}
          exportRows={this.exportRows}
          model="certificates"
          rows={certificates}
          totalCount={totalCount}
          loadRows={this.loadRows}
        />
      </div>
    );
  }
}

export const CertificateList = connect(mapStateToProps)(CertificateListBase);
