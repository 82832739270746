import React from 'react';
import { MenuItem, Select, TableCell } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { connect, StateType } from '../../../../core/store';

const mapStateToProps = ({ fieldOfStudies }: StateType) => ({
  fieldOfStudies
});

interface Props extends WithProps<typeof mapStateToProps> {
  filter: any;
  onFilter: Function;
}

const FieldOfStudyFilterCell = ({ fieldOfStudies, filter, onFilter }: Props) => (
  <TableCell padding="none">
    <Select
      fullWidth={true}
      value={filter ? filter.value : ''}
      onChange={e => {
        onFilter(e.target.value ? { value: e.target.value } : null);
      }}
      inputProps={{
        name: 'fieldOfStudyId',
        id: 'fieldOfStudyId'
      }}>
      <MenuItem value="">
        <em>All</em>
      </MenuItem>
      {fieldOfStudies.map(study => (
        <MenuItem key={study.id} value={study.id}>
          {study.name}
        </MenuItem>
      ))}
    </Select>
  </TableCell>
);

export default connect(mapStateToProps)(FieldOfStudyFilterCell);
