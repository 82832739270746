import React, { Component, forwardRef } from 'react';
import {
  Avatar,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  Paper,
  Switch,
  TextField,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { Link, match } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { identity, isEqual, pickBy } from 'lodash';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../core/store';
import { upload } from '../utilities';

import { PtinInput } from './components/ptin-input';

const cameraIcon = require('../../images/icons/camera.png');

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      height: 125,
      margin: '0 auto',
      width: 125
    },
    camera: {
      width: 44,
      top: -80,
      position: 'relative',
      opacity: 0,
      '&:hover': {
        opacity: 0.8
      }
    },
    'avatar-empty': {
      opacity: 0.8
    },
    avatarFile: {
      display: 'none'
    },
    avatarContainer: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      display: 'inline-block',
      fontSize: 28,
      padding: '40px 80px',
      textAlign: 'center',
      '& a': {
        cursor: 'pointer'
      }
    },
    submit: {
      minWidth: 150
    },
    formControl: {
      height: 48
    },
    irsContainer: {
      width: '100%'
    },
    irsOption: {
      display: 'inline-block'
    },
    name: {
      marginTop: -40
    },
    cell: {
      textAlign: 'left'
    },
    cellHeader2: {
      textAlign: 'center',
      fontWeight: 'normal',
      paddingLeft: 20
    },
    cellHeader3: {
      textAlign: 'center',
      fontWeight: 'normal',
      paddingLeft: 20,
      paddingTop: 50
    },
    cell2: {
      paddingLeft: 20
    },
    actionButtons: {
      marginTop: 20,
      marginLeft: theme.spacing(-1)
    },
    button: {
      margin: theme.spacing(1),
      padding: '10px 12px',
      fontSize: 16,
      lineHeight: '20px',
      color: '#040D14',
      fontWeight: 'bold',
      textAlign: 'center',
      border: '1px solid #040D14',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.17)',
      textDecoration: 'none',
      whiteSpace: 'nowrap'
    },
    rightSide: {
      [theme.breakpoints.down('sm')]: {
        padding: '12px',
        width: '100%'
      },
      [theme.breakpoints.up('md')]: {
        padding: '20px'
      }
    },
    sectionWrapper: {
      marginTop: '40px'
    }
  });

const mapStateToProps = ({ user, profile }: StateType) => ({
  user,
  profile
});

interface UrlParameters {
  profileId: string;
}

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  match: match<UrlParameters>;
}

interface State {
  avatarFile: File | undefined;
  form: any;
}

const changePasswordLink = forwardRef((props: any, ref) => (
  <a href="https://sso.canopytax.com/forgot" target="_blank" rel="noreferrer" {...props} />
));

class ProfileBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {} as any,
      avatarFile: undefined
    };
  }

  componentDidMount(): void {
    const { match } = this.props;

    if (match.params.profileId) {
      actions.getProfile(+match.params.profileId);
    }
  }

  async componentDidUpdate(): Promise<void> {
    const { avatarFile, form } = this.state;

    if (avatarFile && form.id) {
      try {
        const avatarUrl = await upload(avatarFile);
        actions.updateProfile(form.id, { avatarUrl });
      } catch (error) {
        actions.createError(error);
      }

      this.setState({
        avatarFile: undefined
      });
    }
  }

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    const { profile, match, user } = newProps;
    const { form: prevForm } = prevState;
    const form = match.params.profileId ? profile : user;

    if (!form || form.updated === prevForm.updated) {
      return {};
    }

    return { form };
  }

  chooseAvatar = () => {
    const avatarFile = document.getElementById('avatarFile');
    if (avatarFile) {
      avatarFile.click();
    }
  };

  handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files == null) {
      return;
    }
    const avatarFile = files[0];
    this.setState({
      avatarFile
    });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    const key = event.target.id || event.target.name;

    this.setState(state => ({
      form: {
        ...state.form,
        [key]: event.target.value
      }
    }));
  };

  handleCheck = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: event.target.checked
      }
    }));
  };

  save = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { form } = this.state;
    if (form) {
      const {
        email,
        firstName,
        firstNameOnCertificate,
        lastName,
        lastNameOnCertificate,
        irsEnabled,
        ptin,
        webinarEmailRemind1d,
        webinarEmailRemind1h,
        webinarEmailRemind10m,
        newCourseNotifyEmail,
        newCourseNotifyInapp,
        missedWebinarNotifyEmail,
        missedWebinarNotifyInapp
      } = form;

      if (irsEnabled && !/P\d{8}/.test(ptin)) {
        return;
      }

      const defined = pickBy(
        {
          email,
          firstName,
          firstNameOnCertificate,
          irsEnabled,
          lastName,
          lastNameOnCertificate,
          ptin
        },
        identity
      );

      actions.updateProfile(form.id, {
        ...defined,
        irsEnabled,
        webinarEmailRemind1d,
        webinarEmailRemind1h,
        webinarEmailRemind10m,
        newCourseNotifyEmail,
        newCourseNotifyInapp,
        missedWebinarNotifyEmail,
        missedWebinarNotifyInapp
      });
    }
  };

  render() {
    const { classes, user } = this.props;
    const { form } = this.state;

    const disabled = !user || (form.role === 'admin' && form.id !== user.id);
    const saveDisabled = disabled || isEqual(user, form);

    return (
      <Paper>
        <Helmet title="Edit Profile" />
        <Grid container>
          {form.id != null && (
            <Grid item>
              <div className={classes.avatarContainer}>
                <a
                  onClick={
                    !disabled
                      ? this.chooseAvatar
                      : () => {
                          /* empty */
                        }
                  }>
                  <Avatar alt="Avatar" src={form.avatarUrl} className={classes.avatar} />
                  <img className={classes.camera + (form.avatarUrl ? '' : ` ${classes['avatar-empty']}`)} alt="Avatar" src={cameraIcon} />
                </a>
                <p className={classes.name}>
                  {form.firstName} {form.lastName}
                </p>
              </div>
            </Grid>
          )}
          <Grid item className={classes.rightSide}>
            {form.id != null && (
              <form noValidate autoComplete="off" onSubmit={this.save}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                      Profile &amp; Preferences
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-end">
                    <Button disabled={saveDisabled} type="submit" variant="contained" color="primary" className={classes.submit}>
                      Save
                    </Button>
                  </Grid>

                  <Grid item md={6} sm={12}>
                    <input type="file" id="avatarFile" className={classes.avatarFile} onChange={this.handleAvatarChange} />

                    <TextField
                      disabled={disabled}
                      id="firstName"
                      fullWidth={true}
                      label="First Name"
                      margin="normal"
                      onChange={this.handleChange}
                      required={true}
                      value={form.firstName}
                    />

                    <TextField
                      disabled={disabled}
                      id="lastName"
                      fullWidth={true}
                      label="Last Name"
                      margin="normal"
                      onChange={this.handleChange}
                      required={true}
                      value={form.lastName}
                    />

                    <TextField
                      disabled={true}
                      id="email"
                      fullWidth={true}
                      label="Email"
                      type="email"
                      margin="normal"
                      onChange={this.handleChange}
                      required={true}
                      value={form.email}
                    />
                    <div className={classes.actionButtons}>
                      <Button component={changePasswordLink} variant="outlined" className={classes.button}>
                        Change Password
                      </Button>
                    </div>
                  </Grid>

                  <Grid item lg={6} md={12}>
                    <div>
                      <FormControl margin="normal" className={classes.formControl}>
                        <FormLabel>I Need IRS Credit</FormLabel>

                        <div className={classes.irsContainer}>
                          <div className={classes.irsOption}>No</div>
                          <Switch
                            checked={form.irsEnabled === true}
                            onChange={this.handleCheck('irsEnabled')}
                            value={`${form.irsEnabled}`}
                            color="primary"
                          />
                          <div className={classes.irsOption}>Yes</div>
                        </div>
                      </FormControl>

                      {form.irsEnabled && (
                        <React.Fragment>
                          <TextField
                            id="firstNameOnCertificate"
                            fullWidth={true}
                            label="First Name On Certificate"
                            margin="normal"
                            onChange={this.handleChange}
                            required={true}
                            value={form.firstNameOnCertificate || ''}
                          />

                          <TextField
                            id="lastNameOnCertificate"
                            fullWidth={true}
                            label="Last Name On Certificate"
                            margin="normal"
                            onChange={this.handleChange}
                            required={true}
                            value={form.lastNameOnCertificate || ''}
                          />

                          <FormControl margin="normal" fullWidth={true}>
                            <InputLabel disableAnimation={true}>PTIN</InputLabel>
                            <Input
                              id="ptin"
                              onChange={this.handleChange}
                              inputComponent={PtinInput as any}
                              value={form.ptin || ''}
                              required
                            />
                          </FormControl>
                        </React.Fragment>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                      Notification Preferences
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <table>
                      <thead>
                        <tr>
                          <th className={classes.cell}>Webinar Reminders</th>
                          <th className={classes.cellHeader2}>Email</th>
                          <th className={classes.cellHeader2} />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1 Day before webinar begins</td>
                          <td className={classes.cell2}>
                            <Checkbox
                              id="webinarEmailRemind1d"
                              checked={form.webinarEmailRemind1d}
                              onChange={this.handleCheck('webinarEmailRemind1d')}
                            />
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <td>1 Hour before webinar begins</td>
                          <td className={classes.cell2}>
                            <Checkbox
                              id="webinarEmailRemind1h"
                              checked={form.webinarEmailRemind1h}
                              onChange={this.handleCheck('webinarEmailRemind1h')}
                            />
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <td>10 Minutes before webinar begins</td>
                          <td className={classes.cell2}>
                            <Checkbox
                              id="webinarEmailRemind10m"
                              checked={form.webinarEmailRemind10m}
                              onChange={this.handleCheck('webinarEmailRemind10m')}
                            />
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <th />
                          <th className={classes.cellHeader3}>Email</th>
                          <th className={classes.cellHeader3}>In App</th>
                        </tr>
                        <tr>
                          <td>New Course Added</td>
                          <td className={classes.cell2}>
                            <Checkbox
                              id="newCourseNotifyEmail"
                              checked={form.newCourseNotifyEmail}
                              onChange={this.handleCheck('newCourseNotifyEmail')}
                            />
                          </td>
                          <td className={classes.cell2}>
                            <Checkbox
                              id="newCourseNotifyInapp"
                              checked={form.newCourseNotifyInapp}
                              onChange={this.handleCheck('newCourseNotifyInapp')}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Missed Webinar</td>
                          <td className={classes.cell2}>
                            <Checkbox
                              id="missedWebinarNotifyEmail"
                              checked={form.missedWebinarNotifyEmail}
                              onChange={this.handleCheck('missedWebinarNotifyEmail')}
                            />
                          </td>
                          <td className={classes.cell2}>
                            <Checkbox
                              id="missedWebinarNotifyInapp"
                              checked={form.missedWebinarNotifyInapp}
                              onChange={this.handleCheck('missedWebinarNotifyInapp')}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table />
                  </Grid>
                </Grid>
              </form>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export const Profile = connect(mapStateToProps)(withStyles(styles)(ProfileBase));
