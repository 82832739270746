import { createStyles } from '@material-ui/core';

const styles = createStyles({
  hint: {
    color: '#777',
    marginTop: '-15px'
  },
  paper: {
    width: 'calc(100% + 200px)',
    marginLeft: -100,
    marginTop: 20,
    padding: '20px 100px',
    boxShadow: 'none'
  },
  firstSubHeader: {
    marginBlockEnd: 0
  },
  subHeader: {
    marginBlockStart: '60px',
    marginBlockEnd: 0
  },
  datePicker: {
    display: 'inline-block',
    marginRight: 20
  },
  datePickerButton: {
    display: 'inline-block'
  },
  recurringControl: {
    width: 90,
    marginRight: 20
  },
  datesList: {
    width: 140,
    maxHeight: 330,
    overflow: 'scroll'
  },
  timesList: {
    width: 200,
    maxHeight: 330,
    overflow: 'scroll'
  },
  listItem: {
    padding: '5px 0'
  },
  individualSession: {
    display: 'inline-block',
    minWidth: 160
  },
  blackoutDate: {
    display: 'inline-block',
    minWidth: 90
  },
  listItemRemove: {
    cursor: 'pointer',
    fontSize: '1em',
    lineHeight: 1.35
  },
  alertSubHeader: {
    marginTop: 20,
    marginBottom: 20,
    fontWeight: 'normal'
  }
});

export default styles;
