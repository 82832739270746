import React, { Component } from 'react';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../core/store';
import { PollingQuestionDialog } from '../../../shared';
import { CourseType } from '../../../types';

const mapStateToProps = ({ pollingQuestion }: StateType) => ({
  pollingQuestion
});

interface Props extends WithProps<typeof mapStateToProps> {
  course: CourseType;
  player: any;
  userWebinars?: any[];
}

interface State {
  open: boolean;
}

/**
 * Manages the display of polling questions during a webinars.
 */
class DialogPollingQuestionLauncherBase extends Component<Props, State> {
  interval: number;
  fullscreen = false;
  timeouts: number[] = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    actions.getNextPollingQuestion();
    this.setQuestionTimers();
  }

  componentWillUnmount() {
    this.timeouts.forEach(window.clearTimeout);
    this.timeouts = [];
    window.clearInterval(this.interval);
  }

  setQuestionTimers = () => {
    const { course, userWebinars } = this.props;

    if (!course || !userWebinars) {
      return;
    }

    const registeredWebinar = userWebinars.find(userWebinar => userWebinar.courseId === course.id);

    if (!registeredWebinar) {
      return;
    }

    const questionTimes = course.webinarPollingQuestionDisplayTimes.map(displayTime =>
      displayTime
        .split(':')
        .reverse()
        .map(time => parseInt(time))
        .reduce((prev, curr, i) => prev + curr * 60 ** i, 0)
    );

    const alreadyAskedQuestionTimes: number[] = [];

    this.interval = window.setInterval(() => {
      const { player } = this.props;

      const questionTime = questionTimes
        .filter(time => player.currentTime - time >= 0 && player.currentTime - time < 30)
        .find(time => player.currentTime >= time && !alreadyAskedQuestionTimes.includes(time));

      if (questionTime) {
        alreadyAskedQuestionTimes.push(questionTime);

        this.fullscreen = player.fullscreen.active;
        if (this.fullscreen) {
          player.fullscreen.exit();
        }
        this.setState({ open: true });
      }
    }, 1000);
  };

  closePollingQuestion = () => {
    this.setState({ open: false });
    if (this.fullscreen) {
      this.props.player.fullscreen.enter();
    }
    actions.getNextPollingQuestion();
  };

  render() {
    const { course, pollingQuestion, userWebinars } = this.props;

    if (!course || !userWebinars || !pollingQuestion) {
      return null;
    }

    const registeredWebinar = userWebinars.find(userWebinar => userWebinar.courseId === course.id);
    const { open } = this.state;

    return (
      <PollingQuestionDialog
        open={open}
        onClose={this.closePollingQuestion}
        pollingQuestion={pollingQuestion}
        registeredWebinar={registeredWebinar}
      />
    );
  }
}

export const DialogPollingQuestionLauncher = connect(mapStateToProps)(DialogPollingQuestionLauncherBase);
