import prodEnv from '../../environments/environment.prod.json';
import stageEnv from '../../environments/environment.stage.json';
import devEnv from '../../environments/environment.json';

type Environment = 'production' | 'stage' | 'development';

const env = getEnvironment();

function getEnvironment(): Environment {
  const hostname = window.location.hostname;
  if (/^courses\.canopytax\.com$/i.test(hostname)) {
    return 'production';
  } else if (/^courses\.canopy\.ninja$/i.test(hostname)) {
    return 'stage';
  } else {
    return 'development';
  }
}

export function isProductionEnv(): boolean {
  return env === 'production';
}

export function isStageEnv(): boolean {
  return env === 'stage';
}

export function isDevelopmentEnv(): boolean {
  return env === 'development';
}

export function environment() {
  if (env === 'production') {
    return prodEnv;
  } else if (env === 'stage') {
    return stageEnv;
  }
  return devEnv;
}
