import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, createStyles, WithStyles, withStyles } from '@material-ui/core';

import { CourseSeries } from './course-series';

const styles = createStyles({
  button: {
    marginRight: 10
  },
  container: {
    padding: '20px 40px'
  },
  successHeader: {
    marginTop: 120,
    marginBottom: 0
  },
  successSubHeader: {
    fontSize: '1.5rem'
  }
});

interface Props extends WithStyles<typeof styles> {
  course: any;
}

const rootLink = forwardRef((props: any, ref) => <Link innerRef={ref} to="/" {...props} />);
const myCoursesLink = forwardRef((props: any, ref) => <Link innerRef={ref} state={{ tab: 2 }} to="/my/courses#2" {...props} />);

const SavedCourseBase: React.FunctionComponent<Props> = ({ classes, course }) => {
  return (
    <div className={classes.container}>
      <h1 className={classes.successHeader}>
        Success,
        <br />
        Course Saved.
      </h1>
      <p className={classes.successSubHeader}>Course was saved to My Courses</p>
      <Button component={rootLink} variant="contained" color="secondary" className={classes.button}>
        Continue Browsing Courses
      </Button>
      <Button component={myCoursesLink} variant="outlined" className={classes.button}>
        View My Courses
      </Button>
      <br />
      <br />
      <CourseSeries course={course} />
    </div>
  );
};

export const CourseSaved = withStyles(styles)(SavedCourseBase);
