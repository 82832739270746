import React, { Component, createRef } from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import { isEqual } from 'lodash';
import 'custom-event-polyfill';
import 'url-polyfill';
import Hls from 'hls.js';
// @ts-ignore
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import { fileExist } from '../../utilities';

/**
 * Wrapper for the Plyr component.
 * Sets up responsive display.
 * Has callback function setPlayer() to pass player instance to parent for event handling.
 */

const styles = (theme: Theme) =>
  createStyles({
    player: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      margin: 'auto'
    },
    playerWrapper: {
      margin: '40px 40px 0px',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        margin: 5
      }
    }
  });

interface Props extends WithStyles<typeof styles> {
  config?: any;
  handleEventPlaying?: Function;
  onEnded?: () => void;
  setPlayer?: Function;
  video: any;
}

class PlayerBase extends Component<Props> {
  // Ref to HTML Video element
  playerRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.playerRef = createRef();
  }

  componentDidMount() {
    this.setupPlayer();
  }

  // Inistial new player whenever video changes.
  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.video, this.props.video)) {
      this.setupPlayer();
    }
  }

  // Setup player instance, map event playing handler and call setPlayer if set.
  setupPlayer = () => {
    const { config = {}, handleEventPlaying, onEnded, setPlayer, video } = this.props;
    config.poster = video.thumbnailUrl;
    const player = new Plyr(this.playerRef.current, config);

    const hlsSource = video.url.replace('mp4', 'm3u8');

    if (!Hls.isSupported() || !fileExist(hlsSource)) {
      this.playerRef.current.src = video.url;
    } else {
      const hls = new Hls();
      hls.loadSource(hlsSource);
      hls.attachMedia(this.playerRef.current);
    }

    if (handleEventPlaying) {
      player.on('play', handleEventPlaying);
    }
    if (onEnded) {
      player.on('ended', onEnded);
    }

    if (setPlayer) {
      setPlayer(player);
    }
  };

  render() {
    const { classes, video } = this.props;

    const playerStyle = {
      height: '100%',
      maxWidth: video.width,
      maxHeight: video.height,
      width: '100%'
    };

    // Dynamic responsive container based on the video / height ratio.
    const paddingTop = Math.round((100 / (video.width / video.height)) * 100) / 100;
    const playerWrapperStyle = {
      paddingTop: `${paddingTop}%` /* Player ratio e.g. 100 / (1280 / 720) */
    };

    return (
      <div className={classes.playerWrapper} style={playerWrapperStyle}>
        <div className={classes.player} style={playerStyle}>
          <video
            controls={true}
            controlsList="nodownload"
            id="player"
            preload="auto"
            ref={this.playerRef}
            src={video.url}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </div>
    );
  }
}

export const Player = withStyles(styles)(PlayerBase);
