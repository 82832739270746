import React, { Component, forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { uniq } from 'lodash';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../core/store';
import { promiseEach } from '../../../utilities';
import { TableGrid } from '../../shared';

import SeriesDialog from './series-dialog';

const styles = createStyles({
  selectActions: {
    display: 'inline-block'
  }
});

const mapStateToProps = ({ courses, message, coursesTotal: totalCount, adminColumns }: StateType) => ({
  courses,
  message,
  totalCount,
  adminColumns
});

type Props = WithStyles<typeof styles> & WithProps<typeof mapStateToProps>;

interface State {
  selectedCourses: any[];
}

class CourseListBase extends Component<Props, State> {
  courseCreateLink = forwardRef((props: any, ref) => <Link innerRef={ref} to="/admin/courses/create" {...props} />);

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedCourses: []
    };
  }

  componentDidMount(): void {
    actions.getAdminColumns();
  }

  componentWillUnmount() {
    actions.unsetCourses();
  }

  exportRows = (method: string, params: Record<string, any> = {}) => {
    actions.exportCourses(method, params);
  };

  handleChangeStatus = (selection: number[], status: string) => () => {
    const { courses } = this.props;
    const statusMessage =
      status === 'published'
        ? 'If enabled, automatic webinars will be created. '
        : 'Remaining webinars will run as scheduled, and no additional manual or automatic webinars can be scheduled once a course is archived.';

    if (
      // eslint-disable-next-line
      confirm(`Are you sure you want to change the selected course statuses to ${status}?.  ${statusMessage}`)
    ) {
      promiseEach(selection, select => {
        const course = courses[select];
        return actions.patchCourse(course.id, { status });
      });
    }
  };

  handleOpenCreateSeriesDialog = () => {
    actions.openSeriesDialog({
      courses: this.state.selectedCourses,
      courseId: null,
      seriesId: null
    });
  };

  loadRows = (params: Record<string, any>) => {
    actions.getCourses(params);
  };

  getSelectActions = () => ({ selection }: { selection: number[] }) => {
    const { courses, classes } = this.props;
    const disabled = selection.length === 0;

    const selectedCourses = selection.map(x => courses[x]);
    const uniqueSeriesIds = uniq(selectedCourses.map(c => c.courseSeriesId));
    const seriesDisabled = selectedCourses.length < 2 || uniqueSeriesIds.length > 1 || uniqueSeriesIds[0] !== null;

    return (
      <div className={classes.selectActions}>
        <Button variant="outlined" component={this.courseCreateLink}>
          Create Course
        </Button>
        <Button disabled={disabled} onClick={this.handleChangeStatus(selection, 'published')} variant="outlined">
          Publish
        </Button>
        <Button disabled={disabled} onClick={this.handleChangeStatus(selection, 'archived')} variant="outlined">
          Archive
        </Button>
        <Button disabled={seriesDisabled} onClick={this.handleOpenCreateSeriesDialog} variant="outlined">
          Create Series
        </Button>
      </div>
    );
  };

  onSelectionChange = (rows: any) => {
    this.setState({ selectedCourses: rows });
  };

  getConfig = () => {
    const selectActions = this.getSelectActions();

    return {
      columns: [
        { name: 'name', title: 'Name' },
        { name: 'courseSeriesPart', title: 'Series' },
        { name: 'status', title: 'Status' },
        { name: 'credits', title: 'Credits' },
        { name: 'credittype', title: 'Credit Type' },
        { name: 'level', title: 'Level' },
        { name: 'categoryId', title: 'Category' },
        { name: 'fieldOfStudyId', title: 'Field Of Study' },
        { name: 'updated', title: 'Last Modified' },
        { name: 'updatedByName', title: 'Last Modified By' },
        { name: 'published', title: 'Published' },
        { name: 'publishedByName', title: 'Published By' },
        { name: 'certificatesGranted', title: 'Certificates' },
        { name: 'registrations', title: 'Registrants' },
        { name: 'scheduledWebinarsCount', title: 'Webinar Sch.' },
        { name: 'webinarRegistrations', title: 'Webinar Reg.' },
        { name: 'instructor', title: 'Instructor' },
        { name: 'lastReview', title: 'Last Review' },
        { name: 'lastReviewBy', title: 'Last Review By' },
        { name: 'id', title: 'ID' },
        { name: 'irsId', title: 'IRS ID' },
        { name: 'ctecId', title: 'CTEC ID' },
        { name: 'averageRating', title: 'Average Rating' }
      ],
      columnExtensions: [
        { columnName: 'averageRating', width: 170 },
        { columnName: 'name', width: 400 },
        { columnName: 'categoryId', width: 180 },
        { columnName: 'credits', width: 90 },
        { columnName: 'credittype', width: 120 },
        { columnName: 'fieldOfStudyId', width: 200 },
        { columnName: 'id', width: 100 },
        { columnName: 'webinarRegistrations', width: 170 },
        { columnName: 'published', width: 180 },
        { columnName: 'publishedByName', width: 200 },
        { columnName: 'level', width: 120 },
        { columnName: 'lastReview', width: 180 },
        { columnName: 'lastReviewBy', width: 200 },
        { columnName: 'updated', width: 180 },
        { columnName: 'updatedByName', width: 200 }
      ],
      dateColumns: ['updated', 'published', 'lastReview'],
      filteringStateColumnExtensions: [
        { columnName: 'averageRating', filteringEnabled: false },
        { columnName: 'courseSeriesPart', filteringEnabled: false },
        { columnName: 'certificatesGranted', filteringEnabled: false },
        { columnName: 'instructor', filteringEnabled: false },
        { columnName: 'publishedByName', filteringEnabled: false },
        { columnName: 'registrations', filteringEnabled: false },
        { columnName: 'scheduledWebinarsCount', filteringEnabled: false },
        { columnName: 'webinarRegistrations', filteringEnabled: false },
        { columnName: 'updatedByName', filteringEnabled: false }
      ],
      sortingStateColumnExtensions: [
        { columnName: 'averageRating', sortingEnabled: false },
        { columnName: 'courseSeriesPart', sortingEnabled: false },
        { columnName: 'certificatesGranted', sortingEnabled: false },
        { columnName: 'instructor', sortingEnabled: false },
        { columnName: 'publishedByName', sortingEnabled: false },
        { columnName: 'registrations', sortingEnabled: false },
        { columnName: 'webinarScheduled', sortingEnabled: false },
        { columnName: 'webinarRegistrations', sortingEnabled: false },
        { columnName: 'updatedByName', sortingEnabled: false }
      ],
      selectActions,
      urlColumns: ['name'],
      urlColumnsBase: '/admin/courses'
    };
  };

  render() {
    const { courses, totalCount, adminColumns } = this.props;
    const config = this.getConfig();

    return (
      <div>
        <Helmet title="Admin List" />
        <TableGrid
          actions={actions}
          adminColumns={adminColumns}
          config={config}
          exportRows={this.exportRows}
          model="courses"
          rows={courses}
          totalCount={totalCount}
          loadRows={this.loadRows}
          onSelectionChange={this.onSelectionChange}
        />
        <SeriesDialog />
      </div>
    );
  }
}

export const CourseList = withStyles(styles)(connect(mapStateToProps)(CourseListBase));
