import axios, { AxiosError } from 'axios';
import { captureException, Severity, withScope } from '@sentry/browser';
import { pick } from 'lodash';

import { LOGIN_URL } from '../../constants';
import { getAuthToken, removeAuthToken } from '../../core/services/auth';
import { environment } from '../environment';

import { NetworkError } from './network-error';

type HttpMethod = 'get' | 'post' | 'delete' | 'patch';

const env = environment();

function responseInterceptor(response: any): any {
  return response;
}

function responseErrorInterceptor(error: AxiosError) {
  withScope(scope => {
    let customError: NetworkError;

    const { response, config } = error;
    const { method, url } = pick(config, ['method', 'url']);
    if (response) {
      const { data, status } = pick(response, ['data', 'status']);

      // don't need to capture unauthorized requests
      if (status == 401 || status == 403) {
        return;
      }

      if (status >= 400 && status < 500) {
        scope.setLevel(Severity.Warning);
      }
      customError = new NetworkError(`${status} - ${data.error}`, url, method, status, data);
    } else {
      customError = new NetworkError(error.message, url, method);
    }
    captureException(customError);
  });

  if (error && error.response && error.response.status === 401) {
    removeAuthToken();
    window.location.replace(LOGIN_URL);
  }

  return Promise.reject(error);
}

axios.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

function parseResponse(res: any): Promise<any> {
  if (res.status === 204) {
    return Promise.resolve(res);
  }

  const { data, headers, status } = res;

  const response: any = {
    data,
    headers,
    status
  };

  return response;
}

function parseError(error: any) {
  const response = {
    error: error.message,
    errorMessage: error.message,
    errorMeta: error.meta || {}
  };

  if (error.response) {
    const { data } = error.response;

    if (data.error) {
      response.error = new Error(data.error);
      response.errorMessage = data.error;
      response.errorMeta = data.meta;
    }

    // RAML returns a body with the field 'errors' populated
    if (data.errors && Array.isArray(data.errors)) {
      response.error = new Error(data.errors[0].message);
    }
  }

  return response;
}

export default function request(method: HttpMethod, endpoint: string, data?: any, opts: any = {}): Promise<any> {
  const headers: any = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
    Expires: '0',
    Pragma: 'no-cache'
  };

  const authToken = getAuthToken();
  if (authToken) {
    headers.Authorization = `Bearer ${authToken.accessToken}`;
  }

  const url = `${env.apiUrl}${endpoint}`;
  const options: any = {
    headers,
    method,
    url,
    params: method === 'get' ? data : {},
    data: method !== 'get' ? data : {},
    ...opts
  };

  return axios(options).then(parseResponse).catch(parseError);
}
