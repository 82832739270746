import React, { Component } from 'react';
import { addDays, compareAsc, format, isBefore, isValid } from 'date-fns';
import { DatePicker, DateTimePicker, TimePicker } from '@material-ui/pickers';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Icon,
  Paper,
  WithStyles,
  withStyles
} from '@material-ui/core';

import { Select } from '../../../../../../shared';
import { request, timezoneAbbr } from '../../../../../../utilities';
import { generate, isBlacklisted, isWithinDisplayInterval, rrule, toDateString } from '../../../../../../utilities/schedule';
import { WEBINAR_MAX_AUTOSCHEDULE_DAYS } from '../../../../../../constants';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  containerState: any;
  setContainerState: Function;
  handleAddSchedule: Function;
  handleRemoveSchedule: Function;
  blackoutDates: string[];
  handleAddBlackoutDate: Function;
  handleRemoveBlackoutDate: Function;
}

interface State {
  scheduledOn: Date | null;
  blackoutDate: Date | null;
  alertOpen: boolean;
  conflictingCourses: any[];
}

type FormChangeEvent = React.ChangeEvent<{ instructor?: any } & (HTMLInputElement | HTMLSelectElement)>; // | { target: { id?: string, name?: string, value: string, instructor?: any } };

class Schedule extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      alertOpen: false,
      conflictingCourses: [],
      scheduledOn: null,
      blackoutDate: null
    };
  }

  formatDateTimeLabel = (date: Date, invalidLabel: string) => {
    if (date === null) {
      return '';
    }

    if (isValid(date)) {
      return format(date, 'MMM do hh:mm a');
    }

    return invalidLabel;
  };

  formatTimeLabel = (date: Date, invalidLabel: string) => {
    if (date === null) {
      return '';
    }

    if (isValid(date)) {
      return format(date, 'hh:mm a');
    }

    return invalidLabel;
  };

  formatDateLabel = (date: Date, invalidLabel: string) => {
    if (date === null) {
      return '';
    }

    if (isValid(date)) {
      return format(date, 'MMM do');
    }

    return invalidLabel;
  };

  getAutoSchedules = () => {
    const { isRecurring, recurringTime, recurringDay, recurringWeeks, webinarScheduled = [] } = this.props.containerState.form;

    const savedAutoSchedules = webinarScheduled
      .map((w: any) => ({ ...w, scheduledOn: new Date(w.scheduledOn) }))
      .filter((w: any) => w.isAuto)
      .sort((a: any, b: any) => compareAsc(a.scheduledOn, b.scheduledOn));

    if (!isRecurring) {
      return savedAutoSchedules;
    }

    const time = new Date(recurringTime);
    const rule = rrule(time.getHours(), time.getMinutes(), recurringDay, recurringWeeks);

    let startDate = new Date();
    if (savedAutoSchedules.length > 0) {
      startDate = savedAutoSchedules[savedAutoSchedules.length - 1].scheduledOn;
    }

    const initial = savedAutoSchedules.length === 0;
    const autoSchedules = generate(startDate, addDays(new Date(), WEBINAR_MAX_AUTOSCHEDULE_DAYS), rule, initial).map(d => ({
      id: null,
      scheduledOn: d
    }));

    return [...savedAutoSchedules, ...autoSchedules].sort((a, b) => compareAsc(a.scheduledOn, b.scheduledOn));
  };

  getIndividualSessions = () => {
    const { schedulesToAdd, schedulesToRemoveIds, form } = this.props.containerState;
    let { webinarScheduled = [] } = form;

    webinarScheduled = webinarScheduled
      .map((w: any) => ({ ...w, scheduledOn: new Date(w.scheduledOn) }))
      .filter((w: any) => !w.isAuto && schedulesToRemoveIds.indexOf(w.id) === -1);

    return [...webinarScheduled, ...schedulesToAdd].sort((a, b) => compareAsc(a.scheduledOn, b.scheduledOn));
  };

  getBlackoutDates = () => {
    const { blackoutDatesToAdd, blackoutDatesToRemove } = this.props.containerState;
    let { blackoutDates = [] } = this.props;
    blackoutDates = blackoutDates.filter(w => blackoutDatesToRemove.indexOf(w) === -1);
    return [...blackoutDates, ...blackoutDatesToAdd].sort((a, b) => compareAsc(new Date(a), new Date(b)));
  };

  getComputedSchedules = () => {
    const autoSchedules = this.getAutoSchedules();
    const individualSessions = this.getIndividualSessions();
    const blackoutDates = this.getBlackoutDates();

    return [...individualSessions, ...autoSchedules]
      .filter(s => !isBlacklisted(s.scheduledOn, blackoutDates))
      .filter(s => isWithinDisplayInterval(s.scheduledOn))
      .sort((a, b) => compareAsc(a.scheduledOn, b.scheduledOn));
  };

  handleAddWebinar = () => {
    const { handleAddSchedule } = this.props;
    const { scheduledOn } = this.state;

    if (!scheduledOn) {
      return;
    }

    if (isBlacklisted(scheduledOn, this.getBlackoutDates())) {
      alert('Blackout date conflict. Remove conflicting webinar blackout date in order to add this session.');
      return;
    }

    handleAddSchedule(scheduledOn);
    this.setState({ scheduledOn: null });
  };

  handleRemoveWebinar = (id: number) => () => {
    this.props.handleRemoveSchedule(id);
  };

  handleAddBlackoutDate = async () => {
    const { blackoutDate } = this.state;

    if (!blackoutDate) {
      return;
    }

    const blackoutDateString = toDateString(blackoutDate);

    const sessionsDateStrings = this.getIndividualSessions().map(s => toDateString(s.scheduledOn));
    if (sessionsDateStrings.indexOf(blackoutDateString) !== -1) {
      alert('Individual session conflict. Remove conflicting individual session time in order to add this blackout date.');
      return;
    }

    if (this.getBlackoutDates().indexOf(blackoutDateString) !== -1) {
      alert('Blackout date conflict. Blackout date already exists.');
      return;
    }

    const { error, errorMessage, errorMeta } = await request('post', `/blackout-dates/${blackoutDateString}/?dry=true`);
    if (error && errorMessage === 'Course schedule conflict') {
      this.setState({ alertOpen: true, conflictingCourses: errorMeta.courses });
      return;
    }

    this.props.handleAddBlackoutDate(blackoutDateString);
    this.setState({ blackoutDate: null });
  };

  handleRemoveBlackoutDate = (blackoutDate: string) => () => {
    this.props.handleRemoveBlackoutDate(blackoutDate);
  };

  handleFormChange = (event: FormChangeEvent) => {
    if (typeof event.persist === 'function') {
      event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    }

    const { containerState, setContainerState } = this.props;
    const { form } = containerState;
    const { id, name } = event.target;
    const key = id || name;
    let { value }: { value: string | number } = event.target;

    if (value && ['credits'].includes(key)) {
      value = parseInt(value, 10);
    } else if (typeof value === 'string') {
      value = value.trim();
    }

    const stateChange = {
      form: {
        ...form,
        [key]: value
      }
    };

    setContainerState(stateChange);
  };

  handleTimeChange = (date: Date) => {
    const { containerState, setContainerState } = this.props;
    const { form } = containerState;

    setContainerState({
      form: {
        ...form,
        recurringTime: date
      }
    });
  };

  handleIsRecurringChange = (event: FormChangeEvent, value: boolean) => {
    const { containerState, setContainerState } = this.props;
    const { form } = containerState;

    setContainerState({
      form: {
        ...form,
        isRecurring: value
      }
    });
  };

  handleCloseAlert = () => {
    this.setState({ alertOpen: false });
  };

  renderControls = (form: any, classes: any) => {
    const { status, isRecurring, recurringTime, recurringDay, recurringWeeks } = form;

    const isArchived = status === 'archived';

    const { scheduledOn, blackoutDate } = this.state;

    return (
      <div>
        <h4 className={classes.firstSubHeader}>Recurring Session</h4>
        <div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRecurring}
                  disabled={isArchived}
                  name="isRecurring"
                  onChange={this.handleIsRecurringChange}
                  value={'true'}
                />
              }
              label="Auto Scheduling Enabled"
            />
          </div>

          {isRecurring && (
            <div>
              <TimePicker
                disabled={isArchived}
                autoOk={true}
                value={recurringTime}
                onChange={this.handleTimeChange}
                className={classes.recurringControl}
                label="Time"
                labelFunc={this.formatTimeLabel}
              />
              <Select
                disabled={isArchived}
                value={recurringDay}
                className={classes.recurringControl}
                onChange={this.handleFormChange}
                options={[
                  { id: 1, name: 'Monday' },
                  { id: 2, name: 'Tuesday' },
                  { id: 3, name: 'Wednesday' },
                  { id: 4, name: 'Thursday' },
                  { id: 5, name: 'Friday' }
                ]}
                label="Day of Week"
                name="recurringDay"
              />
              <Select
                disabled={isArchived}
                value={recurringWeeks}
                className={classes.recurringControl}
                onChange={this.handleFormChange}
                options={[
                  { id: 1, name: '1 Week' },
                  { id: 2, name: '2 Weeks' },
                  { id: 3, name: '3 Weeks' },
                  { id: 4, name: '4 Weeks' }
                ]}
                label="Frequency"
                name="recurringWeeks"
              />
            </div>
          )}
        </div>

        {!isArchived && (
          <div>
            <h4 className={classes.subHeader}>Add Session</h4>
            <div className={classes.datePickerContainer}>
              <div className={classes.datePicker}>
                <DateTimePicker
                  autoOk={true}
                  value={scheduledOn || null}
                  disablePast={true}
                  onChange={date => this.setState({ scheduledOn: date })}
                  label="Scheduled On"
                  labelFunc={this.formatDateTimeLabel}
                />
              </div>

              <Button className={classes.datePickerButton} disabled={!scheduledOn} onClick={this.handleAddWebinar} variant="outlined">
                Create
              </Button>
            </div>

            <h4 className={classes.subHeader}>Add Blackout Date</h4>
            <div className={classes.datePickerContainer}>
              <div className={classes.datePicker}>
                <DatePicker
                  autoOk={true}
                  value={blackoutDate || null}
                  disablePast={true}
                  onChange={date => this.setState({ blackoutDate: date })}
                  label="Date"
                  labelFunc={this.formatDateLabel}
                />
              </div>

              <Button className={classes.datePickerButton} disabled={!blackoutDate} onClick={this.handleAddBlackoutDate} variant="outlined">
                Create
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderAlert() {
    const { classes } = this.props;
    const { alertOpen, conflictingCourses, blackoutDate } = this.state;

    return (
      <Dialog open={alertOpen} onClose={this.handleCloseAlert}>
        <DialogTitle>Individual Session Conflict</DialogTitle>
        <DialogContent>
          {blackoutDate && isBefore(blackoutDate, addDays(new Date(), WEBINAR_MAX_AUTOSCHEDULE_DAYS)) ? (
            <DialogContentText>
              You can no longer blackout this date because webinars have been scheduled and published on this date.
            </DialogContentText>
          ) : (
            <DialogContentText>
              Remove individual webinars planned on that date in order to add this blackout date
              <h3 className={classes.alertSubHeader}>
                <strong>Courses with planned webinars on this date:</strong>
              </h3>
              {conflictingCourses.map((c, index) => (
                <DialogContentText key={index}>
                  <strong>{c.name}</strong>
                </DialogContentText>
              ))}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseAlert} color="primary">
            {' '}
            OK{' '}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes, containerState } = this.props;

    const { form } = containerState;

    const { status } = form;

    const isPublished = status === 'published';
    const isArchived = status === 'archived';
    //  const isDraft = status === 'draft';
    return (
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <h2>{form.name} Course Schedule</h2>
            <p className={classes.hint}> The times you see and select are in {timezoneAbbr()} timezone </p>
          </Grid>
          <Grid item xs={4}>
            {this.renderControls(form, classes)}
          </Grid>
          <Grid item xs={3}>
            <h4>Scheduled Sessions</h4>
            <div className={classes.timesList}>
              {this.getComputedSchedules().map((webinar, index) => {
                const start = format(webinar.scheduledOn, 'MMM dd, yyyy - h:mm a');
                return (
                  <div key={index} className={classes.listItem}>
                    <span className={classes.individualSession}>{start}</span>
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={3}>
            <h4>Individual Sessions</h4>
            <div className={classes.timesList}>
              {this.getIndividualSessions().map((webinar, index) => {
                const start = format(new Date(webinar.scheduledOn), 'MMM dd, yyyy - h:mm a');
                return (
                  <div key={index} className={classes.listItem}>
                    <span className={classes.individualSession}>{start}</span>
                    {(!isPublished || !isWithinDisplayInterval(webinar.scheduledOn) || webinar.id < 0) && !isArchived && (
                      <Icon className={classes.listItemRemove} onClick={this.handleRemoveWebinar(webinar.id)}>
                        close
                      </Icon>
                    )}
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={2}>
            <h4>Blackout Dates</h4>
            <div className={classes.datesList}>
              {this.getBlackoutDates().map((d, index) => {
                const [year, month, day] = d.split('-').map((x: string) => +x); // 2018-10-01 => [2018, 10, 1]
                const formatted = format(new Date(year, month - 1, day), 'MMM dd, yyyy');
                return (
                  <div key={index} className={classes.listItem}>
                    <span className={classes.blackoutDate}>{formatted}</span>
                    {!isArchived && (
                      <Icon className={classes.listItemRemove} onClick={this.handleRemoveBlackoutDate(d)}>
                        close
                      </Icon>
                    )}
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>

        {this.renderAlert()}
      </Paper>
    );
  }
}

export default withStyles(styles)(Schedule);
