import Cookies from 'js-cookie';

const TOKEN = 'userToken';

export function getAuthToken(): any | null {
  const token = localStorage.getItem(TOKEN);
  if (!token) {
    return null;
  }
  return JSON.parse(token);
}

export function setAuthToken(token: any) {
  return localStorage.setItem(TOKEN, JSON.stringify(token));
}

export function removeAuthToken(): void {
  localStorage.removeItem(TOKEN);
  Cookies.remove('AuthenticatedUser');
}
