import { promiseEach, request } from '../../utilities';
import { StateType } from '../store';

export async function getBlackoutDates() {
  const { data, error } = await request('get', '/blackout-dates');
  if (error) {
    return { error: 'Unable to get blackout dates.' };
  }

  return { blackoutDates: data };
}

export async function createBlackoutDate(state: StateType, _a: any, date: string) {
  const { error } = await request('post', `/blackout-dates/${date}`);
  if (error) {
    return { error: 'Unable to create blackout date.' };
  }

  return { blackoutDates: [...state.blackoutDates, date] };
}

export async function deleteBlackoutDate(state: StateType, _a: any, date: string) {
  const { error } = await request('delete', `/blackout-dates/${date}`);
  if (error) {
    return { error: 'Unable to delete blackout date.' };
  }

  return { blackoutDates: state.blackoutDates.filter(d => d !== date) };
}

export async function updateBlackoutDatesList(state: StateType, _a: any, toAdd: string[], toRemove: string[]) {
  let hasErrors = false;
  let blackoutDates = [...state.blackoutDates];

  await promiseEach(toAdd, async date => {
    const { error } = await request('post', `/blackout-dates/${date}`);
    if (error) {
      hasErrors = true;
    } else {
      blackoutDates = [...blackoutDates, date];
    }
  });

  await promiseEach(toRemove, async date => {
    const { error } = await request('delete', `/blackout-dates/${date}`);
    if (error) {
      hasErrors = true;
    } else {
      blackoutDates = blackoutDates.filter(d => d !== date);
    }
  });

  if (hasErrors) {
    return {
      error: 'Error saving blackout dates listing.',
      blackoutDates
    };
  }

  return { blackoutDates };
}
