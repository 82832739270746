import axios from 'axios';

import request from '../request/request';

/**
 * Create upload token for storage (AWS S3).
 */
async function createUploadToken(params: Record<string, any>) {
  const { data, error } = await request('post', '/users/upload-token', params);
  if (error) {
    throw new Error(error);
  }
  return data;
}

/**
 * Create upload token file for and then POST to token url (AWS S3).
 *
 * @param {File} file
 * @return {string} Destination URL.
 */
export default async function upload(file: File): Promise<string> {
  const token = await createUploadToken({ filename: file.name });
  const { fields, url } = token;
  const data: FormData = new FormData();

  Object.entries(fields).forEach(entry => {
    data.append(entry[0], entry[1] as any); // TODO: forced a cast validate
  });

  data.append('Content-Type', file.type);
  data.append('file', file);

  await axios({
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'POST',
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      // eslint-disable-next-line
      console.log(percentCompleted);
    },
    url
  });

  // public URL on S3
  return `${url}/${fields.key}`;
}
