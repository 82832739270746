import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const ArrayOfStringFormatter = (props: any) => {
  const { value } = props;
  if (!value || !Array.isArray(value)) {
    return null;
  }
  return <span>{value.join(', ')}</span>;
};

// @ts-ignore
const ArrayOfStringTypeProvider = ({ for: forProp, ...others }) => (
  <DataTypeProvider formatterComponent={ArrayOfStringFormatter} for={forProp} {...others} />
);

export default ArrayOfStringTypeProvider;
