import { request } from '../../utilities';
import { StateType } from '../store';

export async function getNotifications() {
  const { data, error } = await request('get', '/notifications');
  if (error) {
    return { error: 'Unable to get notifications' };
  }

  return { notifications: data };
}

// TODO: is this just terribly named with an HTTP method of delete?
export async function readNotification(state: StateType, _a: any, notificationId: number) {
  const { error } = await request('delete', `/notifications/${notificationId}`);
  if (error) {
    return { error: 'Unable to update notifications' };
  }

  return { notifications: state.notifications.filter(n => n.id !== notificationId) };
}

export async function readAllNotifications() {
  const { error } = await request('delete', '/notifications');
  if (error) {
    return { error: 'Unable to update notifications' };
  }

  return { notifications: [] };
}
