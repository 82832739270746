import React, { Component } from 'react';
import {
  createStyles,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core';

/**
 * Select component wrapper.
 * Will become controlled component when the IconComponent is clicked.
 * Pass the prop color 'white' to use white theme.
 */

const styles = (theme: Theme) =>
  createStyles({
    disabled: {
      color: (theme as any).canopy.disabled,
      backgroundColor: 'white !important'
    },
    selectArrow: {
      cursor: 'pointer',
      position: 'absolute',
      right: 10,
      top: '25%'
    },
    whiteRoot: {
      backgroundColor: 'white',
      boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
      fontWeight: 600,
      padding: '8px 10px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    whiteMenuItemClass: {
      '&:focus': {
        backgroundColor: '#FFF'
      }
    },
    whiteLabelRoot: {
      paddingLeft: 10,
      zIndex: 1
    },
    whiteLabelShrink: {
      paddingLeft: 0
    }
  });

interface Props extends WithStyles<typeof styles> {
  className: string;
  color?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  input?: React.ReactElement;
  multiple?: boolean;
  name: string;
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  options: { id: number | string; name: string }[];
  value?: any;
}

interface State {
  open: boolean;
}

class SelectBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      classes,
      className,
      color,
      disabled,
      fullWidth,
      input,
      label,
      multiple,
      name,
      onChange,
      onClose,
      onOpen,
      options,
      value
    } = this.props;
    const isValueValid = typeof value === 'string' || typeof value === 'number' || Array.isArray(value);

    // configure color theme if set
    let rootClass = '';
    let labelClass = '';
    let labelShrinkClass = '';
    let menuItemClass = '';
    if (color === 'white') {
      rootClass = classes.whiteRoot;
      labelClass = classes.whiteLabelRoot;
      labelShrinkClass = classes.whiteLabelShrink;
      menuItemClass = classes.whiteMenuItemClass;
    }

    // component can be controlled from parent, but will switch to self controlled when IconButton is clicked.
    const open = this.state.open || !!this.props.open;

    return (
      <FormControl component={'fieldset'} className={className}>
        {label && (
          <InputLabel classes={{ root: labelClass, shrink: labelShrinkClass }} htmlFor={name}>
            {label}
          </InputLabel>
        )}
        <MuiSelect
          classes={{ root: rootClass, select: menuItemClass }}
          disableUnderline={true}
          fullWidth={fullWidth}
          IconComponent={() => (
            <Icon className={classes.selectArrow} onClick={this.handleOpen}>
              keyboard_arrow_down
            </Icon>
          )}
          input={input}
          disabled={disabled}
          inputProps={{
            disabled,
            name,
            id: name
          }}
          multiple={multiple}
          onClose={onClose || this.handleClose}
          onOpen={onOpen || this.handleOpen}
          open={open}
          onChange={onChange}
          value={isValueValid ? value : ''}>
          {options.map(opt => (
            <MenuItem classes={{ root: menuItemClass, selected: classes.disabled }} disabled={value === opt.id} key={opt.id} value={opt.id}>
              {opt.name}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    );
  }
}

export const Select = withStyles(styles)(SelectBase);
