import { createStyles, Theme } from '@material-ui/core';

const style = (theme: Theme) =>
  createStyles({
    changeRegistration: {
      color: theme.palette.secondary.main,
      fontSize: '0.9rem',
      textDecoration: 'none'
    },
    courseDetailsButton: {
      margin: 20,
      padding: '10px 16px;'
    },
    creditType: {
      fontSize: '0.9rem'
    },
    fieldOfStudy: {
      color: 'white',
      textDecoration: 'none',
      fontSize: 16,
      display: 'block',
      marginTop: 30
    },
    headerLink: {
      color: 'white',
      textDecoration: 'none',
      fontSize: 21,
      fontWeight: 900,
      display: 'block',
      marginTop: 9
    },
    icon: {
      color: 'black',
      fontSize: 12,
      textDecoration: 'none',
      '& span': {
        fontSize: 22
      }
    },
    iconContainer: {
      color: 'black',
      display: 'inline-block',
      margin: 10
    },
    link: {
      color: 'black',
      fontSize: 12,
      height: 20,
      textDecoration: 'none',
      width: 20
    },
    linkContainer: {
      color: 'black'
    },
    panel: {
      textAlign: 'center',
      backgroundColor: 'white',
      boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
      height: 450,
      width: 300
    },
    panelBody: {
      height: 260
    },
    panelHeader: {
      position: 'relative',
      color: 'white',
      height: 183,
      padding: '10px 20px'
    },
    purple: {
      background: 'linear-gradient(135deg, rgba(52,37,175,1) 0%, rgba(190,105,213,1) 100%);'
    },
    scheduledOn: {
      marginBottom: 5
    },
    closeIcon: {
      position: 'absolute',
      top: 5,
      right: 5,
      cursor: 'pointer',
      '&:hover': {
        top: 3,
        right: 3,
        fontSize: 28
      }
    },
    part: {
      position: 'absolute',
      width: '100%',
      bottom: 15,
      left: 0,
      textAlign: 'center',
      fontSize: 14,
      lineHeight: 1.3
    }
  });

export default style;
