import React from 'react';
import { MenuItem, Select, TableCell } from '@material-ui/core';

interface Props {
  filter: any;
  onFilter: Function;
}

const RoleFilterCell = ({ filter, onFilter }: Props) => (
  <TableCell padding="none">
    <Select
      fullWidth={true}
      value={filter ? filter.value : ''}
      onChange={e => {
        onFilter(e.target.value ? { value: e.target.value } : null);
      }}
      inputProps={{
        name: 'role',
        id: 'role'
      }}>
      <MenuItem value="">
        <em>All</em>
      </MenuItem>
      <MenuItem value="admin">Admin</MenuItem>
    </Select>
  </TableCell>
);

export default RoleFilterCell;
