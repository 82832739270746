import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { CourseType } from '../../../types';

const styles = createStyles({
  p: {
    marginBottom: 10
  },
  link: {
    textDecoration: 'none',
    color: '#3346FF',
    lineHeight: '30px'
  }
});

interface Props extends WithStyles<typeof styles> {
  course: CourseType;
  preview?: boolean;
}

const CourseSeriesBase: React.FunctionComponent<Props> = ({ course, preview = false, classes }) => {
  const { otherCoursesInSeries = [] } = course;

  const alertPreview = () => {
    // eslint-disable-next-line
    alert('This is a preview only, navigation is not possible.');
    return false;
  };

  const noopAlert = () => {
    /* empty */
  };

  return (
    <div>
      {otherCoursesInSeries.length > 0 && (
        <p className={classes.p}>
          <b>Other Courses in Series:</b>
        </p>
      )}
      {otherCoursesInSeries.map(c => (
        <div key={c.id}>
          <a href={preview ? '#' : `/courses/${c.id}`} className={classes.link} onClick={preview ? alertPreview : noopAlert}>
            Part {c.courseSeriesPart}: {c.name}
          </a>
        </div>
      ))}
    </div>
  );
};

export const CourseSeries = withStyles(styles)(CourseSeriesBase);
