import React, { Component } from 'react';
import { Button, createStyles, Grid, Icon, IconButton, InputAdornment, TextField, WithStyles, withStyles } from '@material-ui/core';

import { Select } from '../../../../../../shared';

const styles = createStyles({
  formControl: {
    width: '100%'
  }
});

interface Props extends WithStyles<typeof styles> {
  expanded?: string;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>) => void;
  handleArrayChange: (prop: string, index: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddAnswer: () => void;
  handleRemoveAnswer: (answerIndex: number) => void;
  question: any;
  submitting: boolean;
}

class AddQuestion extends Component<Props> {
  render() {
    const { classes, expanded, handleChange, handleArrayChange, handleAddAnswer, handleRemoveAnswer, question, submitting } = this.props;
    const { answers, correctAnswer, feedback } = question;

    return (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <div>
            <TextField
              id="question"
              disabled={submitting}
              fullWidth
              label="Question"
              margin="none"
              onChange={handleChange}
              required={expanded === 'question'}
              type="text"
              value={question.question}
            />
          </div>

          <Select
            onChange={handleChange}
            label="Correct Answer"
            name="correctAnswer"
            value={correctAnswer}
            options={answers.map((answer: any, i: number) => ({
              id: i,
              name: i + 1
            }))}
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={4}>
          {answers.map((a: any, index: number) => (
            <div key={index}>
              <TextField
                id="answers"
                disabled={submitting}
                fullWidth
                label={`Answer ${index + 1}`}
                margin="none"
                onChange={handleArrayChange('answers', index)}
                type="text"
                required={expanded === 'question' && index < 2}
                value={a}
                InputProps={
                  index < 2
                    ? {}
                    : {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => handleRemoveAnswer(index)}>
                              <Icon>delete</Icon>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                }
              />
            </div>
          ))}

          <br />
          <Button variant="outlined" onClick={handleAddAnswer}>
            Add Answer
          </Button>
        </Grid>
        <Grid item xs={4}>
          {feedback.map((f: any, index: number) => (
            <div key={index}>
              <TextField
                id="feedback"
                disabled={submitting}
                fullWidth
                label={`Feedback ${index + 1}`}
                margin="none"
                onChange={handleArrayChange('feedback', index)}
                type="text"
                required={expanded === 'question' && (index < 2 || !!answers[index])}
                value={f}
              />
            </div>
          ))}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AddQuestion);
