import React, { Component } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  LinearProgress,
  TextField,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DropzoneState } from 'react-dropzone';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../../../core/store';
import { CREATE_WEBINAR_VIDEO_SUCCESS } from '../../../../../constants';
import { durationToSeconds, upload } from '../../../../../utilities';

let Dropzone = require('react-dropzone');
if ('default' in Dropzone) {
  Dropzone = Dropzone.default;
}

const styles = createStyles({
  addVideo: {
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    width: 250,
    height: 141,
    marginRight: 15
  },
  addVideoButton: {
    width: '100%',
    height: '100%'
  },
  dropzone: {
    textAlign: 'center',
    border: '1px dotted',
    cursor: 'pointer',
    width: '100%'
  },
  dropzoneContainer: {
    width: '100%'
  },
  panels: {
    marginTop: 10
  },
  progressContainer: {
    flexGrow: 1
  }
});

const mapStateToProps = ({ message }: StateType) => ({ message });

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  containerState: any;
  setContainerState: Function;
}

interface State {
  displayTime: string;
  expanded: string | undefined;
  videoFile: File | undefined;
  open: boolean;
  submitting: boolean;
}

class DialogAdd extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displayTime: '',
      open: false,
      submitting: false,
      expanded: undefined,
      videoFile: undefined
    };
  }

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    if (prevState.open && newProps.message === CREATE_WEBINAR_VIDEO_SUCCESS) {
      return {
        expanded: undefined,
        open: false,
        displayTime: '',
        submitting: false,
        videoFile: undefined
      };
    }
    return {};
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDisplayTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      displayTime: event.target.value
    });
  };

  handleSave = async () => {
    const { containerState } = this.props;
    const { displayTime, videoFile } = this.state;
    const { form } = containerState;
    const { webinarPollingQuestionDisplayTimes: displayTimes = [] } = form;

    if (!videoFile && !displayTime) {
      actions.createError(new Error('Please choose an option.'));
      return;
    }

    if (displayTime) {
      const validTime = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)([0-5]?\d)$/;
      if (!validTime.test(displayTime)) {
        actions.createError(new Error('Display time input is invalid. Expected format `hh:mm:ss` or `mm:ss`.'));
        return;
      }
    }

    this.setState({
      submitting: true
    });

    const closeDialog = () => this.setState({ open: false, submitting: false });

    if (videoFile) {
      const videoUrl = await upload(videoFile);
      actions.createWebinarVideo(form.id, videoUrl);
    } else if (displayTime) {
      const webinarPollingQuestionDisplayTimes = [displayTime, ...displayTimes];
      webinarPollingQuestionDisplayTimes.sort((a, b) => {
        const aDur = durationToSeconds(a);
        const bDur = durationToSeconds(b);
        return aDur - bDur;
      });

      actions.patchWebinarPollingDisplayTimes(form.id, webinarPollingQuestionDisplayTimes);
      closeDialog();
    }
  };

  handlePanelChange = (panel: string) => (event: React.ChangeEvent, expanded: boolean) => {
    this.setState({
      displayTime: '',
      expanded: expanded ? panel : undefined,
      videoFile: undefined
    });
  };

  onDropVideo = (videoFiles: File[]) => {
    const [videoFile] = videoFiles;
    this.setState(
      {
        videoFile
      },
      this.handleSave
    );
  };

  render() {
    const { classes, containerState } = this.props;
    const { expanded, submitting, videoFile } = this.state;
    const { form } = containerState;
    const { webinarVideos = [] } = form;
    const videoLabel = webinarVideos.length ? 'Replace Video' : 'Add Video';

    return (
      <div>
        <div className={classes.addVideo}>
          <Button className={classes.addVideoButton} onClick={this.handleOpen}>
            <Icon style={{ fontSize: 60 }}>add_circle</Icon>
          </Button>
        </div>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md">
          <DialogTitle id="form-dialog-title">Upload Options</DialogTitle>
          <DialogContent>
            <div className={classes.panels}>
              <ExpansionPanel expanded={expanded === 'video'} onChange={this.handlePanelChange('video')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{videoLabel}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.dropzoneContainer}>
                    <Dropzone accept="video/*,.mp4" onDrop={this.onDropVideo} multiple={false}>
                      {({
                        getRootProps,
                        getInputProps
                      }: {
                        getRootProps: DropzoneState['getRootProps'];
                        getInputProps: DropzoneState['getInputProps'];
                      }) => (
                        <section>
                          <div className={classes.dropzone} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Drop video here, or click to select one to upload.</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {videoFile && (
                      <ul>
                        <li>{videoFile.name}</li>
                      </ul>
                    )}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'question'} onChange={this.handlePanelChange('question')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Add Question</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div>
                    <TextField
                      id="displayTime"
                      disabled={submitting}
                      fullWidth
                      label="Display Time"
                      margin="none"
                      onChange={this.handleDisplayTimeChange}
                      type="text"
                    />
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </DialogContent>
          {submitting && (
            <div className={classes.progressContainer}>
              <LinearProgress variant="query" />
            </div>
          )}
          <DialogActions>
            <Button variant="outlined" disabled={submitting} onClick={this.handleClose}>
              Cancel
            </Button>
            <Button disabled={submitting} onClick={this.handleSave} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps)(DialogAdd));
