import React, { Component } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  WithStyles,
  withStyles
} from '@material-ui/core';

import { download, saveAs } from '../../../utilities';

const styles = createStyles({
  container: {
    display: 'inline-flex'
  },
  dialogProgress: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1
  }
});

interface Props extends WithStyles<typeof styles> {
  exportRows: Function;
  classes: {
    container: string;
    dialogProgress: string;
  };
}

interface State {
  openMenu: boolean;
  openDialog: boolean;
  exporting: boolean;
  menuAnchor: HTMLElement | null;
}

class ExportViewDialogBase extends Component<Props, State> {
  timeout?: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false,
      exporting: false,
      openMenu: false,
      menuAnchor: null
    };
  }

  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      menuAnchor: event.currentTarget,
      openMenu: true
    });
  };

  handleCloseMenu = () => {
    this.setState({ openMenu: false });
  };

  handleOpenDialog = () => {
    this.setState({ openDialog: true, openMenu: false });
  };

  handleRegistrationsDownload = () => {
    this.handleCloseMenu();
    downloadRegistrants().then();
  };

  handleCoursesDownload = () => {
    this.handleCloseMenu();
    downloadCourses().then();
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleExport = (type: string) => {
    const { exportRows } = this.props;
    exportRows(type);
    this.setState({
      exporting: true
    });
    this.timeout = window.setTimeout(() => {
      this.setState({
        exporting: false,
        openDialog: false
      });
    }, 2000);
  };

  handleExportSearch = () => {
    this.handleExport('search');
  };

  handleExportAll = () => {
    this.handleExport('all');
  };

  render() {
    const { classes } = this.props;
    const { exporting } = this.state;

    return (
      <div className={classes.container}>
        <Button id="export-button" variant="contained" color="primary" onClick={this.handleOpenMenu}>
          Export
        </Button>
        <Menu id="export-menu" anchorEl={this.state.menuAnchor} open={this.state.openMenu} onClose={this.handleCloseMenu}>
          <MenuItem onClick={this.handleOpenDialog}>Courses/User Info</MenuItem>
          <MenuItem onClick={this.handleRegistrationsDownload}>Registrations</MenuItem>
          <MenuItem onClick={this.handleCoursesDownload}>Certificates</MenuItem>
        </Menu>
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {!exporting && <span>Select What To Export</span>}
            {exporting && <span>Exporting...</span>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {!exporting && <span>Export all records or just the search results.</span>}
            </DialogContentText>
            <div className={classes.dialogProgress}>{exporting && <CircularProgress />}</div>
          </DialogContent>
          <DialogActions>
            <Button id="all" disabled={exporting} onClick={this.handleExportAll} variant="outlined">
              Export All
            </Button>
            <Button id="search" disabled={exporting} onClick={this.handleExportSearch} variant="outlined">
              Export Search Results
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

async function downloadRegistrants() {
  const response = await download('/reports/registrants', {});
  if (!response) {
    return {};
  }

  const filename = 'export-registrants.csv';
  saveAs(response.data, filename);
  return {};
}

async function downloadCourses() {
  const response = await download('/reports/courses', {});
  if (!response) {
    return {};
  }

  const filename = 'export-certificates.csv';
  saveAs(response.data, filename);
  return {};
}

export const ExportViewDialog = withStyles(styles)(ExportViewDialogBase);
