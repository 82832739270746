import React, { Component } from 'react';
import { match, Route } from 'react-router-dom';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../core/store';

import { Course, CourseInstructor, SelfStudy, Webinar } from './routes';

const mapStateToProps = ({ course }: StateType) => ({
  course
});

interface UrlParameters {
  courseId: string;
}

interface Props extends WithProps<typeof mapStateToProps> {
  match: match<UrlParameters>;
}

class CourseRouterBase extends Component<Props> {
  componentDidMount() {
    const { match } = this.props;
    actions.getCourse(+match.params.courseId);
  }

  componentWillUnmount() {
    actions.unsetCourse();
  }

  render() {
    const { course, match } = this.props;

    if (!course) {
      return null;
    }

    return (
      <div>
        <Route path={match.url} exact={true} render={(props: any) => <Course {...props} course={course} />} />
        <Route path={`${match.url}/instructor`} exact={true} render={(props: any) => <CourseInstructor {...props} course={course} />} />
        <Route path={`${match.url}/self-study`} exact={true} render={(props: any) => <SelfStudy {...props} course={course} />} />
        <Route path={`${match.url}/webinar`} exact={true} render={(props: any) => <Webinar {...props} course={course} />} />
      </div>
    );
  }
}

export const CourseRouter = connect(mapStateToProps)(CourseRouterBase);
