import React from 'react';
import { createStyles, Grid, LinearProgress, Theme, WithStyles, withStyles } from '@material-ui/core';

import { CourseType } from '../../../types';

const styles = (theme: Theme) =>
  createStyles({
    averageRating: {
      margin: 0,
      fontSize: 100,
      paddingTop: 50
    },
    container: {
      margin: '0 auto',
      width: '70%',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    ratingBar: {
      display: 'inline-block',
      height: 20,
      width: 340,
      [theme.breakpoints.down('sm')]: {
        width: 260
      }
    },
    ratingBarPrimary: {
      backgroundColor: '#54d5b8'
    },
    ratingContainer: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      marginBottom: 15,
      '& span': {
        paddingLeft: 10
      }
    },
    ratingPrimary: {
      backgroundColor: '#e6e6e6'
    },
    rightPane: {
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        padding: 20
      }
    },
    leftPane: {
      textAlign: 'center'
    }
  });

interface Props extends WithStyles<typeof styles> {
  course: CourseType;
}

const CourseRatingBase: React.FunctionComponent<Props> = ({ classes, course }) => {
  const { ratings = [] } = course;
  const ratingsDefaults = [
    { star: 5, count: 0 },
    { star: 4, count: 0 },
    { star: 3, count: 0 },
    { star: 2, count: 0 },
    { star: 1, count: 0 }
  ];
  const ratingsByStar = ratings.reduce((result, rating) => {
    const existing = result.find(res => res.star === rating.rating);
    if (existing) {
      existing.count += 1;
    }
    return result;
  }, ratingsDefaults);

  const normalizeRating = (count: number) => (count > 0 ? Math.ceil((count / ratings.length) * 100) : 0);

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <div className={classes.leftPane}>
            <h1 className={classes.averageRating}>{course.averageRating}</h1>
            <h2>Average Review</h2>
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className={classes.rightPane}>
            <h2>Customer Reviews</h2>
            {ratingsByStar.map(starRating => (
              <div key={starRating.star} className={classes.ratingContainer}>
                <LinearProgress
                  classes={{
                    colorPrimary: classes.ratingPrimary,
                    barColorPrimary: classes.ratingBarPrimary
                  }}
                  className={classes.ratingBar}
                  value={normalizeRating(starRating.count)}
                  variant="determinate"
                />
                <span>{starRating.star} Star</span>
              </div>
            ))}
            <p>{ratings.length} Reviews</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export const CourseRating = withStyles(styles)(CourseRatingBase);
