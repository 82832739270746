import React, { Component } from 'react';
import { CircularProgress, createStyles, Grid, Theme, withStyles, WithStyles } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../../../core/store';

import { WebinarPassedDialog } from './webinar-passed-dialog';
import { WebinarFailedDialog } from './webinar-failed-dialog';

const styles = (theme: Theme) =>
  createStyles({
    button: {
      marginRight: 20
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 5,
      marginTop: 20,
      marginBottom: 20,
      width: 200
    },
    leaveFeedback: {
      color: theme.palette.common.black,
      fontSize: 12
    }
  });

const mapStateToProps = ({ user, userWebinars }: StateType) => ({
  user,
  userWebinars
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  course: any;
  handleContinue: () => void;
}

class WebinarClosedBase extends Component<Props> {
  // Timeout to track in progress webinars who haven't reported a final status.
  timeout?: number;

  componentDidMount() {
    this.getWebinar();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.userWebinars !== this.props.userWebinars) {
      this.getStatus();
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  getStatus = () => {
    const registeredWebinar = this.getRegisteredWebinar();

    if (!this.timeout && (!registeredWebinar || registeredWebinar.status === 'pending')) {
      this.timeout = window.setTimeout(() => {
        this.getWebinar();
      }, 2000);
    }
  };

  getWebinar = () => {
    const { user } = this.props;
    const registeredWebinar = this.getRegisteredWebinar();
    if (user && registeredWebinar) {
      actions.getUserWebinar(user.id, registeredWebinar.webinarId);
    }
  };

  getRegisteredWebinar = () => {
    const { course, userWebinars = [] } = this.props;
    return userWebinars.find(userWebinar => userWebinar.courseId === course.id);
  };

  onFailedDialogClose = (retry = false) => {
    const { handleContinue } = this.props;
    if (retry) {
      handleContinue();
    }
  };

  onPassedDialogClose = () => {
    /* empty */
  };

  render() {
    const { course } = this.props;
    const registration = this.getRegisteredWebinar();

    if (!registration) {
      return null;
    }

    const { status } = registration;
    if (status === 'failed') {
      return <WebinarFailedDialog open={true} onClose={this.onFailedDialogClose} course={course} />;
    } else if (status === 'passed') {
      return <WebinarPassedDialog open={true} onClose={this.onPassedDialogClose} course={course} />;
    } else {
      return (
        <Grid container justify="center" alignItems="center">
          <CircularProgress />
        </Grid>
      );
    }
  }
}

export const WebinarClosed = connect(mapStateToProps)(withStyles(styles)(WebinarClosedBase));
