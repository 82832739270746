import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
  WithStyles
} from '@material-ui/core';

const styles = createStyles({
  modalActions: {
    justifyContent: 'flex-start'
  }
});

interface Props extends WithStyles<typeof styles> {
  course: any;
  open: boolean;
  onClose: (retry: boolean) => void;
}

const WebinarFailedDialogBase: React.FunctionComponent<Props> = ({ course, onClose, open, classes }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const selfStudyLink = forwardRef((props: any, ref) => <Link innerRef={ref} to={`/courses/${course.id}`} {...props} />);
  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={open}
      onClose={() => onClose(false)}
      fullScreen={fullScreen}
      maxWidth={fullScreen ? false : 'md'}>
      <DialogTitle>Assessment failure</DialogTitle>
      <DialogContent>
        <Typography variant="body2">Minimum Requirements to receive credit for this webinar were not met</Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.modalActions }}>
        <Button color="primary" component={selfStudyLink} variant="contained">
          Take Self-Study
        </Button>
        <Button component={selfStudyLink} onClick={() => onClose(true)} variant="outlined">
          Reschedule Webinar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const WebinarFailedDialog = withStyles(styles)(WebinarFailedDialogBase);
