import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const FieldOfStudyTypeProvider = (props: any) => {
  const { fieldOfStudies } = props;

  const FieldOfStudyFormatter = (formatProps: any) => {
    const { value } = formatProps;
    const fieldOfStudy = fieldOfStudies.find((field: any) => field.id === value);

    if (!fieldOfStudy) {
      return <span>{value}</span>;
    }

    return <span>{fieldOfStudy.name}</span>;
  };

  return <DataTypeProvider formatterComponent={FieldOfStudyFormatter} {...props} />;
};

export default FieldOfStudyTypeProvider;
