import React, { Component } from 'react';
import { Input, MenuItem, Select, TableCell } from '@material-ui/core';

import { CREDIT_TYPES } from '../../../../constants';

interface Props {
  filter: any;
  onFilter: Function;
}

interface State {
  open: boolean;
}

class TypeFilterCell extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { onFilter } = this.props;
    const { value } = e.target;
    onFilter(value ? { value } : null);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { filter } = this.props;
    const { open } = this.state;

    const select = (
      <Select
        fullWidth={true}
        open={open}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        multiple
        onChange={this.handleSelectChange}
        input={<Input id="select-multiple-checkbox" />}
        inputProps={{
          name: 'type',
          id: 'type'
        }}
        value={filter ? filter.value : []}>
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {CREDIT_TYPES.map(type => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    );

    return <TableCell padding="none">{select}</TableCell>;
  }
}

export default TypeFilterCell;
