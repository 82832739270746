import { meanBy } from 'lodash';

import {
  CourseRatingType,
  CourseResourceType,
  CourseSelfStudyAssessmentType,
  CourseSelfStudyQuestionType,
  CourseSelfStudyVideoType,
  CourseType,
  CourseWebinarScheduledType,
  CourseWebinarVideoType
} from '../types';
import { array, boolean, number, string } from '../utilities/parse';

import { parseInstructor } from './instructor';

/**
 * Course related parsing functions for API responses.
 */

export function parseCourseRating(input: any): CourseRatingType {
  return {
    id: number(input, 'id'),
    rating: number(input, 'rating')
  };
}

export function parseCourseResource(input: any): CourseResourceType {
  return {
    id: number(input, 'id'),
    name: string(input, 'name'),
    url: string(input, 'url')
  };
}

export function parseWebinarScheduled(input: any): CourseWebinarScheduledType {
  return {
    id: number(input, 'id'),
    scheduledOn: string(input, 'scheduledOn'),
    isAuto: boolean(input, 'isAuto') // TODO: isAuto doesn't exist on CourseWebinarScheduledType
  } as any;
}

export function parseWebinarVideo(input: any): CourseWebinarVideoType {
  return {
    id: number(input, 'id'),
    duration: string(input, 'duration'),
    thumbnailUrl: string(input, 'thumbnailUrl'),
    height: number(input, 'height'), // TODO: height doesn't exist on CourseWebinarVideoType
    url: string(input, 'url'),
    updated: string(input, 'updated'),
    width: number(input, 'width')
  } as any;
}

export function parseSelfStudyAssessment(input: any): CourseSelfStudyAssessmentType {
  return {
    id: number(input, 'id'),
    answers: array(input, 'answers', string),
    correctAnswer: number(input, 'correctAnswer'),
    question: string(input, 'question'),
    updated: string(input, 'updated')
  };
}

export function parseSelfStudyQuestion(input: any): CourseSelfStudyQuestionType {
  return {
    id: number(input, 'id'),
    answers: array(input, 'answers', string),
    correctAnswer: number(input, 'correctAnswer'),
    feedback: array(input, 'feedback', string),
    position: number(input, 'position'),
    question: string(input, 'question'),
    updated: string(input, 'updated') // TODO: updated doesn't exist on CourseSelfStudyQuestionType
  } as any;
}

export function parseSelfStudyVideo(input: any): CourseSelfStudyVideoType {
  return {
    id: number(input, 'id'),
    duration: string(input, 'duration'),
    height: number(input, 'height'), // TODO: height doesn't exist on CourseSelfStudyVideoType
    thumbnailUrl: string(input, 'thumbnailUrl'),
    title: string(input, 'title'),
    position: number(input, 'position'),
    url: string(input, 'url'),
    updated: string(input, 'updated'),
    width: number(input, 'width')
  } as any;
}

export function parseCourse(input: any): CourseType {
  const ratings = array(input, 'ratings', parseCourseRating);
  const averageRating = ratings.length ? parseFloat(meanBy(ratings, 'rating').toFixed(1)) : 0;
  const categoryId = input.categoryId ? number(input, 'categoryId') : null;
  const fieldOfStudyId = input.fieldOfStudyId ? number(input, 'fieldOfStudyId') : null;
  const instructorId = input.instructorId ? number(input, 'instructorId') : null;

  return {
    id: number(input, 'id'),
    advancedPrep: string(input, 'advancedPrep'),
    averageRating,
    categoryId,
    certificatesGranted: number(input, 'certificatesGranted'), // TODO: certificatesGranted doesn't exist on CourseType
    credits: number(input, 'credits'),
    credittype: array(input, 'credittype', string),
    ctecId: string(input, 'ctecId'),
    fieldOfStudyId,
    irsId: string(input, 'irsId'),
    instructor: parseInstructor(input.instructor),
    instructorId,
    lastReview: string(input, 'lastReview'),
    lastReviewBy: string(input, 'lastReviewBy'),
    learningObjectives: string(input, 'learningObjectives'),
    level: string(input, 'level'),
    logoUrl: string(input, 'logoUrl'),
    name: string(input, 'name'),
    overview: string(input, 'overview'),
    published: string(input, 'published'),
    publishedByName: string(input, 'publishedByName'),
    ratings,
    registrations: number(input, 'registrations'),
    resources: array(input, 'resources', parseCourseResource),
    selfStudyAssessments: array(input, 'selfStudyAssessments', parseSelfStudyAssessment),
    selfStudyVideos: array(input, 'selfStudyVideos', parseSelfStudyVideo),
    selfStudyQuestions: array(input, 'selfStudyQuestions', parseSelfStudyQuestion),
    status: string(input, 'status'),
    updated: string(input, 'updated'),
    updatedByName: string(input, 'updatedByName'),
    url: `/admin/courses/${input.id}`,
    webinarPollingQuestionDisplayTimes: array(input, 'webinarPollingQuestionDisplayTimes', string),
    webinarRegistrations: number(input, 'webinarRegistrations'),
    scheduledWebinarsCount: number(input, 'scheduledWebinarsCount'),
    webinarScheduled: array(input, 'webinarScheduled', parseWebinarScheduled),
    webinarVideos: array(input, 'webinarVideos', parseWebinarVideo),
    youtubeUrl: string(input, 'youtubeUrl'),
    isRecurring: boolean(input, 'isRecurring'),
    recurringTime: string(input, 'recurringTime'),
    recurringDay: number(input, 'recurringDay'),
    recurringWeeks: number(input, 'recurringWeeks'),
    courseSeriesId: input.courseSeriesId,
    courseSeriesPart: number(input, 'courseSeriesPart'),
    seriesPartsCount: number(input, 'seriesPartsCount'),
    otherCoursesInSeries: input.otherCoursesInSeries || []
  } as any;
}

export function parseCourses(input: any[]): CourseType[] {
  return input.map(row => parseCourse(row));
}
