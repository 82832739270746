import React, { Component } from 'react';
import { Button, createStyles, Dialog, DialogTitle, Grid, Paper, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { differenceInMilliseconds, differenceInSeconds, isAfter, isBefore } from 'date-fns';
import { WithProps } from 'react-waterfall';
import { Prompt } from 'react-router';

import { actions, connect, StateType } from '../../../core/store';
import { Player, UserAvatar } from '../../../shared';
import { CourseType } from '../../../types';

import { DialogPollingQuestionLauncher } from './dialog-polling-question-launcher';
import { WebinarNoticeDialog } from './webinar-notice-dialog/webinar-notice-dialog';
import { NavigationBlocker } from './navigation-blocker';

const downloadIcon = require('../../../../images/icons/download.png');

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      marginRight: 10
    },
    commentAvatar: {
      marginTop: 20
    },
    commentAvatarContainer: {
      textAlign: 'right'
    },
    commentContainer: {
      [theme.breakpoints.down('sm')]: {
        margin: 10
      }
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 2,
      margin: '15px 0',
      width: 140
    },
    downloadIcon: {
      paddingTop: 2
    },
    instructor: {
      color: (theme as any).canopy.disabled,
      fontWeight: 'bold',
      paddingTop: 10,
      paddingBottom: 10
    },
    joinDiscussion: {
      color: theme.palette.common.black
    },
    joinDiscussionHeader: {
      textAlign: 'center'
    },
    leftPane: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      display: 'inline-block',
      height: '100%',
      padding: '30px 20px',
      [theme.breakpoints.down('sm')]: {
        padding: '18px 12px'
      },
      width: '100%'
    },
    overviewHeader: {
      fontSize: 32,
      fontWeight: 900
    },
    overviewMeta: {
      fontSize: 16,
      margin: '8px 0px'
    },
    resource: {
      textDecoration: 'none',
      color: 'black',
      fontWeight: 600,
      verticalAlign: 'top'
    },
    resources: {
      margin: '0px 40px 40px'
    },
    rightPane: {
      padding: 20,
      [theme.breakpoints.down('sm')]: {
        padding: 10
      }
    }
  });

const mapStateToProps = ({ fieldOfStudies, jwt, userWebinars }: StateType) => ({
  fieldOfStudies,
  jwt,
  userWebinars
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  course: CourseType;
  preview?: boolean;
}

interface State {
  playing: boolean;
  blockTransition: boolean;
  player: Record<string, any> | null;
}

class WebinarBase extends Component<Props, State> {
  hasAutoPlay = true;
  // player: Record<string, any> | null = null;
  timeout?: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      playing: false,
      blockTransition: false,
      player: null
    };
  }

  componentDidMount() {
    const { jwt } = this.props;
    actions.getUserWebinars(jwt.userId);
  }

  componentDidUpdate() {
    this.play();
  }

  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  handleEventPlaying = () => {
    this.setState({ blockTransition: true });
    if (!this.hasAutoPlay) {
      this.seek();
    }
  };

  handleManualPlay = async () => {
    const { player } = this.state;
    if (!player) {
      return;
    }
    await player.play();
    this.seek();
    this.hasAutoPlay = true;
    this.forceUpdate();
  };

  play = () => {
    const { player } = this.state;
    if (!player) {
      return;
    }
    const { course, userWebinars } = this.props;

    if (!course || !userWebinars || this.state.playing) {
      return;
    }

    const registration = userWebinars.find(userWebinar => userWebinar.courseId === course.id);

    if (!registration) {
      return;
    }

    const scheduledOn = new Date(registration.scheduledOn);
    const now = new Date();

    if (isBefore(now, scheduledOn) && !this.timeout) {
      const milliSecondsToStart = differenceInMilliseconds(scheduledOn, now);
      this.timeout = window.setTimeout(() => this.play(), milliSecondsToStart);
    } else if (isAfter(now, scheduledOn)) {
      this.setState({ playing: true }, async () => {
        try {
          await player.play();
          this.seek();
        } catch (exception) {
          this.hasAutoPlay = false;
          this.forceUpdate();
        }
      });
    }
  };

  setPlayer = (player: any) => {
    this.setState({ player });
  };

  seek = () => {
    const { player } = this.state;
    if (!player) {
      return;
    }
    const { course, userWebinars } = this.props;

    if (!course || !userWebinars) {
      return;
    }

    const registration = userWebinars.find(userWebinar => userWebinar.courseId === course.id);

    if (!registration) {
      return;
    }

    const { scheduledOn } = registration;
    const scheduledOnDate = new Date(scheduledOn);
    const now = new Date();
    const secondsSinceStart = differenceInSeconds(now, scheduledOnDate);
    if (secondsSinceStart > 1) {
      player.forward(secondsSinceStart);
    }
  };

  videoEnded = (): void => {
    this.setState({ blockTransition: false });
  };

  render() {
    const { classes, course, fieldOfStudies, preview, userWebinars = [] } = this.props;
    const { blockTransition, player } = this.state;
    const registration = userWebinars.find(userWebinar => userWebinar.courseId === course.id);
    if (!registration && !preview) {
      return null;
    }

    const fieldOfStudy = fieldOfStudies.find(s => s.id === course.fieldOfStudyId);
    const { credittype = [], instructor, webinarVideos = [] } = course;
    const [webinarVideo] = webinarVideos;
    const playerConfig = {
      controls: !preview
        ? ['fullscreen', 'mute', 'volume']
        : ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'pip', 'airplay', 'fullscreen'],
      clickToPlay: false,
      muted: false
    };

    const showJoinDialog = !this.hasAutoPlay && this.state.playing;

    return (
      <Paper>
        <Grid container spacing={0}>
          <Grid item xs={12} md={3}>
            <div className={classes.leftPane}>
              <Typography variant="h3" gutterBottom>
                Course Overview
              </Typography>
              <div className={classes.divider} />

              <p className={classes.overviewMeta}>
                <b>Field Of Study:</b> {fieldOfStudy ? fieldOfStudy.name : ''}
              </p>
              <p className={classes.overviewMeta}>
                <b>Credit Type:</b> {credittype.join(', ')}
              </p>
              <p className={classes.overviewMeta}>
                <b>Credit Hours:</b> {course.credits}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={9}>
            <div className={classes.rightPane}>
              {instructor.id > 0 && (
                <div className={classes.instructor}>
                  <UserAvatar classes={{ avatar: classes.avatar }} url={course.instructor.avatarUrl} height={45} width={45} />
                  by {course.instructor.firstName} {course.instructor.lastName}
                </div>
              )}
              <Typography variant="h3" gutterBottom>
                {course.name}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <NavigationBlocker navigationBlocked={false} />
            {webinarVideo && (
              <Player
                config={playerConfig}
                handleEventPlaying={this.handleEventPlaying}
                onEnded={this.videoEnded}
                setPlayer={this.setPlayer}
                video={webinarVideo}
              />
            )}
            {!webinarVideo && <p>Upload a webinar video to preview.</p>}
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className={classes.resources}>
              <h3>Resource Files</h3>

              {course.resources.map((resource, index) => (
                <div key={index}>
                  {!!resource.url && (
                    <a className={classes.resource} href={resource.url || '#'} rel="noreferrer noopener" target="_blank">
                      <img className={classes.downloadIcon} src={downloadIcon} />
                      &nbsp;&nbsp;
                      {resource.name || resource.url}
                    </a>
                  )}
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
        <Prompt when={blockTransition} message="Are you sure you want to leave this webinar" />
        {!preview && !!player && <DialogPollingQuestionLauncher course={course} player={player} userWebinars={userWebinars} />}
        {!preview && !!player && <WebinarNoticeDialog course={course} player={player} userWebinars={userWebinars} />}
        <Dialog open={showJoinDialog} aria-labelledby="webinar-started-title">
          <DialogTitle id="webinar-started-title">Webinar Has Started</DialogTitle>
          {/*
                // @ts-ignore */}
          <center>
            <Button color="primary" variant="contained" onClick={this.handleManualPlay}>
              Join
            </Button>
            {/*
                // @ts-ignore */}
          </center>
          <br />
          <br />
        </Dialog>
      </Paper>
    );
  }
}

export const Webinar = connect(mapStateToProps)(withStyles(styles)(WebinarBase));
