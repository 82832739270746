import React, { Component } from 'react';
import { createStyles, Grid, Paper, Tab, Tabs, Theme, WithStyles, withStyles } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../core/store';
import { CoursePanel } from '../shared';

import { SelfStudyPanel } from './self-study-panel';
import { WebinarPanel } from './webinar-panel';

const styles = (theme: Theme) =>
  createStyles({
    courses: {
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap'
    },
    coursesContainer: {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
      }
    },
    header: {
      fontSize: 42,
      fontWeight: 200,
      marginBottom: 50,
      padding: 0
    },
    headerArrow: {
      fontSize: '2rem'
    },
    section: {
      marginBottom: 60
    },
    tabBarContainer: {
      marginBottom: 18
    },
    tab: {
      textTransform: 'none',
      fontWeight: 700,
      margin: '0 18px',
      color: '#040D14'
    },
    tabLabelContainer: {
      padding: '17px 5px 12px'
    },
    tabLabel: {
      fontSize: 21
    },
    tabsIndicator: {
      backgroundColor: theme.palette.primary.main,
      height: 3
    }
  });

const mapStateToProps = ({ jwt, userSelfStudies, userWebinars, savedCourses }: StateType) => ({
  jwt,
  userSelfStudies,
  userWebinars,
  savedCourses
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  location: any;
}

interface State {
  displayWebinar: boolean;
  displaySelfStudy: boolean;
  currentTab: number;
}

class MyCoursesBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      displaySelfStudy: true,
      displayWebinar: true,
      currentTab: +window.location.hash.replace(/^#/, '')
    };
  }

  componentDidMount() {
    const { jwt, location } = this.props;
    actions.getUserSelfStudyDetails(jwt.userId, true);
    actions.getUserWebinarDetails(jwt.userId, true);
    actions.getUserSavedCourseDetails(jwt.userId);

    if (location.state && location.state.tab) {
      this.setState({ currentTab: location.state.tab });
    }
  }

  handleChangeTab = (e: any, tab: number) => {
    window.location.hash = `${tab}`;
    this.setState({ currentTab: tab });
  };

  handleRemoveWebinar = (userWebinar: any) => () => {
    const { courseId, webinarId } = userWebinar;
    if (
      // eslint-disable-next-line
      confirm('Are you sure you want to cancel your webinar registration?')
    ) {
      actions.unregisterWebinar(courseId, webinarId);
    }
  };

  handleRemoveSelfStudy = (userSelfStudy: any) => () => {
    const { courseId } = userSelfStudy;
    if (
      // eslint-disable-next-line
      confirm('Are you sure you want to cancel your self study?')
    ) {
      actions.unregisterSelfStudy(courseId);
    }
  };

  handleRemoveSavedCourse = (savedCourse: any) => () => {
    const { courseId } = savedCourse;
    actions.unsaveUserCourse(courseId);
  };

  render() {
    const { classes, userSelfStudies = [], userWebinars = [], savedCourses = [] } = this.props;

    const tabClasses = {
      root: classes.tab
    };

    return (
      <div>
        <Paper className={classes.tabBarContainer}>
          <Tabs classes={{ indicator: classes.tabsIndicator }} centered value={this.state.currentTab} onChange={this.handleChangeTab}>
            <Tab label="Webinars" classes={tabClasses} />
            <Tab label="Self Study" classes={tabClasses} />
            <Tab label="Saved" classes={tabClasses} />
          </Tabs>
        </Paper>

        <Grid container spacing={2} className={classes.coursesContainer}>
          {this.state.currentTab === 0 &&
            userWebinars.map(userWebinar => (
              <Grid item key={userWebinar.id}>
                <WebinarPanel userWebinar={userWebinar} onRemove={this.handleRemoveWebinar(userWebinar)} />
              </Grid>
            ))}

          {this.state.currentTab === 1 &&
            userSelfStudies.map(userSelfStudy => (
              <Grid item key={userSelfStudy.id}>
                <SelfStudyPanel userSelfStudy={userSelfStudy} onRemove={this.handleRemoveSelfStudy(userSelfStudy)} />
              </Grid>
            ))}

          {this.state.currentTab === 2 &&
            savedCourses.map(savedCourse => (
              <Grid item key={savedCourse.id}>
                <CoursePanel course={savedCourse.course} removeEnabled={true} onRemove={this.handleRemoveSavedCourse(savedCourse)} />
              </Grid>
            ))}
        </Grid>
      </div>
    );
  }
}

export const MyCourses = connect(mapStateToProps)(withStyles(styles)(MyCoursesBase));
