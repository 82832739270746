import { request } from '../../utilities';
import { parseCategories, parseFieldOfStudies } from '../../parse';

/**
 * Get course categories and extract fields of study into the store.
 */
export async function getCategories() {
  const { data, error } = await request('get', '/courses/categories');
  if (error) {
    return { error: 'Unable to load course categories.  Please refresh your screen.' };
  }
  const categories = parseCategories(data);
  const fieldOfStudies = parseFieldOfStudies(data);
  return { categories, fieldOfStudies };
}
