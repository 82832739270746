import { request } from '../../utilities';
import { StateType } from '../store';

/**
 * Create self study rating.
 *
 */
export async function createSelfStudyRating(state: StateType, _a: any, courseId: number, rating: number) {
  const { error } = await request('post', `/courses/${courseId}/ratings`, { rating });

  if (error) {
    return { error: error.message };
  }

  const { userSelfStudies = [] } = state;
  const userSelfStudy = userSelfStudies.find(selfStudy => selfStudy.courseId === courseId);

  if (userSelfStudy) {
    userSelfStudy.rated = true;
  }

  return {
    userSelfStudies: [...userSelfStudies],
    message: 'Self study rating saved!'
  };
}

/**
 * Create webinar rating.
 *
 */
export async function createWebinarRating(state: StateType, _a: any, courseId: number, rating: number) {
  const { error } = await request('post', `/courses/${courseId}/ratings`, { rating });

  if (error) {
    return { error: error.message };
  }

  const { userWebinars = [] } = state;
  const userWebinar = userWebinars.find(webinar => webinar.courseId === courseId);

  if (userWebinar) {
    userWebinar.rated = true;
  }

  return {
    userWebinars: [...userWebinars],
    message: 'Webinar rating saved!'
  };
}
