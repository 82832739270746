import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { isEqual } from 'lodash';
import { createStyles, Grid, LinearProgress, Theme, withStyles, WithStyles } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../core/store';
import { CoursePanel, RefineViewButton, SearchButton, Select } from '../shared';
import { isWithinDisplayInterval } from '../utilities/schedule';

import { RefineViewDialog } from './refine-view-dialog';

const SORT_BYS = [
  { id: 'scheduledOn', name: 'Upcoming' },
  { id: 'topRated', name: 'Top Rated' },
  { id: 'popular', name: 'Most Popular' },
  { id: 'published', name: 'Recently Added' }
];

const styles = (theme: Theme) =>
  createStyles({
    coursesContainer: {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
      }
    },
    filters: {
      marginBottom: 20
    },
    formControl: {
      textAlign: 'left',
      verticalAlign: 'top',
      minWidth: 180
    },
    searchButton: {
      [theme.breakpoints.down('sm')]: {
        order: -1,
        width: '100%'
      }
    }
  });

const rowsPerPage = 1000;

const mapStateToProps = ({ courses, fieldOfStudies, coursesTotal: totalCount, courseListState }: StateType) => ({
  courses,
  fieldOfStudies,
  totalCount,
  courseListState
});

type Props = WithStyles<typeof styles> & WithProps<typeof mapStateToProps>;

interface State {
  categoryId: number[];
  credittype: string[];
  currentPage: number;
  fieldOfStudyId: number[];
  loading: boolean;
  lastQueryParams: any;
  refineViewOpen: boolean;
  search: string;
  sortBy: string;
}

class CourseList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      categoryId: [],
      credittype: [],
      currentPage: 1,
      fieldOfStudyId: [],
      lastQueryParams: {},
      loading: false,
      refineViewOpen: false,
      search: '',
      sortBy: 'published'
    };
  }

  componentDidMount() {
    const { courseListState } = this.props;

    if (courseListState) {
      // @ts-ignore TODO: figure out what courseListState is exactly to correct the typing
      this.setState(courseListState, this.getRows);
    } else {
      this.getRows();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.state.loading && prevProps.courses !== this.props.courses) {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    actions.setCourseListState({
      ...this.state,
      lastQueryParams: {} // don't persist last query params or view won't re-render
    });
    actions.unsetCourses();
  }

  changeSearch = (search: string) => {
    if (search.length > 0) {
      this.setState({ search }, this.getRows);
    } else {
      this.setState({ search: '' }, this.getRows);
    }
  };

  handleFormChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    const key = event.target.name || event.target.id;

    const newState: any = {
      [key]: event.target.value
    };
    this.setState(newState, this.getRows);
  };

  handleCloseRefineView = () => {
    this.setState({
      refineViewOpen: false
    });
  };

  handleOpenRefineView = () => {
    this.setState({
      refineViewOpen: true
    });
  };

  handleChangePage = (currentPage: number) => () => {
    this.setState({ currentPage }, this.getRows);
  };

  handleRefineViewChange = (query: any) => {
    const { categoryId, credittype, fieldOfStudyId } = query;
    this.setState({ categoryId, credittype, fieldOfStudyId }, this.refreshView);
  };

  refreshView = () => {
    this.setState(
      {
        refineViewOpen: false
      },
      this.getRows
    );
  };

  queryParams() {
    const { categoryId, credittype, currentPage, fieldOfStudyId, search, sortBy } = this.state;
    const query: any = {
      categoryId: categoryId.slice(), // clone array so updates aren't referenced
      credittype: credittype.slice(),
      fieldOfStudyId: fieldOfStudyId.slice(),
      rowsPerPage
    };

    if (sortBy) {
      query.order = sortBy;
      query.direction = sortBy === 'published' ? 'desc' : 'asc';
    }

    if (search) {
      query.search = search;
    }
    query.page = currentPage;

    return query;
  }

  getRows = () => {
    const queryParams = this.queryParams();

    if (isEqual(queryParams, this.state.lastQueryParams)) {
      return;
    }

    this.setState({
      loading: true,
      lastQueryParams: queryParams
    });

    actions.getAvailableCourses(queryParams);
  };

  render() {
    const { courses, classes } = this.props;
    const { loading, refineViewOpen, sortBy } = this.state;

    const filteredCourses = courses
      .map(course => ({
        ...course,
        webinarScheduled: course.webinarScheduled.filter(w => isWithinDisplayInterval(new Date(w.scheduledOn)))
      }))
      .filter(c => c.selfStudyVideos.length > 0 || c.webinarScheduled.length > 0);

    return (
      <div>
        <Helmet title="Course Discovery" />
        {loading && <LinearProgress variant="query" />}
        <Grid container className={classes.filters} spacing={1}>
          <Grid item>
            <RefineViewButton className={classes.formControl} onClick={this.handleOpenRefineView}>
              Refine View
            </RefineViewButton>
          </Grid>

          <Grid item>
            <Select
              className={classes.formControl}
              color="white"
              onChange={this.handleFormChange}
              name="sortBy"
              options={SORT_BYS}
              value={sortBy}
            />
          </Grid>

          <Grid item className={classes.searchButton}>
            <SearchButton changeSearch={this.changeSearch} placeholder="Search Course Name" />
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.coursesContainer}>
          {filteredCourses.map(course => (
            <Grid item key={course.id}>
              <CoursePanel course={course} />
            </Grid>
          ))}
        </Grid>
        <RefineViewDialog
          containerState={this.state}
          open={refineViewOpen}
          handleClose={this.handleCloseRefineView}
          handleChange={this.handleRefineViewChange}
        />
      </div>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps)(CourseList));
