import { getFeatureToggles } from '../../utilities';
import { StateType } from '../store';

export async function fetchFeatureToggles(_s: StateType, actions: any, features: string | string[]) {
  try {
    actions.setFeatureTogglesLoading(true);

    const data = await getFeatureToggles(features);
    return { featureToggles: { data, loading: false } };
  } catch (e) {
    actions.setFeatureTogglesLoading(false);
    return { error: e.message };
  }
}

export async function setFeatureTogglesLoading({ featureToggles: { data } }: any, actions: any, loading: boolean) {
  return { featureToggles: { data, loading } };
}
