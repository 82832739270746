import React, { Component, forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link, match, Redirect } from 'react-router-dom';
import { Button, createStyles, Grid, Paper, TextField, WithStyles, withStyles } from '@material-ui/core';
import { WithProps } from 'react-waterfall';
import { DropzoneState } from 'react-dropzone';

import { actions, connect, StateType } from '../../core/store';
import { upload } from '../../utilities';

let Dropzone = require('react-dropzone');
if ('default' in Dropzone) {
  Dropzone = Dropzone.default;
}

const styles = createStyles({
  actionButtons: {
    textAlign: 'right',

    '& a': {
      marginRight: 20
    },
    '& button': {
      marginRight: 20
    }
  },

  dropzone: {
    margin: '40px 20px 0px',
    textAlign: 'center',
    border: '1px dotted',
    cursor: 'pointer'
  },

  paper: {
    margin: '40px 0px',
    padding: 60
  }
});

const mapStateToProps = ({ instructors }: StateType) => ({
  instructors
});

interface UrlParameters {
  instructorId: string;
}

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  match: match<UrlParameters>;
}

interface State {
  avatarFile: File | undefined;
  form: any;
  redirect: boolean;
  submitting: boolean;
}

class InstructorEditBase extends Component<Props, State> {
  instructorsLink = forwardRef((props: any, ref) => <Link innerRef={ref} to="/admin/instructors" {...props} />);

  constructor(props: Props) {
    super(props);
    this.state = {
      form: {} as any,
      redirect: false,
      submitting: false,
      avatarFile: undefined
    };
  }

  componentDidMount(): void {
    actions.getInstructors();
  }

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    const { match } = newProps;
    const { instructorId } = match.params;
    const instructor = newProps.instructors.find(q => q.id === parseInt(instructorId, 10));

    if (instructor) {
      if (prevState.form.updated !== instructor.updated) {
        const redirect = !!prevState.form.id;
        return {
          form: instructor,
          submitting: false,
          redirect
        };
      }
    }
    return {};
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { avatarFile, form } = this.state;

    if (!avatarFile && !form.avatarUrl) {
      actions.createError(new Error('Avatar image is required.'));
      return;
    }

    this.setState(
      {
        submitting: true
      },
      async () => {
        let avatarUrl;
        if (avatarFile) {
          avatarUrl = await upload(avatarFile);
        }
        // eslint-disable-next-line
        const { id, url, updated, ...data } = form;
        actions.updateInstructor(id, {
          ...data,
          avatarUrl
        });
      }
    );
  };

  handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    this.setState(state => ({
      form: {
        ...state.form,
        [event.target.id]: event.target.value
      }
    }));
  };

  onDrop = (avatars: File[]) => {
    const avatarFile = avatars[0];
    this.setState({
      avatarFile
    });
  };

  render() {
    const { classes } = this.props;
    const { avatarFile, form, redirect, submitting } = this.state;

    if (!form.id) {
      return null;
    }

    if (redirect) {
      return <Redirect to="/admin/instructors" />;
    }

    return (
      <Paper className={classes.paper}>
        <Helmet title={`Edit Instructor ${form.firstName} ${form.lastName}`} />
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.actionButtons}>
              <Button disabled={submitting} component={this.instructorsLink} color="primary">
                Cancel
              </Button>
              <Button disabled={submitting} type="submit" color="primary" variant="contained">
                Update
              </Button>
            </Grid>
            <Grid item xs={6}>
              <div>
                <TextField
                  id="firstName"
                  defaultValue={form.firstName}
                  disabled={submitting}
                  label="First Name"
                  fullWidth
                  onChange={this.handleFormChange}
                  required={true}
                />
              </div>
              <div>
                <TextField
                  id="lastName"
                  defaultValue={form.lastName}
                  disabled={submitting}
                  label="Last Name"
                  fullWidth
                  onChange={this.handleFormChange}
                  required={true}
                />
              </div>
              <div>
                <TextField
                  id="email"
                  defaultValue={form.email}
                  disabled={submitting}
                  label="Email Address"
                  type="email"
                  fullWidth
                  onChange={this.handleFormChange}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div>
                <TextField
                  id="company"
                  defaultValue={form.company}
                  disabled={submitting}
                  label="Company"
                  multiline
                  fullWidth
                  onChange={this.handleFormChange}
                />
              </div>
              <div>
                <TextField
                  id="companyUrl"
                  defaultValue={form.companyUrl}
                  disabled={submitting}
                  label="CompanyUrl"
                  type="url"
                  multiline
                  fullWidth
                  onChange={this.handleFormChange}
                />
              </div>
              <div>
                <TextField
                  id="phone"
                  defaultValue={form.phone}
                  disabled={submitting}
                  label="Phone"
                  type="tel"
                  multiline
                  fullWidth
                  onChange={this.handleFormChange}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <TextField
                  id="biography"
                  defaultValue={form.biography}
                  disabled={submitting}
                  label="Biography"
                  multiline
                  fullWidth
                  onChange={this.handleFormChange}
                  required={true}
                />
              </div>
              <div>
                <Dropzone onDrop={this.onDrop} accept="image/*," multiple={false}>
                  {({
                    getRootProps,
                    getInputProps
                  }: {
                    getRootProps: DropzoneState['getRootProps'];
                    getInputProps: DropzoneState['getInputProps'];
                  }) => (
                    <section>
                      <div className={classes.dropzone} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drop an avatar here, or click to select one to upload.</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {avatarFile && (
                  <ul>
                    <li>{avatarFile.name}</li>
                  </ul>
                )}
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

export const InstructorEdit = withStyles(styles)(connect(mapStateToProps)(InstructorEditBase));
