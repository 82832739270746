import React, { Component } from 'react';
import { Button, Chip, createStyles, Grid, WithStyles, withStyles } from '@material-ui/core';

import { actions } from '../../../../../core/store';
import { toTitleCase } from '../../../../../utilities';
import { CourseType } from '../../../../../types';

import Preview from './preview';

const styles = createStyles({
  actions: {
    textAlign: 'right'
  },
  button: {
    marginRight: 20
  },
  chip: {
    marginRight: 20
  },
  part: {
    color: '#3346FF',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.3,
    marginRight: 10
  }
});

interface Props extends WithStyles<typeof styles> {
  course: CourseType;
  onCancelClick: () => void;
  preview: string;
  submitting: boolean;
  formHasChanges: boolean;
}

class ActionButtons extends Component<Props> {
  handleArchive = () => {
    if (
      // eslint-disable-next-line
      confirm(
        'Are you sure you want to change the selected course statuses to archived?  Remaining webinars will run as scheduled, and no additional manual or automatic webinars can be scheduled once a course is archived.'
      )
    ) {
      const { course } = this.props;
      actions.patchCourse(course.id, { status: 'archived' });
    }
  };

  render() {
    const { classes, course, onCancelClick, preview, submitting, formHasChanges } = this.props;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.actions}>
            {course.courseSeriesId && (
              <span className={classes.part}>
                Part {course.courseSeriesPart} of {course.seriesPartsCount}
              </span>
            )}
            {course.status && <Chip label={toTitleCase(course.status)} className={classes.chip} />}
            <Button className={classes.button} disabled={submitting || !formHasChanges} onClick={onCancelClick} variant="outlined">
              Cancel
            </Button>

            {course.status !== 'archived' && (
              <Button className={classes.button} variant="outlined" disabled={submitting} onClick={this.handleArchive}>
                Archive
              </Button>
            )}
            <Preview course={course} disabled={submitting} preview={preview} />
            <Button className={classes.button} variant="contained" disabled={submitting || !formHasChanges} color="primary" type="submit">
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ActionButtons);
