import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../core/store';
import { TableGrid } from '../shared';

const config = {
  columns: [
    { name: 'name', title: 'Course' },
    { name: 'scheduledOn', title: 'Scheduled' },
    { name: 'webinarRegistrations', title: 'Registrants' },
    { name: 'credits', title: 'Credits' },
    { name: 'credittype', title: 'Credit Type' },
    { name: 'level', title: 'Level' },
    { name: 'categoryId', title: 'Category' },
    { name: 'fieldOfStudyId', title: 'Field Of Study' },
    { name: 'instructor', title: 'Instructor' },
    { name: 'id', title: 'ID' }
  ],
  columnExtensions: [
    { columnName: 'name', width: 280 },
    { columnName: 'credits', width: 90 },
    { columnName: 'categoryId', width: 180 },
    { columnName: 'fieldOfStudyId', width: 200 },
    { columnName: 'id', width: 100 },
    { columnName: 'credittype', width: 120 },
    { columnName: 'level', width: 120 },
    { columnName: 'scheduledOn', width: 180 },
    { columnName: 'instructor', width: 200 }
  ],
  dateColumns: ['scheduledOn'],
  filteringStateColumnExtensions: [
    { columnName: 'instructor', filteringEnabled: false },
    { columnName: 'webinarRegistrations', filteringEnabled: false }
  ],
  sortingStateColumnExtensions: [
    { columnName: 'instructor', sortingEnabled: false },
    { columnName: 'webinarRegistrations', sortingEnabled: false }
  ]
};

const mapStateToProps = ({ adminColumns, webinarsTotal: totalCount, webinars }: StateType) => ({
  adminColumns,
  totalCount,
  webinars
});

type Props = WithProps<typeof mapStateToProps>;

class WebinarListBase extends Component<Props> {
  componentDidMount(): void {
    actions.getAdminColumns();
  }

  exportRows = (method: string, params: Record<string, any> = {}) => {
    actions.exportWebinars(method, params);
  };

  loadRows = (params: Record<string, any>) => {
    actions.getWebinars(params);
  };

  render() {
    const { webinars, totalCount, adminColumns } = this.props;
    return (
      <div>
        <Helmet title="Webinar List" />
        <TableGrid
          actions={actions}
          adminColumns={adminColumns}
          config={config}
          exportRows={this.exportRows}
          model="webinars"
          rows={webinars}
          totalCount={totalCount}
          loadRows={this.loadRows}
        />
      </div>
    );
  }
}

export const WebinarList = connect(mapStateToProps)(WebinarListBase);
