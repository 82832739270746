import React, { forwardRef } from 'react';
import { format, isValid } from 'date-fns';
import { Tooltip } from '@material-ui/core';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const DateDisplay = forwardRef(({ date }: { date: string }, ref: any) => <div ref={ref}>{date}</div>);
const DateFormatter = ({ value }: { value: null | string }) => {
  if (!value) {
    return null;
  }
  const valueAsDate = new Date(value);
  if (isValid(valueAsDate)) {
    const dateDisplay = format(valueAsDate, 'MMM do yyyy, h:mm a');
    return (
      <Tooltip title={dateDisplay}>
        <DateDisplay date={dateDisplay} />
      </Tooltip>
    );
  }

  return null;
};

// @ts-ignore
const DateTypeProvider = ({ for: forProp, ...others }) => <DataTypeProvider formatterComponent={DateFormatter} for={forProp} {...others} />;

export default DateTypeProvider;
