import { InstructorType } from '../types';
import { number, string } from '../utilities/parse';

/**
 * Instructor related parsing functions for API responses.
 */
export function parseInstructor(input: any = {}): InstructorType {
  return {
    id: number(input, 'id'),
    avatarUrl: string(input, 'avatarUrl'),
    biography: string(input, 'biography'),
    company: string(input, 'company'),
    companyUrl: string(input, 'companyUrl'),
    email: string(input, 'email'),
    firstName: string(input, 'firstName'),
    lastName: string(input, 'lastName'),
    phone: string(input, 'phone'),
    updated: string(input, 'updated'),
    url: input ? `/admin/instructors/${input.id}` : '' // TODO: fix - this has to be cast because url isn't defined on InstructorType
  } as any;
}

export function parseInstructors(input: any[]): InstructorType[] {
  return input.map(row => parseInstructor(row));
}
