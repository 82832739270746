/* eslint-disable no-param-reassign */

import axios from 'axios';

import { isProductionEnv } from './environment';

function getFeatureFromStorage(feature: string): null | boolean {
  const featureFromStorage = window.localStorage.getItem(`feature:${feature}`);
  return featureFromStorage == null ? null : featureFromStorage.trim().toLowerCase() === 'true';
}

function loadFeaturesFromStorage(features: string[]): { [key: string]: boolean } {
  return features
    .map(feature => ({ feature, value: getFeatureFromStorage(feature) }))
    .filter(feature => feature.value != null)
    .reduce((obj: Record<string, boolean>, next) => {
      obj[next.feature] = next.value as boolean;
      return obj;
    }, {});
}

function generateFeatureUri(feature: string[]) {
  return feature.map(singleFeature => `feature=${singleFeature}`).join('&');
}

// TODO: extract the logic for getting the environment and what storage to use outside of this function
export function getFeatureToggles(features: string | string[]): Promise<{ [key: string]: boolean }> {
  if (!Array.isArray(features)) {
    features = [features];
  }
  const storageFeatures = loadFeaturesFromStorage(features);

  // only search for features not in the localstorage
  const uriFeatures = [...features];
  Object.keys(storageFeatures).forEach(feature => {
    const indexOfFeature = uriFeatures.indexOf(feature);
    if (indexOfFeature > -1) {
      uriFeatures.splice(indexOfFeature, 1);
    }
  });

  // no end-point query required
  if (uriFeatures.length === 0) {
    return Promise.resolve(storageFeatures);
  }

  const environment = isProductionEnv() ? 'Production' : 'stage'; // TODO: check if endpoint is no longer case-sensitive
  const options: Record<string, any> = {
    method: 'get',
    url: `https://tm.canopytax.com/api/envs/${environment}/toggles?${generateFeatureUri(uriFeatures)}`
  };

  return axios(options)
    .then(result => ({
      ...result.data,
      ...storageFeatures
    }))
    .catch(() => {
      const uriFeaturesOff = uriFeatures.reduce((obj: Record<string, boolean>, feature) => {
        obj[feature] = false;
        return obj;
      }, {});

      return {
        ...uriFeaturesOff,
        ...storageFeatures
      };
    });
}
