import { request } from '../../utilities';
import { parsePollingQuestion, parsePollingQuestions } from '../../parse';
import { UPDATE_POLLING_QUESTION_SUCCESS } from '../../constants';
import { StateType } from '../store';

function createErrorState(error: Error) {
  return { error: error.message };
}

/**
 * Create polling question answer.
 */
export async function answerPollingQuestion(_s: StateType, _a: any, questionId: number, params: any) {
  const { error } = await request('post', `/polling-questions/${questionId}/answer`, params);
  if (error) {
    return createErrorState(error);
  }
  return { message: 'Your answer has been recorded.' };
}

/**
 * Create polling question.
 */
export async function createPollingQuestion(state: StateType, _a: any, params: any) {
  const { data, error } = await request('post', '/polling-questions', params);
  if (error) {
    return createErrorState(error);
  }
  const pollingQuestion = parsePollingQuestion(data);
  return {
    pollingQuestions: [...state.pollingQuestions, pollingQuestion],
    pollingQuestionsTotal: state.pollingQuestionsTotal + 1
  };
}

/**
 * Search polling questions.
 *
 */
export async function getPollingQuestions(_s: StateType, _a: any, params: any) {
  const { data, error, headers } = await request('get', '/polling-questions', params);
  if (error) {
    return createErrorState(error);
  }
  const pollingQuestionsTotal = parseInt(headers['x-count'], 10);
  const pollingQuestions = parsePollingQuestions(data);
  return { pollingQuestions, pollingQuestionsTotal };
}

/**
 * Get polling question.
 */
export async function getPollingQuestion(_s: StateType, _a: any, questionId: number) {
  const { data, error } = await request('get', `/polling-questions/${questionId}`);
  if (error) {
    return createErrorState(error);
  }
  const pollingQuestion = parsePollingQuestion(data);
  return { pollingQuestion };
}

/**
 * Get next unaswered polling question for logged in user.
 */
export async function getNextPollingQuestion() {
  const { data, error } = await request('get', `/polling-questions/next`);
  if (error) {
    // don't display error message when there are no more polling questions.
    return { pollingQuestion: undefined };
  }
  const pollingQuestion = parsePollingQuestion(data);
  return { pollingQuestion };
}

/**
 * Update polling question.
 */
export async function updatePollingQuestion(_s: StateType, _a: any, question: any) {
  const { id, ...params } = question;
  const { error, data } = await request('patch', `/polling-questions/${id}`, params);
  if (error) {
    return createErrorState(error);
  }
  const message = UPDATE_POLLING_QUESTION_SUCCESS;
  const pollingQuestion = parsePollingQuestion(data);
  return {
    message,
    pollingQuestion
  };
}
