import React, { Component } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { sortBy } from 'lodash';

import { actions } from '../../../../core/store';

const styles = createStyles({
  label: {
    background: 'black',
    color: 'white',
    fontSize: 12,
    padding: '2px 5px',
    borderRadius: 3,
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer'
  }
});

interface Props extends WithStyles<typeof styles> {
  row: any;
  value: number;
}

class CoursePartFormatter extends Component<Props> {
  handleOpen = () => {
    const { row } = this.props;

    if (row.otherCoursesInSeries && row.otherCoursesInSeries.length) {
      actions.openSeriesDialog({
        courseId: row.id,
        seriesId: row.courseSeriesId,
        courses: sortBy([row, ...row.otherCoursesInSeries], 'courseSeriesPart')
      });
    }
  };

  render() {
    const { row, classes, value } = this.props;
    return (
      <div>
        {(row.courseSeriesId || (row.course && row.course.courseSeriesId)) && (
          <button className={classes.label} onClick={this.handleOpen}>
            Part {value}
          </button>
        )}
      </div>
    );
  }
}

const CoursePartTypeProvider = (props: any) => <DataTypeProvider formatterComponent={withStyles(styles)(CoursePartFormatter)} {...props} />;

export default CoursePartTypeProvider;
