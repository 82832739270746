import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../core/store';

import { LoginPresenter } from './presenter';

const mapStateToProps = ({ user, jwt }: StateType) => ({
  user,
  jwt
});

interface Props extends WithProps<typeof mapStateToProps> {
  location: any;
}

interface State {
  error: string | null;
  form: {
    email: string;
  };
  redirectToReferrer: boolean;
  submitting: boolean;
}

class BaseComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      form: {
        email: ''
      },
      redirectToReferrer: false,
      submitting: false
    };
  }

  handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { form } = this.state;

    this.setState({ submitting: true }, async () => {
      await actions.login(form.email);
      await actions.getCategories();
    });
  };

  handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [event.target.id]: event.target.value
      }
    });
  };

  redirectOnAuth = () => {
    const { jwt, user } = this.props;
    const { submitting } = this.state;

    if (user) {
      this.setState({ redirectToReferrer: true });
    }

    if (submitting && jwt.error) {
      this.setState({ submitting: false });
    }
  };

  componentDidMount() {
    this.redirectOnAuth();
  }

  componentDidUpdate() {
    this.redirectOnAuth();
  }

  render() {
    const { jwt, user } = this.props;
    const { form, redirectToReferrer, submitting } = this.state;

    let from;
    if (this.props.location.state) {
      ({ from } = this.props.location.state);
    }

    if (user && user.role === 'admin') {
      from = '/admin/courses';
    } else if (!from) {
      from = '/';
    }

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <LoginPresenter form={form} handleFormChange={this.handleFormChange} jwt={jwt} login={this.handleLogin} submitting={submitting} />
    );
  }
}

export const Login = connect(mapStateToProps)(BaseComponent);
