import React, { Component } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Button, createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../core/store';
import { studyToGradient, toTitleCase } from '../../utilities';
import { CertificateType } from '../../types';

import { Resubmit } from './resubmit';

const styles = (theme: Theme) =>
  createStyles({
    arrowPopper: {
      '&[x-placement*="bottom"] $arrowArrow': {
        top: 0,
        left: 0,
        marginTop: '-1em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent rgba(0, 0, 0, 0.12) transparent`
        }
      },
      '&[x-placement*="top"] $arrowArrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-1em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: `rgba(0, 0, 0, 0.12) transparent transparent transparent`
        }
      },
      '&[x-placement*="right"] $arrowArrow': {
        left: 0,
        marginLeft: '-1em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent rgba(0, 0, 0, 0.12) transparent transparent`
        }
      },
      '&[x-placement*="left"] $arrowArrow': {
        right: 0,
        marginRight: '-1em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 0 1em 1em',
          borderColor: `transparent transparent transparent rgba(0, 0, 0, 0.12)`
        }
      }
    },
    arrowArrow: {
      position: 'absolute',
      fontSize: 9,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid'
      }
    },
    certificateDetailsButton: {
      margin: '4px 0px 17px',
      padding: '10px 16px;'
    },
    creditType: {
      fontSize: 14
    },
    fieldOfStudy: {
      color: 'white',
      textDecoration: 'none',
      fontSize: 16,
      display: 'block',
      marginTop: 30
    },
    headerLink: {
      color: 'white',
      textDecoration: 'none',
      fontSize: 21,
      fontWeight: 900,
      display: 'block',
      marginTop: 9
    },
    lightTooltip: {
      background: theme.palette.common.white,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 11
    },
    link: {
      color: theme.palette.secondary.main,
      fontSize: '0.9rem',
      textDecoration: 'none'
    },
    infoIcon: {
      fontSize: 11,
      color: '#8F8F8F',
      verticalAlign: 'super'
    },
    irsStatus: {
      color: '#8F8F8F',

      fontSize: 14,
      marginTop: 36,
      '& a': {
        color: '#8F8F8F',
        textDecoration: 'none'
      }
    },

    irsLink: {
      cursor: 'pointer'
    },
    panel: {
      textAlign: 'center',
      backgroundColor: 'white',
      boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
      height: 450,
      width: 300
    },
    panelBody: {
      height: 260
    },
    panelHeader: {
      position: 'relative',
      color: 'white',
      height: 183,
      padding: '10px 20px'
    },
    part: {
      position: 'absolute',
      width: '100%',
      bottom: 15,
      left: 0,
      textAlign: 'center',
      fontSize: 14,
      lineHeight: 1.3
    }
  });

const mapStateToProps = ({ fieldOfStudies, user }: StateType) => ({
  fieldOfStudies,
  user
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  certificate: CertificateType; //  The certificate we are displaying in the panel.
  certificates: CertificateType[]; // All user certificates (for Resubmit operation).
}

interface State {
  openIrsStatus: boolean;
}

class CertificatePanelBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { openIrsStatus: false };
  }

  handleDownloadCertificate = () => {
    const { certificate } = this.props;
    actions.downloadCertificate(certificate.id);
  };

  handleIrsResubmitClose = () => {
    this.setState({ openIrsStatus: false });
  };

  handleIrsResubmitOpen = () => {
    this.setState({ openIrsStatus: true });
  };

  render() {
    const { certificate, certificates, classes, fieldOfStudies, user } = this.props;
    const { course } = certificate;
    const { credittype, credits } = course;
    const fieldOfStudy = fieldOfStudies.find(s => s.id === course.fieldOfStudyId);
    if (!fieldOfStudy) {
      return null;
    }
    const gradientStyle = studyToGradient(fieldOfStudy);
    const courseUrl = `/courses/${course.id}`;
    const { openIrsStatus } = this.state;

    return (
      <div className={classes.panel}>
        <div className={`${classes.panelHeader}`} style={gradientStyle}>
          {fieldOfStudy && (
            <Link className={classes.fieldOfStudy} to={courseUrl}>
              {fieldOfStudy.name}
            </Link>
          )}

          <Link className={classes.headerLink} to={courseUrl}>
            {course.name}
          </Link>

          {course.courseSeriesId && (
            <div className={classes.part}>
              Part {course.courseSeriesPart} of {course.seriesPartsCount}
            </div>
          )}
        </div>
        <div className={classes.panelBody}>
          <p>
            <b>
              Date Completed
              <br />
              {format(new Date(certificate.created), 'EEE, MMMM d, yyyy')}
            </b>
          </p>
          {credittype.length > 0 && (
            <p className={classes.creditType}>
              {credittype.join(' & ')} | {credits} Hour{credits > 1 && <span>s</span>}
            </p>
          )}

          <Button className={classes.certificateDetailsButton} color="primary" onClick={this.handleDownloadCertificate} variant="contained">
            View Certificate
          </Button>

          <br />

          <Link className={classes.link} to={`/courses/${course.id}`}>
            Course Details
          </Link>

          <br />

          {!!course.youtubeUrl && (
            <a href={course.youtubeUrl} className={classes.link} target="_blank" rel="noopener noreferrer">
              View Recording
            </a>
          )}

          {!!user && (
            <Resubmit
              certificate={certificate}
              certificates={certificates}
              onClose={this.handleIrsResubmitClose}
              open={openIrsStatus}
              user={user}
            />
          )}

          {credittype.includes('CE') && user && user.irsEnabled && (
            <div>
              {certificate.irsStatus === 'failed' && (
                <p className={classes.irsStatus}>
                  <a className={classes.irsLink} onClick={this.handleIrsResubmitOpen}>
                    Uploaded to IRS:&nbsp;
                    <span style={{ color: '#F9445A' }}>
                      {toTitleCase(certificate.irsStatus)}&nbsp;
                      <span className={classes.infoIcon}>&#9432;</span>
                    </span>
                  </a>
                </p>
              )}
              {certificate.irsStatus !== 'failed' && (
                <p className={classes.irsStatus}>Uploaded to IRS: {toTitleCase(certificate.irsStatus)}</p>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const CertificatePanel = withStyles(styles)(connect(mapStateToProps)(CertificatePanelBase));
