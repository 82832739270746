/**
 * Create snackbar message.
 *
 */
import { StateType } from '../store';

export function createMessage(_s: StateType, _a: any, message: string) {
  return { message };
}

/**
 * Wrapper for handleError that can be used in action responses.
 *
 */
export function createError(_s: StateType, _a: any, error: Error) {
  return { error: error.message };
}

/**
 * Unset error from store.
 */
export function unsetError() {
  return { error: undefined };
}

/**
 * Unset message from store.
 */
export function unsetMessage() {
  return { message: undefined };
}
