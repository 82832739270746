import React from 'react';
import { Link } from 'react-router-dom';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import { UserAvatar } from '../../../../shared';

const AvatarFormatter = ({ row, value }: { row?: any; value: string }) => {
  if (row && row.url) {
    return (
      <Link to={`${row.url}`}>
        <UserAvatar url={value} />
      </Link>
    );
  }

  return <UserAvatar url={value} />;
};

// @ts-ignore
const AvatarTypeProvider = ({ for: forProp, ...others }) => (
  <DataTypeProvider formatterComponent={AvatarFormatter} for={forProp} {...others} />
);

export default AvatarTypeProvider;
