import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, createStyles, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../store';
import { LOGIN_URL } from '../../../constants';

const styles = createStyles({
  appBarButton: {
    display: 'inline-block',
    fontWeight: 900,
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '8px 16px',
    fontSize: '0.875rem',
    minWidth: 64,
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    minHeight: 36,
    boxSizing: 'border-box',
    lineHeight: 1.4,
    textDecoration: 'none',
    textTransform: 'none'
  }
});

const mapStateToProps = ({ jwt }: StateType) => ({ jwt });

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  history: any;
}

let MenuAuthButtonBase: any = ({ classes, jwt, history }: Props) => {
  if (jwt && jwt.accessToken) {
    return (
      <Button
        className={classes.appBarButton}
        color="inherit"
        onClick={async () => {
          await actions.logout();
          history.push('/');
        }}>
        Log Out
      </Button>
    );
  }

  const loginPage = `${LOGIN_URL}?redirect_to=${encodeURIComponent(window.location.href)}`;

  return (
    <a href={loginPage} className={classes.appBarButton}>
      Sign In
    </a>
  );
};

MenuAuthButtonBase = withStyles(styles)(MenuAuthButtonBase);
MenuAuthButtonBase = connect(mapStateToProps)(MenuAuthButtonBase);

export const MenuAuthButton = withRouter(({ history }) => <MenuAuthButtonBase history={history} />);
