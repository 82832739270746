import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
  WithStyles,
  withStyles
} from '@material-ui/core';

import { actions } from '../../../../core/store';

const styles = createStyles({
  modalActions: {
    justifyContent: 'flex-start'
  }
});

interface Props extends WithStyles<typeof styles> {
  registration: any;
  open: boolean;
  onClose: (retakeAssessment: boolean) => void;
}

const myCoursesLink = forwardRef((props: any, ref) => <Link innerRef={ref} to="/my/courses" {...props} />);

const FailedAssessmentDialogBase: React.FunctionComponent<Props> = ({ registration, open, onClose, classes }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleRetake = () => {
    actions.deleteSelfStudyAssessmentAnswers(registration.userId, registration.courseId);
    onClose(true);
  };

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth={fullScreen ? false : 'md'}>
      <DialogTitle>Unfortunately, you did not pass the Final Assessment</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          In order to receive credit, you must receive at least 70% on the Final Assessment. You can try again below, or come back later.
        </Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.modalActions }}>
        <Button onClick={handleRetake} variant="contained" color="primary">
          Retake Assessment
        </Button>
        <Button component={myCoursesLink} variant="outlined">
          My Courses
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const FailedAssessmentDialog = withStyles(styles)(FailedAssessmentDialogBase);
