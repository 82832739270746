import React, { Component } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';

import { actions } from '../../../core/store';

const styles = (theme: Theme) =>
  createStyles({
    answerLabel: {
      '&:not(:last-child)': {
        marginBottom: 12
      }
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 5,
      marginTop: 15,
      marginBottom: 15,
      width: 180
    },
    formControl: {
      width: '100%'
    },
    questionOf: {
      color: theme.palette.text.disabled,
      fontSize: 16,
      margin: 0,
      marginBottom: 0,
      lineHeight: '20px'
    }
  });

interface Props extends WithStyles<typeof styles> {
  assessments: any[];
  onClose: () => void;
  open: boolean;
  preview?: boolean;
  registration?: any;
}

interface State {
  answers: string[];
  assessmentIndex: number;
}

// TODO: this is used in self-study and in admin, so move to the root shared module
class SelfStudyAssessmentBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      answers: [],
      assessmentIndex: 0
    };
  }

  handleChange = (assessmentIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { answers } = this.state;
    answers[assessmentIndex] = event.target.value;
    this.setState({ answers: [...answers] });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { assessments, registration, onClose } = this.props;
    if (!registration) {
      return;
    }

    const { userId, courseId } = registration;
    const { answers, assessmentIndex } = this.state;

    if (answers.length === assessments.length) {
      const payload = answers.map((answer, i) => ({
        answer,
        assessmentId: assessments[i].id
      }));
      // submit assessment if all questions have been answered.
      actions.createSelfStudyAssessmentAnswers(userId, courseId, payload);
      this.setState({
        answers: [],
        assessmentIndex: 0
      });
      onClose();
    } else {
      // increment assessment index to next question
      this.setState({
        assessmentIndex: assessmentIndex + 1
      });
    }
  };

  render() {
    const { assessments = [], classes, onClose, open, preview } = this.props;
    const { assessmentIndex, answers } = this.state;

    const assessment = assessments[assessmentIndex];
    const answer = answers[assessmentIndex] || '';

    if (!assessment) {
      return null;
    }

    return (
      <Dialog disableBackdropClick={!preview} disableEscapeKeyDown={!preview} open={open} fullWidth={true} maxWidth="xs" onClose={onClose}>
        <DialogTitle>Final Assessment</DialogTitle>
        <form onSubmit={this.handleSubmit}>
          <DialogContent>
            <p className={classes.questionOf}>
              Question {assessmentIndex + 1} of {assessments.length}
            </p>
            <Typography variant="h5" gutterBottom>
              {assessment.question}
            </Typography>
            <div className={classes.divider} />

            <FormControl component={'fieldset'} required className={classes.formControl}>
              <RadioGroup aria-label="answer" name="answer" onChange={this.handleChange(assessmentIndex)} value={answer}>
                {assessment.answers.map((a: string, i: number) => (
                  <FormControlLabel
                    key={i}
                    value={a}
                    className={classes.answerLabel}
                    control={
                      <Radio
                        inputProps={{
                          required: true
                        }}
                      />
                    }
                    label={a}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button disabled={!answer || preview} type="submit" color="secondary" variant="contained" size="large">
              Next
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export const SelfStudyAssessment = withStyles(styles)(SelfStudyAssessmentBase);
