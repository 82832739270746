import React, { Component, forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../core/store';
import { TableGrid } from '../shared';

const changePasswordLink = forwardRef((props: any, ref) => (
  <a href="https://sso.canopytax.com/forgot" target="_blank" rel="noreferrer" {...props} />
));
const selectActions = () => (
  <Button component={changePasswordLink} variant="outlined">
    Reset Password
  </Button>
);

const config = {
  columns: [
    { name: 'avatarUrl', title: 'Avatar' },
    { name: 'firstName', title: 'First Name' },
    { name: 'lastName', title: 'Last Name' },
    { name: 'email', title: 'Email' },
    { name: 'lastLogin', title: 'Last Login' },
    { name: 'role', title: 'Admin Type' }
  ],
  columnExtensions: [
    { columnName: 'avatarUrl', width: 90 },
    { columnName: 'firstName', width: 170 },
    { columnName: 'lastName', width: 170 },
    { columnName: 'lastLogin', width: 180 },
    { columnName: 'role', width: 140 }
  ],
  dateColumns: ['lastLogin'],
  filteringStateColumnExtensions: [{ columnName: 'avatarUrl', filteringEnabled: false }],
  urlColumns: ['firstName', 'lastName', 'email'],
  urlColumnsBase: '/users',
  selectActions
};

const mapStateToProps = ({ admins, adminsTotal: totalCount, adminColumns }: StateType) => ({
  admins,
  totalCount,
  adminColumns
});

type Props = WithProps<typeof mapStateToProps>;

class AdminListBase extends Component<Props> {
  componentDidMount(): void {
    actions.getAdminColumns();
  }

  exportRows = (method: string, params: Record<string, any> = {}) => {
    actions.exportAdmins(method, params);
  };

  loadRows = (params: Record<string, any>) => {
    actions.getAdmins(params);
  };

  render() {
    const { admins, totalCount, adminColumns } = this.props;
    return (
      <div>
        <Helmet title="Admin List" />
        <TableGrid
          actions={actions}
          adminColumns={adminColumns}
          config={config}
          exportRows={this.exportRows}
          model="admins"
          rows={admins}
          totalCount={totalCount}
          loadRows={this.loadRows}
        />
      </div>
    );
  }
}

export const AdminList = connect(mapStateToProps)(AdminListBase);
