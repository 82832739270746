import React, { Component } from 'react';
import { createStyles, Grid, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { isEqual } from 'lodash';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../core/store';
import { RefineViewButton, Select } from '../shared';

import { RefineViewDialog } from './refine-view-dialog';
import { CertificatePanel } from './certificate-panel/certificate-panel';

const styles = (theme: Theme) =>
  createStyles({
    actions: {
      marginBottom: 12
    },
    certificateContainer: {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
      }
    },
    formControl: {
      minWidth: '180px'
    },
    exportControl: {
      minWidth: '180px',
      textAlign: 'left'
    }
  });

const EXPORT_BYS = [
  { id: '0', name: 'Export' },
  { id: 'all', name: 'Export All' },
  { id: 'search', name: 'Export View' }
];

const mapStateToProps = ({ certificates, jwt }: StateType) => ({
  certificates,
  jwt
});

type Props = WithStyles<typeof styles> & WithProps<typeof mapStateToProps>;

interface State {
  endDate?: string;
  exportBy: string;
  fieldOfStudyId: number[];
  lastQueryParams: any;
  loading: boolean;
  refineViewOpen: boolean;
  startDate?: string;
}

class MyCertificatesBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      exportBy: '0',
      fieldOfStudyId: [],
      lastQueryParams: {},
      loading: false,
      refineViewOpen: false
    };
  }

  componentDidMount() {
    this.getRows();
  }

  getRows = () => {
    const queryParams = this.queryParams();

    if (isEqual(queryParams, this.state.lastQueryParams)) {
      return;
    }

    this.setState({
      loading: true,
      lastQueryParams: queryParams
    });

    actions.getCertificates(queryParams);
  };

  handleOpenRefineView = () => {
    this.setState({ refineViewOpen: true });
  };

  handleCloseRefineView = () => {
    this.setState({ refineViewOpen: false });
  };

  handleExportChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { userId } = this.props.jwt;
    const { value: exportBy } = event.target;
    const queryParams = exportBy === 'all' ? { userId } : this.queryParams();
    actions.exportCertificates(exportBy, queryParams);

    this.setState({
      exportBy: '0'
    });
  };

  handleRefineViewChange = (query: any) => {
    const { endDate, fieldOfStudyId, startDate } = query;
    this.setState({ endDate, fieldOfStudyId, startDate }, this.refreshView);
  };

  queryParams() {
    const { jwt } = this.props;
    const { userId } = jwt;
    const { endDate, fieldOfStudyId, startDate } = this.state;
    const created = [];

    if (startDate) {
      created.push(startDate);
    }

    if (endDate) {
      created.push(endDate);
    }

    const query: any = {
      created,
      fieldOfStudyId: fieldOfStudyId.slice(),
      userId,
      rowsPerPage: 100
    };

    return query;
  }

  refreshView = () => {
    this.setState(
      {
        refineViewOpen: false
      },
      this.getRows
    );
  };

  render() {
    const { certificates, classes } = this.props;
    const { exportBy, refineViewOpen } = this.state;

    const exportSelectDisabled = certificates.length === 0;
    return (
      <div>
        <Typography variant="h4" gutterBottom>
          Course Certificates
        </Typography>
        <Grid container spacing={1} className={classes.actions}>
          <Grid item>
            <RefineViewButton className={classes.formControl} onClick={this.handleOpenRefineView}>
              Refine View
            </RefineViewButton>
          </Grid>
          <Grid item>
            <Select
              disabled={exportSelectDisabled}
              className={classes.exportControl}
              color="white"
              onChange={this.handleExportChange}
              name="exportBy"
              options={EXPORT_BYS}
              value={exportBy}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.certificateContainer}>
          {certificates.map(certificate => (
            <Grid item key={certificate.id}>
              <CertificatePanel certificate={certificate} certificates={certificates} />
            </Grid>
          ))}
        </Grid>
        <RefineViewDialog
          containerState={this.state}
          open={refineViewOpen}
          handleClose={this.handleCloseRefineView}
          handleChange={this.handleRefineViewChange}
        />
      </div>
    );
  }
}

export const MyCertificates = connect(mapStateToProps)(withStyles(styles)(MyCertificatesBase));
