import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const WebinarScheduledFormatter = (props: any) => {
  const { row, value } = props;
  const count = row.status === 'published' ? value.length : 0;
  return <span>{count}</span>;
};

// @ts-ignore
const WebinarScheduledTypeProvider = ({ for: forProp, ...others }) => (
  <DataTypeProvider formatterComponent={WebinarScheduledFormatter} for={forProp} {...others} />
);

export default WebinarScheduledTypeProvider;
