import React, { useState, forwardRef } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { CourseFeedbackDialog } from '../../../shared';
import { CourseType, UserSelfStudyType } from '../../../../types';

const styles = createStyles({
  modalActions: {
    justifyContent: 'flex-start'
  }
});

interface Props extends WithStyles<typeof styles> {
  course: CourseType;
  registration: UserSelfStudyType;
  open: boolean;
  onClose: () => void;
}

interface State {
  hideFeedback: boolean;
}

const viewCertificateLink = forwardRef((props: any, ref) => <Link innerRef={ref} to="/my/certificates" {...props} />);

const PassedSelfStudyAssessmentDialogBase: React.FunctionComponent<Props> = ({ course, registration, open, onClose, classes }) => {
  const [hideFeedback, setHideFeedback] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const showFeedback = open && !registration.feedback && !hideFeedback;
  return (
    <>
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={open && !showFeedback}
        onClose={onClose}
        fullScreen={fullScreen}
        maxWidth={fullScreen ? false : 'md'}>
        <DialogTitle>Congratulations, you passed with a score of {registration.score}%</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            If enabled, your course completion will be shared with the IRS automatically. Check your certification page for the status of
            your CE accreditation.
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.modalActions }}>
          <Button component={viewCertificateLink} variant="contained" color="primary">
            View Certificate
          </Button>
        </DialogActions>
      </Dialog>

      <CourseFeedbackDialog
        open={showFeedback}
        onClose={() => setHideFeedback(true)}
        registration={registration}
        course={course}
        deliveryMethod="self-study"
      />
    </>
  );
};

export const PassedSelfStudyAssessmentDialog = withStyles(styles)(PassedSelfStudyAssessmentDialogBase);
