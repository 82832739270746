import React, { Component } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { WithProps } from 'react-waterfall';
import { DropzoneState } from 'react-dropzone';

import { actions, connect, StateType } from '../../../core/store';
import { upload } from '../../../utilities';

let Dropzone = require('react-dropzone');
if ('default' in Dropzone) {
  Dropzone = Dropzone.default;
}

const styles = createStyles({
  container: {
    display: 'inline-flex'
  },
  dropzone: {
    margin: '40px 20px 0px',
    textAlign: 'center',
    border: '1px dotted',
    cursor: 'pointer'
  }
});

const mapStateToProps = ({ instructors }: StateType) => ({
  instructors
});

type Props = WithStyles<typeof styles> & WithProps<typeof mapStateToProps>;

interface State {
  avatarFile?: File;
  form: any;
  open: boolean;
  submitting: boolean;
}

class InstructorCreate extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      avatarFile: undefined,
      open: false,
      form: {},
      submitting: false
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { instructors } = this.props;

    if (prevProps.instructors.length !== instructors.length) {
      this.setState({
        avatarFile: undefined,
        form: {},
        submitting: false,
        open: false
      });
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    this.setState(state => ({
      form: {
        ...state.form,
        [event.target.id]: event.target.value
      }
    }));
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { avatarFile, form } = this.state;

    if (!avatarFile) {
      actions.createError(new Error('Avatar image is required.'));
      return;
    }

    this.setState(
      {
        submitting: true
      },
      async () => {
        const avatarUrl = await upload(avatarFile);

        await actions.createInstructor({
          ...form,
          avatarUrl
        });
      }
    );
  };

  onDrop = (avatars: File[]) => {
    const avatarFile = avatars[0];
    this.setState({
      avatarFile
    });
  };

  render() {
    const { classes } = this.props;
    const { avatarFile, submitting } = this.state;

    return (
      <div className={classes.container}>
        <Helmet title="Create Instructor" />
        <Button variant="outlined" onClick={this.handleOpen}>
          Create Instructor
        </Button>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md">
          <form onSubmit={this.handleSubmit}>
            <DialogTitle id="form-dialog-title">Create Instructor</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div>
                    <TextField
                      id="firstName"
                      disabled={submitting}
                      label="First Name"
                      fullWidth
                      onChange={this.handleFormChange}
                      required={true}
                    />
                  </div>
                  <div>
                    <TextField
                      id="lastName"
                      disabled={submitting}
                      label="Last Name"
                      fullWidth
                      onChange={this.handleFormChange}
                      required={true}
                    />
                  </div>
                  <div>
                    <TextField
                      id="email"
                      disabled={submitting}
                      label="Email Address"
                      type="email"
                      fullWidth
                      onChange={this.handleFormChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <TextField id="company" disabled={submitting} label="Company" multiline fullWidth onChange={this.handleFormChange} />
                  </div>
                  <div>
                    <TextField
                      id="companyUrl"
                      disabled={submitting}
                      label="CompanyUrl"
                      type="url"
                      multiline
                      fullWidth
                      onChange={this.handleFormChange}
                    />
                  </div>
                  <div>
                    <TextField
                      id="phone"
                      disabled={submitting}
                      label="Phone"
                      type="tel"
                      multiline
                      fullWidth
                      onChange={this.handleFormChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <TextField
                      id="biography"
                      disabled={submitting}
                      label="Biography"
                      multiline
                      fullWidth
                      onChange={this.handleFormChange}
                      required={true}
                    />
                  </div>
                  <div>
                    <Dropzone onDrop={this.onDrop} accept="image/*," multiple={false}>
                      {({
                        getRootProps,
                        getInputProps
                      }: {
                        getRootProps: DropzoneState['getRootProps'];
                        getInputProps: DropzoneState['getInputProps'];
                      }) => (
                        <section>
                          <div className={classes.dropzone} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Drop an avatar here, or click to select one to upload.</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {avatarFile && (
                      <ul>
                        <li>{avatarFile.name}</li>
                      </ul>
                    )}
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={submitting} onClick={this.handleClose} variant="outlined">
                Cancel
              </Button>
              <Button disabled={submitting} type="submit" color="primary" variant="contained">
                Create
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps)(InstructorCreate));
