import { Component } from 'react';

import { actions } from '../core/store';

export class Logout extends Component {
  componentDidMount(): void {
    actions.logout();
  }

  render() {
    return null;
  }
}
