import React, { Component } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  InputAdornment,
  TextField,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../core/store';

const styles = createStyles({
  container: {
    display: 'inline-flex'
  }
});

const mapStateToProps = ({ error, pollingQuestions }: StateType) => ({
  error,
  pollingQuestions
});

type Props = WithStyles<typeof styles> & WithProps<typeof mapStateToProps>;

interface State {
  defaultPosition: string;
  form: any;
  open: boolean;
  submitting: boolean;
}

class PollingQuestionCreate extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      defaultPosition: '',
      open: false,
      form: {
        answers: ['', ''] // Create two empty answers by default
      },
      submitting: false
    };
  }

  componentDidUpdate(prevProps: Props) {
    // Unlock for when error happens
    if (this.state.submitting && this.props.error) {
      this.setState({ submitting: false });
      return;
    }

    // Reset form when new polling question added.
    if (prevProps.pollingQuestions.length !== this.props.pollingQuestions.length) {
      const position = this.props.pollingQuestions.length;
      const defaultPosition = String(position);

      this.setState({
        defaultPosition,
        form: {
          answers: ['', ''],
          position
        },
        submitting: false,
        open: false
      });
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAddAnswer = () => {
    const { form } = this.state;
    const { answers } = form;
    answers.push('');
    this.setState({
      form: {
        ...form,
        answers
      }
    });
  };

  handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    const { form } = this.state;
    const { answers } = form;
    if (!event.target.value) {
      return;
    }

    answers[event.target.id] = event.target.value;
    this.setState({
      form: {
        ...form,
        answers
      }
    });
  };

  handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    this.setState(state => ({
      form: {
        ...state.form,
        [event.target.id]: event.target.value
      }
    }));
  };

  handleRemoveAnswer = (answerIndex: number) => () => {
    const { form } = this.state;
    const { answers } = form;
    if (answers.length > 2) {
      answers.splice(answerIndex, 1);
      this.setState({
        form: {
          ...form,
          answers
        }
      });
    }
  };

  handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    this.setState(state => ({
      form: {
        ...state.form,
        [event.target.id]: parseInt(event.target.value, 10)
      }
    }));
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    actions.createPollingQuestion(this.state.form);
    this.setState({
      submitting: true
    });
    actions.getPollingQuestions();
  };

  getAnswerInputProps = (index: number) =>
    index < 2
      ? {}
      : {
          endAdornment: (
            <InputAdornment position="end">
              <Icon onClick={this.handleRemoveAnswer(index)}>close</Icon>
            </InputAdornment>
          )
        };

  render() {
    const { classes } = this.props;
    const { defaultPosition, form, submitting } = this.state;
    const { answers } = form;

    return (
      <div className={classes.container}>
        <Button variant="outlined" onClick={this.handleOpen}>
          Create Polling Question
        </Button>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md">
          <form onSubmit={this.handleSubmit}>
            <DialogTitle id="form-dialog-title">Create Polling Question</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div>
                    <TextField
                      id="question"
                      disabled={submitting}
                      label="Question"
                      fullWidth
                      multiline
                      onChange={this.handleFormChange}
                      required={true}
                    />
                  </div>
                  <div>
                    <TextField
                      id="position"
                      defaultValue={defaultPosition}
                      disabled={submitting}
                      label="Position"
                      type="number"
                      inputProps={{ min: 0 }}
                      fullWidth
                      onChange={this.handleNumberChange}
                      required={true}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  {answers.map((_: any, index: number) => {
                    const InputProps = this.getAnswerInputProps(index);
                    return (
                      <TextField
                        id={`${index}`}
                        key={index}
                        disabled={submitting}
                        label={`Answer ${index + 1}`}
                        fullWidth
                        InputProps={InputProps}
                        multiline
                        onChange={this.handleAnswerChange}
                        required={index < 2}
                      />
                    );
                  })}
                  <br />
                  <br />
                  <div>
                    <Button onClick={this.handleAddAnswer} variant="outlined">
                      Add Answer
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={submitting} onClick={this.handleClose} variant="outlined">
                Cancel
              </Button>
              <Button disabled={submitting} type="submit" color="primary" variant="contained">
                Create
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps)(PollingQuestionCreate));
