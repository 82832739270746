import React, { Component } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { isEqual } from 'lodash';

import { actions } from '../../core/store';
import { UserAnswerType } from '../../types';

const styles = (theme: Theme) =>
  createStyles({
    answerLabel: {
      '&:not(:last-child)': {
        marginBottom: 12
      }
    },
    dialogForm: {
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column'
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 5,
      marginBottom: 22,
      width: 180
    },
    formControl: {
      width: '100%'
    },
    modalActions: {
      justifyContent: 'flex-start'
    }
  });

interface Props extends WithStyles<typeof styles> {
  onClose?: () => void;
  position?: number;
  preview?: any;
  questions?: any[];
  registration?: any;
}

interface State {
  answer: string;
  complete: boolean;
  needAnswer: any[];
}

class SelfStudyQuestionBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      answer: '',
      complete: false,
      needAnswer: []
    };
  }

  componentDidMount() {
    this.checkForQuestion();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.questions !== this.props.questions ||
      !isEqual(prevProps.position, this.props.position) ||
      !isEqual(prevProps.registration, this.props.registration) ||
      !isEqual(prevProps.preview, this.props.preview)
    ) {
      this.checkForQuestion();
    }
  }

  checkForQuestion = () => {
    const { questions, registration, position = -1, preview } = this.props;
    if (preview) {
      return;
    }

    if (!registration || !questions) {
      return;
    }

    const { answers } = registration;
    const needAnswer = questions.filter(
      question => question.position <= position && !answers.find((a: UserAnswerType) => a.selfStudyQuestionId === question.id)
    );

    const stateChange: any = {
      needAnswer
    };

    if (needAnswer.length === 0) {
      stateChange.answer = '';
    }

    this.setState({
      complete: false,
      needAnswer
    });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ answer: event.target.value });
  };

  handleComplete = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ complete: true });
  };

  handleClose = () => {
    this.setState({ needAnswer: [] });
  };

  handleSubmitForm = () => {
    const { registration } = this.props;
    if (!registration) {
      return;
    }

    const { answer, needAnswer } = this.state;
    const [question] = needAnswer;

    const payload = {
      questionId: question.id,
      answer
    };

    const { courseId, userId } = registration;
    actions.createSelfStudyQuestionAnswer(userId, courseId, payload);

    this.setState({ answer: '', needAnswer: [], complete: false });
  };

  render() {
    const { classes, onClose, preview } = this.props;
    const { answer, complete, needAnswer } = this.state;

    if (!preview && needAnswer.length === 0) {
      return null;
    }

    const question = preview || needAnswer[0];
    const correct = question.answers[question.correctAnswer] === answer;
    const answerIndex = question.answers.findIndex((a: string) => a === answer);

    return (
      <Dialog
        disableBackdropClick={!preview}
        disableEscapeKeyDown={!preview}
        open={!!question}
        onClose={onClose}
        fullWidth={true}
        maxWidth="md">
        <DialogTitle>Quiz Question</DialogTitle>
        <form onSubmit={this.handleComplete} className={classes.dialogForm}>
          <DialogContent>
            {complete ? (
              <>
                <Typography variant="h4" gutterBottom>
                  {correct ? 'Correct' : 'Incorrect'}
                </Typography>
                <div className={classes.divider} />
                <Typography variant="body2">{question.feedback[answerIndex]}</Typography>
              </>
            ) : (
              <>
                <Typography variant="h5" gutterBottom>
                  {question.question}
                </Typography>
                <div className={classes.divider} />

                <FormControl component={'fieldset'} className={classes.formControl} required>
                  <RadioGroup aria-label="answer" name="answer" onChange={this.handleChange} value={answer}>
                    {question.answers.map((a: string, i: number) => (
                      <FormControlLabel
                        key={i}
                        value={a}
                        className={classes.answerLabel}
                        control={
                          <Radio
                            inputProps={{
                              required: true
                            }}
                          />
                        }
                        label={a}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </>
            )}
          </DialogContent>
          <DialogActions classes={{ root: classes.modalActions }}>
            {complete ? (
              <Button color="secondary" type="button" onClick={this.handleSubmitForm} variant="contained" size="large">
                Next
              </Button>
            ) : (
              <Button disabled={!answer || !!preview} type="submit" color="secondary" variant="contained" size="large">
                Next
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export const SelfStudyQuestion = withStyles(styles)(SelfStudyQuestionBase);
