import React, { Component, forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../core/store';
import { TableGrid } from '../shared';

const mapStateToProps = ({ adminColumns, learners, learnersTotal: totalCount }: StateType) => ({
  adminColumns,
  learners,
  totalCount
});

type Props = WithProps<typeof mapStateToProps>;

const changePasswordLink = forwardRef((props: any, ref) => (
  <a href="https://sso.canopytax.com/forgot" target="_blank" rel="noreferrer" {...props} />
));
const selectActions = () => (
  <Button component={changePasswordLink} variant="outlined">
    Reset Password
  </Button>
);

const config = {
  columns: [
    { name: 'avatarUrl', title: 'Avatar' },
    { name: 'firstName', title: 'First Name' },
    { name: 'lastName', title: 'Last Name' },
    { name: 'firstNameOnCertificate', title: 'First Name On Cert' },
    { name: 'lastNameOnCertificate', title: 'Last Name On Cert' },
    { name: 'email', title: 'Email' },
    { name: 'ptin', title: 'PTIN' },
    { name: 'id', title: 'ID' },
    { name: 'lastLogin', title: 'Last Login' },
    { name: 'certificatesGranted', title: 'Certificates' },
    { name: 'registrations', title: 'Registrations' },
    { name: 'created', title: 'Account Created' },
    { name: 'hubspotUserId', title: 'Hubspot ID' }
  ],
  columnExtensions: [
    { columnName: 'email', width: 320 },
    { columnName: 'ptin', width: 100 },
    { columnName: 'id', width: 100 },
    { columnName: 'avatarUrl', width: 90 },
    { columnName: 'firstName', width: 170 },
    { columnName: 'lastName', width: 170 },
    { columnName: 'firstNameOnCertificate', width: 120 },
    { columnName: 'lastNameOnCertificate', width: 120 },
    { columnName: 'lastLogin', width: 180 },
    { columnName: 'created', width: 180 },
    { columnName: 'certificatesGranted', width: 120 },
    { columnName: 'registrations', width: 120 },
    { columnName: 'hubspotUserId', width: 140 }
  ],
  dateColumns: ['lastLogin', 'created'],
  filteringStateColumnExtensions: [
    { columnName: 'avatarUrl', filteringEnabled: false },
    { columnName: 'certificatesGranted', filteringEnabled: false },
    { columnName: 'registrations', filteringEnabled: false }
  ],
  urlColumns: ['firstName', 'lastName', 'firstNameOnCertificate', 'lastNameOnCertificate', 'email'],
  sortingStateColumnExtensions: [
    { columnName: 'certificatesGranted', sortingEnabled: false },
    { columnName: 'registrations', sortingEnabled: false }
  ],
  selectActions
};

class LearnerListBase extends Component<Props> {
  componentDidMount(): void {
    actions.getAdminColumns();
  }

  exportRows = (method: string, params: Record<string, any> = {}) => {
    actions.exportLearners(method, params);
  };

  loadRows = (params: Record<string, any>) => {
    actions.getLearners(params);
  };

  render() {
    const { adminColumns, learners, totalCount } = this.props;
    return (
      <div>
        <Helmet title="Learner List" />
        <TableGrid
          actions={actions}
          adminColumns={adminColumns}
          config={config}
          exportRows={this.exportRows}
          model="learners"
          rows={learners}
          totalCount={totalCount}
          loadRows={this.loadRows}
        />
      </div>
    );
  }
}

export const LearnerList = connect(mapStateToProps)(LearnerListBase);
