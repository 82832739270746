import React, { Component } from 'react';
import Downshift from 'downshift';
import { createStyles, MenuItem, Paper, TextField, Theme, WithStyles, withStyles } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../../../core/store';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: 16
    },
    container: {
      flexGrow: 1,
      position: 'relative'
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0
    },
    inputRoot: {
      flexWrap: 'wrap'
    }
  });

const mapStateToProps = ({ instructors }: StateType) => ({
  instructors
});

export type FormChangeEvent = { target: { id: string; value: any; instructor: any } };

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  onChange: (event: FormChangeEvent) => void;
  instructorId?: number;
}

interface State {
  inputValue: string;
  selectedItem: string[];
}

class InstructorAutocompleteBase extends Component<Props, State> {
  componentDidMount() {
    actions.getInstructors({ page: -1 });
  }

  getInstructors(inputValue: string | null) {
    let count = 0;
    const { instructors } = this.props;

    return instructors.filter(instructor => {
      const { firstName, lastName } = instructor;
      const keep = (!inputValue || `${firstName} ${lastName}`.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) && count < 5;

      if (keep) {
        count += 1;
      }

      return keep;
    });
  }

  renderInput(inputProps: Record<string, any>) {
    const { InputProps, classes, ...other } = inputProps;

    return (
      <TextField
        autoComplete="off"
        InputProps={{
          classes: {
            root: classes.inputRoot
          },
          ...InputProps
        }}
        {...other}
      />
    );
  }

  renderInstructor({ instructor, index, itemProps, highlightedIndex, selectedItem }: Record<string, any>) {
    const isHighlighted = highlightedIndex === index;
    const { firstName, lastName } = instructor;
    const label = `${firstName} ${lastName}`;

    const selectedName = selectedItem ? `${selectedItem.firstName} ${selectedItem.lastName}` : '';
    const isSelected = selectedName.indexOf(label) > -1;

    return (
      <MenuItem
        {...itemProps}
        key={instructor.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400
        }}>
        {label}
      </MenuItem>
    );
  }

  handleOnChange = (instructor: any) => {
    const { onChange } = this.props;
    onChange({ target: { id: 'instructorId', value: instructor.id, instructor } });
  };

  render() {
    const { classes, instructorId, instructors } = this.props;

    if (!instructors.length) {
      return null;
    }

    let defaultSelectedItem;
    if (instructorId) {
      defaultSelectedItem = instructors.find(i => i.id === instructorId);
    }

    return (
      <div className={classes.root}>
        <Downshift
          initialSelectedItem={defaultSelectedItem}
          itemToString={(item: any) => (item ? `${item.firstName} ${item.lastName}` : '')}
          onChange={this.handleOnChange}>
          {({ getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex }) => (
            <div className={classes.container}>
              {this.renderInput({
                classes,
                fullWidth: true,
                InputProps: getInputProps({
                  placeholder: 'Instructor',
                  id: 'instructorId'
                })
              })}
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {this.getInstructors(inputValue).map((instructor, index) =>
                    this.renderInstructor({
                      instructor,
                      index,
                      itemProps: getItemProps({
                        item: instructor
                      }),
                      highlightedIndex,
                      selectedItem
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

export const InstructorAutocomplete = withStyles(styles)(connect(mapStateToProps)(InstructorAutocompleteBase));
