import React from 'react';
import { MenuItem, Select, TableCell } from '@material-ui/core';

import { IRS_STATUSES } from '../../../../constants';

interface Props {
  filter: any;
  onFilter: Function;
}

const IrsStatusFilterCell = ({ filter, onFilter }: Props) => (
  <TableCell padding="none">
    <Select
      fullWidth={true}
      value={filter ? filter.value : ''}
      onChange={e => {
        onFilter(e.target.value ? { value: e.target.value } : null);
      }}
      inputProps={{
        name: 'irsStatus',
        id: 'irsStatus'
      }}>
      <MenuItem value="">
        <em>All</em>
      </MenuItem>
      {IRS_STATUSES.map(status => (
        <MenuItem key={status.id} value={status.id}>
          {status.name}
        </MenuItem>
      ))}
    </Select>
  </TableCell>
);

export default IrsStatusFilterCell;
