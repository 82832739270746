import React, { Component } from 'react';
import { createStyles, Icon, List, ListItem, ListItemText, Paper, Typography, WithStyles, withStyles } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../store';
import { NotificationType } from '../../../types';

const styles = createStyles({
  paper: {
    marginTop: '-0.7em',
    marginRight: '-3em',
    padding: '0 10px',
    width: 450,
    border: '1px solid #C0C0C0',
    backgroundColor: '#f5f5f5'
  },
  caughtUp: {
    padding: '120px 0',
    margin: 'auto',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#909497',
    textAlign: 'center'
  },
  top: {
    padding: '20px 0 5px 0',
    textAlign: 'right'
  },
  clearButton: {
    cursor: 'pointer'
  },
  notList: {
    maxHeight: 400,
    overflowY: 'scroll',
    backgroundColor: '#f5f5f5'
  },
  notItem: {
    cursor: 'pointer',
    background: '#fff',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.08)',
    padding: '10px 20px',
    marginTop: 10
  },
  bottom: {
    cursor: 'pointer',
    height: 35,
    textAlign: 'center'
  },
  bottomChevron: {
    lineHeight: '1.5em'
  },
  removeIcon: {
    fontSize: 16,
    color: '#040D14',
    marginTop: -25,
    marginRight: -10
  },
  notTextPrimary: {
    color: '#040D14',
    fontSize: 16,
    lineHeight: 1
  },
  notTextSecondary: {
    color: '#8F8F8F',
    fontSize: 16,
    lineHeight: 1.5
  }
});

const mapStateToProps = ({ notifications }: StateType) => ({
  notifications
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  onClose: () => void;
}

class NotificationsPanelBase extends Component<Props> {
  notificationType(notification: NotificationType) {
    if (notification.kind === 'missed_webinar') {
      return 'Missed Webinar';
    }
    if (notification.kind === 'new_course') {
      return 'New Course Added';
    }
    return notification.kind;
  }

  handleClearAll = () => {
    actions.readAllNotifications();
    this.props.onClose();
  };

  handleNavigation = (notification: NotificationType) => async () => {
    this.props.onClose();
    await actions.readNotification(notification.id);
    window.location.pathname = `/courses/${notification.courseId}`;
  };

  handleClear = (notificationId: number) => () => {
    const { notifications, onClose } = this.props;
    actions.readNotification(notificationId);

    // line above is about to remove the last notification
    // closing the dialogue
    if (notifications.length === 1) {
      onClose();
    }
  };

  render() {
    const { classes, notifications } = this.props;

    return (
      <Paper className={classes.paper}>
        {notifications.length > 0 && (
          <div className={classes.top}>
            <a className={classes.clearButton} onClick={this.handleClearAll}>
              Clear All
            </a>
          </div>
        )}
        {notifications.length > 0 && (
          <List className={classes.notList}>
            {notifications.map(notification => (
              <ListItem key={notification.id} className={classes.notItem}>
                <ListItemText
                  disableTypography
                  onClick={this.handleNavigation(notification)}
                  primary={<Typography className={classes.notTextPrimary}>{this.notificationType(notification)}</Typography>}
                  secondary={<Typography className={classes.notTextSecondary}>{notification.courseName}</Typography>}
                />
                <Icon className={classes.removeIcon} onClick={this.handleClear(notification.id)}>
                  close
                </Icon>
              </ListItem>
            ))}
          </List>
        )}
        {notifications.length === 0 && <p className={classes.caughtUp}>All Caught Up</p>}
        <div className={classes.bottom} onClick={this.props.onClose}>
          <Icon className={classes.bottomChevron}>expand_less</Icon>
        </div>
      </Paper>
    );
  }
}

export const NotificationsPanel = withStyles(styles)(connect(mapStateToProps)(NotificationsPanelBase));
