import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AdminRouter } from './admin';
import CourseList from './course-list';
import { CourseRouter } from './course';
import { MyCertificates } from './my-certificates';
import { MyCourses } from './my-courses';
import { Login } from './login';
import { Logout } from './logout';
import { Profile } from './profile';
import { NotFound } from './core';
import { ExceptionBoundary, PrivateRoute } from './shared';

const CourseListHOC = ExceptionBoundary(PrivateRoute(CourseList));
const CourseRouterHOC = ExceptionBoundary(PrivateRoute(CourseRouter));
const AdminRouterHOC = ExceptionBoundary(PrivateRoute(AdminRouter));
const MyCoursesHOC = ExceptionBoundary(PrivateRoute(MyCourses));
const MyCertificatesHOC = ExceptionBoundary(PrivateRoute(MyCertificates));
const ProfileHOC = ExceptionBoundary(PrivateRoute(Profile));
const LoginHOC = ExceptionBoundary(Login);
const LogoutHOC = ExceptionBoundary(PrivateRoute(Logout));

export const AppRoutes: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/" component={CourseListHOC} exact />
      <Route path="/courses/:courseId([0-9]+)" component={CourseRouterHOC} />
      <Route path="/admin" component={AdminRouterHOC} />
      <Route path="/my/courses" component={MyCoursesHOC} />
      <Route path="/my/certificates" component={MyCertificatesHOC} />
      <Route path="/profile/:profileId?" component={ProfileHOC} />
      <Route path="/login" component={LoginHOC} />
      <Route path="/logout" component={LogoutHOC} />
      <Route component={NotFound} />
    </Switch>
  );
};
