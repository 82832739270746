/**
 * Save the file contents as if the user had clicked a link to download the file directly.
 */
export default function saveAs(data: string, filename: string): void {
  // IE11 & Edge
  if (window.navigator.msSaveBlob) {
    const blob = new Blob([data]);
    window.navigator.msSaveBlob(blob, filename);
  } else {
    // All other browsers
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const link = window.document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
  }
}
