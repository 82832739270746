import React, { Component } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  TextField,
  Typography,
  WithStyles,
  withStyles,
  withWidth
} from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import { isWidthDown, WithWidthProps } from '@material-ui/core/withWidth';

import { actions } from '../../core/store';
import { PtinInput } from '../../profile/components/ptin-input';
import { CertificateType, UserType } from '../../types';

const styles = createStyles({
  dialogActions: {
    justifyContent: 'flex-start'
  },
  irsError: {
    fontWeight: 600,
    color: red['A700'],
    margin: '0 10px'
  }
});

interface Props extends WithStyles<typeof styles>, WithWidthProps {
  certificate: CertificateType;
  certificates: CertificateType[];
  open: boolean;
  onClose: () => void;
  user: UserType;
}

interface State {
  firstNameOnCertificate: string;
  hasChange: boolean;
  lastNameOnCertificate: string;
  ptin: string;
}

class ResubmitBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasChange: false,
      ptin: '',
      firstNameOnCertificate: '',
      lastNameOnCertificate: ''
    };
  }

  componentDidMount() {
    this.setUserData();
  }

  componentDidUpdate() {
    this.setUserData();
  }

  handleIrsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, hasChange: true } as any);
  };

  handleResubmitIrs = async () => {
    const { ptin, firstNameOnCertificate, lastNameOnCertificate } = this.state;
    const { certificates, user } = this.props;
    await actions.updateProfile(user.id, { ptin, firstNameOnCertificate, lastNameOnCertificate });

    // Resubmit all failed certificates.
    certificates
      .filter(cert => cert.irsStatus === 'failed')
      .forEach(c => {
        actions.resubmitCertificate(c.id);
      });

    this.props.onClose();
  };

  setUserData = () => {
    const { user } = this.props;

    if (!this.state.hasChange && user) {
      const { firstNameOnCertificate, lastNameOnCertificate, ptin } = user;
      const shouldUpdate =
        ptin !== this.state.ptin ||
        firstNameOnCertificate !== this.state.firstNameOnCertificate ||
        lastNameOnCertificate !== this.state.lastNameOnCertificate;

      if (shouldUpdate) {
        this.setState({
          firstNameOnCertificate,
          lastNameOnCertificate,
          ptin
        });
      }
    }
  };

  close = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { ptin } = this.state;
    const { certificate, classes, open, user, width } = this.props;

    if (!user) {
      return null;
    }

    const fullScreen = isWidthDown('sm', width!);
    return (
      <Dialog open={open} onClose={this.close} fullScreen={fullScreen} maxWidth={fullScreen ? false : 'sm'}>
        <DialogTitle>Please correct and resubmit</DialogTitle>
        <DialogContent>
          <Typography variant="body2" className={classes.irsError}>
            {certificate.irsStatusMessage}
          </Typography>

          <FormControl margin="normal" fullWidth={true}>
            <InputLabel disableAnimation={true} required>
              PTIN Number
            </InputLabel>
            <Input name="ptin" onChange={this.handleIrsChange} inputComponent={PtinInput as any} value={ptin} required />
          </FormControl>

          <TextField
            margin="normal"
            fullWidth={true}
            defaultValue={user.firstNameOnCertificate}
            name="firstNameOnCertificate"
            label="First name on your PTIN record"
            required
            onChange={this.handleIrsChange}
          />

          <TextField
            margin="normal"
            fullWidth={true}
            defaultValue={user.lastNameOnCertificate}
            name="lastNameOnCertificate"
            label="Last name on your PTIN record"
            required
            onChange={this.handleIrsChange}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            color="primary"
            disabled={!this.state.ptin || !this.state.firstNameOnCertificate || !this.state.lastNameOnCertificate}
            onClick={this.handleResubmitIrs}
            variant="contained">
            Resubmit
          </Button>
          <Button onClick={this.close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const Resubmit = withStyles(styles)(withWidth()(ResubmitBase));
