import React, { Component } from 'react';
import { AppBar, createStyles, Paper, Tab, Tabs, Tooltip, WithStyles, withStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { match, Redirect } from 'react-router-dom';
import { Prompt } from 'react-router';
import { isEqual, omitBy } from 'lodash';
import { WithProps } from 'react-waterfall';

import { CourseWebinarScheduledType } from '../../../types';
import { actions, connect, StateType } from '../../../core/store';
import { CREATE_COURSE_SUCCESS, LEAVE_COURSE_EDIT_MESSAGE, PATCH_COURSE_SUCCESS } from '../../../constants';
import { promiseEach, upload } from '../../../utilities';

import { CourseDetails, SelfStudy, Webinar } from './routes';

const styles = createStyles({
  paper: {
    marginTop: 40
  }
});

const mapStateToProps = ({ message, error, course, blackoutDates }: StateType) => ({
  message,
  error,
  course,
  blackoutDates
});

interface UrlParameters {
  courseId: string;
}

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  match: match<UrlParameters>;
}

interface State {
  form: any;
  logoFile?: File;
  redirect?: string;
  showNewSaveWarning: boolean;
  submitting: boolean;
  cancelling: boolean;
  tab: number;
  schedulesToAdd: CourseWebinarScheduledType[];
  schedulesToRemoveIds: number[];
  blackoutDatesToAdd: string[];
  blackoutDatesToRemove: string[];
}

let scheduleId = -1;

class CourseCreateBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {},
      showNewSaveWarning: false,
      submitting: false,
      cancelling: false,
      tab: 0,
      schedulesToAdd: [],
      schedulesToRemoveIds: [],
      blackoutDatesToAdd: [],
      blackoutDatesToRemove: []
    };
  }

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    const { course, error, message } = newProps;
    const { form, submitting } = prevState;
    const stateChange: any = {};

    if (course) {
      if (!isEqual(form.updated, course.updated)) {
        stateChange.form = course;
      } else if (form.resources !== course.resources) {
        stateChange.form = {
          ...form,
          ...form.resources
        };
      }
    }

    if ((error || message === PATCH_COURSE_SUCCESS || message === CREATE_COURSE_SUCCESS) && submitting) {
      stateChange.submitting = false;
    }

    return stateChange;
  }

  // prevent notifications form rerendering all tabs
  shouldComponentUpdate(nextProps: Props) {
    // don't rerender when message is removed
    if (!nextProps.message && this.props.message) {
      return false;
    }

    // do not rerender when error is changed.
    return nextProps.error === this.props.error;
  }

  componentDidMount() {
    const { match } = this.props;
    actions.getInstructors();

    const { courseId } = match.params;
    if (courseId) {
      actions.getCourse(courseId);
    }
    actions.getBlackoutDates();
  }

  componentWillUnmount() {
    actions.unsetCourse();
    actions.unsetCourses();
  }

  resetForm() {
    this.setState({
      form: this.props.course,
      schedulesToAdd: [],
      schedulesToRemoveIds: [],
      blackoutDatesToAdd: [],
      blackoutDatesToRemove: []
    });
  }

  formHasChanges = () => {
    const { course = {} } = this.props;
    const { form, schedulesToAdd, schedulesToRemoveIds, blackoutDatesToAdd, blackoutDatesToRemove } = this.state;

    if (schedulesToAdd.length > 0 || schedulesToRemoveIds.length > 0 || blackoutDatesToAdd.length > 0 || blackoutDatesToRemove.length > 0) {
      return true;
    }

    let dirty = false;

    if (form.id) {
      dirty = !!Object.entries(course).find(entry => form[entry[0]] !== entry[1]);
    } else {
      dirty = !!Object.values(form).find(f => !!f);
    }

    return dirty;
  };

  handleTabChange = (event: React.ChangeEvent, tab: number) => {
    if (this.formHasChanges()) {
      // eslint-disable-next-line
      if (confirm(LEAVE_COURSE_EDIT_MESSAGE)) {
        this.resetForm();
        this.setState({ tab });
      } else {
        event.preventDefault();
      }
    } else {
      this.setState({ tab });
    }
  };

  handleCancelClick = () => {
    this.setState({ cancelling: true }, () => {
      // eslint-disable-next-line
      if (confirm('Are you sure you want to cancel?')) {
        const { form } = this.state;
        const message = form.id ? 'Course edit cancelled.' : 'Course creation cancelled.';
        actions.createMessage(message);
        this.setState({ redirect: '/admin/courses' });
      } else {
        this.setState({ cancelling: false });
      }
    });
  };

  handleAddSchedule = (scheduledOn: string) => {
    this.setState({
      schedulesToAdd: [...this.state.schedulesToAdd, { id: scheduleId--, scheduledOn }]
    });
  };

  handleRemoveSchedule = (id: number) => {
    if (id < 0) {
      this.setState({
        schedulesToAdd: this.state.schedulesToAdd.filter(s => s.id !== id)
      });
    } else {
      this.setState({
        schedulesToRemoveIds: [...this.state.schedulesToRemoveIds, id]
      });
    }
  };

  handleAddBlackoutDate = (blackoutDate: string) => {
    this.setState({ blackoutDatesToAdd: [...this.state.blackoutDatesToAdd, blackoutDate] });
  };

  handleRemoveBlackoutDate = (blackoutDate: string) => {
    if (this.state.blackoutDatesToAdd.indexOf(blackoutDate) !== -1) {
      this.setState({ blackoutDatesToAdd: this.state.blackoutDatesToAdd.filter(d => d !== blackoutDate) });
    } else {
      this.setState({ blackoutDatesToRemove: [...this.state.blackoutDatesToRemove, blackoutDate] });
    }
  };

  showNewSaveWarning = () => {
    const { form } = this.state;
    if (!form.id) {
      this.setState({ showNewSaveWarning: true });
    }
  };

  hideNewSaveWarning = () => {
    const { form } = this.state;
    if (!form.id) {
      this.setState({ showNewSaveWarning: false });
    }
  };

  handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    const { form } = this.state;

    if (!form.name) {
      // eslint-disable-next-line
      alert('Please enter a course name to save.');
      return;
    }

    const message =
      "Are you sure you want to update this course? This can result in new webinars being scheduled based on the course's settings.";
    // eslint-disable-next-line
    const confirmPatch = form.id && form.status === 'published' ? confirm(message) : true;

    if (confirmPatch) {
      this.setState({ submitting: true }, this.saveCourse);
    }
  };

  saveCourse = async () => {
    const { logoFile, form, schedulesToAdd, schedulesToRemoveIds, blackoutDatesToAdd, blackoutDatesToRemove } = this.state;
    const { resources: resourceFiles } = form;
    let logoUrl;

    if (logoFile) {
      logoUrl = await upload(logoFile);
    }

    let resources: any[] = [];
    if (resourceFiles) {
      resources = await Promise.all(
        resourceFiles.map(async (resource: any) => {
          if (resource.url) {
            return resource;
          }

          const { name: fileName } = resource;
          const name = fileName.substring(0, fileName.lastIndexOf('.'));
          const url = await upload(resource);

          return { name, url };
        })
      );
    }

    const {
      advancedPrep,
      categoryId,
      credits,
      credittype,
      ctecId,
      fieldOfStudyId,
      instructorId,
      irsId,
      lastReviewBy,
      learningObjectives,
      level,
      overview,
      name,
      pubished,
      status,
      youtubeUrl,
      webinarPollingQuestionDisplayTimes,
      isRecurring,
      recurringTime,
      recurringDay,
      recurringWeeks
    } = form;

    // DatePicker returns empty strings when not set.  API expects null.
    let { lastReview } = form;
    if (!lastReview) {
      lastReview = null;
    }

    const payload = omitBy(
      {
        advancedPrep,
        categoryId,
        credits,
        credittype,
        ctecId,
        fieldOfStudyId,
        instructorId,
        irsId,
        lastReview,
        lastReviewBy,
        learningObjectives,
        level,
        logoUrl,
        overview,
        name,
        pubished,
        status,
        youtubeUrl,
        webinarPollingQuestionDisplayTimes,
        isRecurring,
        recurringTime,
        recurringDay,
        recurringWeeks
      },
      prop => prop === undefined
    );

    if (form.id) {
      await actions.patchCourse(form.id, payload, resources);
      await promiseEach(schedulesToAdd, s => actions.createWebinarScheduled(form.id, { scheduledOn: s.scheduledOn }));
      await promiseEach(schedulesToRemoveIds, id => actions.deleteWebinarScheduled(form.id, id));
      await actions.updateBlackoutDatesList(blackoutDatesToAdd, blackoutDatesToRemove);
      this.resetForm();
    } else {
      await actions.createCourse(payload, resources);
    }
  };

  setContainerState = (newState: any, callback?: () => void) => {
    this.setState(newState, callback);
  };

  render() {
    const { classes, course, blackoutDates, match } = this.props;
    const { redirect, showNewSaveWarning, tab } = this.state;

    if (match.params.courseId && !course) {
      return null;
    }

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    const disabled = !course || !course.id;
    const tabs = [
      { label: 'Course Details', component: CourseDetails },
      { label: 'Webinar', component: Webinar },
      { label: 'Self Study', component: SelfStudy }
    ];

    return (
      <Paper className={classes.paper}>
        <Prompt message={LEAVE_COURSE_EDIT_MESSAGE} when={this.formHasChanges() && !this.state.cancelling} />
        <Helmet title={course ? `Edit Course ${course.name}` : 'Create Course'} />
        <AppBar position="static" color="default">
          <Tabs
            value={tab}
            onChange={this.handleTabChange}
            onMouseOut={this.hideNewSaveWarning}
            onMouseOver={this.showNewSaveWarning}
            onBlur={this.hideNewSaveWarning}
            onFocus={this.showNewSaveWarning}
            indicatorColor="primary"
            textColor="primary"
            centered={true}>
            {tabs.map((t, index) => (
              <Tab disabled={disabled} key={index} label={t.label} title={t.label} />
            ))}
          </Tabs>

          {disabled && (
            <Tooltip
              open={showNewSaveWarning}
              title="Please save the Course Details first to enable managing webinar and self study information.">
              <span />
            </Tooltip>
          )}
          {tabs.map((t, index) => {
            const { component: Component } = t;
            if (index === tab) {
              return (
                <Component
                  course={course}
                  blackoutDates={blackoutDates}
                  containerState={this.state}
                  formHasChanges={this.formHasChanges()}
                  handleCancelClick={this.handleCancelClick}
                  handleSubmit={this.handleSubmit}
                  handleAddSchedule={this.handleAddSchedule}
                  handleRemoveSchedule={this.handleRemoveSchedule}
                  handleAddBlackoutDate={this.handleAddBlackoutDate}
                  handleRemoveBlackoutDate={this.handleRemoveBlackoutDate}
                  key={index}
                  setContainerState={this.setContainerState}
                />
              );
            }

            return null;
          })}
        </AppBar>
      </Paper>
    );
  }
}

export const CourseCreate = withStyles(styles)(connect(mapStateToProps)(CourseCreateBase));
