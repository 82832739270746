import React, { Component, forwardRef } from 'react';
import { Button, createStyles, Grid, Icon, InputAdornment, Paper, TextField, WithStyles, withStyles } from '@material-ui/core';
import { Link, match, Redirect } from 'react-router-dom';
import { WithProps } from 'react-waterfall';

import { PollingQuestionDialog } from '../../shared';
import { actions, connect, StateType } from '../../core/store';
import { UPDATE_POLLING_QUESTION_SUCCESS } from '../../constants';

const styles = createStyles({
  actionButtons: {
    textAlign: 'right'
  },
  actionButton: {
    marginRight: 20
  },
  paper: {
    margin: '40px 0px',
    padding: 60
  }
});

const mapStateToProps = ({ error, message, pollingQuestion }: StateType) => ({
  error,
  message,
  pollingQuestion
});

interface UrlParameters {
  questionId: string;
}

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  match: match<UrlParameters>;
}

interface State {
  form: any;
  preview: boolean;
  submitting: boolean;
  redirect: boolean;
}

const resetForm = (): any => ({ answers: [] });

class PollingQuestionEditBase extends Component<Props, State> {
  adminQuestionsLink = forwardRef((props: any, ref) => <Link innerRef={ref} to="/admin/polling-questions" {...props} />);

  constructor(props: Props) {
    super(props);
    this.state = {
      form: resetForm(),
      preview: false,
      submitting: false,
      redirect: false
    };
  }

  componentDidMount(): void {
    const { match } = this.props;
    actions.getPollingQuestion(match.params.questionId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.submitting && this.props.message === UPDATE_POLLING_QUESTION_SUCCESS) {
      // polling question successfully update, reset form and redirect
      const redirect = !!prevState.form.id;
      this.setState({ submitting: false, form: resetForm(), redirect });
    } else if (this.state.submitting && this.props.error) {
      // there was an error with saving.  unlock form.
      this.setState({ submitting: false });
    } else {
      // check if polling question has been updated and update state
      const { pollingQuestion } = this.props;
      if (pollingQuestion && prevState.form.updated !== pollingQuestion.updated) {
        this.setState({ form: pollingQuestion });
      }
    }
  }

  handleAddAnswer = () => {
    const { form } = this.state;
    const { answers } = form;
    answers.push('');
    this.setState({
      form: {
        ...form,
        answers
      }
    });
  };

  handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    const { form } = this.state;
    const { answers } = form;
    if (!event.target.value) {
      return;
    }

    answers[event.target.id] = event.target.value;
    this.setState({
      form: {
        ...form,
        answers
      }
    });
  };

  handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    this.setState(state => ({
      form: {
        ...state.form,
        [event.target.id]: event.target.value
      }
    }));
  };

  handleRemoveAnswer = (answerIndex: number) => () => {
    const { form } = this.state;
    const { answers } = form;
    if (answers.length > 2) {
      answers.splice(answerIndex, 1);
      this.setState({
        form: {
          ...form,
          answers
        }
      });
    }
  };

  handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    const { value } = event.target;
    this.setState(state => ({
      form: {
        ...state.form,
        [event.target.id]: value ? parseInt(value, 10) : 0
      }
    }));
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { answers: unfiltered, question, position, id } = this.state.form;
    const answers = unfiltered.filter(Boolean);

    this.setState(
      {
        submitting: true
      },
      async () => {
        await actions.updatePollingQuestion({
          answers,
          question,
          position,
          id
        });
        actions.getPollingQuestions();
      }
    );
  };

  showPreview = () => {
    this.setState({
      preview: true
    });
  };

  hidePreview = () => {
    this.setState({
      preview: false
    });
  };

  handlePublish = () => {
    // eslint-disable-next-line
    if (confirm('Are you sure you want to publish this polling question?')) {
      const { id } = this.state.form;
      actions.updatePollingQuestion({
        status: 'published',
        id
      });
    }
  };

  handleUnpublish = () => {
    // eslint-disable-next-line
    if (confirm('Are you sure you want to unpublish this polling question?')) {
      const { id } = this.state.form;
      actions.updatePollingQuestion({
        status: 'unpublished',
        id
      });
    }
  };

  getAnswerInputProps = (index: number) =>
    index < 2
      ? {}
      : {
          endAdornment: (
            <InputAdornment position="end">
              <Icon onClick={this.handleRemoveAnswer(index)}>close</Icon>
            </InputAdornment>
          )
        };

  render() {
    const { classes } = this.props;
    const { form, redirect, preview, submitting } = this.state;
    const { answers } = form;

    // redirect on save
    if (redirect) {
      return <Redirect to="/admin/polling-questions" />;
    }

    // don't render until question available
    if (!form.id) {
      return null;
    }

    return (
      <Paper className={classes.paper}>
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.actionButtons}>
              <Button disabled={submitting} className={classes.actionButton} component={this.adminQuestionsLink}>
                Cancel
              </Button>

              {form.status === 'published' && (
                <Button className={classes.actionButton} onClick={this.handleUnpublish} variant="outlined">
                  Unpublish
                </Button>
              )}
              {form.status === 'unpublished' && (
                <Button className={classes.actionButton} onClick={this.handlePublish} variant="outlined">
                  Publish
                </Button>
              )}

              <Button className={classes.actionButton} onClick={this.showPreview} variant="outlined">
                Preview
              </Button>
              <Button className={classes.actionButton} onClick={this.handleAddAnswer} variant="outlined">
                Add Answer
              </Button>
              <Button className={classes.actionButton} disabled={submitting} type="submit" color="primary" variant="contained">
                Update
              </Button>
            </Grid>
            <Grid item xs={6}>
              <div>
                <TextField
                  id="question"
                  disabled={submitting}
                  label="Question"
                  fullWidth
                  multiline
                  onChange={this.handleFormChange}
                  defaultValue={form.question}
                  required={true}
                />
              </div>
              <div>
                <TextField
                  id="position"
                  disabled={submitting}
                  label="Position"
                  type="number"
                  inputProps={{ min: 0 }}
                  fullWidth
                  onChange={this.handleNumberChange}
                  defaultValue={form.position}
                  required={true}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              {answers.map((answer: string, index: number) => {
                const InputProps = this.getAnswerInputProps(index);
                return (
                  <TextField
                    id={`${index}`}
                    key={index}
                    disabled={submitting}
                    label={`Answer ${index + 1}`}
                    fullWidth
                    multiline
                    onChange={this.handleAnswerChange}
                    defaultValue={form.answers[index]}
                    InputProps={InputProps}
                    required={index < 2}
                  />
                );
              })}
            </Grid>
          </Grid>
        </form>
        <PollingQuestionDialog onClose={this.hidePreview} open={preview} pollingQuestion={form} preview={true} />
      </Paper>
    );
  }
}

export const PollingQuestionEdit = withStyles(styles)(connect(mapStateToProps)(PollingQuestionEditBase));
