const fileExist = (fileUrl: string) => {
  try {
    const http = new XMLHttpRequest();
    http.open('HEAD', fileUrl, false);
    http.send();
    return http.status < 400;
  } catch (e) {
    return false;
  }
};

export default fileExist;
