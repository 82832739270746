import React, { Component, forwardRef } from 'react';
import { configureScope } from '@sentry/browser';
import { WithProps } from 'react-waterfall';
import { Link } from 'react-router-dom';
import {
  createStyles,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core';
import { Assignment, Person, Search, Work } from '@material-ui/icons';
import { Helmet } from 'react-helmet';

import { actions, connect, StateType } from './core/store';
import { AppRoutes } from './app.routes';
import { Menu, StatusSnackbar } from './core';
import helmetConfig from './core/helmetConfig';

import './app.router.css';

const styles = (theme: Theme) =>
  createStyles({
    canopyDrawerIcon: {
      width: 24
    },
    mainContent: {
      padding: '32px 40px',
      [theme.breakpoints.only('sm')]: {
        padding: 20
      },
      [theme.breakpoints.down('xs')]: {
        padding: 12
      }
    },
    sideDrawer: {
      width: 280
    }
  });

function createLink(url: string) {
  return forwardRef((props: any, ref) => <Link innerRef={ref} to={url} {...props} />);
}

const featureToggles: string[] = [];

const mapStateToProps = ({ user }: StateType) => ({
  user
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {}

interface State {
  isDrawerOpen: boolean;
}

class AppRouterBase extends Component<Props, State> {
  notificationTimeout?: number;
  featureToggleTimeout?: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDrawerOpen: false
    };
  }

  componentDidMount(): void {
    const { user } = this.props;
    if (user) {
      this.setupNotifications();
    }
    if (featureToggles.length > 0) {
      this.getFeatureToggles();
      this.featureToggleTimeout = window.setInterval(this.getFeatureToggles, 30 * 1000);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { user } = this.props;
    if (user && !prevProps.user) {
      this.setupNotifications();
    }
    if (user && user != prevProps.user) {
      configureScope(scope => {
        scope.setUser({
          id: `${user.id}`
        });
      });
    }
  }

  componentWillUnmount() {
    if (this.notificationTimeout) {
      window.clearInterval(this.notificationTimeout);
    }

    if (this.featureToggleTimeout) {
      window.clearInterval(this.featureToggleTimeout);
    }
  }

  setupNotifications() {
    if (!this.notificationTimeout) {
      actions.getNotifications();
      this.notificationTimeout = window.setInterval(actions.getNotifications, 60 * 1000);
    }
  }

  getFeatureToggles = () => {
    actions.fetchFeatureToggles(featureToggles);
  };

  toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    this.setState({ isDrawerOpen: open });
  };

  sideList() {
    const { classes } = this.props;

    return (
      <div className={classes.sideDrawer} onClick={this.toggleDrawer(false)} onKeyDown={this.toggleDrawer(false)}>
        <List>
          <ListItem component="a" href="https://www.canopytax.com" button={true}>
            <ListItemIcon>
              <img
                src="https://s3-us-west-2.amazonaws.com/app.canopytax.com/images/canopy-logomark.svg"
                className={classes.canopyDrawerIcon}
              />
            </ListItemIcon>
            <ListItemText primary="Canopy" />
          </ListItem>
          <Divider />
          <ListItem component={createLink('/profile')} button={true}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="My profile" />
          </ListItem>
          <ListItem component={createLink('/')} button={true}>
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            <ListItemText primary="Discover Courses" />
          </ListItem>
          <ListItem component={createLink('/my/courses')} button={true}>
            <ListItemIcon>
              <Work />
            </ListItemIcon>
            <ListItemText primary="My Courses" />
          </ListItem>
          <ListItem component={createLink('/my/certificates')} button={true}>
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary="Certificates" />
          </ListItem>
        </List>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { isDrawerOpen } = this.state;

    return (
      <>
        <CssBaseline />

        <Helmet titleTemplate="%s - Canopy Tax" title="Canopy Tax" meta={helmetConfig.meta} link={helmetConfig.link} />
        <Menu onMenuIconClick={this.toggleDrawer(true)} />

        <Drawer anchor="left" open={isDrawerOpen} onClose={this.toggleDrawer(false)}>
          {this.sideList()}
        </Drawer>

        <div className={classes.mainContent}>
          <AppRoutes />
        </div>

        <StatusSnackbar />
      </>
    );
  }
}

export const AppRouter = withStyles(styles)(connect(mapStateToProps)(AppRouterBase));
