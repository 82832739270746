import React, { Component } from 'react';
import { Button, createStyles, Grid, Icon, Theme, Tooltip, WithStyles, withStyles } from '@material-ui/core';
import { arrayMove, SortableContainer, SortableElement, SortableHandle, SortEnd } from 'react-sortable-hoc';
import { isEqual, sortBy } from 'lodash';

import { SelfStudyAssessment } from '../../../../../course';
import { actions } from '../../../../../core/store';
import { prettyDuration } from '../../../../../utilities';
import { ActionButtons } from '../../shared';
import { SelfStudyQuestion } from '../../../../../shared';

import { AddSelfStudyDialog } from './add-self-study-dialog/add-self-study-dialog';
import { EditQuestionDialog } from './edit-question-dialog';
import { EditAssessmentDialog } from './edit-assessment-dialog';

const styles = (theme: Theme) =>
  createStyles({
    actionButton: {
      minWidth: 48
    },
    container: {
      margin: '0px 40px',
      padding: 60
    },
    displayTime: {
      textAlign: 'center',
      backgroundColor: theme.palette.secondary.main,
      width: 250,
      height: 141,
      marginRight: 15,
      cursor: 'pointer'
    },
    displayTimeHeader: {
      margin: 'auto',
      lineHeight: '150px',
      verticalAlign: 'middle',
      color: 'white'
    },
    timelineActions: {
      display: 'inline-block',
      textAlign: 'right',
      width: 144
    },
    timelineMeta: {
      display: 'inline-block',
      width: 106
    },
    video: {
      width: 250,
      height: 141,
      marginRight: 15,
      cursor: 'pointer'
    },
    videoThumbnail: {
      width: '100%',
      height: '100%'
    },
    videos: {
      display: 'inline-flex',
      flexWrap: 'wrap'
    }
  });

interface Props extends WithStyles<typeof styles> {
  containerState: any;
  handleCancelClick: () => void;
  handleSubmit: Function;
  setContainerState: Function;
  formHasChanges: boolean;
}

interface State {
  timeline: any[];
  previewQuestion?: any;
  previewAssessment?: any[];
  question?: any;
  selfStudyAssessments: any[];
  selfStudyQuestions: any[];
  selfStudyVideos: any[];
}

class SelfStudy extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      timeline: [],
      selfStudyAssessments: [],
      selfStudyVideos: [],
      selfStudyQuestions: []
    };
  }

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    const { containerState } = newProps;
    const { form } = containerState;
    const { selfStudyAssessments = [], selfStudyQuestions = [], selfStudyVideos = [] } = form;
    const {
      selfStudyAssessments: prevAssessments = [],
      selfStudyQuestions: prevQuestions = [],
      selfStudyVideos: prevVideos = []
    } = prevState;

    const updatedQuestion = selfStudyQuestions.find((question: any) => {
      const prevQuestion = prevQuestions.find(pq => pq.id === question.id);
      if (!prevQuestion) {
        return true;
      }
      return !isEqual(prevQuestion.updated, question.updated);
    });
    const newQuestion = selfStudyQuestions.length !== prevQuestions.length;

    const updatedVideo = selfStudyVideos.find((video: any) => {
      const prevVideo = prevVideos.find(pq => pq.id === video.id);
      if (!prevVideo) {
        return true;
      }
      return !isEqual(prevVideo.updated, video.updated);
    });
    const newVideo = selfStudyVideos.length !== prevVideos.length;

    const updatedAssessment = selfStudyAssessments.find((assessment: any) => {
      const prevAssessment = prevAssessments.find(a => a.id === assessment.id);
      if (!prevAssessment) {
        return true;
      }
      return !isEqual(prevAssessment.updated, assessment.updated);
    });
    const newAssessment = selfStudyAssessments.length !== prevAssessments.length;

    if (updatedAssessment || newAssessment || updatedQuestion || updatedVideo || newQuestion || newVideo) {
      const timeline = sortBy([...selfStudyVideos, ...selfStudyQuestions], 'position');

      return {
        timeline,
        selfStudyAssessments: [...selfStudyAssessments],
        selfStudyQuestions: [...selfStudyQuestions],
        selfStudyVideos: [...selfStudyVideos]
      };
    }

    return {};
  }

  handleDeleteAssessment = () => {
    const { containerState } = this.props;
    const { form } = containerState;
    const { selfStudyAssessments } = form;

    // eslint-disable-next-line
    if (confirm('Are you sure you want to delete the self study assessment?')) {
      selfStudyAssessments.forEach((a: any) => {
        actions.deleteSelfStudyAssessment(form.id, a.id);
      });
    }
  };

  handleDeleteVideo = (videoId: number) => () => {
    // eslint-disable-next-line
    if (confirm('Are you sure you want to delete the self study video?')) {
      const { containerState } = this.props;
      const { form } = containerState;
      actions.deleteSelfStudyVideo(form.id, videoId);
    }
  };

  handleDeleteQuestion = (questionId: number) => () => {
    const { containerState } = this.props;
    const { form } = containerState;

    // eslint-disable-next-line
    if (confirm('Are you sure you want to delete the self study question?')) {
      actions.deleteSelfStudyQuestion(form.id, questionId);
    }
  };

  handleEditVideo = (video: any) => () => {
    const { containerState } = this.props;
    const { form } = containerState;
    // eslint-disable-next-line no-alert
    const title = prompt('Enter a video title:', video.title);
    if (title) {
      actions.patchSelfStudyVideo(form.id, video.id, {
        title
      });
    }
  };

  handlePreviewAssessment = (questions: any[]) => () => {
    this.setState({
      previewAssessment: questions
    });
  };

  handlePreviewQuestion = (question: any) => () => {
    this.setState({
      previewQuestion: question
    });
  };

  handleClosePreviewQuestion = () => {
    this.setState({
      previewQuestion: undefined
    });
  };

  handleClosePreviewAssessment = () => {
    this.setState({
      previewAssessment: undefined
    });
  };

  handleSortSave = () => {
    const { containerState } = this.props;
    const { timeline } = this.state;
    const { form } = containerState;

    timeline.forEach((item, position) => {
      if (item.question) {
        actions.patchSelfStudyQuestion(form.id, item.id, {
          position
        });
      } else {
        actions.patchSelfStudyVideo(form.id, item.id, {
          position
        });
      }
    });
  };

  onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex === newIndex) {
      return;
    }

    this.setState(
      {
        timeline: arrayMove(this.state.timeline, oldIndex, newIndex)
      },
      () => {
        this.handleSortSave();
      }
    );
  };

  dragHandle = () => {
    const { classes } = this.props;
    return SortableHandle(() => (
      <Button size="small" className={classes.actionButton}>
        <Icon>drag_handle</Icon>
      </Button>
    ));
  };

  renderAssessment() {
    const { classes, containerState } = this.props;
    const { form } = containerState;
    const { selfStudyAssessments = [] } = form;

    if (!selfStudyAssessments.length) {
      return <div />;
    }

    return (
      <div key="assessment">
        <div className={classes.displayTime}>
          {
            // eslint-disable-next-line
            <a onClick={this.handlePreviewAssessment(selfStudyAssessments)}>
              <h2 className={classes.displayTimeHeader}>Final Assessment</h2>
            </a>
          }
        </div>
        <div>
          <div className={classes.timelineMeta} />
          <div className={classes.timelineActions}>
            <EditAssessmentDialog courseId={form.id} questions={selfStudyAssessments} />
            <Button size="small" className={classes.actionButton} onClick={this.handleDeleteAssessment}>
              <Icon>delete</Icon>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderQuestion(question: any) {
    const { classes, containerState } = this.props;
    const { form } = containerState;
    const DragHandle = this.dragHandle();
    return (
      <div key={`Q${question.id}`}>
        <div className={classes.displayTime}>
          <Tooltip title={question.question} placement="top">
            <a onClick={this.handlePreviewQuestion(question)}>
              <h2 className={classes.displayTimeHeader}>Question {question.number}</h2>
            </a>
          </Tooltip>
        </div>

        <div>
          <div className={classes.timelineMeta} />
          <div className={classes.timelineActions}>
            <EditQuestionDialog courseId={form.id} question={question} />
            <Button size="small" className={classes.actionButton} onClick={this.handleDeleteQuestion(question.id)}>
              <Icon>delete</Icon>
            </Button>
            <DragHandle />
          </div>
        </div>
      </div>
    );
  }

  renderVideo(video: any) {
    const { classes } = this.props;
    const DragHandle = this.dragHandle();
    return (
      <div key={`V${video.id}`}>
        <div className={classes.video}>
          <img alt={video.title} className={classes.videoThumbnail} src={video.thumbnailUrl} />
        </div>
        <div>
          <div className={classes.timelineMeta}>{prettyDuration(video.duration)}</div>
          <div className={classes.timelineActions}>
            <Button size="small" className={classes.actionButton} onClick={this.handleEditVideo(video)}>
              <Icon>edit</Icon>
            </Button>

            <Button size="small" className={classes.actionButton} onClick={this.handleDeleteVideo(video.id)}>
              <Icon>delete</Icon>
            </Button>
            <DragHandle />
          </div>
          <p>
            <b>{video.title}</b>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { classes, containerState, handleCancelClick, setContainerState, formHasChanges } = this.props;
    const { form, submitting } = containerState;
    const { previewAssessment = [], previewQuestion, timeline } = this.state;

    const SortableItem = SortableElement(({ item }: { item: any }) => (item.question ? this.renderQuestion(item) : this.renderVideo(item)));

    const AssessmentItem = SortableElement(() => this.renderAssessment());

    const DialogItem = SortableElement(() => (
      <AddSelfStudyDialog containerState={containerState} setContainerState={setContainerState} newPos={timeline.length} />
    ));

    const SortableList = SortableContainer(({ items }: { items: any[] }) => (
      <div className={classes.videos}>
        {items.map((item, index) => (
          <SortableItem key={`item-${index}`} index={index} item={item} />
        ))}
        <AssessmentItem index={items.length} disabled={true} />
        <DialogItem index={items.length + 1} disabled={true} />
      </div>
    ));

    return (
      <div className={classes.container}>
        <ActionButtons
          course={form}
          preview="self-study"
          onCancelClick={handleCancelClick}
          submitting={submitting}
          formHasChanges={formHasChanges}
        />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2>{form.name} - Self Study Timeline</h2>

            <SortableList axis={'xy'} items={timeline} onSortEnd={this.onSortEnd} useDragHandle={true} />
          </Grid>
        </Grid>
        <SelfStudyQuestion preview={previewQuestion} onClose={this.handleClosePreviewQuestion} />
        <SelfStudyAssessment
          assessments={previewAssessment}
          open={previewAssessment ? previewAssessment.length > 0 : false}
          onClose={this.handleClosePreviewAssessment}
          preview={true}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SelfStudy);
