import React, { Component } from 'react';
import { captureException, withScope } from '@sentry/browser';
import { WithProps } from 'react-waterfall';
import { match } from 'react-router';

import { connect, StateType } from '../../core/store';

const mapStateToProps = ({ user }: StateType) => ({
  user
});

interface Props extends WithProps<typeof mapStateToProps> {
  match?: match;
}

export const ExceptionBoundary = (BaseComponent: React.ComponentType<any>) => {
  class ExceptionBoundaryBase extends Component<Props> {
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
      const { match = null } = this.props;

      withScope(scope => {
        scope.setExtras({
          reactError: errorInfo,
          spaURL: JSON.stringify(match)
        });
        captureException(error);
      });
    }

    render() {
      /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
      const { user, ...others } = this.props;
      return <BaseComponent {...others} />;
    }
  }

  return connect(mapStateToProps)(ExceptionBoundaryBase);
};
