import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Source Sans Pro",-apple-system,system-ui,BlinkMacSystemFont,sans-serif'
  },
  palette: {
    primary: {
      light: '#7d73ff',
      main: '#3346ff',
      dark: '#001cca',
      contrastText: '#fff'
    },
    secondary: {
      light: '#73c3ff',
      main: '#2693ff',
      dark: '#0066cb',
      contrastText: '#fff'
    },
    error: red
  },
  // @ts-ignore
  canopy: {
    background: '#eef1f6',
    disabled: '#9B9B9B'
  }
});

export default theme;
