import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const InstructorFormatter = (props: any) => {
  const { value } = props;

  if (!value || typeof value !== 'object') {
    return null;
  }

  return (
    <span>
      {value.firstName} {value.lastName}
    </span>
  );
};

// @ts-ignore
const InstructorTypeProvider = ({ for: forProp, ...others }) => (
  <DataTypeProvider formatterComponent={InstructorFormatter} for={forProp} {...others} />
);

export default InstructorTypeProvider;
