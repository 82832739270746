import React, { Component } from 'react';
import { format, isValid } from 'date-fns';
import { Button, createStyles, Grid, Icon, Theme, WithStyles, withStyles } from '@material-ui/core';

import { actions } from '../../../../../core/store';
import { prettyDuration } from '../../../../../utilities';
import { ActionButtons } from '../../shared';

import Schedule from './schedule/schedule';
import DialogAdd from './add-webinar-dialog';

const styles = (theme: Theme) =>
  createStyles({
    datePickerContainer: {
      marginTop: 20
    },
    displayTime: {
      textAlign: 'center',
      backgroundColor: theme.palette.secondary.main,
      width: 250,
      height: 141,
      marginRight: 15
    },
    displayTimeHeader: {
      margin: 'auto',
      lineHeight: '150px',
      verticalAlign: 'middle',
      color: 'white'
    },
    form: {
      margin: '0px 40px',
      padding: 60
    },
    formControl: {
      width: '100%'
    },
    timelineActions: {
      display: 'inline-block',
      width: 64
    },
    timelineDelete: {
      textAlign: 'right'
    },
    timelineMeta: {
      display: 'inline-block',
      width: 186
    },
    video: {
      width: 250,
      height: 141,
      marginRight: 15
    },
    videoThumbnail: {
      width: '100%',
      height: '100%'
    },
    videos: {
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      width: '100%'
    }
  });

interface Props extends WithStyles<typeof styles> {
  containerState: any;
  handleCancelClick: () => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  setContainerState: (state: { [index: string]: any }) => void;
  formHasChanges: boolean;
  handleAddSchedule: Function;
  handleRemoveSchedule: Function;
  blackoutDates: string[];
  handleAddBlackoutDate: Function;
  handleRemoveBlackoutDate: Function;
}

interface State {
  scheduledOn: Date | undefined;
}

class Webinar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      scheduledOn: undefined
    };
  }

  formatLabel = (date: Date, invalidLabel: string) => {
    if (date === null) {
      return '';
    }

    if (isValid(date)) {
      const label = format(date, 'MMM do hh:mm a');
      return label;
    }

    return invalidLabel;
  };

  handleChange = (propName: string) => (date: Date) => {
    const stateChange = {};
    // @ts-ignore
    stateChange[propName] = date;
    this.setState(stateChange);
  };

  handleDeleteVideo = () => {
    // eslint-disable-next-line
    if (confirm('Are you sure you want to delete the webinar video?')) {
      const { containerState } = this.props;
      const { form } = containerState;
      actions.deleteWebinarVideo(form.id);
    }
  };

  handleDeleteQuestion = (index: number) => () => {
    // eslint-disable-next-line
    if (confirm('Are you sure you want to delete the webinar polling question?')) {
      const { containerState } = this.props;
      const { form } = containerState;
      const { webinarPollingQuestionDisplayTimes } = form;

      webinarPollingQuestionDisplayTimes.splice(index, 1);

      actions.patchWebinarPollingDisplayTimes(form.id, webinarPollingQuestionDisplayTimes);
    }
  };

  handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { containerState, setContainerState } = this.props;
    const { form } = containerState;
    const { name, value } = event.target;

    setContainerState({
      form: {
        ...form,
        [name]: value
      }
    });
  };

  render() {
    const {
      classes,
      containerState,
      handleCancelClick,
      handleSubmit,
      setContainerState,
      formHasChanges,
      handleAddSchedule,
      handleRemoveSchedule,
      blackoutDates,
      handleAddBlackoutDate,
      handleRemoveBlackoutDate
    } = this.props;
    const { form, submitting } = containerState;
    const { webinarVideos = [], webinarPollingQuestionDisplayTimes = [] } = form;
    const [webinarVideo] = webinarVideos;
    const daysBetweenOptions = [];
    for (let i = 1; i <= 100; i++) {
      daysBetweenOptions.push({ id: i, name: i });
    }

    return (
      <form autoComplete="off" onSubmit={handleSubmit} className={classes.form}>
        <ActionButtons
          course={form}
          preview="webinar"
          onCancelClick={handleCancelClick}
          submitting={submitting}
          formHasChanges={formHasChanges}
        />

        <Schedule
          containerState={containerState}
          setContainerState={setContainerState}
          blackoutDates={blackoutDates}
          handleAddSchedule={handleAddSchedule}
          handleRemoveSchedule={handleRemoveSchedule}
          handleAddBlackoutDate={handleAddBlackoutDate}
          handleRemoveBlackoutDate={handleRemoveBlackoutDate}
        />

        <Grid container spacing={3}>
          <Grid item xs={6} />

          <Grid item xs={12}>
            <h2>Webinar Timeline</h2>

            <div className={classes.videos}>
              {webinarVideo && (
                <div>
                  <div className={classes.video}>
                    <img alt="thumbnail url" className={classes.videoThumbnail} src={webinarVideo.thumbnailUrl} />
                  </div>
                  <div>
                    <div className={classes.timelineMeta}>{prettyDuration(webinarVideo.duration)}</div>

                    <div className={classes.timelineActions}>
                      <Button className={classes.timelineDelete} size="small" onClick={this.handleDeleteVideo}>
                        <Icon>delete</Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              {webinarPollingQuestionDisplayTimes.map((displayTime: string, index: number) => (
                <div key={index}>
                  <div className={classes.displayTime}>
                    <h2 className={classes.displayTimeHeader}>Question {index + 1}</h2>
                  </div>

                  <div>
                    <div className={classes.timelineMeta}>Display Time: {displayTime}</div>

                    <div className={classes.timelineActions}>
                      <Button className={classes.timelineDelete} size="small" onClick={this.handleDeleteQuestion(index)}>
                        <Icon>delete</Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              ))}

              <DialogAdd containerState={containerState} setContainerState={setContainerState} />
            </div>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(Webinar);
