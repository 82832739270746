import React, { ChangeEvent, Component } from 'react';
import { Button, createStyles, Grid, Icon, IconButton, InputAdornment, TextField, WithStyles, withStyles } from '@material-ui/core';

import { Select } from '../../../../../../shared';

const styles = createStyles({
  container: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  }
});

interface Props extends WithStyles<typeof styles> {
  submitting: boolean;
  expanded?: string;
  assessment: any;
  handleChange: (val: number) => (event: ChangeEvent<HTMLElement>) => void;
  handleArrayChange: (questionIndex: number, prop: string, index: number, event: any) => void;
  handleAddAnswer: (val: number) => void;
  handleAddQuestion: () => void;
  handleRemoveAnswer: (questionIndex: number, answerIndex: number) => void;
  handleRemoveQuestion: (val: number) => void;
}

class AddFinalAssessmentPresenter extends Component<Props> {
  render() {
    const {
      assessment,
      classes,
      expanded,
      handleChange,
      handleArrayChange,
      handleAddAnswer,
      handleAddQuestion,
      handleRemoveAnswer,
      handleRemoveQuestion,
      submitting
    } = this.props;

    const { questions } = assessment;

    return (
      <div className={classes.container}>
        {questions.map((question: any, index: number) => {
          const { answers, correctAnswer } = question;
          return (
            <div key={index}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div>
                    <TextField
                      id="question"
                      disabled={submitting}
                      fullWidth
                      label="Question"
                      margin="none"
                      onChange={handleChange(index)}
                      required={expanded === 'assessment'}
                      type="text"
                      value={question.question}
                    />
                  </div>

                  <Select
                    onChange={handleChange(index)}
                    label="Correct Answer"
                    name="correctAnswer"
                    value={correctAnswer}
                    options={answers.map((answer: any, i: number) => ({
                      id: i,
                      name: i + 1
                    }))}
                    className={classes.formControl}
                  />

                  <br />
                  <br />
                  {index !== 0 && (
                    <Button variant="outlined" onClick={() => handleRemoveQuestion(index)}>
                      Remove Question
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {answers.map((a: any, i: number) => (
                    <div key={i}>
                      <TextField
                        id="answers"
                        disabled={submitting}
                        fullWidth
                        label={`Answer ${i + 1}`}
                        margin="none"
                        onChange={event => {
                          handleArrayChange(index, 'answers', i, event);
                        }}
                        type="text"
                        required={expanded === 'assessment' && i < 2}
                        value={a}
                        InputProps={
                          i < 2
                            ? {}
                            : {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => handleRemoveAnswer(index, i)}>
                                      <Icon>delete</Icon>
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }
                        }
                      />
                    </div>
                  ))}
                  <br />
                  <Button variant="outlined" onClick={() => handleAddAnswer(index)}>
                    Add Answer
                  </Button>
                </Grid>
              </Grid>
            </div>
          );
        })}
        <Grid container spacing={3}>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleAddQuestion}>
              Add Question
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AddFinalAssessmentPresenter);
