import React from 'react';
import { bool } from 'prop-types';
import { Prompt } from 'react-router-dom';

interface Props {
  navigationBlocked: boolean;
}

const NavigationBlocker: React.FunctionComponent<Props> = ({ navigationBlocked }) => {
  if (navigationBlocked) {
    window.onbeforeunload = () => true;
  } else {
    window.onbeforeunload = null;
  }
  return (
    <Prompt
      when={navigationBlocked}
      message="By leaving before the end of the webinar and reviewing the course, you will not receive credit. Are you sure you want to leave?"
    />
  );
};

NavigationBlocker.propTypes = {
  navigationBlocked: bool.isRequired
};

export { NavigationBlocker };
