import parseMs from 'parse-ms';

const plur = (word: any, count: any) => (count === 1 ? word : `${word}s`);

/**
 * Convert milliseconds into user friendly time.
 * Based on code from https://www.npmjs.com/package/pretty-ms
 */
const prettyMs = (ms: any, opts: any = {}): string => {
  if (!Number.isFinite(ms)) {
    throw new TypeError('Expected a finite number');
  }

  if (ms < 1000) {
    const msDecimalDigits = typeof opts.msDecimalDigits === 'number' ? opts.msDecimalDigits : 0;
    return (
      (msDecimalDigits ? ms.toFixed(msDecimalDigits) : Math.ceil(ms)) + (opts.verbose ? ` ${plur('millisecond', Math.ceil(ms))}` : 'ms')
    );
  }

  const ret: any[] = [];

  const add = (val: any, long: any, short: any, valStr?: any) => {
    if (val === 0) {
      return;
    }

    const postfix = opts.verbose ? ` ${plur(long, val)}` : short;

    ret.push((valStr || val) + postfix);
  };

  const parsed = parseMs(ms);

  add(Math.trunc(parsed.days / 365), 'year', 'y');
  add(parsed.days % 365, 'day', 'd');
  add(parsed.hours, 'hour', 'h');
  add(parsed.minutes, 'minute', 'm');

  if (opts.compact) {
    add(parsed.seconds, 'second', 's');
    return `~${ret[0]}`;
  }

  const sec = (ms / 1000) % 60;
  const secDecimalDigits = typeof opts.secDecimalDigits === 'number' ? opts.secDecimalDigits : 0;
  const secFixed = sec.toFixed(secDecimalDigits);
  const secStr = opts.keepDecimalsOnWholeSeconds ? secFixed : secFixed.replace(/\.0+$/, '');
  add(sec, 'second', 's', secStr);

  return ret.join(' ');
};

export default prettyMs;
