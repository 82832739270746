import React, { Component, forwardRef } from 'react';
import { Button, createStyles, Dialog, Icon, Theme, WithStyles, withStyles } from '@material-ui/core';
import { isEqual } from 'lodash';
import { addSeconds, differenceInMilliseconds, format, isAfter, isBefore } from 'date-fns';
import { Link, Redirect } from 'react-router-dom';

import { actions } from '../../../../core/store';
import { WEBINAR_MAX_MISSED_SECONDS_PER_CREDIT } from '../../../../constants';
import { timezoneAbbr } from '../../../../utilities';
import { CourseType, UserWebinarType } from '../../../../types';

import { WebinarClosed } from './webinar-closed/webinar-closed';

const zoneAbbr = timezoneAbbr();

type NoticeState = 'not-started' | 'closed' | 'late-start' | null;

const styles = (theme: Theme) =>
  createStyles({
    button: {
      marginRight: 20
    },
    container: {
      margin: '40px 80px'
    },
    closeContainer: {
      textAlign: 'right'
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 5,
      marginTop: 20,
      marginBottom: 20,
      width: 200
    }
  });

// TODO: only the webinar registration should be passed in rather than searching through user-webinars
interface Props extends WithStyles<typeof styles> {
  course: CourseType;
  player: any;
  userWebinars?: UserWebinarType[];
}

interface State {
  notice: NoticeState;
  redirect: string | null;
  registeredWebinar: Record<string, any> | null;
}

class WebinarNoticeDialogBase extends Component<Props, State> {
  startTimeout?: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      notice: null,
      redirect: null,
      registeredWebinar: null
    };
  }

  componentDidMount() {
    this.notice();
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.course, this.props.course) || prevProps.userWebinars !== this.props.userWebinars) {
      this.notice();
    }
  }

  componentWillUnmount() {
    if (this.startTimeout) {
      window.clearTimeout(this.startTimeout);
    }
  }

  notice = () => {
    const { course, userWebinars } = this.props;

    if (!course || !userWebinars) {
      return;
    }

    const registeredWebinar = userWebinars.find(userWebinar => userWebinar.courseId === course.id);

    if (!registeredWebinar) {
      this.setState({
        notice: 'closed'
      });
      return;
    }

    const scheduledOn = new Date(registeredWebinar.scheduledOn);
    const duration = parseInt(course.webinarVideos[0].duration, 10);
    const scheduledEnd = addSeconds(scheduledOn, duration);
    const minimumWatchSeconds = WEBINAR_MAX_MISSED_SECONDS_PER_CREDIT * (course.credits || 1);

    const cutOffSeconds = minimumWatchSeconds >= duration ? 60 : minimumWatchSeconds;
    const cutOffTime = addSeconds(scheduledOn, cutOffSeconds);
    const now = new Date();

    let notice: NoticeState = null;
    if (isBefore(now, scheduledOn)) {
      notice = 'not-started';

      if (!this.startTimeout) {
        const milliSecondsToStart = differenceInMilliseconds(scheduledOn, now);
        this.startTimeout = window.setTimeout(this.notice, milliSecondsToStart);
      }
    } else if (isAfter(now, scheduledEnd)) {
      notice = 'closed';
    } else if (isAfter(now, cutOffTime) && !registeredWebinar.joined) {
      notice = 'late-start';
    }

    if (!notice && !registeredWebinar.joined) {
      actions.joinWebinar(course.id, registeredWebinar.webinarId);
    }

    if (notice !== 'closed' && this.props.player) {
      this.props.player.once('ended', this.notice);
    }

    this.setState({ notice, registeredWebinar });
  };

  handleContinue = () => {
    this.setState({ notice: null });
  };

  handleExit = () => {
    this.setState({
      redirect: '/my/courses'
    });
  };

  render() {
    const { classes, course, userWebinars } = this.props;
    const { notice, redirect, registeredWebinar } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    if (!course || !userWebinars) {
      return null;
    }

    const Close = (
      <div className={classes.closeContainer}>
        <Button onClick={this.handleExit}>
          <Icon>close</Icon>
        </Button>
      </div>
    );

    const courseLink = forwardRef((props: any, ref) => <Link innerRef={ref} to={`/courses/${course.id}`} {...props} />);
    return (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        maxWidth="md"
        fullWidth={true}
        open={!!notice}
        onClose={this.handleExit}>
        <div className={classes.container}>
          {notice === 'not-started' && registeredWebinar && (
            <div>
              {Close}
              <h1>
                Welcome, this Webinar will begin&nbsp;
                {`${format(new Date(registeredWebinar.scheduledOn), 'EEEE MMMM d, h:mm a')} ${zoneAbbr}`}
              </h1>
              <div className={classes.divider} />
              <p>The webinar will start automatically at its scheduled time.</p>
            </div>
          )}
          {notice === 'late-start' && (
            <div>
              {Close}
              <h1>You joined after the cut off time to receive credit. </h1>
              <div className={classes.divider} />
              <p>You are welcome to complete the course, but will not receive credit.</p>
              <br />
              <Button className={classes.button} color="primary" component={courseLink} variant="contained">
                Reschedule
              </Button>
              <Button className={classes.button} onClick={this.handleContinue} variant="outlined">
                Join Anyway
              </Button>
              <Button className={classes.button} component={courseLink} onClick={this.handleContinue} variant="outlined">
                Start Self Study
              </Button>
            </div>
          )}
          {notice === 'closed' && <WebinarClosed course={course} handleContinue={this.handleContinue} />}
        </div>
      </Dialog>
    );
  }
}

export const WebinarNoticeDialog = withStyles(styles)(WebinarNoticeDialogBase);
