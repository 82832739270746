import { request } from '../../utilities';
import { LOGOUT_URL } from '../../constants';
import { StateType } from '../store';
import { removeAuthToken, setAuthToken } from '../services/auth';

/**
 * Login to API and save auth token in local storage.
 *
 */
export async function login(_s: StateType, _a: any, email: string) {
  const token = await request('post', '/access-tokens', {
    email
  });
  const { data: jwt, error } = token;

  if (error) {
    return { jwt: { error: 'Invalid email or password.' } };
  }

  setAuthToken(jwt);

  return { jwt };
}

/**
 * Logout and destroy cookies.
 */
export async function logout() {
  await request('delete', `/access-tokens`, {});
  removeAuthToken();
  window.location.href = LOGOUT_URL;
  return {};
}

/**
 * Authenticate SSO endpoint with JWT and set auth token if success.
 *
 */
export async function sso(_s: StateType, _a: any, jwt: string) {
  const token = await request('post', '/access-tokens/sso', {
    jwt
  });
  const { data, error } = token;

  if (error) {
    return { jwt: { error: error.message } };
  }

  setAuthToken(data);

  return { jwt: data };
}
