import React, { Component } from 'react';
import { Button, createStyles, Grid, Paper, TextField, WithStyles, withStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';

const styles = createStyles({
  container: {
    padding: '40px 50px 40px',
    height: 100
  },
  getStartedHeader: {
    display: 'inline-block',
    marginRight: 20
  },
  getStartedGrid: {
    textAlign: 'right',
    paddingRight: 20
  },
  formContainer: {
    paddingTop: 20,
    paddingBottom: 60
  },
  formParagraph: {
    fontSize: '1.2rem'
  },
  paper: {
    padding: '10px 10px 10px 10%'
  }
});

interface Props extends WithStyles<typeof styles> {
  form: any;
  handleFormChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  jwt: any;
  login: (event: React.FormEvent<HTMLFormElement>) => void;
  submitting: boolean;
}

class BaseComponent extends Component<Props> {
  render() {
    const { classes, form, handleFormChange, jwt, login, submitting } = this.props;

    return (
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.container}>
          <Helmet title="Login" />
          <Paper className={classes.paper}>
            <Grid item xs={12} className={classes.getStartedGrid}>
              <div>
                <p className={classes.getStartedHeader}>Don&apos;t have an account?</p>
                <Button color="primary" variant="contained">
                  Get Started
                </Button>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.formContainer}>
              <h1>Development Sign In To Canopy</h1>

              <p className={classes.formParagraph}>Enter your details below.</p>

              <form noValidate autoComplete="off" onSubmit={login}>
                <div>
                  <TextField
                    disabled={submitting}
                    error={!!jwt.error}
                    fullWidth={true}
                    helperText={jwt.error}
                    id="email"
                    label="Email"
                    margin="normal"
                    onChange={handleFormChange}
                    required={true}
                    type="email"
                    value={form.email}
                  />
                </div>
                <Button style={{ marginTop: 28 }} type="submit" variant="contained" color="primary" disabled={submitting}>
                  Sign In
                </Button>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export const LoginPresenter = withStyles(styles)(BaseComponent);
