import React, { Component, forwardRef } from 'react';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Button, createStyles, FormControl, FormControlLabel, Radio, RadioGroup, Theme, WithStyles, withStyles } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../core/store';
import { REGISTER_WEBINAR_SUCCESS } from '../../../constants';
import { timezoneAbbr } from '../../../utilities';
import { isWithinDisplayInterval } from '../../../utilities/schedule';
import { CourseType } from '../../../types';
import googleCalendarIcon from '../../../../images/calendars/gcal.png';
import icalIcon from '../../../../images/calendars/ical.png';
import outlookIcon from '../../../../images/calendars/outlook.png';

import { CourseSeries } from './course-series';

const zoneAbbr = timezoneAbbr();

const styles = (theme: Theme) =>
  createStyles({
    backArrow: {
      fontSize: '1.3rem',
      fontWeight: 'bold'
    },
    backButton: {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 'normal',
      padding: 8,
      marginBottom: 20,
      minWidth: 'auto'
    },
    button: {
      marginRight: 10
    },
    container: {
      padding: '20px 40px'
    },
    radioLabel: {
      fontSize: '1rem'
    },
    successHeader: {
      marginTop: 120,
      marginBottom: 0
    },
    successSubHeader: {
      fontSize: '1.5rem'
    },
    calAddHeader: {
      marginTop: 70,
      marginBottom: 25,
      fontWeight: 'bold'
    },
    calCell: {
      textAlign: 'center',
      cursor: 'pointer'
    },
    calCell2: {
      textAlign: 'center',
      cursor: 'pointer',
      paddingLeft: 40
    },
    calIcon: {
      width: 70,
      height: 68
    }
  });

const mapStateToProps = (state: StateType) => ({
  message: state.message,
  userWebinars: state.userWebinars,
  userSelfStudies: state.userSelfStudies,
  savedCourses: state.savedCourses,
  googleCalendarLink: state.googleCalendarLink,
  icalAttachmentContent: state.icalAttachmentContent
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  course: CourseType;
  handleChangeStep: Function;
}

interface State {
  complete: boolean;
  webinarId: number;
}

class WebinarEnrollmentBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      complete: false,
      webinarId: -1
    };
  }

  componentDidUpdate() {
    if (!this.state.complete && this.props.message === REGISTER_WEBINAR_SUCCESS) {
      this.setState({ complete: true });
    }
  }

  handleEnroll = async () => {
    const { course, userWebinars = [], userSelfStudies = [], savedCourses } = this.props;
    const { webinarId } = this.state;

    const webinar = course.webinarScheduled.find(web => web.id === webinarId);
    const webinarEnrollment = userWebinars.find(web => web.courseId === course.id);
    const selfStudyEnrollment = userSelfStudies.find(st => st.courseId === course.id);
    const savedCourse = savedCourses.find(sc => sc.courseId === course.id);

    if (webinar) {
      const scheduledOn = `${format(new Date(webinar.scheduledOn), 'MMMM d, yyyy, h:mm a')} ${zoneAbbr}`;

      // eslint-disable-next-line
      if (confirm(`Are you sure you want to register for the ${course.name} webinar on ${scheduledOn}?`)) {
        if (webinarEnrollment && webinarEnrollment.webinarId !== webinar.id) {
          await actions.unregisterWebinar(course.id, webinarEnrollment.webinarId, false);
        }
        if (selfStudyEnrollment) {
          await actions.unregisterSelfStudy(course.id, false);
        }
        if (savedCourse) {
          await actions.unsaveUserCourse(course.id, false);
        }
        actions.registerWebinar(course.id, webinarId);
      }
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ webinarId: parseInt(event.target.value, 10) });
  };

  handleGcalClick = () => {
    const { googleCalendarLink } = this.props;
    window.open(googleCalendarLink, '_blank');
  };

  handleIcalClick = () => {
    const { course, icalAttachmentContent } = this.props;
    if (icalAttachmentContent == null) {
      return;
    }

    const blob = new Blob([icalAttachmentContent], { type: 'text/calendar;charset=utf-8' });
    saveAs(blob, `${course.name}.ics`);
  };

  render() {
    const { classes, course, handleChangeStep } = this.props;
    const { complete, webinarId } = this.state;
    const webinarScheduled = course.webinarScheduled.filter(w => isWithinDisplayInterval(new Date(w.scheduledOn)));

    const courseListLink = forwardRef((props: any, ref) => <Link innerRef={ref} to="/" {...props} />);
    const myCoursesLink = forwardRef((props: any, ref) => <Link innerRef={ref} to="/my/courses" {...props} />);

    if (complete) {
      return (
        <div className={classes.container}>
          <h1 className={classes.successHeader}>
            Success,
            <br />
            Webinar Added!
          </h1>
          <p className={classes.successSubHeader}>Course was saved to My Courses</p>
          <Button component={courseListLink} variant="contained" color="secondary" className={classes.button}>
            Continue Browsing Courses
          </Button>
          <Button component={myCoursesLink} variant="outlined" className={classes.button}>
            View In My Courses
          </Button>

          <p className={classes.calAddHeader}>Add this webinar to my Calendar</p>

          <table>
            <tbody>
              <tr>
                <td className={classes.calCell} onClick={this.handleIcalClick}>
                  <img src={icalIcon} className={classes.calIcon} />
                </td>
                <td className={classes.calCell2} onClick={this.handleGcalClick}>
                  <img src={googleCalendarIcon} className={classes.calIcon} />
                </td>
                <td className={classes.calCell2} onClick={this.handleIcalClick}>
                  <img src={outlookIcon} className={classes.calIcon} />
                </td>
              </tr>
              <tr>
                <td className={classes.calCell} onClick={this.handleIcalClick}>
                  <span>iCal</span>
                </td>
                <td className={classes.calCell2} onClick={this.handleGcalClick}>
                  <span>Google Cal</span>
                </td>
                <td className={classes.calCell2} onClick={this.handleIcalClick}>
                  <span>Outlook Cal</span>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <CourseSeries course={course} />
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <Button className={classes.backButton} onClick={handleChangeStep('details')}>
          <span className={classes.backArrow} dangerouslySetInnerHTML={{ __html: '&xlarr;' }} />
          &nbsp;&nbsp;
        </Button>

        <h2>Select Webinar Time</h2>
        <FormControl component={'fieldset'} required>
          <RadioGroup aria-label="webinarId" name="webinarId" value={`${webinarId}`} onChange={this.handleChange}>
            {webinarScheduled.map(web => (
              <FormControlLabel
                classes={{ label: classes.radioLabel }}
                key={web.id}
                value={`${web.id}`}
                control={<Radio />}
                label={`${format(new Date(web.scheduledOn), 'MMMM d, yyyy, h:mm a')} ${zoneAbbr}`}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <br />
        <br />
        <Button color="primary" disabled={!webinarId} onClick={this.handleEnroll} variant="contained">
          Enroll
        </Button>
      </div>
    );
  }
}

export const WebinarEnrollment = withStyles(styles)(connect(mapStateToProps)(WebinarEnrollmentBase));
