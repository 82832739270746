import { CertificateType } from '../types';
import { array, number, string } from '../utilities/parse';

/**
 * Certificate related parsing functions for API responses.
 */

function parseCertificateCourseInstructor(input: any = {}) {
  return {
    firstName: string(input, 'firstName'),
    lastName: string(input, 'lastName')
  };
}

function parseCertificateCourse(input: any = {}) {
  return {
    id: number(input, 'id'),
    categoryId: number(input, 'categoryId'),
    credits: number(input, 'credits'),
    credittype: array(input, 'credittype', string),
    ctecId: string(input, 'ctecId'),
    fieldOfStudyId: number(input, 'fieldOfStudyId'),
    name: string(input, 'name'),
    instructor: parseCertificateCourseInstructor(input.instructor),
    irsId: string(input, 'irsId'),
    youtubeUrl: string(input, 'youtubeUrl'),
    courseSeriesId: input.courseSeriesId,
    courseSeriesPart: number(input, 'courseSeriesPart'),
    seriesPartsCount: number(input, 'seriesPartsCount')
  };
}

function parseCertificateUser(input: any = {}) {
  return {
    id: number(input, 'id'),
    email: string(input, 'email'),
    firstName: string(input, 'firstName'),
    lastName: string(input, 'lastName'),
    firstNameOnCertificate: string(input, 'firstNameOnCertificate'),
    lastNameOnCertificate: string(input, 'lastNameOnCertificate'),
    ptin: string(input, 'ptin')
  };
}

export function parseCertificate(input: any = {}): CertificateType {
  return {
    id: number(input, 'id'),
    course: parseCertificateCourse(input.course),
    created: string(input, 'created'),
    deliveryMethod: string(input, 'deliveryMethod'),
    irsStatus: string(input, 'irsStatus'),
    irsStatusMessage: string(input, 'irsStatusMessage'),
    user: parseCertificateUser(input.user)
  };
}

export function parseCertificates(input: Record<string, any>[]): CertificateType[] {
  return input.map(row => parseCertificate(row));
}
