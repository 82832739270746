import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, createStyles, Tooltip, withStyles } from '@material-ui/core';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const styles = createStyles({
  link: {
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%'
  }
});

let LinkFormatter: any = (props: any) => {
  const { classes, row, value } = props;

  const rowLink = forwardRef((props: any, ref) => <Link innerRef={ref} to={row.url} {...props} />);
  return (
    <Tooltip title={value} placement="top-start">
      <Button component={rowLink} className={classes.link}>
        {value}
      </Button>
    </Tooltip>
  );
};

LinkFormatter = withStyles(styles)(LinkFormatter);

// @ts-ignore
const UrlTypeProvider = ({ for: forProp, ...others }) => <DataTypeProvider formatterComponent={LinkFormatter} for={forProp} {...others} />;

export default UrlTypeProvider;
