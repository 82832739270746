import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const CategoryTypeProvider = (props: any) => {
  const { categories } = props;

  const CategoryFormatter = (formatProps: any) => {
    const { value } = formatProps;
    const category = categories.find((cat: any) => cat.id === value);

    if (!category) {
      return <span>{value}</span>;
    }

    return <span>{category.name}</span>;
  };

  return <DataTypeProvider formatterComponent={CategoryFormatter} {...props} />;
};

export default CategoryTypeProvider;
