import { request } from '../../utilities';
import { parseInstructor, parseInstructors } from '../../parse';
import { StateType } from '../store';

export async function createInstructor(state: StateType, _a: any, params: any) {
  const { data, error } = await request('post', '/instructors', params);
  if (error) {
    return { error: error.message };
  }
  const { instructors = [] } = state;
  const instructor = parseInstructor(data);

  return {
    instructors: [...instructors, instructor],
    message: 'Instructor created.'
  };
}

export async function getInstructors(_s: StateType, _a: any, params: any) {
  const { data, error, headers } = await request('get', '/instructors', params);
  if (error) {
    return { error: error.message };
  }
  const instructorsTotal = parseInt(headers['x-count'], 10);
  const instructors = parseInstructors(data);
  return { instructors, instructorsTotal };
}

export async function updateInstructor(state: StateType, _a: any, id: number, params: any) {
  const { data, error } = await request('patch', `/instructors/${id}`, params);
  if (error) {
    return { error: error.message };
  }

  const message = 'The instructor has been successfully updated.';
  const instructors = [...state.instructors];
  const updatedInstructorIndex = instructors.findIndex(q => q.id === id);

  instructors.splice(updatedInstructorIndex, 1, parseInstructor(data));

  return {
    message,
    instructors
  };
}
