import axios from 'axios';

import { getAuthToken } from '../../core/services/auth';
import { environment } from '../environment';

const env = environment();

export default function download(endpoint: string, data: Record<string, any>, config = {}): Promise<any> {
  const url = `${env.apiUrl}${endpoint}`;
  // eslint-disable-next-line
  const { page, ...params } = data;
  const headers: any = {};

  const authToken = getAuthToken();
  if (authToken) {
    headers.Authorization = `Bearer ${authToken.accessToken}`;
  }

  return axios({
    headers,
    method: 'GET',
    url,
    params,
    ...config,
    responseType: 'blob'
  }).catch((err: any) => ({ error: err }));
}
