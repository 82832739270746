import React, { Component, forwardRef } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Button, Icon, WithStyles, withStyles } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { connect, StateType } from '../../../core/store';
import { studyToGradient } from '../../../utilities';

import styles from './course-panel.style';

const selfStudyIcon = require('../../../../images/icons/self-study.png');
const webinarIcon = require('../../../../images/icons/webinar.png');

const mapStateToProps = ({ fieldOfStudies }: StateType) => ({
  fieldOfStudies
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  course: any;
  preview?: boolean;
  removeEnabled?: boolean;
  onRemove?: () => void;
}

class CoursePanelBase extends Component<Props> {
  remove = (event: React.MouseEvent) => {
    event.stopPropagation();

    const {
      onRemove = () => {
        /* empty */
      }
    } = this.props;
    onRemove();
  };

  render() {
    const { classes, course, fieldOfStudies, preview, removeEnabled } = this.props;

    if (!course) {
      return null;
    }

    const fieldOfStudy = fieldOfStudies.find(s => s.id === course.fieldOfStudyId);
    if (!fieldOfStudy) {
      return null;
    }
    const { selfStudyVideos = [], webinarScheduled = [] } = course;
    const [nextWebinar] = webinarScheduled;
    const { credittype = [], credits } = course;
    const gradientStyle = studyToGradient(fieldOfStudy);
    const courseUrl = preview ? '#' : `/courses/${course.id}`;
    const courseLink = forwardRef((props: any, ref) => <Link innerRef={ref} to={preview ? '#' : `/courses/${course.id}`} {...props} />);

    return (
      <div className={classes.panel}>
        <div className={`${classes.panelHeader}`} style={gradientStyle}>
          {removeEnabled && (
            <Icon className={classes.closeIcon} onClick={this.remove}>
              close
            </Icon>
          )}
          {fieldOfStudy && (
            <Link className={classes.fieldOfStudy} to={courseUrl}>
              {fieldOfStudy.name}
            </Link>
          )}

          <Link className={classes.headerLink} to={courseUrl}>
            {course.name}
          </Link>

          {course.courseSeriesId && (
            <div className={classes.part}>
              Part {course.courseSeriesPart} of {course.seriesPartsCount}
            </div>
          )}
        </div>
        <div className={classes.panelBody}>
          {nextWebinar && (
            <p>
              <b>
                Next Webinar
                <br />
                {format(new Date(nextWebinar.scheduledOn), 'EEE, MMMM d, h:mm a')}
              </b>
            </p>
          )}
          {!nextWebinar && (
            <p>
              &nbsp;
              <br />
              &nbsp;
            </p>
          )}
          {credittype.length > 0 && (
            <p className={classes.creditType}>
              {credittype.join(' & ')} | {credits} Hour{credits > 1 && <span>s</span>}
            </p>
          )}

          <Button component={courseLink} className={classes.courseDetailsButton} color="primary" variant="contained">
            Course Details
          </Button>

          <div>
            {webinarScheduled.length > 0 && (
              <div className={classes.iconContainer}>
                <Link className={classes.icon} to={courseUrl}>
                  <img src={webinarIcon} />
                  <br />
                  Webinar
                </Link>
              </div>
            )}

            {selfStudyVideos.length > 0 && (
              <div className={classes.iconContainer}>
                <Link className={classes.icon} to={courseUrl}>
                  <img src={selfStudyIcon} />
                  <br />
                  Self Study
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const CoursePanel = connect(mapStateToProps)(withStyles(styles)(CoursePanelBase));
