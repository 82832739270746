import React, { Component } from 'react';
import { Avatar, WithStyles, withStyles } from '@material-ui/core';

/**
 * Display user avatar at supplied height and width (default 30x30).
 */

const styles = {
  avatar: {
    display: 'inline-flex',
    verticalAlign: 'middle'
  }
};

interface Props extends WithStyles<typeof styles> {
  url: string;
  className?: string;
  width?: number;
  height?: number;
}

class UserAvatarBase extends Component<Props> {
  render() {
    const { url, className = '', classes, width = 30, height = 30 } = this.props;
    const src = url || 'https://www.gravatar.com/avatar/92e7332be28e2d06883c813a6e6e8c46';

    return (
      <Avatar
        alt="Avatar"
        src={src}
        className={`${classes.avatar} ${className}`}
        style={{
          width,
          height
        }}
      />
    );
  }
}

export const UserAvatar = withStyles(styles)(UserAvatarBase);
