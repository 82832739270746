import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from '../../core/store';
import { LOGIN_URL } from '../../constants';

interface PropsAuthorize {
  jwt: any;
  location?: string;
}

export const PrivateRoute = (BaseComponent: React.ComponentType<any>) => {
  const ComponentWithRouter = withRouter(BaseComponent);
  let Authorize: any = ({ jwt, ...props }: PropsAuthorize) => {
    if (jwt.accessToken) {
      return <ComponentWithRouter {...props} />;
    }

    window.location.href = `${LOGIN_URL}?redirect_to=${encodeURIComponent(window.location.href)}`;
    return null;
  };

  Authorize = connect(state => ({ jwt: state.jwt }))(Authorize);
  return Authorize;
};
