/**
 * Iterate over an array which contains values, with the given iterator function with
 * the signature (value) where value is the resolved value
 * in the input array. Iteration happens serially.
 *
 * @param {Array<any>} arr
 * @param {Function} fn
 * @return {Promise<Array>}
 */
export default function promiseEach(arr: any, fn: (val: any) => Promise<any>) {
  if (!Array.isArray(arr)) return Promise.reject(new Error('Non array passed to each'));
  if (arr.length === 0) return Promise.resolve();
  return arr.reduce((prev, cur) => prev.then(() => fn(cur)), Promise.resolve());
}
