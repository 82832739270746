import { addDays, addWeeks, isAfter, isBefore, setDay, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';

import { WEBINAR_MAX_AUTOSCHEDULE_DAYS } from '../../constants';

interface RecurrenceRule {
  hour: number;
  minute: number;
  dow: number;
  frequency: number;
}

export function rrule(hour: number, minute: number, dow: number, frequency: number) {
  if ((hour !== 0 && !hour) || hour < 0 || hour > 23) {
    throw new Error('Bad hour');
  }
  if ((minute !== 0 && !minute) || minute < 0 || minute > 59) {
    throw new Error('Bad minute');
  }
  if (!dow || dow < 1 || dow > 5) {
    throw new Error('Bad dow');
  }
  if (!frequency || frequency < 1 || frequency > 4) {
    throw new Error('Bad frequency');
  }
  return { hour, minute, dow, frequency };
}

/**
 *  Zeroes seconds and milliseconds;
 *  Returns new date
 */
export function zero(date: Date): Date {
  let newDate = date;
  newDate = setSeconds(date, 0);
  newDate = setMilliseconds(date, 0);
  return newDate;
}

/**
 *  Sets hours and minutes on a date; Also, zeroes seconds and milliseconds;
 *  Returns new date
 */
export function setTime(date: Date, hour: number, minute: number): Date {
  let newDate = date;
  newDate = setHours(newDate, hour);
  newDate = setMinutes(newDate, minute);
  newDate = zero(newDate);
  return newDate;
}

/**
 *  Generates next date based on recurrence rule
 */
export function nextDate(date: Date, rule: RecurrenceRule, initial = false): Date {
  let newDate = setDay(date, rule.dow);
  newDate = setTime(newDate, rule.hour, rule.minute);

  if (isAfter(newDate, date)) {
    return newDate;
  }
  if (initial) {
    return addWeeks(newDate, 1);
  }
  return addWeeks(newDate, rule.frequency);
}

/**
 *  Generates an array of dates until stopDate
 */
export function generate(lastKnowDate: Date, stopDate: Date, rule: RecurrenceRule, initial = false): Date[] {
  const array = [];
  let lastDate = lastKnowDate;
  let runInitial = initial;

  // eslint-disable-next-line
  while (true) {
    lastDate = nextDate(lastDate, rule, runInitial);
    runInitial = false;
    if (isAfter(lastDate, stopDate)) {
      break;
    } else {
      array.push(lastDate);
    }
  }

  return array;
}

/**
 *  Tells whether the date is within 28 days from now
 */
export function isWithinDisplayInterval(date: Date): boolean {
  return isBefore(date, addDays(new Date(), WEBINAR_MAX_AUTOSCHEDULE_DAYS));
}

/**
 *  Converts Thu Oct 18 2018 15:08:27 GMT+0300 (Moscow Standard Time) to 2018-18-10
 */
export function toDateString(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // in js months are 0-based
  const paddedMonth = month > 9 ? month : `0${month}`;
  const date2 = date.getDate();
  const paddedDate = date2 > 9 ? date2 : `0${date2}`;

  return [year, paddedMonth, paddedDate].join('-');
}

/**
 *  checks if date is blaclisted against array in format of ["2018-01-01", ...]
 */
export function isBlacklisted(date: Date, blackoutDates: string[]): boolean {
  return blackoutDates.indexOf(toDateString(date)) !== -1;
}
