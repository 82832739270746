import React, { Component } from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  WithStyles,
  withStyles
} from '@material-ui/core';

import { actions } from '../../../../../core/store';
import { Select } from '../../../../../shared';
import { SelfStudyAssessment } from '../../../../../course';
import { promiseEach } from '../../../../../utilities';

const styles = createStyles({
  container: {
    display: 'inline-flex',
    verticalAlign: 'top'
  },
  formControl: {
    width: '100%'
  }
});

interface Props extends WithStyles<typeof styles> {
  courseId: number;
  questions: any[];
}

interface State {
  open: boolean;
  previewAssessment?: any[];
  questions: any[];
  submitting: boolean;
}

class EditAssessmentDialogBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { questions } = props;
    this.state = {
      open: false,
      questions,
      submitting: false
    };
  }

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    const { questions } = newProps;
    const { questions: prevQuestions } = prevState;
    const hasChange = questions.find((q, i) => prevQuestions[i] && q.updated !== prevQuestions[i].updated);

    if (hasChange) {
      return { questions };
    }

    return {};
  }

  handleAddQuestion = () => {
    const { questions } = this.state;
    const question = {
      answers: ['', ''],
      question: ''
    };
    this.setState({
      questions: [...questions, question]
    });
  };

  handleAddAnswer = (questionIndex: number) => () => {
    const { questions } = this.state;
    const question = questions[questionIndex];
    question.answers.push('');
    this.setState({
      questions: [...questions]
    });
  };

  handleArrayChange = (questionIndex: number) => (prop: string, index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { questions } = this.state;
    const question = questions[questionIndex];
    question[prop][index] = event.target.value;

    this.setState({
      questions: [...questions]
    });
  };

  handleChange = (questionIndex: number) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    const key = event.target.id || event.target.name;
    const { questions } = this.state;
    const question = questions[questionIndex];
    question[key] = event.target.value;
    this.setState({
      questions: [...questions]
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleRemoveAnswer = (questionIndex: number) => (answerIndex: number) => () => {
    const { questions } = this.state;
    const question = questions[questionIndex];
    const { answers } = question;

    answers.splice(answerIndex, 1);

    this.setState({
      questions: [...questions]
    });
  };

  handleRemoveQuestion = (questionId: number) => () => {
    const { courseId } = this.props;
    // eslint-disable-next-line
    if (confirm('Are you sure you want to delete this assessment question?')) {
      actions.deleteSelfStudyAssessment(courseId, questionId);
    }
  };

  handlePreviewAssessment = () => {
    const { questions } = this.state;
    this.setState({
      previewAssessment: questions
    });
  };

  handleClosePreviewAssessment = () => {
    this.setState({
      previewAssessment: undefined
    });
  };

  handleSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { courseId } = this.props;
    const { questions } = this.state;

    promiseEach(questions, q => {
      const { question, answers, correctAnswer } = q;
      const payload = {
        answers,
        correctAnswer: parseInt(correctAnswer, 10),
        question
      };

      if (q.id) {
        return actions.patchSelfStudyAssessment(courseId, q.id, payload);
      }

      return actions.createSelfStudyAssessment(courseId, payload);
    });
  };

  render() {
    const { classes } = this.props;
    const { previewAssessment, questions, submitting } = this.state;
    const disabled =
      questions.length === 0 || questions.some(q => !q.question || q.correctAnswer === undefined || !q.answers[0] || !q.answers[1]);

    return (
      <div className={classes.container}>
        <Button size="small" onClick={this.handleOpen}>
          <Icon>edit</Icon>
        </Button>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md">
          <form onSubmit={this.handleSave}>
            <DialogTitle id="form-dialog-title">Edit Self Study Assessment</DialogTitle>
            <DialogContent>
              {questions.map((question, index) => {
                const { answers } = question;
                return (
                  <div key={index}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <div>
                          <TextField
                            id="question"
                            defaultValue={question.question}
                            disabled={submitting}
                            fullWidth
                            label="Question"
                            margin="none"
                            onChange={this.handleChange(index)}
                            required={true}
                            type="text"
                          />
                        </div>

                        <Select
                          onChange={this.handleChange(index)}
                          label="Correct Answer"
                          name="correctAnswer"
                          value={question.correctAnswer}
                          options={answers.map((_: string, i: number) => ({
                            id: i,
                            name: i + 1
                          }))}
                          className={classes.formControl}
                        />

                        <br />
                        <br />
                        {index !== 0 && (
                          <Button variant="outlined" onClick={this.handleRemoveQuestion(question.id)}>
                            Remove Question
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {answers.map((a: string, i: number) => (
                          <div key={i}>
                            <TextField
                              id="answers"
                              value={a}
                              disabled={submitting}
                              fullWidth
                              label={`Answer ${i + 1}`}
                              margin="none"
                              onChange={this.handleArrayChange(index)('answers', i)}
                              type="text"
                              required={i < 2}
                              InputProps={
                                i < 2
                                  ? {}
                                  : {
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton onClick={this.handleRemoveAnswer(index)(i)}>
                                            <Icon>delete</Icon>
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }
                              }
                            />
                          </div>
                        ))}
                        <br />
                        <Button variant="outlined" onClick={this.handleAddAnswer(index)}>
                          Add Answer
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
              <Grid container spacing={3}>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <Button variant="outlined" onClick={this.handleAddQuestion}>
                    Add Question
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={submitting} variant="outlined" onClick={this.handleClose}>
                Cancel
              </Button>
              <Button disabled={submitting} variant="outlined" onClick={this.handlePreviewAssessment}>
                Preview
              </Button>
              <Button disabled={submitting || disabled} type="submit" color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <SelfStudyAssessment
          assessments={previewAssessment ? previewAssessment : []}
          open={previewAssessment ? previewAssessment.length > 0 : false}
          onClose={this.handleClosePreviewAssessment}
          preview={true}
        />
      </div>
    );
  }
}

export const EditAssessmentDialog = withStyles(styles)(EditAssessmentDialogBase);
