import React from 'react';
import Select from '@material-ui/core/Select';
import TableCell from '@material-ui/core/TableCell';
import MenuItem from '@material-ui/core/MenuItem';

interface Props {
  filter: any;
  onFilter: Function;
}

const StatusFilterCell = ({ filter, onFilter }: Props) => (
  <TableCell padding="none">
    <Select
      fullWidth={true}
      value={filter ? filter.value : ''}
      onChange={e => {
        onFilter(e.target.value ? { value: e.target.value } : null);
      }}
      inputProps={{
        name: 'status',
        id: 'status'
      }}>
      <MenuItem value="">
        <em>All</em>
      </MenuItem>
      <MenuItem value="draft">Draft</MenuItem>
      <MenuItem value="published">Published</MenuItem>
    </Select>
  </TableCell>
);

export default StatusFilterCell;
