import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, WithStyles, withStyles } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { connect, StateType } from '../core/store';
import { studyToGradient } from '../utilities';
import { UserSelfStudyType } from '../types';
import styles from '../shared/components/course-panel/course-panel.style';

const selfStudyIcon = require('../../images/icons/self-study.png');

const mapStateToProps = ({ fieldOfStudies }: StateType) => ({
  fieldOfStudies
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  userSelfStudy: UserSelfStudyType;
  onRemove: () => void;
}

const SelfStudyPanelBase: React.FunctionComponent<Props> = ({ classes, fieldOfStudies, userSelfStudy, onRemove }) => {
  const { course } = userSelfStudy;
  if (!course) {
    return null;
  }

  const fieldOfStudy = fieldOfStudies.find(s => s.id === course.fieldOfStudyId);
  if (!fieldOfStudy) {
    return null;
  }

  const gradientStyle = studyToGradient(fieldOfStudy);

  const selfStudyLink = forwardRef((props: any, ref) => <Link innerRef={ref} to={`/courses/${course.id}/self-study`} {...props} />);

  return (
    <div className={classes.panel}>
      <div className={`${classes.panelHeader}`} style={gradientStyle}>
        <Icon className={classes.closeIcon} onClick={onRemove}>
          close
        </Icon>
        {fieldOfStudy && (
          <Link className={classes.fieldOfStudy} to={`/courses/${course.id}/self-study`}>
            {fieldOfStudy.name}
          </Link>
        )}

        <Link className={classes.headerLink} to={`/courses/${course.id}/self-study`}>
          {course.name}
        </Link>

        {course.courseSeriesId && (
          <div className={classes.part}>
            Part {course.courseSeriesPart} of {course.seriesPartsCount}
          </div>
        )}
      </div>
      <div className={classes.panelBody}>
        <br />
        <p>
          <Link className={classes.changeRegistration} to={`/courses/${course.id}`}>
            Change Course Registration
          </Link>
        </p>

        <p className={classes.creditType}>
          {course.credittype.join(' & ')} | {course.credits} Hour{course.credits > 1 && <span>s</span>}
        </p>

        <Button component={selfStudyLink} className={classes.courseDetailsButton} color="primary" variant="contained">
          Resume Course
        </Button>

        <div className={classes.linkContainer}>
          <Link className={classes.link} to={`/courses/${course.id}/self-study`}>
            <img src={selfStudyIcon} />
            <br />
            Self Study
          </Link>
        </div>
      </div>
    </div>
  );
};

export const SelfStudyPanel = connect(mapStateToProps)(withStyles(styles)(SelfStudyPanelBase));
