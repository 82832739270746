const favicon = require('../../images/icons/favicon.png');
const favicon16 = require('../../images/icons/favicon-16x16.png');
const favicon32 = require('../../images/icons/favicon-32x32.png');
const favicon96 = require('../../images/icons/favicon-96x96.png');
const favicon128 = require('../../images/icons/favicon-128.png');
const favicon196 = require('../../images/icons/favicon-196x196.png');
const appleicon57 = require('../../images/icons/apple-touch-icon-57x57.png');
const appleicon60 = require('../../images/icons/apple-touch-icon-60x60.png');
const appleicon72 = require('../../images/icons/apple-touch-icon-72x72.png');
const appleicon76 = require('../../images/icons/apple-touch-icon-76x76.png');
const appleicon114 = require('../../images/icons/apple-touch-icon-114x114.png');
const appleicon120 = require('../../images/icons/apple-touch-icon-120x120.png');
const appleicon144 = require('../../images/icons/apple-touch-icon-144x144.png');
const appleicon152 = require('../../images/icons/apple-touch-icon-152x152.png');
const mstile = require('../../images/icons/mstile-144x144.png');
const mstile70 = require('../../images/icons/mstile-70x70.png');
const mstile150 = require('../../images/icons/mstile-150x150.png');
const mstile310 = require('../../images/icons/mstile-310x310.png');
const mstile310x150 = require('../../images/icons/mstile-310x150.png');

const config = {
  link: [
    { rel: 'shortcut icon', type: 'image/png', href: favicon },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16 },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: favicon32 },
    { rel: 'icon', type: 'image/png', sizes: '96x96', href: favicon96 },
    { rel: 'icon', type: 'image/png', sizes: '128x128', href: favicon128 },
    { rel: 'icon', type: 'image/png', sizes: '196x196', href: favicon196 },
    { rel: 'apple-touch-icon-precomposed', sizes: '57x57', href: appleicon57 },
    { rel: 'apple-touch-icon-precomposed', sizes: '60x60', href: appleicon60 },
    { rel: 'apple-touch-icon-precomposed', sizes: '72x72', href: appleicon72 },
    { rel: 'apple-touch-icon-precomposed', sizes: '76x76', href: appleicon76 },
    { rel: 'apple-touch-icon-precomposed', sizes: '114x114', href: appleicon114 },
    { rel: 'apple-touch-icon-precomposed', sizes: '120x120', href: appleicon120 },
    { rel: 'apple-touch-icon-precomposed', sizes: '144x144', href: appleicon144 },
    { rel: 'apple-touch-icon-precomposed', sizes: '152x152', href: appleicon152 }
  ],
  meta: [
    { charset: 'utf-8' },
    { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
    { name: 'description', content: '' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
    { name: 'apple-mobile-web-app-title', content: 'Canopy Tax' },
    { name: 'msapplication-TileColor', content: '#FFFFFF' },
    { name: 'msapplication-TileImage', content: mstile },
    { name: 'msapplication-square70x70logo', content: mstile70 },
    { name: 'msapplication-square150x150logo', content: mstile150 },
    { name: 'msapplication-wide310x150logo', content: mstile310x150 },
    { name: 'msapplication-square310x310logo', content: mstile310 },
    { name: 'robots', content: 'noindex, nofollow' }
  ]
};

export default config;
