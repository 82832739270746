import { reverse, sortBy } from 'lodash';

import { download, request, saveAs } from '../../utilities';
import {
  parseUsers,
  parseUserSavedCourses,
  parseUserSelfStudies,
  parseUserSelfStudyAnswer,
  parseUserWebinar,
  parseUserWebinars
} from '../../parse';
import { StateType } from '../store';

function createErrorState(error: Error) {
  return { error: error.message };
}

/**
 * Create course feedback.
 *
 */
export async function createCourseFeedback(state: StateType, _a: any, courseId: number, feedback: any) {
  const { error } = await request('post', `/courses/${courseId}/feedback`, feedback);
  if (error) {
    return createErrorState(error);
  }

  const { userSelfStudies = [] } = state;
  const userSelfStudy = userSelfStudies.find(selfStudy => selfStudy.courseId === courseId);

  if (userSelfStudy) {
    userSelfStudy.feedback = true;
    return {
      message: 'Your self study feedback has been recorded.',
      userSelfStudies: [...userSelfStudies]
    };
  }

  return {};
}

/**
 * Create self study assessment answers.
 *
 */
export async function createSelfStudyAssessmentAnswers(state: StateType, actions: any, userId: number, courseId: number, params: any) {
  const { data, error } = await request('post', `/users/${userId}/self-study/${courseId}/assessment`, params);

  if (error) {
    return createErrorState(error);
  }

  const { userSelfStudies = [] } = state;
  const userSelfStudy = userSelfStudies.find(selfStudy => selfStudy.courseId === courseId);

  if (userSelfStudy) {
    userSelfStudy.status = data.status;
    userSelfStudy.score = data.score;

    return {
      message: 'Your self study assessment has been recorded.',
      userSelfStudies: [...userSelfStudies]
    };
  }

  return {};
}

/**
 * Create self study question answers.
 *
 */
export async function createSelfStudyQuestionAnswer(state: StateType, _a: any, userId: number, courseId: number, params: any) {
  const { data, error } = await request('post', `/users/${userId}/self-study/${courseId}/question`, params);

  if (error) {
    return createErrorState(error);
  }

  const answer = parseUserSelfStudyAnswer(data);
  const { userSelfStudies = [] } = state;
  const userSelfStudy = userSelfStudies.find(selfStudy => selfStudy.courseId === courseId);

  if (userSelfStudy) {
    (userSelfStudy as any).answers.push(answer);
    return {
      message: 'Your self study answer has been recorded.',
      userSelfStudies: [...userSelfStudies]
    };
  }

  return {};
}

// TODO: bad use of react-waterfall
export async function downloadCertificate(_s: StateType, _a: any, certificateId: number) {
  const response = await download(`/certificates/${certificateId}/download`, {});
  if (!response) {
    return {};
  }

  const filename = 'certificate.pdf';
  saveAs(response.data, filename);
  return {};
}

/**
 * Search learners.
 *
 */
export async function getLearners(_s: StateType, _a: any, params: any) {
  const payload = Object.assign({}, params, { role: 'customer' });
  const { data, error, headers } = await request('get', '/users', payload);
  if (error) {
    return createErrorState(error);
  }
  const learnersTotal = parseInt(headers['x-count'], 10);
  const learners = parseUsers(data);
  return { learners, learnersTotal };
}

/**
 * Get profile for admin editing.
 *
 */
export async function getProfile(_s: StateType, _a: any, userId: number) {
  const { data: profile, error } = await request('get', `/users/${userId}`);
  return error ? createErrorState(error) : { profile };
}

/**
 * Get logged in user.
 *
 */
export async function getUser(_s: StateType, actions: any, userId: number) {
  const { data: user, error } = await request('get', `/users/${userId}`);

  if (error) {
    return createErrorState(error);
  }

  return { user };
}

/**
 * Get user self study registrations.
 *
 */
export async function getUserSelfStudies(_s: StateType, _a: any, userId: number) {
  const { data, error } = await request('get', `/users/${userId}/self-study`);

  if (error) {
    return createErrorState(error);
  }

  const userSelfStudies = parseUserSelfStudies(data);
  return { userSelfStudies };
}

/**
 * Get user self study registrations with course details.
 * Pass available flag to limit results to pending/failed status.
 *
 */
export async function getUserSelfStudyDetails(_s: StateType, _a: any, userId: number, available = false) {
  const { data, error } = await request('get', `/users/${userId}/self-study?available=${available.toString()}`);

  if (error) {
    return createErrorState(error);
  }

  const registrations: Record<string, any>[] = await Promise.all(
    data.map(async (d: any) => {
      const { data: course } = await request('get', `/courses/${d.courseId}`);
      return { ...d, course };
    })
  );

  const userSelfStudies = parseUserSelfStudies(registrations);
  return { userSelfStudies };
}

/**
 * Get user webinar registrations.
 *
 */
export async function getUserWebinars(_s: StateType, actions: any, userId: number) {
  const { data, error } = await request('get', `/users/${userId}/webinars`);

  if (error) {
    return createErrorState(error);
  }

  // Currently the backend creates a certificate for a user's webinar session when they fetch the webinars

  const userWebinars = parseUserWebinars(data);
  return { userWebinars };
}

/**
 * Get user webinar registrations with course details.
 * Pass available flag to limit results to pending/failed status.
 *
 */
export async function getUserWebinarDetails(_s: StateType, actions: any, userId: number, available = false) {
  const { data, error } = await request('get', `/users/${userId}/webinars?available=${available.toString()}`);

  if (error) {
    return createErrorState(error);
  }

  // Currently the backend creates a certificate for a user's webinar session when they fetch the webinars

  const registrations = await Promise.all(
    data.map(async (d: any) => {
      const { data: course } = await request('get', `/courses/${d.courseId}`);
      return { ...d, course };
    })
  );

  const userWebinars = parseUserWebinars(registrations);
  return { userWebinars };
}

/**
 * Get user webinar registration.
 *
 */
export async function getUserWebinar(state: StateType, _a: any, userId: number, webinarId: number) {
  const { data, error } = await request('get', `/users/${userId}/webinars/${webinarId}`);

  if (error) {
    return createErrorState(error);
  }

  const userWebinar = parseUserWebinar(data);
  const { userWebinars = [] } = state;
  const userWebinarIndex = userWebinars.findIndex(userWeb => userWeb.id === userWebinar.id);

  if (userWebinarIndex === -1) {
    return {};
  }

  userWebinars.splice(userWebinarIndex, 1, userWebinar);

  return { userWebinars: [...userWebinars] };
}

/**
 * Update profile data for user.
 *
 */
export async function updateProfile(state: StateType, _a: any, userId: number, data: any) {
  const { data: user, error } = await request('patch', `/users/${userId}`, data);
  if (error) {
    return createErrorState(error);
  }
  const message = 'User been successfully updated.';
  const userGroups = ['admins', 'learners'];
  const stateChange: any = { message };
  userGroups.forEach(role => {
    // @ts-ignore
    stateChange[role] = [...state[role]];
    const updatedAdminIndex = stateChange[role].findIndex((a: any) => a.id === user.id);

    if (updatedAdminIndex >= 0) {
      const updatedUser = {
        ...stateChange[role][updatedAdminIndex],
        ...user
      };
      stateChange[role][updatedAdminIndex] = updatedUser;
    }
  });

  const userState = ['user.ts', 'profile'];
  userState.forEach(s => {
    // @ts-ignore
    if (state[s] && state[s].id === user.id) {
      stateChange.message = 'Profile has been successfully updated.';
      stateChange[s] = user;
    }
  });

  return stateChange;
}

export async function getUserSavedCourses(_s: StateType, _a: any, userId: number): Promise<{ error: string } | { savedCourses: any[] }> {
  const { data, error } = await request('get', `/users/${userId}/courses`);
  if (error) {
    return createErrorState(error);
  }

  const savedCourses = parseUserSavedCourses(data);
  return { savedCourses };
}

// TODO: what is the difference between this action and the one above? Expensive to get all of a courses details like this
export async function getUserSavedCourseDetails(
  _s: StateType,
  _a: any,
  userId: number
): Promise<{ error: string } | { savedCourses: any[] }> {
  const { data, error } = await request('get', `/users/${userId}/courses`);
  if (error) {
    return createErrorState(error);
  }

  const saves = await Promise.all(
    data.map(async (d: any) => {
      const { data: course } = await request('get', `/courses/${d.courseId}`);
      return { ...d, course };
    })
  );

  const sortedSaves = reverse(sortBy(saves, 'created'));

  const savedCourses = parseUserSavedCourses(sortedSaves);
  return { savedCourses };
}
