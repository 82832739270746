import createStore from 'react-waterfall';

import {
  CategoryType,
  CertificateType,
  CourseType,
  FieldOfStudyType,
  InstructorType,
  NotificationType,
  PollingQuestionType,
  SeriesDialogType,
  UserSavedCourseType,
  UserSelfStudyType,
  UserType,
  UserWebinarType,
  WebinarType
} from '../types';

import * as actionsCreators from './actions';
import { getAuthToken } from './services/auth';

/**
 * Data store for application.
 */

/**
 * Check for existing auth token in local storage, otherwise set to
 * unauthenticated user.
 */
const jwt = getAuthToken() || {
  accessToken: null, // Current user access token.  Null if not logged in.
  userId: null, // Current user ID
  error: null // Login failure error message
};

// TODO: need to fix the typing for this store
export interface StateType {
  admins: UserType[];
  adminsTotal: number;
  adminColumns: Record<string, any>[];
  blackoutDates: string[];
  categories: CategoryType[];
  certificates: CertificateType[];
  certificatesTotal: number;
  course: CourseType | undefined;
  courseListState: Record<string, any> | undefined;
  courses: CourseType[];
  savedCourses: UserSavedCourseType[];
  coursesTotal: number;
  error: string | undefined;
  fieldOfStudies: FieldOfStudyType[];
  instructors: InstructorType[];
  instructorsTotal: number;
  jwt: Record<string, any>;
  message: string | undefined;
  learners: UserType[];
  learnersTotal: number;
  notifications: NotificationType[];
  pollingQuestions: PollingQuestionType[];
  pollingQuestionsTotal: number;
  pollingQuestion: PollingQuestionType | undefined;
  profile: Record<string, any> | undefined;
  user: UserType | undefined;
  userSelfStudies: UserSelfStudyType[] | undefined;
  userWebinars: UserWebinarType[] | undefined;
  webinars: WebinarType[];
  webinarsTotal: number;
  googleCalendarLink: string | undefined;
  icalAttachmentContent: string | undefined;
  seriesDialog: SeriesDialogType;
  featureToggles: { data: Record<string, boolean>; loading: boolean };
}

/**
 * Store
 */
const initialState: StateType = {
  admins: [], // List of admins
  adminsTotal: 0, // Total number of admins in search.
  adminColumns: [], // Table column configuration for admin interface.
  blackoutDates: [], // A list of blackout dates in iso-short format "2018-10-19"
  categories: [], // All course categories.
  certificates: [], // List of certificates
  certificatesTotal: 0, // Total number of certificates in search.
  course: undefined, // Course being editted.
  courseListState: undefined, // Course list search state.
  courses: [], // List of courses
  coursesTotal: 0, // Total number of courses in search.
  savedCourses: [], // List of saved courses
  error: undefined, // Catch all error message displayed in snackbar
  fieldOfStudies: [], // All course field of studies.
  instructors: [], // List of instructors in search.
  instructorsTotal: 0, // Total number of instructors in search
  jwt, // Auth token
  learners: [], // List of leaners.
  learnersTotal: 0, // Total number of learners in search.
  message: undefined, // Catch all message displayed in snackbar
  notifications: [], // List of notifications
  pollingQuestions: [], // List of polling questions
  pollingQuestionsTotal: 0, // Total number of polling questions in search.
  pollingQuestion: undefined, // Question being editted by admin, or answered by end-user.
  profile: undefined, // User for admin profile changes.
  user: undefined, // Current user profile
  userSelfStudies: [],
  userWebinars: undefined,
  webinars: [], // List of webinars.
  webinarsTotal: 0, // Total number of webinars in search.
  googleCalendarLink: undefined,
  icalAttachmentContent: undefined,
  seriesDialog: { open: false, courses: [] }, // series dialog state.
  featureToggles: { data: {}, loading: false }
};

/**
 * Default state.  Usefull when you need to logout and reset the store.
 */
export const defaultState = { ...initialState };

export const { Provider, connect, actions } = createStore({
  initialState,
  actionsCreators
});
