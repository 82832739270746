import { request } from '../../utilities';
import { parseCertificates } from '../../parse';
import { StateType } from '../store';

/**
 * Search certificates.
 *
 */
export async function getCertificates(_s: StateType, _a: any, params: any) {
  const { data, error, headers } = await request('get', `/certificates`, params);
  if (error) {
    return { error: error.message };
  }
  const certificatesTotal = parseInt(headers['x-count'], 10);
  const certificates = parseCertificates(data);
  return { certificates, certificatesTotal };
}

export async function resubmitCertificate(state: StateType, _a: any, certificateId: number) {
  const { data, error } = await request('patch', `/certificates/${certificateId}`);

  if (error) {
    return { error: error.message };
  }

  const { certificates } = state;
  const certificate = certificates.find(cert => cert.id === data.id);
  if (certificate) {
    certificate.irsStatus = data.irsStatus;
    certificate.irsStatusMessage = data.irsStatusMessage;
    return { certificates: [...certificates], message: 'Your certificate has been resubmitted.' };
  }

  return {};
}
