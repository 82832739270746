import React, { Component } from 'react';
import { debounce } from 'lodash';
import { createStyles, Fab, Icon, Theme, WithStyles, withStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

const styles = (theme: Theme) =>
  createStyles({
    button: {
      width: 40,
      height: 40
    },
    cancelButton: {
      width: 40,
      height: 40,
      color: theme.palette.getContrastText(grey[900]),
      backgroundColor: grey[900],
      '&:hover': {
        backgroundColor: grey[500]
      }
    },
    searchContainer: {
      display: 'inline-block',
      position: 'relative'
    },
    searchClose: {
      cursor: 'pointer',
      position: 'absolute',
      fontSize: 39,
      fontWeight: 600
    },
    searchInput: {
      border: '1px solid #ccc',
      borderRadius: 30,
      boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
      fontSize: 16,
      padding: '10px 16px 10px 46px',
      outline: 0,
      verticalAlign: 'bottom',
      width: 260
    }
  });

interface Props extends WithStyles<typeof styles> {
  changeSearch: Function;
  disabled?: boolean;
  placeholder?: string;
}

interface State {
  showSearch: boolean;
}

class SearchButtonBase extends Component<Props, State> {
  debounceChangeSearch: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      showSearch: false
    };
  }

  componentDidMount() {
    this.debounceChangeSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      this.props.changeSearch(event.target.value);
    }, 250);
  }

  static getDerivedStateFromProps(newProps: Props) {
    const { disabled } = newProps;
    if (disabled) {
      return {
        showSearch: false,
        disabled: true
      };
    }
    return { disabled };
  }

  toggleSearch = () => {
    if (this.state.showSearch) {
      this.props.changeSearch(''); // reset form on close
    }

    this.setState({
      showSearch: !this.state.showSearch
    });
  };

  handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    this.debounceChangeSearch(event);
  };

  render() {
    const { classes, disabled, placeholder = 'Search' } = this.props;
    const { showSearch } = this.state;

    return (
      <React.Fragment>
        {showSearch && (
          <div className={classes.searchContainer}>
            <Icon className={classes.searchClose} onClick={this.toggleSearch}>
              cancel
            </Icon>
            <input
              className={classes.searchInput}
              disabled={disabled}
              type="text"
              id="search"
              placeholder={placeholder}
              onChange={this.handleChangeSearch}
            />
          </div>
        )}
        {!showSearch && (
          <div>
            <Fab disabled={disabled} onClick={this.toggleSearch} color="primary" aria-label="add" className={classes.button}>
              <Icon>search</Icon>
            </Fab>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export const SearchButton = withStyles(styles)(SearchButtonBase);
