import React, { Component } from 'react';
import { ContentState, convertFromHTML, Editor, EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import InputLabel from '@material-ui/core/InputLabel';
import 'draft-js/dist/Draft.css';

import { BlockStyleControls } from './block-style-controls';
import { InlineStyleControls } from './inline-style-controls';

import './styles.css';

/**
 * Richtext editor based on Draft JS.
 */

interface Props {
  defaultValue?: string;
  id: string;
  onChange: Function;
  placeholder?: string;
}

interface State {
  defaultValue?: string;
  editorState: EditorState;
}

export class RichTextEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty() };
  }

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    const { defaultValue } = newProps;
    // editor empty value of <p><br></p> breaks html conversion
    if (defaultValue && defaultValue !== '<p><br></p>' && !prevState.defaultValue) {
      const blocksFromHTML = convertFromHTML(defaultValue);
      const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      const editorState = EditorState.createWithContent(contentState);
      return { defaultValue, editorState };
    }

    return {};
  }

  // TODO: not sure if editorState gets passed
  handleKeyCommand = (command: string, editorState?: EditorState) => {
    // @ts-ignore
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.handleChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  mapKeyToEditorCommand = (e: React.KeyboardEvent): any => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, this.state.editorState, 4 /* maxDepth */);
      if (newEditorState !== this.state.editorState) {
        this.handleChange(newEditorState);
      }
      return;
    }
    // eslint-disable-next-line consistent-return
    return getDefaultKeyBinding(e);
  };

  toggleBlockType = (blockType: string) => {
    this.handleChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  toggleInlineStyle = (inlineStyle: string) => {
    this.handleChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  };

  handleChange = (editorState: EditorState) => {
    const { id, onChange } = this.props;
    this.setState({ editorState });
    onChange({ target: { id, value: stateToHTML(editorState.getCurrentContent()) } });
  };

  render() {
    const { editorState } = this.state;
    const { id, placeholder } = this.props;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    return (
      <div>
        <InputLabel htmlFor={id}>{placeholder}</InputLabel>
        <div className="RichEditor-root">
          <BlockStyleControls editorState={editorState} onToggle={this.toggleBlockType} />
          <InlineStyleControls editorState={editorState} onToggle={this.toggleInlineStyle} />
          <div className={className}>
            <Editor
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={this.mapKeyToEditorCommand}
              onChange={this.handleChange}
              placeholder={placeholder}
              spellCheck={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
