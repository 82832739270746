import React, { Component } from 'react';
import { Button, createStyles, Dialog, Icon, WithStyles, withStyles } from '@material-ui/core';
import { arrayMove, SortableContainer, SortableElement, SortableHandle, SortEnd } from 'react-sortable-hoc';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../core/store';

const styles = createStyles({
  title: {
    minWidth: 370,
    padding: '20px 45px',
    margin: 0,
    color: '#FFFFFF',
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 1.3,
    background: '#3346FF'
  },
  content: {
    padding: '20px 30px 40px'
  },
  header: {
    color: '#040D14',
    fontSize: 16,
    fontWeight: 'bold',
    margin: '0 0 25px'
  },
  button: {
    marginRight: '15px'
  },
  series: {
    paddingLeft: 0,
    marginLeft: 0,
    marginBottom: 50
  },
  part: {
    width: '100%',
    background: '#EEF1F6',
    color: '#040D14',
    listStyle: 'none',
    lineHeight: 2,
    marginTop: 15,
    padding: '0 50px',
    position: 'relative',
    zIndex: 1000000
  },
  handle: {
    cursor: 'row-resize',
    fontSize: 24,
    lineHeight: 1.5,
    position: 'absolute',
    left: 10,
    color: '#CDD0D6'
  },
  remove: {
    position: 'absolute',
    right: 10,
    background: 'transparent',
    border: 'none',
    lineHeight: 1.4,
    cursor: 'pointer',
    opacity: 0.5
  }
});

const DragHandle = SortableHandle(({ classes }: { classes: any }) => <Icon className={classes.handle}>drag_handle</Icon>);

const SortableItem = SortableElement(
  ({ classes, value, onRemove, selectedId }: { value: any; onRemove: any; classes: any; selectedId: any }) => {
    const style =
      selectedId === value.id
        ? {
            backgroundColor: '#f7ebe8'
          }
        : {};
    return (
      <li className={classes.part} style={style}>
        <DragHandle classes={classes} />
        {value.name}
        <Icon className={classes.remove} onClick={onRemove(value.id)}>
          close
        </Icon>
      </li>
    );
  }
);

const SortableList = SortableContainer(
  ({ classes, items, selectedId, onRemove }: { items: any[]; onRemove: any; classes: any; selectedId: any }) => (
    <ul className={classes.series}>
      {items.map((value, index) => (
        <SortableItem key={value.id} index={index} value={value} onRemove={onRemove} classes={classes} selectedId={selectedId} />
      ))}
    </ul>
  )
);

const mapStateToProps = ({ seriesDialog }: StateType) => ({
  seriesDialog
});

type Props = WithStyles<typeof styles> & WithProps<typeof mapStateToProps>;

interface State {
  initialized: boolean;
  courses: any[];
}

class SeriesDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      initialized: false,
      courses: []
    };
  }

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    // copy courses on open
    if (!prevState.initialized && newProps.seriesDialog.open) {
      return {
        initialized: true,
        courses: newProps.seriesDialog.courses.map(c => ({ ...c }))
      };
    }

    // clean up courses on close
    if (!newProps.seriesDialog.open) {
      return {
        initialized: false,
        courses: []
      };
    }

    return {};
  }

  handleClose = () => {
    actions.closeSeriesDialog();
  };

  handleSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    this.setState(state => ({
      courses: arrayMove(state.courses, oldIndex, newIndex)
    }));
  };

  handleRemove = (id: number) => () => {
    this.setState(state => ({
      courses: state.courses.filter(c => c.id !== id)
    }));
  };

  handleSave = () => {
    const { courses } = this.state;
    const { seriesId } = this.props.seriesDialog;
    actions.setCourseSeries(
      seriesId,
      courses.map(c => c.id)
    );
  };

  render() {
    const { classes } = this.props;
    const { open, courseId, seriesId } = this.props.seriesDialog;
    const { courses } = this.state;

    const saveDisabled = (!seriesId && courses.length < 2) || courses.length === 1;

    return (
      <Dialog onClose={this.handleClose} open={open}>
        <h2 className={classes.title}>{courseId ? 'Update' : 'Create'} Course Series</h2>
        <div className={classes.content}>
          <h4 className={classes.header}>Set series order from top to bottom</h4>
          <SortableList
            items={this.state.courses}
            onSortEnd={this.handleSortEnd}
            onRemove={this.handleRemove}
            useDragHandle={true}
            classes={classes}
            selectedId={courseId}
          />
          <Button variant="contained" color="primary" className={classes.button} onClick={this.handleSave} disabled={saveDisabled}>
            Save
          </Button>
          <Button variant="outlined" onClick={this.handleClose}>
            Cancel
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps)(SeriesDialog));
