import React, { Component } from 'react';
import classNames from 'classnames';
import { DatePicker } from '@material-ui/pickers';
import { createStyles, IconButton, TableCell, Theme, WithStyles, withStyles } from '@material-ui/core';
import { format, isBefore, isSameDay, isValid, isWithinInterval } from 'date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const styles = (theme: Theme) =>
  createStyles({
    datePicker: {
      width: '100%'
    },
    dayWrapper: {
      position: 'relative'
    },
    day: {
      width: 36,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: '0 2px',
      color: 'inherit'
    },
    disabled: {
      color: theme.palette.text.disabled
    },
    customDayHighlight: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '2px',
      right: '2px',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '50%'
    },
    nonCurrentMonthDay: {
      color: theme.palette.text.disabled
    },
    highlightNonCurrentMonthDay: {
      color: '#676767'
    },
    highlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white
    },
    firstHighlight: {
      extend: 'highlight',
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%'
    },
    endHighlight: {
      extend: 'highlight',
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%'
    }
  });

interface Props extends WithStyles<typeof styles> {
  filter: any;
  onFilter: Function;
}

interface State {
  step: boolean;
  startDate: Date | undefined;
  endDate: Date | undefined;
}

class DateFilterCell extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      step: false,
      startDate: undefined,
      endDate: undefined
    };
  }

  handleDateChange = (date: MaterialUiPickersDate) => {
    const { onFilter } = this.props;
    const { step } = this.state;
    const datePart = date && step ? 'endDate' : 'startDate';
    this.setState(
      {
        endDate: !step || !date ? undefined : this.state.endDate,
        [datePart]: date,
        step: !step
      } as any,
      () => {
        const { startDate, endDate } = this.state;
        onFilter(startDate ? { value: [startDate, endDate] } : null);
      }
    );
  };

  renderWrappedWeekDay = (date: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean) => {
    const { classes } = this.props;
    const { startDate: start, endDate: end } = this.state;

    date = date || new Date();

    // eslint-disable-next-line
    const dayIsBetween = start ? isWithinInterval(date, { start, end: end || start }) : false;
    const isFirstDay = start ? isSameDay(date, start) : false;
    const isLastDay = end ? isSameDay(date, end) : false;
    const isBeforeStartDate = start ? isBefore(date, start) : false;

    const wrapperClassName = classNames({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    });

    const dayClassName = classNames(classes.day, {
      [classes.disabled]: isBeforeStartDate,
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(date, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  formatLabel = (startDate: MaterialUiPickersDate, invalidLabel: string) => {
    if (startDate === null) {
      return '';
    }

    const { endDate } = this.state;

    if (isValid(startDate)) {
      let label = format(startDate, 'MMM do');
      if (endDate) {
        label += ` - ${format(endDate, 'MMM do')}`;
      }

      return label;
    }

    return invalidLabel;
  };

  shouldDisableDate = (date: Date) => {
    const { step, startDate } = this.state;
    if (!step || !startDate) {
      return false;
    }

    return isBefore(date, startDate);
  };

  render() {
    const { classes, filter } = this.props;
    const values = filter ? filter.value : [];
    // force formatLabel to rerender with value change
    const value = values[0] ? new Date(values[0]) : null;
    return (
      <TableCell padding="none">
        <DatePicker
          autoOk={true}
          className={classes.datePicker}
          clearable
          value={value}
          onChange={this.handleDateChange}
          renderDay={this.renderWrappedWeekDay}
          labelFunc={this.formatLabel}
          shouldDisableDate={this.shouldDisableDate}
        />
      </TableCell>
    );
  }
}

export default withStyles(styles)(DateFilterCell);
