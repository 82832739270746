import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, createStyles, Tooltip, withStyles } from '@material-ui/core';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const styles = createStyles({
  link: {
    justifyContent: 'flex-start',
    textTransform: 'none'
  }
});

let LinkValueFormatter: any = (props: any) => {
  const { classes, value } = props;

  if (!value) {
    return null;
  }

  const url = value.startsWith('http') ? value : `http://${value}`;
  const link = forwardRef((props: any, ref) => <Link innerRef={ref} to={url} {...props} target="_blank" />);

  return (
    <Tooltip title={value} placement="top-start">
      <Button component={link} className={classes.link}>
        {value}
      </Button>
    </Tooltip>
  );
};

LinkValueFormatter = withStyles(styles)(LinkValueFormatter);

// @ts-ignore
const LinkValueTypeProvider = ({ for: forProp, ...others }) => (
  <DataTypeProvider formatterComponent={LinkValueFormatter} for={forProp} {...others} />
);

export default LinkValueTypeProvider;
