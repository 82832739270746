import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../core/store';
import { TableGrid } from '../../shared';

import InstructorCreate from './instructor-create';

const selectActions = () => <InstructorCreate />;

const config = {
  columns: [
    { name: 'avatarUrl', title: 'Avatar' },
    { name: 'firstName', title: 'First Name' },
    { name: 'lastName', title: 'Last Name' },
    { name: 'email', title: 'Email' },
    { name: 'phone', title: 'Phone' },
    { name: 'company', title: 'Company' },
    { name: 'companyUrl', title: 'Company URL' }
  ],
  columnExtensions: [
    { columnName: 'avatarUrl', width: 90 },
    { columnName: 'firstName', width: 170 },
    { columnName: 'lastName', width: 170 },
    { columnName: 'email', width: 320 },
    { columnName: 'phone', width: 120 },
    { columnName: 'company', width: 200 },
    { columnName: 'companyUrl', width: 260 }
  ],
  filteringStateColumnExtensions: [{ columnName: 'avatarUrl', filteringEnabled: false }],
  selectActions,
  linkColumns: ['companyUrl'],
  urlColumns: ['firstName', 'lastName', 'email', 'company'],
  urlColumnsBase: '/admin/instructors'
};

const mapStateToProps = ({ instructors, instructorsTotal: totalCount, adminColumns }: StateType) => ({
  instructors,
  totalCount,
  adminColumns
});

type Props = WithProps<typeof mapStateToProps>;

class InstructorListBase extends Component<Props> {
  componentDidMount(): void {
    actions.getAdminColumns();
  }

  exportRows = (method: string, params: Record<string, any> = {}) => {
    actions.exportInstructors(method, params);
  };

  loadRows = (params: Record<string, any>) => {
    actions.getInstructors(params);
  };

  render() {
    const { instructors, totalCount, adminColumns } = this.props;
    return (
      <div>
        <Helmet title="Instructor List" />
        <TableGrid
          actions={actions}
          adminColumns={adminColumns}
          config={config}
          exportRows={this.exportRows}
          model="instructors"
          rows={instructors}
          totalCount={totalCount}
          loadRows={this.loadRows}
        />
      </div>
    );
  }
}

export const InstructorList = connect(mapStateToProps)(InstructorListBase);
