import { sortBy } from 'lodash';

import { CategoryType, FieldOfStudyType } from '../types';
import { array, number, string } from '../utilities/parse';

/**
 * Category related parsing functions for API responses.
 */
export function parseCategory(input: Record<string, any> = {}): CategoryType {
  return {
    id: number(input, 'id'),
    fieldOfStudy: string(input, 'fieldOfStudy'),
    fieldOfStudyId: number(input, 'fieldOfStudyId'),
    name: string(input, 'name')
  };
}

export function parseCategories(input: Record<string, any>[]): CategoryType[] {
  return sortBy(
    input.map(row => parseCategory(row)),
    'name'
  );
}

export function parseFieldOfStudy(input: Record<string, any> = {}): FieldOfStudyType {
  return {
    id: number(input, 'id'),
    categories: array(input, 'categories', number),
    name: string(input, 'name'),
    gradientStart: string(input, 'gradientStart'),
    gradientEnd: string(input, 'gradientEnd')
  };
}

// Parse field of studies from categories API response.
export function parseFieldOfStudies(categories: any[]): FieldOfStudyType[] {
  const studies = categories.reduce((result, cat) => {
    const study = result.find((r: any) => r.id === cat.fieldOfStudyId);
    if (!study) {
      result.push({
        id: cat.fieldOfStudyId,
        name: cat.fieldOfStudy,
        gradientEnd: cat.fieldOfStudyGradientEnd,
        gradientStart: cat.fieldOfStudyGradientStart,
        categories: [cat.id]
      });
    } else {
      study.categories.push(cat.id);
    }
    return result;
  }, []);
  return sortBy(
    studies.map((row: any) => parseFieldOfStudy(row)),
    'name'
  );
}
