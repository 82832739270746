/**
 * Simple solution to print timezone abbreviations for USA visitors.
 * All international visitors will see no timezone information.
 * TODO implement date-fns@2.0 timezone abbreviations when they land.
 */
export default function timezoneAbbr() {
  const d = new Date(); // or whatever date you have

  const offsetInMinutes = d.getTimezoneOffset();
  const offset = offsetInMinutes / 60;

  const x = (d.getMonth() + 1) * 100 + d.getDate();
  const isDaylight = x >= 310 && x < 1104;

  if (offset === (isDaylight ? 7 : 8)) {
    return 'PT';
  } else if (offset === (isDaylight ? 6 : 7)) {
    return 'MT';
  } else if (offset === (isDaylight ? 5 : 6)) {
    return 'CT';
  } else if (offset === (isDaylight ? 4 : 5)) {
    return 'ET';
  } else if (offset > 0) {
    return `GMT-${offset}`;
  }

  return `GMT+${offset * -1}`;
}
