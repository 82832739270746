import { download, request, saveAs } from '../../utilities';
import { parseUsers } from '../../parse';
import { StateType } from '../store';

// TODO: All of these export functions should be in a service. Incorrect use of react-waterfall

/**
 * Export admins to CSV download.
 *
 * @returns {void} A browser file download is initiated.
 */
export async function exportAdmins(_s: StateType, _a: any, method: string, params: any) {
  const data = Object.assign({}, params, {
    role: 'admin',
    model: 'admins',
    method
  });
  const response = await download('/users/export', data);
  if (!response) {
    return {};
  }

  const filename = 'export-admins.csv';
  saveAs(response.data, filename);
  return {};
}

/**
 * Export certificates to CSV download.
 *
 * @returns {void} A browser file download is initiated.
 */
export async function exportCertificates(_s: StateType, _a: any, method: string, params: any) {
  const data = Object.assign({}, params, {
    model: 'certificates',
    method
  });
  const response = await download('/certificates/export', data);
  if (!response) {
    return {};
  }

  const filename = 'export-certificates.csv';
  saveAs(response.data, filename);
  return {};
}

/**
 * Export courses to CSV download.
 *
 * @returns {void} A browser file download is initiated.
 */
export async function exportCourses(_s: StateType, _a: any, method: string, params: any) {
  const data = Object.assign({}, params, {
    model: 'courses',
    method
  });
  const response = await download('/courses/export', data);
  if (!response) {
    return {};
  }

  const filename = 'export-courses.csv';
  saveAs(response.data, filename);
  return {};
}

/**
 * Export learners to CSV download.
 *
 * @returns {void} A browser file download is initiated.
 */
export async function exportLearners(_s: StateType, _a: any, method: string, params: any) {
  const data = Object.assign({}, params, {
    role: 'customer',
    model: 'learners',
    method
  });
  const response = await download('/users/export', data);
  if (!response) {
    return {};
  }

  const filename = 'export-admins.csv';
  saveAs(response.data, filename);
  return {};
}

/**
 * Export polling questions to CSV download.
 *
 * @returns {void} A browser file download is initiated.
 */
export async function exportPollingQuestions(_s: StateType, _a: any, method: string, params: any) {
  const data = Object.assign({}, params, {
    model: 'pollingQuestions',
    method
  });
  const response = await download('/polling-questions/export', data);
  if (!response) {
    return {};
  }

  const filename = 'export-polling-questions.csv';
  saveAs(response.data, filename);
  return {};
}

/**
 * Export instructors to CSV download.
 *
 * @returns {void} A browser file download is initiated.
 */
export async function exportInstructors(_s: StateType, _a: any, method: string, params: any) {
  const data = Object.assign({}, params, {
    model: 'instructors',
    method
  });
  const response = await download('/instructors/export', data);
  if (!response) {
    return {};
  }

  const filename = 'export-instructors.csv';
  saveAs(response.data, filename);
  return {};
}

/**
 * Export webinars to CSV download.
 *
 * @returns {void} A browser file download is initiated.
 */
export async function exportWebinars(_s: StateType, _a: any, method: string, params: any) {
  const data = Object.assign({}, params, {
    model: 'webinars',
    method
  });
  const response = await download('/webinars/export', data);
  if (!response) {
    return {};
  }

  const filename = 'export-webinrs.csv';
  saveAs(response.data, filename);
  return {};
}

/**
 * Search admins.
 *
 */
export async function getAdmins(_s: StateType, _a: any, params: any) {
  const payload = Object.assign({}, params, { role: 'admin' });
  const { data, error, headers } = await request('get', '/users', payload);
  if (error) {
    return { error: error.message };
  }
  const adminsTotal = parseInt(headers['x-count'], 10);
  const admins = parseUsers(data);
  return { admins, adminsTotal };
}

/**
 * Get admin table column configuration for admin UI.
 *
 */
export async function getAdminColumns(_s: StateType, _a: any, params: any) {
  const { data: adminColumns, error } = await request('get', '/admin/columns', params);
  if (error) {
    return { error: error.message };
  }
  return { adminColumns };
}

/**
 * Save admin columns changes to API and update state.
 *
 */
async function saveAdminColumns(state: StateType, updates: Promise<any>[]) {
  try {
    const updatedAdminColumns = await Promise.all(updates);

    const adminColumns = state.adminColumns.map(column => {
      const isUpdated = updatedAdminColumns.find(c => c.id === column.id);
      if (isUpdated) {
        return isUpdated;
      }

      return column;
    });

    return {
      adminColumns,
      message: 'Admin columns saved!'
    };
  } catch (error) {
    return { error: error.message };
  }
}

/**
 * Save admin columns changes to API and update state.
 *
 */
export async function updateAdminColumns(state: StateType, _a: any, updatedAdminColumns: any[]) {
  const updates = updatedAdminColumns.map(
    updatedAdminColumn =>
      new Promise(async (resolve, reject) => {
        const { columnName, hidden, modelName } = updatedAdminColumn;
        const data = { columnName, modelName, hidden };
        const { data: adminColumn, error } = await request('post', `/admin/columns`, data);
        if (error) {
          return reject(error.message);
        }
        return resolve(adminColumn);
      })
  );

  return saveAdminColumns(state, updates);
}

/**
 * Save the column order for the supplied module.
 *
 */
export async function orderAdminColumns(state: StateType, _a: any, modelName: string, columnOrder: string[]) {
  const updates = columnOrder.map(
    (columnName, index) =>
      new Promise(async (resolve, reject) => {
        const priority = columnOrder.length - index;
        const data = { columnName, modelName, priority };
        const { data: adminColumn, error } = await request('post', `/admin/columns`, data);
        if (error) {
          return reject(error.message);
        }
        return resolve(adminColumn);
      })
  );

  return saveAdminColumns(state, updates);
}
