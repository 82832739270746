import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Collapse,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Switch,
  Theme,
  withStyles,
  WithStyles,
  withWidth
} from '@material-ui/core';
import { isEqual, pick } from 'lodash';
import { WithProps } from 'react-waterfall';
import { isWidthDown, WithWidthProps } from '@material-ui/core/withWidth';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { CREDIT_TYPES } from '../constants';
import { connect, StateType } from '../core/store';

const styles = (theme: Theme) =>
  createStyles({
    list: {
      backgroundColor: theme.palette.background.paper
    },
    dialogActions: {
      justifyContent: 'flex-start'
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },
    sticky: {
      top: theme.spacing(1) * -1
    }
  });

const mapStateToProps = ({ categories, fieldOfStudies }: StateType) => ({
  categories,
  fieldOfStudies
});

interface Props extends WithStyles<typeof styles>, WithWidthProps, WithProps<typeof mapStateToProps> {
  containerState: any;
  handleClose: Function;
  handleChange: Function;
  open: boolean;
}

interface State {
  categoryId: number[];
  credittype: string[];
  fieldOfStudyId: number[];
  openFieldListId: number | null;
}

class RefineViewDialogBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      categoryId: [],
      credittype: [],
      fieldOfStudyId: [],
      openFieldListId: null
    };
  }

  componentDidMount() {
    this.resetForm();
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.containerState, this.props.containerState)) {
      this.resetForm();
    }
  }

  handleClose = () => {
    const { handleClose } = this.props;
    this.resetForm();
    handleClose();
  };

  toggleCategory(categoryId: number) {
    return () => {
      const isContained = this.state.categoryId.indexOf(categoryId) > -1;
      if (isContained) {
        const filtered = this.state.categoryId.filter(value => value !== categoryId);
        this.setState({ categoryId: filtered });
      } else {
        this.setState({ categoryId: [...this.state.categoryId, categoryId] });
      }
    };
  }

  toggleCreditType = (creditType: string) => {
    return () => {
      const isContained = this.state.credittype.indexOf(creditType) > -1;
      if (isContained) {
        const filtered = this.state.credittype.filter(value => value !== creditType);
        this.setState({ credittype: filtered });
      } else {
        this.setState({ credittype: [...this.state.credittype, creditType] });
      }
    };
  };

  handleSearch = () => {
    const { handleChange } = this.props;

    const searchCriteria = pick(this.state, 'categoryId', 'credittype', 'fieldOfStudyId');
    handleChange(searchCriteria);
  };

  resetForm = () => {
    const { containerState } = this.props;
    const { categoryId, credittype, fieldOfStudyId } = containerState;

    this.setState({
      categoryId,
      credittype,
      fieldOfStudyId,
      openFieldListId: null
    });
  };

  clearFilters = () => {
    this.setState({ categoryId: [], fieldOfStudyId: [], credittype: [] });
  };

  handleListToggle = (fieldOfStudyId: number | null) => {
    return () => {
      if (this.state.openFieldListId === fieldOfStudyId) {
        this.setState({ openFieldListId: null });
      } else {
        this.setState({ openFieldListId: fieldOfStudyId });
      }
    };
  };

  render() {
    const { categories, classes, fieldOfStudies, open, width } = this.props;
    const { categoryId, credittype, fieldOfStudyId, openFieldListId } = this.state;

    const clearFilterBtnDisabled = !categoryId.length && !credittype.length && !fieldOfStudyId.length;

    const fullScreen = isWidthDown('sm', width!);

    return (
      <Dialog open={open} onClose={this.handleClose} fullScreen={fullScreen} maxWidth={fullScreen ? false : 'md'}>
        <DialogTitle>Filter By</DialogTitle>
        <DialogContent>
          <div>
            <List className={classes.list} subheader={<ListSubheader classes={{ sticky: classes.sticky }}>Credit type</ListSubheader>}>
              {CREDIT_TYPES.map(creditType => {
                const switchId = `switch-list-label-${creditType}`;
                return (
                  <ListItem key={creditType}>
                    <ListItemText id={switchId} primary={creditType} />
                    <ListItemSecondaryAction>
                      <Switch
                        edge="end"
                        checked={credittype.includes(creditType)}
                        value={creditType}
                        onChange={this.toggleCreditType(creditType)}
                        inputProps={{ 'aria-labelledby': switchId }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>

            <Divider />

            <List className={classes.list} subheader={<ListSubheader classes={{ sticky: classes.sticky }}>Categories</ListSubheader>}>
              {fieldOfStudies.map(fieldOfStudy => {
                const categoryStudies = categories.filter(category => fieldOfStudy.categories.includes(category.id));
                const fieldOfStudyOpen = openFieldListId === fieldOfStudy.id;

                return (
                  <React.Fragment key={fieldOfStudy.id}>
                    <ListItem button onClick={this.handleListToggle(fieldOfStudy.id)}>
                      <ListItemText primary={fieldOfStudy.name} />
                      {fieldOfStudyOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={fieldOfStudyOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {categoryStudies.map(category => {
                          const labelId = `checkbox-list-label-${category.id}`;
                          return (
                            <ListItem key={category.id} button className={classes.nested} onClick={this.toggleCategory(category.id)}>
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={categoryId.includes(category.id)}
                                  tabIndex={-1}
                                  value={`${category.id}`}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText id={labelId} primary={category.name} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              })}
            </List>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button onClick={this.handleSearch} color="primary" variant="contained" autoFocus>
            View Courses
          </Button>
          <Button onClick={this.clearFilters} variant="contained" disabled={clearFilterBtnDisabled}>
            Clear Filters
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const RefineViewDialog = connect(mapStateToProps)(withStyles(styles)(withWidth()(RefineViewDialogBase)));
