import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { init } from '@sentry/browser';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Event, EventHint } from '@sentry/types';
import { Router } from 'react-router';

import { AppRouter } from './app/app.router';
import { Provider } from './app/core/store';
import theme from './theme';
import { isDevelopmentEnv } from './app/utilities/environment';
import { Auth, history } from './app/core';
import { NetworkError } from './app/utilities/request/network-error';

if (!isDevelopmentEnv()) {
  const sentryDSN = 'https://3793096ebac3475692f7da6320a45885@sentry.prod.canopytax.com/30';

  init({
    dsn: sentryDSN,
    ignoreErrors: ['AbortError', /request aborted/i],
    beforeSend(event: Event, hint?: EventHint): Promise<Event | null> | Event | null {
      if (hint) {
        const exception = hint.originalException;

        if (exception instanceof NetworkError) {
          const fingerprint = [];
          if (exception.url) {
            fingerprint.push(exception.url);
          }
          fingerprint.push(exception.message);
          if (exception.statusCode) {
            fingerprint.push(String(exception.statusCode));
          }
          event.fingerprint = fingerprint;
        }
      }
      return event;
    }
  });
}

const Main: React.FunctionComponent = () => {
  return (
    <AppContainer>
      <Provider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={theme}>
            <Auth>
              <Router history={history}>
                <AppRouter />
              </Router>
            </Auth>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </Provider>
    </AppContainer>
  );
};

function bootstrap() {
  const root = document.getElementById('root');
  if (root === null) {
    throw new Error('No root element.');
  }
  render(<Main />, root);
}

bootstrap();

const { hot } = module as any;
if (hot) {
  hot.accept('modules/root/App', bootstrap);
}
