import React, { Component } from 'react';
import {
  createStyles,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { maxBy, sortBy } from 'lodash';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../core/store';
import { Player, SelfStudyQuestion, UserAvatar } from '../../../shared';
import { prettyTime } from '../../../utilities';
import { CourseType } from '../../../types';

import { SelfStudyAssessment } from './self-study-assessment';
import { FailedAssessmentDialog } from './dialogs/failed-assessment-dialog';
import { PassedSelfStudyAssessmentDialog } from './dialogs/passed-assessment-dialog';
import { CourseInstructionsDialog } from './dialogs/course-instructions-dialog';

const downloadIcon = require('../../../../images/icons/download.png');
const questionIcon = require('../../../../images/icons/question.png');

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      marginRight: 10
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 2,
      margin: '15px 0',
      width: 151
    },
    header: {
      marginBottom: 20
    },
    instructor: {
      color: (theme as any).canopy.disabled,
      fontWeight: 'bold',
      fontSize: 18
    },
    leftPane: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      display: 'inline-block',
      padding: '30px 20px',
      [theme.breakpoints.down('sm')]: {
        padding: '18px 12px'
      },
      width: '100%'
    },
    overviewMeta: {
      margin: '8px 0px',
      fontSize: 16
    },
    resource: {
      textDecoration: 'none',
      color: 'black',
      fontSize: 14,
      fontWeight: 600,
      verticalAlign: 'top'
    },
    resourceContainer: {
      marginBottom: 20
    },
    resources: {
      margin: 20,
      '& h3': {
        fontSize: 24,
        fontWeight: 'bold'
      }
    },
    resourceIcon: {
      width: 20,
      margin: 'auto',
      verticalAlign: 'top',
      display: 'inline-block'
    },
    rightPane: {
      padding: 20,
      [theme.breakpoints.down('sm')]: {
        padding: 10
      }
    },
    videoIcon: {
      textAlign: 'right'
    }
  });

const mapStateToProps = ({ jwt, fieldOfStudies, userSelfStudies }: StateType) => ({
  jwt,
  fieldOfStudies,
  userSelfStudies
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  course: CourseType;
  preview?: boolean;
}

interface State {
  isAssessmentTime: boolean;
  showAssessment: boolean;
  showInstructions: boolean;
  videoIndex: number;
}

class SelfStudyBase extends Component<Props, State> {
  player: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isAssessmentTime: false,
      showAssessment: false,
      showInstructions: false,
      videoIndex: 0
    };
  }

  componentDidMount() {
    const { jwt } = this.props;
    actions.getUserSelfStudies(jwt.userId);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      !this.state.showAssessment &&
      (prevProps.userSelfStudies !== this.props.userSelfStudies || prevState.videoIndex !== this.state.videoIndex)
    ) {
      const { course } = this.props;
      const { videoIndex } = this.state;
      const { selfStudyQuestions, selfStudyVideos } = course;
      const nextVideo = selfStudyVideos[videoIndex + 1];

      // show final assessment when there is no remaining video and all questions have been answered
      if (!nextVideo) {
        const registration: any = this.getRegistration();
        if (registration && selfStudyQuestions.length === registration.answers.length && videoIndex === selfStudyVideos.length) {
          this.handleOpenAssessment();
        }
      }
    }
  }

  getRegistration = () => {
    const { course, userSelfStudies } = this.props;

    if (!userSelfStudies) {
      return null;
    }

    return userSelfStudies.find(userSelfStudy => userSelfStudy.courseId === course.id) || null;
  };

  handleCloseAssessment = () => {
    this.setState({
      showAssessment: false
    });
  };

  handleEventEnded = () => {
    const { videoIndex } = this.state;
    this.setState({
      videoIndex: videoIndex + 1
    });
  };

  handleHideInstructions = () => {
    this.setState({
      showInstructions: false
    });
  };

  handleOpenAssessment = () => {
    this.setState({
      showAssessment: true,
      isAssessmentTime: true
    });
  };

  handleShowInstructions = () => {
    this.setState({
      showInstructions: true
    });
  };

  handleVideoClick = (videoIndex: number) => () => {
    this.setState({
      videoIndex
    });
  };

  getCurrentAnswer = () => {
    const registration = this.getRegistration();
    if (!registration) {
      return undefined;
    }

    const { answers }: { answers: any[] } = registration as any;
    return maxBy(answers, 'position');
  };

  isDisabled = (videoIndex: number) => {
    const { course, preview } = this.props;
    const { selfStudyVideos } = course;
    const registration = this.getRegistration();

    if (preview) {
      return false;
    }

    if (!registration || !Array.isArray(selfStudyVideos)) {
      return true;
    }
    const selfStudyVideo = selfStudyVideos[videoIndex];
    const currAnswer = this.getCurrentAnswer();

    // if no answers then only first video is enabled.
    if (!currAnswer) {
      return videoIndex !== 0;
    }

    // find the position of the next question and disable any videos after.
    // if no next question, enable all remaining videos.
    const nextQuestion = sortBy(course.selfStudyQuestions, 'position').find(q => q.position > currAnswer.position);
    const { position } = nextQuestion || {
      position: selfStudyVideos[selfStudyVideos.length - 1].position
    };

    return selfStudyVideo.position > position;
  };

  setPlayer = (player: any) => {
    this.player = player;
    this.player.on('ended', this.handleEventEnded);
  };

  onFailedDialogClose = (retakeAssessment = false): void => {
    if (retakeAssessment) {
      this.setState({
        showAssessment: true
      });
    }
  };

  render() {
    const { classes, course, fieldOfStudies, preview } = this.props;
    const { isAssessmentTime, showAssessment, showInstructions, videoIndex } = this.state;
    const fieldOfStudy = fieldOfStudies.find(s => s.id === course.fieldOfStudyId);
    const playerConfig = {
      controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'pip', 'airplay', 'fullscreen']
    };
    const {
      credittype = [],
      instructor,
      resources = [],
      selfStudyAssessments = [],
      selfStudyQuestions = [],
      selfStudyVideos = []
    } = course;
    const registration = this.getRegistration();
    if (!registration && !preview) {
      return null;
    }

    const selfStudyVideo = selfStudyVideos[videoIndex];
    const currAnswer = this.getCurrentAnswer();
    const assessmentDisabled = !registration || registration.answers.length !== selfStudyQuestions.length;
    const assessmentColor = registration && registration.status === 'passed' ? 'secondary' : 'action';

    let position;
    if (selfStudyVideo) {
      // Use active video position to determine questions shown.
      position = selfStudyVideo.position;
    } else {
      // All videos have been watched, use currAnswer or last video position, + 1.
      const prevVideo = selfStudyVideos[videoIndex - 1];
      position = currAnswer && currAnswer.position > prevVideo.position ? currAnswer.position + 1 : prevVideo.position + 1;
    }

    return (
      <Paper>
        <Grid container spacing={0}>
          <Grid item xs={12} md={3}>
            <div className={classes.leftPane}>
              <Typography variant="h3" gutterBottom>
                Course Overview
              </Typography>
              <div className={classes.divider} />
              <p className={classes.overviewMeta}>
                <b>Field Of Study:</b> {fieldOfStudy ? fieldOfStudy.name : ''}
              </p>
              <p className={classes.overviewMeta}>
                <b>Credit Type:</b> {credittype.join(', ')}
              </p>
              <p className={classes.overviewMeta}>
                <b>Credit Hours:</b> {course.credits}
              </p>
            </div>

            <List>
              {selfStudyVideos.map((video, index) => {
                const disabled = this.isDisabled(index);
                let completed = false;
                if ((registration && registration.status === 'passed') || (currAnswer && video.position < currAnswer.position)) {
                  completed = true;
                }

                return (
                  <ListItem key={index} disabled={disabled} button onClick={this.handleVideoClick(index)}>
                    <ListItemText primary={`${video.title} (${prettyTime(video.duration)})`} />
                    <ListItemSecondaryAction>
                      <IconButton aria-label="Status" disabled={disabled}>
                        {!disabled && completed && (
                          <Icon className={classes.videoIcon} color="secondary">
                            check_circle
                          </Icon>
                        )}

                        {!disabled && !completed && (
                          <Icon className={classes.videoIcon} color="secondary">
                            radio_button_unchecked
                          </Icon>
                        )}

                        {disabled && <Icon className={classes.videoIcon}>radio_button_unchecked</Icon>}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
              <ListItem button disabled={assessmentDisabled} onClick={this.handleOpenAssessment}>
                <ListItemText primary="Final Assessment" />
                <ListItemSecondaryAction>
                  <IconButton aria-label="Status" disabled={assessmentDisabled}>
                    <Icon className={classes.videoIcon} color={assessmentColor}>
                      assessment
                    </Icon>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            {resources.length > 0 && (
              <div className={classes.resources}>
                <h3>Resource Files</h3>

                {resources.map((resource, index) => (
                  <div key={index} className={classes.resourceContainer}>
                    {!!resource.url && (
                      <a className={classes.resource} href={resource.url || '#'} rel="noreferrer noopener" target="_blank">
                        <img className={classes.resourceIcon} src={downloadIcon} />
                        &nbsp;&nbsp;
                        {resource.name || resource.url}
                      </a>
                    )}
                  </div>
                ))}
                <div className={classes.resourceContainer}>
                  <a
                    className={classes.resource}
                    href="https://us.canopytax.com/courses/glossary/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <Icon color="primary" style={{ marginTop: -2, marginLeft: -2, marginRight: 2 }} className={classes.resourceIcon}>
                      list
                    </Icon>
                    &nbsp;&nbsp;Glossary
                  </a>
                </div>
                <div className={classes.resourceContainer}>
                  <a className={classes.resource} href="#" onClick={this.handleShowInstructions}>
                    <img style={{ marginRight: -4 }} className={classes.resourceIcon} src={questionIcon} />
                    &nbsp;&nbsp; Course Instructions
                  </a>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <div className={classes.rightPane}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {instructor && (
                    <div className={classes.instructor}>
                      <UserAvatar classes={{ avatar: classes.avatar }} url={course.instructor.avatarUrl} height={45} width={45} />
                      by {course.instructor.firstName} {course.instructor.lastName}
                    </div>
                  )}
                  <Typography variant="h3" className={classes.header}>
                    {course.name} {selfStudyVideo ? `- ${selfStudyVideo.title}` : ''}
                  </Typography>
                </Grid>
              </Grid>

              {selfStudyVideo && <Player config={playerConfig} setPlayer={this.setPlayer} video={selfStudyVideo} />}
            </div>
          </Grid>
        </Grid>

        {!preview && isAssessmentTime && registration && (
          <>
            <SelfStudyAssessment
              assessments={selfStudyAssessments}
              open={showAssessment && registration.status === 'pending'}
              onClose={this.handleCloseAssessment}
              registration={registration}
            />
            <FailedAssessmentDialog
              open={registration.status === 'failed'}
              onClose={this.onFailedDialogClose}
              registration={registration}
            />
            <PassedSelfStudyAssessmentDialog
              open={registration.status === 'passed'}
              onClose={() => {
                /* empty */
              }}
              course={course}
              registration={registration}
            />
          </>
        )}

        {!preview && !isAssessmentTime && registration && registration.status === 'pending' && (
          <SelfStudyQuestion position={position} questions={selfStudyQuestions} registration={registration} />
        )}

        <CourseInstructionsDialog open={showInstructions} onClose={this.handleHideInstructions} />
      </Paper>
    );
  }
}

export const SelfStudy = withStyles(styles)(connect(mapStateToProps)(SelfStudyBase));
