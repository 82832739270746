import React, { Component } from 'react';
import { Button, createStyles, Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../core/store';
import { toTitleCase } from '../../../utilities';
import { LEARNER_SAVE_COURSE_SUCCESS, REGISTER_SELF_STUDY_SUCCESS } from '../../../constants';
import { CourseType } from '../../../types';

import { CourseSeries } from './course-series';

const downloadIcon = require('../../../../images/icons/download.png');

const styles = (theme: Theme) =>
  createStyles({
    actions: {
      display: 'inline-block',
      width: 172,
      marginRight: 20,
      verticalAlign: 'top'
    },
    actionButton: {
      width: 172,
      height: 38,
      marginBottom: 20
    },
    container: {
      padding: 20
    },
    downloadIcon: {
      color: theme.palette.secondary.main,
      paddingTop: 2
    },
    resource: {
      textDecoration: 'none',
      color: 'black',
      fontWeight: 400,
      fontSize: 16,
      verticalAlign: 'top'
    },
    richText: {
      fontSize: 16,
      lineHeight: 1.8
    }
  });

const mapStateToProps = (state: StateType) => ({
  fieldOfStudies: state.fieldOfStudies,
  jwt: state.jwt,
  message: state.message,
  userSelfStudies: state.userSelfStudies,
  userWebinars: state.userWebinars,
  savedCourses: state.savedCourses
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  course: CourseType;
  handleChangeStep: Function;
  preview?: boolean;
}

class CourseDetailsBase extends Component<Props> {
  componentDidMount() {
    const { jwt } = this.props;
    actions.getUserSelfStudies(jwt.userId);
    actions.getUserWebinars(jwt.userId);
    actions.getUserSavedCourseDetails(jwt.userId);
  }

  componentDidUpdate() {
    const { handleChangeStep } = this.props;
    if (this.props.message === REGISTER_SELF_STUDY_SUCCESS) {
      handleChangeStep('selfStudyRegistration')();
    }

    if (this.props.message === LEARNER_SAVE_COURSE_SUCCESS) {
      handleChangeStep('savedCourse')();
    }
  }

  alertPreview = () => {
    // eslint-disable-next-line
    alert('This is a preview only, registration is not possible.');
  };

  handleSelfStudyEnroll = async () => {
    const { course, preview, userWebinars = [], savedCourses = [] } = this.props;
    const webinarEnrollment = userWebinars.find(webinar => webinar.courseId === course.id);
    const savedCourse = savedCourses.find(sc => sc.courseId === course.id);

    if (preview) {
      this.alertPreview();
      return;
    }

    if (
      // eslint-disable-next-line
      confirm(`Are you sure you want to register for the ${course.name} self study?`)
    ) {
      if (webinarEnrollment) {
        await actions.unregisterWebinar(webinarEnrollment.courseId, webinarEnrollment.webinarId, false);
      }
      if (savedCourse) {
        await actions.unsaveUserCourse(course.id, false);
      }
      actions.registerSelfStudy(course.id);
    }
  };

  handleSaveCourse = async () => {
    const { course, preview, userWebinars = [], userSelfStudies = [] } = this.props;
    const webinarEnrollment = userWebinars.find(webinar => webinar.courseId === course.id);
    const selfStudyEnrollment = userSelfStudies.find(study => study.courseId === course.id);

    if (preview) {
      this.alertPreview();
      return;
    }

    if (selfStudyEnrollment) {
      const confimMessage = `Are you sure you wish to add “${course.name}” to your Saved Courses?
You will lose any progress made in the course.`.trim();
      // eslint-disable-next-line
      if (!confirm(confimMessage)) {
        return;
      }
    }

    if (webinarEnrollment) {
      await actions.unregisterWebinar(webinarEnrollment.courseId, webinarEnrollment.webinarId, false);
    }
    if (selfStudyEnrollment) {
      await actions.unregisterSelfStudy(selfStudyEnrollment.courseId, false);
    }
    await actions.saveUserCourse(course.id);
  };

  handleRemoveSelfStudy = () => {
    const { course, preview } = this.props;

    if (preview) {
      this.alertPreview();
      return;
    }

    if (
      // eslint-disable-next-line
      confirm('Are you sure you want to leave this self study?  All of your progress will be lost.')
    ) {
      actions.unregisterSelfStudy(course.id);
    }
  };

  handleRemoveWebinar = () => {
    const { course, preview, userWebinars = [] } = this.props;

    if (preview) {
      this.alertPreview();
      return;
    }

    const enrolledWebinar = userWebinars.find(webinar => webinar.courseId === course.id);

    // eslint-disable-next-line
    if (enrolledWebinar && confirm('Are you sure you want to leave this webinar?')) {
      actions.unregisterWebinar(course.id, enrolledWebinar.webinarId);
    }
  };

  render() {
    const {
      classes,
      course,
      preview,
      fieldOfStudies,
      handleChangeStep,
      userSelfStudies = [],
      userWebinars = [],
      savedCourses = []
    } = this.props;
    const fieldOfStudy = fieldOfStudies.find(s => s.id === course.fieldOfStudyId);
    const { credittype = [], resources = [], selfStudyVideos = [], webinarScheduled = [] } = course;
    const enrolledSelfStudy = userSelfStudies.find(selfStudy => selfStudy.courseId === course.id);
    const enrolledWebinar = userWebinars.find(webinar => webinar.courseId === course.id);
    const savedCourse = savedCourses.find(sc => sc.courseId === course.id);
    const enrolled = enrolledSelfStudy || enrolledWebinar;

    return (
      <div className={classes.container}>
        <h2>Course Overview</h2>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <p>
              <b>Level:</b> {toTitleCase(course.level)}
            </p>
            <p>
              <b>Field Of Study:</b> {fieldOfStudy ? fieldOfStudy.name : ''}
            </p>
            <p>
              <b>Credit Type:</b> {credittype.join(', ')}
            </p>
          </Grid>
          <Grid item xs={6}>
            <p>
              <b>Cost:</b> Free
            </p>
            <p>
              <b>Advanced Prep:</b> {course.advancedPrep}
            </p>
            <p>
              <b>Credit Hours:</b> {course.credits}
            </p>
          </Grid>
        </Grid>
        <br />
        <p>
          <b>Learning Objectives:</b>
        </p>
        <div className={classes.richText} dangerouslySetInnerHTML={{ __html: course.learningObjectives }} />
        <br />
        {resources.length > 0 && (
          <p>
            <b>Resource Materials:</b>
          </p>
        )}
        {resources.map((resource, index) => (
          <div key={index}>
            {!!resource.url && (
              <a className={classes.resource} key={index} href={resource.url || '#'} rel="noreferrer noopener" target="_blank">
                {resource.name}
                &nbsp;&nbsp;
                <img alt="Download" className={classes.downloadIcon} src={downloadIcon} />
              </a>
            )}
          </div>
        ))}
        <br />
        <br />

        {(!enrolled || enrolled.status !== 'passed') && (
          <div>
            <div className={classes.actions}>
              <Button
                disabled={webinarScheduled.length === 0}
                variant="contained"
                color="secondary"
                className={classes.actionButton}
                onClick={handleChangeStep('webinar')}>
                Select Webinar Time
              </Button>
            </div>
            <div className={classes.actions}>
              <Button
                disabled={!!enrolledSelfStudy || selfStudyVideos.length === 0}
                variant="contained"
                color="secondary"
                className={classes.actionButton}
                onClick={this.handleSelfStudyEnroll}>
                Select Self Study
              </Button>
            </div>
            <div className={classes.actions}>
              <Button
                disabled={!!savedCourse}
                variant="contained"
                color="secondary"
                className={classes.actionButton}
                onClick={this.handleSaveCourse}>
                Save Course
              </Button>
            </div>
          </div>
        )}
        <br />
        <br />
        <CourseSeries course={course} preview={preview} />
      </div>
    );
  }
}

export const CourseDetails = connect(mapStateToProps)(withStyles(styles)(CourseDetailsBase));
