import { Component } from 'react';
import Cookies from 'js-cookie';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../store';

const mapStateToProps = ({ jwt, user }: StateType) => ({
  jwt,
  user
});

interface Props extends WithProps<typeof mapStateToProps> {
  children: any;
}

interface State {
  canRender: boolean;
}

/**
 * Check for domain cookie containing JWT and authenticate user against SSO endpoint.
 * Should be used before Router.
 */
class BaseComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      canRender: false
    };
  }

  /**
   * Load user data if authenticated.
   * Otherwise check for SSO cookie and validate.
   */
  componentDidMount() {
    const { jwt } = this.props;
    if (jwt.accessToken) {
      this.setState({ canRender: true }, this.loadUser);
    } else {
      this.sso();
    }
  }

  componentDidUpdate(prevProps: any) {
    const { jwt } = this.props;

    if (jwt.accessToken && jwt.accessToken !== prevProps.jwt.accessToken) {
      // New access token found, load user.
      this.loadUser();
    } else if (!jwt.accessToken && !jwt.error) {
      // No access token found, check SSO.
      this.sso();
    }
  }

  /**
   * Load user data, categories and notifications
   */
  loadUser = async () => {
    const { jwt } = this.props;
    await Promise.all([actions.getUser(jwt.userId), actions.getCategories()]);
  };

  /**
   * Check for SSO cookie and validate if available.
   */
  sso = async () => {
    const authUserCookie = Cookies.get('AuthenticatedUser');
    if (authUserCookie) {
      await actions.sso(authUserCookie);
    }

    if (!this.state.canRender) {
      this.setState({ canRender: true });
    }
  };

  render() {
    const { jwt } = this.props;
    const { canRender } = this.state;

    // Block rendering until access token found, or SSO authentication has completed.
    if (!jwt.accessToken && !canRender) {
      return null;
    }

    return this.props.children;
  }
}

export const Auth = connect(mapStateToProps)(BaseComponent);
