import React, { Component } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { createStyles, Grid, Icon, IconButton, TextField, WithStyles, withStyles } from '@material-ui/core';
import { format, isValid } from 'date-fns';
import { DropzoneState } from 'react-dropzone';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../../../../../core/store';
import { CREDIT_TYPES, LEVELS } from '../../../../../constants';
import { RichTextEditor, Select } from '../../../../../shared';
import { ActionButtons } from '../../shared';
import { CategoryType } from '../../../../../types';

import { InstructorAutocomplete } from './instructor-autocomplete';

let Dropzone = require('react-dropzone');
if ('default' in Dropzone) {
  Dropzone = Dropzone.default;
}

const styles = createStyles({
  datePicker: {
    marginTop: 16
  },
  downloadIcon: {
    textAlign: 'right'
  },
  dropzone: {
    margin: '40px 20px 0px',
    textAlign: 'center',
    border: '1px dotted',
    cursor: 'pointer'
  },
  form: {
    margin: '0 40px',
    padding: 60
  },
  formControl: {
    width: '100%'
  },
  resource: {
    color: 'black',
    display: 'inline-block',
    fontWeight: 600,
    lineHeight: '48px',
    textDecoration: 'none',
    verticalAlign: 'middle'
  },
  resources: {
    margin: '40px 20px 0px'
  }
});

const mapStateToProps = ({ categories, fieldOfStudies }: StateType) => ({
  categories,
  fieldOfStudies
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  containerState: any;
  course: any;
  handleCancelClick: () => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  setContainerState: (state: { [index: string]: any }) => void;
  formHasChanges: boolean;
}

interface State {
  credittypeOpen: boolean;
  defaults: any;
}

class CourseDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      credittypeOpen: false,
      defaults: {} as any
    };
  }

  static getDerivedStateFromProps(newProps: Props, prevState: State) {
    const { containerState } = newProps;
    const { form } = containerState;
    const { defaults } = prevState;

    if (!defaults.id && form.id) {
      return { defaults: { ...form } };
    }

    return {};
  }

  formatLabel = (date: Date, invalidLabel: string) => {
    if (date === null) {
      return '';
    }

    if (isValid(date)) {
      const label = format(date, 'MMM do yyyy');
      return label;
    }

    return invalidLabel;
  };

  handleDateChange = (date: Date) => {
    const { containerState, setContainerState } = this.props;
    const { form } = containerState;
    setContainerState({
      form: {
        ...form,
        lastReview: date
      }
    });
  };

  handleFormChange = (event: any) => {
    if (typeof event.persist === 'function') {
      event.persist(); // allow native event access (see: https://facebook.github.io/react/docs/events.html)
    }

    const { containerState, setContainerState } = this.props;
    const { form } = containerState;
    const { id, name } = event.target;
    const key = id || name;
    let { value } = event.target;

    if (value && ['credits'].includes(key)) {
      value = parseInt(value, 10);
    } else if (typeof value === 'string') {
      value = value.trim();
    }

    const stateChange = {
      form: {
        ...form,
        [key]: value
      }
    };

    // reset category when field of study changes
    if (key === 'fieldOfStudyId') {
      stateChange.form.categoryId = undefined;
    }

    // override form instructor object with newly selected instructor
    if (key === 'instructorId' && event.target.instructor) {
      stateChange.form.instructor = event.target.instructor;
    }

    setContainerState(stateChange);

    if (key === 'credittype') {
      this.handleCredittypeClose();
    }
  };

  handleCredittypeClose = () => {
    this.setState({ credittypeOpen: false });
  };

  handleCredittypeOpen = () => {
    this.setState({ credittypeOpen: true });
  };

  handleDeleteResource = (resourceIndex: number) => () => {
    const { containerState, setContainerState } = this.props;
    const { form } = containerState;
    const { resources } = form;
    const resource = resources[resourceIndex];

    if (resource.id) {
      actions.deleteCourseResource(form.id, resource.id);
    } else {
      resources.splice(resourceIndex, 1);
      setContainerState({
        form: {
          ...form,
          resources: [...resources]
        }
      });
    }
  };

  onDropResource = (newResources: File[]) => {
    const { containerState, setContainerState } = this.props;
    const { form } = containerState;
    const { resources: currentResources = [] } = form;
    const resources = [...newResources, ...currentResources];
    setContainerState({
      form: {
        ...form,
        resources
      }
    });
  };

  render() {
    const { categories, classes, containerState, fieldOfStudies, handleCancelClick, handleSubmit, formHasChanges } = this.props;
    const { credittypeOpen, defaults } = this.state;
    const { form, submitting } = containerState;
    const { credittype = [], resources = [] } = form;

    let categoriesFiltered: CategoryType[] = [];
    if (form.fieldOfStudyId) {
      const fieldOfStudy = fieldOfStudies.find(f => f.id === form.fieldOfStudyId);
      if (fieldOfStudy) {
        categoriesFiltered = categories.filter(cat => fieldOfStudy.categories.includes(cat.id));
      }
    }

    return (
      <form autoComplete="off" onSubmit={handleSubmit} className={classes.form}>
        <ActionButtons
          course={form}
          preview="course"
          onCancelClick={handleCancelClick}
          submitting={submitting}
          formHasChanges={formHasChanges}
        />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div>
              <TextField
                id="name"
                defaultValue={defaults.name}
                disabled={submitting}
                label="Course Title"
                fullWidth
                onChange={this.handleFormChange}
                required={true}
              />
            </div>

            <InstructorAutocomplete instructorId={form.instructorId} onChange={this.handleFormChange} />

            <Select
              onChange={this.handleFormChange}
              label="Field Of Study"
              name="fieldOfStudyId"
              value={form.fieldOfStudyId}
              options={fieldOfStudies}
              className={classes.formControl}
            />

            <Select
              disabled={!form.fieldOfStudyId}
              onChange={this.handleFormChange}
              label="Category"
              name="categoryId"
              value={form.categoryId}
              options={categoriesFiltered}
              className={classes.formControl}
            />

            <Select
              onChange={this.handleFormChange}
              label="Level"
              name="level"
              value={form.level}
              options={LEVELS}
              className={classes.formControl}
            />

            <div>
              <TextField
                id="youtubeUrl"
                defaultValue={defaults.youtubeUrl}
                disabled={submitting}
                fullWidth
                label="YouTube Link"
                onChange={this.handleFormChange}
                type="url"
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <Select
              className={classes.formControl}
              onChange={this.handleFormChange}
              label="Credit Type"
              multiple={true}
              name="credittype"
              onClose={this.handleCredittypeClose}
              onOpen={this.handleCredittypeOpen}
              open={credittypeOpen}
              options={CREDIT_TYPES.map(type => ({
                id: type,
                name: type
              }))}
              value={credittype}
            />

            <div>
              <TextField
                id="credits"
                defaultValue={defaults.credits}
                disabled={submitting}
                label="Credits"
                type="number"
                fullWidth
                onChange={this.handleFormChange}
              />
            </div>
            <div>
              <TextField
                id="ctecId"
                defaultValue={defaults.ctecId}
                disabled={submitting}
                label="CTEC ID"
                fullWidth
                onChange={this.handleFormChange}
              />
            </div>
            <div>
              <TextField
                id="irsId"
                defaultValue={defaults.irsId}
                disabled={submitting}
                label="IRS ID"
                fullWidth
                onChange={this.handleFormChange}
              />
            </div>

            <div>
              <TextField
                id="lastReviewBy"
                defaultValue={defaults.lastReviewBy}
                disabled={submitting}
                label="Last Review By"
                fullWidth
                onChange={this.handleFormChange}
              />
            </div>

            <div className={classes.datePicker}>
              <DatePicker
                autoOk={true}
                clearable
                labelFunc={this.formatLabel}
                onChange={this.handleDateChange}
                placeholder="Reviewed On"
                value={form.lastReview || null}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="advancedPrep"
              defaultValue={defaults.advancedPrep}
              disabled={submitting}
              label="Advanced Prep"
              multiline
              fullWidth
              onChange={this.handleFormChange}
            />
          </Grid>

          <Grid item xs={12}>
            <RichTextEditor
              defaultValue={defaults.learningObjectives}
              id="learningObjectives"
              placeholder="Learning Objectives"
              onChange={this.handleFormChange}
            />
          </Grid>

          <Grid item xs={12}>
            <RichTextEditor defaultValue={defaults.overview} id="overview" placeholder="Course Overview" onChange={this.handleFormChange} />
          </Grid>

          <Grid item xs={12}>
            <Dropzone onDrop={this.onDropResource} multiple={true}>
              {({
                getRootProps,
                getInputProps
              }: {
                getRootProps: DropzoneState['getRootProps'];
                getInputProps: DropzoneState['getInputProps'];
              }) => (
                <section>
                  <div className={classes.dropzone} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drop resources here, or click to select one or more to upload.</p>
                  </div>
                </section>
              )}
            </Dropzone>

            <div className={classes.resources}>
              {resources.map((resource: any, index: number) => (
                <div key={index}>
                  {!!resource.url && (
                    <a className={classes.resource} key={index} href={resource.url || '#'} rel="noreferrer noopener" target="_blank">
                      {resource.name}
                      &nbsp;&nbsp;
                    </a>
                  )}
                  {!resource.url && <span>{resource.name}&nbsp;&nbsp;</span>}

                  <IconButton onClick={this.handleDeleteResource(index)}>
                    <Icon className={classes.downloadIcon}>delete</Icon>
                  </IconButton>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps)(CourseDetails));
