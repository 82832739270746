import React, { Component } from 'react';
import { IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { WithProps } from 'react-waterfall';

import { actions, connect, StateType } from '../store';

const mapStateToProps = ({ error, message }: StateType) => ({
  error,
  message
});

type Props = WithProps<typeof mapStateToProps>;

interface State {
  open: boolean;
}

class StatusSnackbarBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason = '') => {
    if (reason === 'clickaway') {
      return;
    }

    actions.unsetError();
    actions.unsetMessage();
    this.setState({ open: false });
  };

  static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps.error || nextProps.message) {
      return { open: true };
    }
    return { open: false };
  }

  render() {
    const { error, message } = this.props;
    const text = error || message;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={this.state.open}
        autoHideDuration={3000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id="message-id">{text}</span>}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleClose}>
            <Close />
          </IconButton>
        ]}
      />
    );
  }
}

export const StatusSnackbar = connect(mapStateToProps)(StatusSnackbarBase);
