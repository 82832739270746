import { PollingQuestionType } from '../types';
import { array, number, string } from '../utilities/parse';

/**
 * Polling Question related parsing functions for API responses.
 */
export function parsePollingQuestion(input: any): PollingQuestionType {
  return {
    id: number(input, 'id'),
    question: string(input, 'question'),
    answers: array(input, 'answers', string),
    position: number(input, 'position'),
    published: string(input, 'published'),
    publishedByName: string(input, 'publishedByName'),
    status: string(input, 'status'),
    totalResponses: number(input, 'totalResponses'),
    updated: string(input, 'updated'),
    updatedByName: string(input, 'updatedByName'),
    url: `/admin/polling-questions/${input.id}`
  };
}

export function parsePollingQuestions(input: any[]): PollingQuestionType[] {
  return input.map(row => parsePollingQuestion(row));
}
