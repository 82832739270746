/**
 * Either validate input as a stirng, or validate the value at input[field] is a string. If
 * invalid, will return an empty string.
 */
export function string(input: any, field: string | number = ''): string {
  if (input === null || input === undefined) {
    return '';
  }

  if (typeof input === 'string') {
    return input;
  }

  if (typeof input !== 'object' || typeof field === 'number') {
    return '';
  }

  // input is an Object, select field from that object
  const value = input[field];
  if (value === null || value === undefined || typeof value !== 'string') {
    return '';
  }
  return value;
}

/**
 * Either validate input as a boolean, or validate the value at input[field] is a boolean. If
 * invalid, will return false.
 */
export function boolean(input: any, field: string | number = ''): boolean {
  if (input === null || input === undefined) {
    return false;
  }

  if (typeof input === 'boolean') {
    return input;
  }

  if (typeof input !== 'object' || typeof field === 'number') {
    return false;
  }

  // input is an Object, select field from that objcet
  const value = input[field];
  if (value === null || value === undefined || typeof value !== 'boolean') {
    return false;
  }
  return value;
}

/**
 * Either validate input as a number, or validate the value at input[field] is a number. If
 * invalid, will return 0.
 */
export function number(input: any, field: string | number = ''): number {
  if (input === null || input === undefined) {
    return 0;
  }

  if (typeof input === 'number') {
    return input;
  }

  if (typeof input !== 'object' || typeof field === 'number') {
    return 0;
  }

  const value = input[field];
  if (value === null || value === undefined || typeof value !== 'number') {
    return 0;
  }
  return value;
}

/**
 * given an input object and field name, validate that the value at input[field]
 * is an Object. If invalid, will return a default value.
 */
export function selectNested(input: any, field: string, defaultValue: any = {}): any {
  if (input === null || input === undefined || typeof input !== 'object') {
    return defaultValue;
  }

  const nested = input[field];
  if (nested === null || nested === undefined || typeof nested !== 'object') {
    return defaultValue;
  }

  return nested;
}

type IteratorFn<T> = (item: any) => T;

/**
 * Given an input object and field name, validates that input[field] is an array and validates
 * each array item based on an iterator function. If value is not an array, returns empty array
 */
export const array = <T>(input: any, field: string, iterator: IteratorFn<T>): T[] => {
  if (input === null || input === undefined || typeof input !== 'object') {
    return [];
  }

  const value = input[field];
  if (value === null || value === undefined || !Array.isArray(value)) {
    return [];
  }

  return value.map(iterator).filter(x => x !== undefined && x !== null);
};
