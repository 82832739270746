import { WebinarType } from '../types';
import { array, number, string } from '../utilities/parse';

import { parseInstructor } from './instructor';

/**
 * Webinar related parsing functions for API responses.
 */
export function parseWebinar(input: any): WebinarType {
  return {
    id: number(input, 'id'),
    categoryId: number(input, 'categoryId'),
    credits: number(input, 'credits'),
    credittype: array(input, 'credittype', string),
    fieldOfStudyId: number(input, 'fieldOfStudyId'),
    instructorId: number(input, 'instructorId'),
    instructor: parseInstructor(input.instructor),
    level: string(input, 'level'),
    name: string(input, 'name'),
    scheduledOn: string(input, 'scheduledOn'),
    webinarRegistrations: number(input, 'webinarRegistrations'),
    updated: string(input, 'updated')
  };
}

export function parseWebinars(input: any[]): WebinarType[] {
  return input.map(row => parseWebinar(row));
}
