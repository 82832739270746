import React, { Component, forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button, createStyles, Grid, Paper, Theme, Tooltip, Typography, WithStyles, withStyles } from '@material-ui/core';

import { UserAvatar } from '../../../shared';
import ceLogo from '../../../../images/partners/ce-black.png';
import cpeLogo from '../../../../images/partners/cpe-black.png';

import { CourseRating } from './course-rating';
import { CourseDetails } from './course-details';
import { CourseSaved } from './course-saved';
import { SelfStudyEnrollment } from './self-study-enrollment';
import { WebinarEnrollment } from './webinar-enrollment';

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      marginRight: 10
    },
    averageRating: {
      margin: 0,
      fontSize: 100
    },
    backArrow: {
      fontSize: '1.3rem',
      fontWeight: 'bold'
    },
    backButton: {
      color: 'white',
      fontSize: 16,
      fontWeight: 'normal',
      padding: 8,
      marginBottom: 20,
      minWidth: 'auto'
    },
    ceType: {
      height: 50
    },
    courseName: {
      marginTop: 12
    },
    cpeType: {
      height: 50,
      marginRight: 70
    },
    creditTypes: {
      backgroundColor: 'lightgray',
      flex: '0 1 100px',
      padding: 25,
      textAlign: 'center'
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 2,
      margin: '15px 0',
      width: 180
    },
    gridContainer: {
      height: '100%'
    },
    part: {
      fontWeight: 'bold',
      fontSize: 18,
      marginTop: 32
    },
    instructor: {
      color: theme.palette.common.white,
      fontWeight: 'bold',
      fontSize: 18,
      paddingTop: 10,
      paddingBottom: 10,
      textDecoration: 'none'
    },
    leftPaneContainer: {
      backgroundColor: theme.palette.primary.main
    },
    leftPane: {
      color: 'white',
      height: '100%',
      minHeight: 945,
      display: 'flex',
      flex: 1,
      flexFlow: 'column',
      width: '100%'
    },
    leftPaneContent: {
      flex: '1 1 auto;',
      padding: 20,
      minHeight: 500
    },
    lightTooltip: {
      background: theme.palette.common.white,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 12
    },
    overview: {
      fontSize: 18,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      marginBottom: 10
    },
    richText: {
      fontSize: 18,
      lineHeight: 1.8
    },
    termsOfServiceLink: {
      color: theme.palette.common.white,
      textDecoration: 'underline'
    },
    tos: {
      flex: '0 1 40px',
      padding: '20px 36px 57px'
    }
  });

interface Props extends WithStyles<typeof styles> {
  course: any;
  preview?: boolean;
}

type Step = 'details' | 'selfStudyRegistration' | 'webinar' | 'savedCourse';

interface State {
  step: Step;
}

class CourseBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      step: 'details'
    };
  }

  backLink = forwardRef((props: any, ref) => <Link innerRef={ref} to="#" {...props} />);

  handleChangeStep = (step: Step) => () => {
    const { preview } = this.props;

    if (preview) {
      // eslint-disable-next-line no-alert
      alert('This is a preview only, registration is not possible.');
      return;
    }

    this.setState(
      {
        step
      },
      () => {
        window.scrollTo(0, 0);
      }
    );
  };

  // Redirect to previous page if available.
  // Otherwise fallback to course discovery.
  handleGoBack = () => {
    const { preview } = this.props;
    if (preview) {
      return;
    }

    const prevPage = window.location.href;

    window.history.go(-1);

    setTimeout(() => {
      if (window.location.href === prevPage) {
        window.location.href = '/';
      }
    }, 500);
  };

  render() {
    const { classes, course, preview } = this.props;
    const { step } = this.state;
    const { credittype = [], instructor } = course;

    return (
      <div>
        <Helmet title={course.name} />
        <Paper>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item md={6} sm={12} className={classes.leftPaneContainer}>
              <div className={classes.leftPane}>
                <div className={classes.leftPaneContent}>
                  <Button className={classes.backButton} component={this.backLink} onClick={this.handleGoBack}>
                    <span className={classes.backArrow} dangerouslySetInnerHTML={{ __html: '&xlarr;' }} />
                    &nbsp;&nbsp; Back
                  </Button>
                  {instructor && (
                    <div>
                      <Link className={classes.instructor} to={preview ? '#' : `/courses/${course.id}/instructor`}>
                        <UserAvatar classes={{ avatar: classes.avatar }} url={instructor.avatarUrl} height={48} width={48} />
                        by {course.instructor.firstName} {course.instructor.lastName}
                      </Link>
                    </div>
                  )}
                  {course.courseSeriesId && (
                    <h2 className={classes.part}>
                      Part {course.courseSeriesPart} of {course.seriesPartsCount}
                    </h2>
                  )}
                  <Typography variant="h3" className={classes.courseName}>
                    {course.name}
                  </Typography>
                  <div className={classes.divider} />
                  <div className={classes.overview}>Course Overview</div>
                  <div className={classes.richText} dangerouslySetInnerHTML={{ __html: course.overview }} />
                </div>
                <div className={classes.tos}>
                  By registering for this course you agree to our&nbsp;
                  <a
                    className={classes.termsOfServiceLink}
                    href="https://us.canopytax.com/courses/tos/"
                    target="_blank"
                    rel="noopener noreferrer">
                    terms of service.
                  </a>
                </div>

                <div className={classes.creditTypes}>
                  {credittype.includes('CPE') && (
                    <Tooltip
                      classes={{ tooltip: classes.lightTooltip }}
                      placement="right-start"
                      title={
                        <p>
                          Canopy is registered with the National Association of State Boards of Accountancy
                          <br />
                          (NASBA) as a sponsor of continuing professional education on the National Registry
                          <br />
                          of CPE Sponsors. State boards of accountancy have ﬁnal authority on the
                          <br />
                          acceptance of individual courses for CPE credit. Complaints regarding registered
                          <br />
                          sponsors may be submitted to the National Registry of CPE Sponsors through
                          <br />
                          its website: www.NASBARegistry.org.
                        </p>
                      }>
                      <img alt="CPE Certification" className={classes.cpeType} src={cpeLogo} />
                    </Tooltip>
                  )}
                  {credittype.includes('CE') && (
                    <Tooltip
                      classes={{ tooltip: classes.lightTooltip }}
                      placement="right-start"
                      title={
                        <p>
                          Canopy has been approved by the IRS to offer Continuing Education
                          <br />
                          in accordance with the standards outlined in Circular 230
                          <br />
                          section 10.6.
                        </p>
                      }>
                      <img alt="CE Certification" className={classes.ceType} src={ceLogo} />
                    </Tooltip>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item md={6} sm={12}>
              {(() => {
                switch (step) {
                  case 'details':
                    return <CourseDetails course={course} handleChangeStep={this.handleChangeStep} preview={preview} />;
                  case 'webinar':
                    return <WebinarEnrollment course={course} handleChangeStep={this.handleChangeStep} />;
                  case 'selfStudyRegistration':
                    return <SelfStudyEnrollment course={course} />;
                  case 'savedCourse':
                    return <CourseSaved course={course} />;
                  default:
                    return null;
                }
              })()}
            </Grid>
          </Grid>
        </Paper>

        <div>
          <CourseRating course={course} />
        </div>
      </div>
    );
  }
}

export const Course = withStyles(styles)(CourseBase);
