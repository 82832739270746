import prettyMs from '../prettyMs';

/**
 * Convert the input duration from seconds to user friendly time span.
 *
 * @param {string} duration
 * @return {string}
 */
export default function prettyDuration(duration: string) {
  if (!duration) {
    return '';
  }

  return prettyMs(parseFloat(duration) * 1000);
}
