import React, { Component } from 'react';
import { Button, createStyles, Icon, WithStyles, withStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

const styles = createStyles({
  button: {
    borderRadius: 0,
    padding: '8px 10px',
    justifyContent: 'left',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);',
    backgroundColor: 'white',
    height: '35px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none'
  },
  rightIcon: {
    position: 'absolute',
    top: 10,
    right: 10
  }
});

interface Props extends WithStyles<typeof styles> {
  className?: string;
  children?: React.ReactChildren | string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

/**
 * Button to be used with various Refine View Dialogs.
 */
class RefineViewButtonBase extends Component<Props & ButtonProps> {
  render() {
    const { children, classes, className = '', ...props } = this.props;
    return (
      <Button className={`${classes.button} ${className}`} {...props}>
        {children}
        <Icon className={classes.rightIcon}>keyboard_arrow_down</Icon>
      </Button>
    );
  }
}

export const RefineViewButton = withStyles(styles)(RefineViewButtonBase);
