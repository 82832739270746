import React, { Component, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
  WithStyles,
  withWidth
} from '@material-ui/core';
import { isWidthDown, WithWidthProps } from '@material-ui/core/withWidth';
import { WithProps } from 'react-waterfall';

import { CourseFeedbackDialog } from '../../../../shared';
import { connect, StateType } from '../../../../../core/store';
import { CourseType, UserWebinarType } from '../../../../../types';

const mapStateToProps = ({ userWebinars }: StateType) => ({
  userWebinars
});

const styles = createStyles({
  modalActions: {
    justifyContent: 'flex-start'
  }
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps>, WithWidthProps {
  course: CourseType;
  open: boolean;
  onClose: () => void;
}

interface State {
  hideFeedback: boolean;
}

const certificatesRoute = forwardRef((props: any, ref) => <Link innerRef={ref} to="/my/certificates" {...props} />);

class WebinarPassedDialogBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hideFeedback: false
    };
  }

  getRegisteredWebinar(): UserWebinarType | null {
    const { course, userWebinars = [] } = this.props;
    return userWebinars.find(userWebinar => userWebinar.courseId === course.id) || null;
  }

  render() {
    const { course, onClose, open, width, classes } = this.props;
    const { hideFeedback } = this.state;
    const registration = this.getRegisteredWebinar();

    const fullScreen = isWidthDown('sm', width!);

    if (!registration) {
      return null;
    }

    const showFeedback = !registration.feedback && !hideFeedback;

    return (
      <>
        <Dialog
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          open={open && !showFeedback}
          onClose={onClose}
          fullScreen={fullScreen}
          maxWidth={fullScreen ? false : 'md'}>
          <DialogTitle>Congratulations, you met all of the requirements and have passed the course</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              You answered the minimum amount of polling questions and watched the webinar for the full required time
            </Typography>

            <Typography variant="body1">
              If enabled, your course completion will be shared with the IRS automatically. Check your certification page for the status of
              your CE accreditation.
            </Typography>
          </DialogContent>
          <DialogActions classes={{ root: classes.modalActions }}>
            <Button component={certificatesRoute} variant="contained" color="primary">
              View Certificate
            </Button>
          </DialogActions>
        </Dialog>

        <CourseFeedbackDialog
          open={showFeedback}
          onClose={() => this.setState({ hideFeedback: true })}
          registration={registration}
          course={course}
          deliveryMethod="webinar"
        />
      </>
    );
  }
}

export const WebinarPassedDialog = connect(mapStateToProps)(withStyles(styles)(withWidth()(WebinarPassedDialogBase)));
