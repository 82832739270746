import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Theme,
  WithStyles,
  withStyles,
  withWidth
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { format, isValid } from 'date-fns';
import { isEqual } from 'lodash';
import { WithProps } from 'react-waterfall';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { isWidthDown, WithWidthProps } from '@material-ui/core/withWidth';

import { connect, StateType } from '../core/store';

const styles = (theme: Theme) =>
  createStyles({
    dialogActions: {
      justifyContent: 'flex-start'
    },
    list: {
      backgroundColor: theme.palette.background.paper
    },
    listContainer: {
      margin: `${theme.spacing(2)}px 0`
    },
    sticky: {
      top: theme.spacing(1) * -1
    }
  });

const mapStateToProps = ({ fieldOfStudies }: StateType) => ({
  fieldOfStudies
});

interface Props extends WithStyles<typeof styles>, WithWidthProps, WithProps<typeof mapStateToProps> {
  containerState: any;
  handleChange: Function;
  handleClose: Function;
  open: boolean;
}

interface State {
  endDate: string | undefined;
  fieldOfStudyId: number[];
  startDate: string | undefined;
}

class RefineViewDialogBase extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fieldOfStudyId: [],
      endDate: undefined,
      startDate: undefined
    };
  }

  componentDidMount() {
    this.resetForm();
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.containerState, this.props.containerState)) {
      this.resetForm();
    }
  }

  formatLabel = (date: Date, invalidLabel: string) => {
    if (date === null) {
      return '';
    }

    if (isValid(date)) {
      return format(date, 'MMM do yyyy');
    }

    return invalidLabel;
  };

  handleClose = () => {
    const { handleClose } = this.props;
    this.resetForm();
    handleClose();
  };

  handleDateChange = (prop: string) => (date: MaterialUiPickersDate) => {
    const newState: any = {
      [prop]: date
    };
    this.setState(newState);
  };

  toggleField(fieldId: number) {
    return () => {
      const isContained = this.state.fieldOfStudyId.indexOf(fieldId) > -1;
      if (isContained) {
        const filtered = this.state.fieldOfStudyId.filter(value => value !== fieldId);
        this.setState({ fieldOfStudyId: filtered });
      } else {
        this.setState({ fieldOfStudyId: [...this.state.fieldOfStudyId, fieldId] });
      }
    };
  }

  handleSearch = () => {
    const { handleChange } = this.props;
    handleChange(this.state);
  };

  resetForm = () => {
    const { containerState } = this.props;
    const { endDate, fieldOfStudyId, startDate } = containerState;
    this.setState({
      endDate,
      fieldOfStudyId,
      startDate
    });
  };

  clearFilters = () => {
    this.setState({ fieldOfStudyId: [], endDate: undefined, startDate: undefined });
  };

  render() {
    const { classes, fieldOfStudies, open, width } = this.props;
    const { endDate, fieldOfStudyId, startDate } = this.state;
    const datePickerStyle = { padding: '3px 0px 3px 6px', width: 155 };

    const clearFilterBtnDisabled = !fieldOfStudyId.length && !startDate && !endDate;

    const fullScreen = isWidthDown('sm', width!);

    return (
      <Dialog open={open} onClose={this.handleClose} fullScreen={fullScreen} maxWidth={fullScreen ? false : 'md'}>
        <DialogTitle>Filter By</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                autoOk={true}
                clearable
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Icon>date_range</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: datePickerStyle
                }}
                labelFunc={this.formatLabel}
                label="Start Date"
                onChange={this.handleDateChange('startDate')}
                value={startDate || null}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                autoOk={true}
                clearable
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Icon>date_range</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: datePickerStyle
                }}
                labelFunc={this.formatLabel}
                onChange={this.handleDateChange('endDate')}
                label="End Date"
                value={endDate || null}
              />
            </Grid>
          </Grid>

          <div className={classes.listContainer}>
            <List className={classes.list} subheader={<ListSubheader classes={{ sticky: classes.sticky }}>Field Of Study</ListSubheader>}>
              {fieldOfStudies.map(field => {
                const labelId = `checkbox-list-label-${field.id}`;
                return (
                  <ListItem key={field.id} button onClick={this.toggleField(field.id)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={fieldOfStudyId.includes(field.id)}
                        tabIndex={-1}
                        value={`${field.id}`}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={field.name} />
                  </ListItem>
                );
              })}
            </List>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button autoFocus onClick={this.handleSearch} color="primary" variant="contained">
            View Certificates
          </Button>
          <Button onClick={this.clearFilters} variant="contained" disabled={clearFilterBtnDisabled}>
            Clear Filters
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const RefineViewDialog = connect(mapStateToProps)(withStyles(styles)(withWidth()(RefineViewDialogBase)));
