export default function durationToSeconds(duration: string) {
  const validTime = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)([0-5]?\d)$/;
  if (!validTime.test(duration)) {
    throw new Error('Display time input is invalid. Expected format `hh:mm:ss` or `mm:ss`.');
  }

  return duration
    .split(':')
    .reverse()
    .reduce((prev, curr: string, i: number) => prev + parseInt(curr, 10) * 60 ** i, 0);
}
