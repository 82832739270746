import { request } from '../../utilities';
import { parseCourse, parseCourses, parseUserSavedCourse } from '../../parse';
import {
  CREATE_COURSE_SUCCESS,
  LEARNER_SAVE_COURSE_SUCCESS,
  LEARNER_UNSAVE_COURSE_SUCCESS,
  PATCH_COURSE_SUCCESS,
  SAVE_COURSE_SERIES_SUCCESS
} from '../../constants';
import { StateType } from '../store';
import { CourseSeries, CourseType } from '../../types';

function createErrorState(error: Error) {
  return { error: error.message };
}

/**
 * Create course resource.
 *
 */
async function createCourseResource(courseId: number, params: any) {
  const { data, error } = await request('post', `/courses/${courseId}/resources`, params);

  if (error) {
    return null;
  }

  return data;
}

/**
 * Create course.
 *
 */
export async function createCourse(_s: StateType, _a: any, params: any, courseResources: Record<string, any>[]) {
  const { data, error } = await request('post', '/courses', params);
  if (error) {
    return createErrorState(error);
  }

  // TODO: figure out the type of resources
  let resources: any = [];
  if (courseResources) {
    resources = await Promise.all(
      courseResources.map(courseResource => (!courseResource.id ? createCourseResource(data.id, courseResource) : courseResource))
    );
  }

  const course = parseCourse({
    ...data,
    resources
  });

  return {
    course,
    message: CREATE_COURSE_SUCCESS
  };
}

/**
 * Delete course resource material.
 *
 */
export async function deleteCourseResource(state: StateType, _a: any, courseId: number, resourceId: number) {
  const { error } = await request('delete', `/courses/${courseId}/resources/${resourceId}`);
  if (error) {
    return createErrorState(error);
  }

  const { course: { resources = [] } = {} } = state;
  const resourceIndex = resources.findIndex(s => s.id === resourceId);
  if (resourceIndex !== -1) {
    resources.splice(resourceIndex, 1);
  }

  const course = parseCourse({
    ...state.course,
    updated: new Date().toISOString(),
    resources: [...resources]
  });

  return {
    message: 'Course resource deleted!',
    course
  };
}

/**
 * Get course.
 *
 */
export async function getCourse(_s: StateType, _a: any, courseId: number) {
  const { data, error } = await request('get', `/courses/${courseId}`);
  if (error) {
    return createErrorState(error);
  }
  const course = parseCourse(data);
  return { course };
}

export async function saveUserCourse(state: StateType, _a: any, courseId: number) {
  const { data, error } = await request('post', `/courses/${courseId}/save`, {});
  if (error) {
    return createErrorState(error);
  }
  const savedCourse = parseUserSavedCourse(data);

  const { savedCourses } = state;

  return {
    message: LEARNER_SAVE_COURSE_SUCCESS,
    savedCourses: [savedCourse, ...savedCourses]
  };
}

export async function unsaveUserCourse(state: StateType, _a: any, courseId: number, showMessage = true) {
  const { error } = await request('delete', `/courses/${courseId}/save`, {});
  if (error) {
    return createErrorState(error);
  }

  const { savedCourses } = state;
  const newSavedCourses = savedCourses.filter(s => s.courseId !== courseId);

  if (showMessage) {
    return {
      message: LEARNER_UNSAVE_COURSE_SUCCESS,
      savedCourses: newSavedCourses
    };
  }

  return { savedCourses: newSavedCourses };
}

/**
 * Search courses.
 *
 */
export async function getCourses(_s: StateType, _a: any, params: any) {
  const { data, error, headers } = await request('get', '/courses', params);
  if (error) {
    return createErrorState(error);
  }
  const coursesTotal = parseInt(headers['x-count'], 10);
  const courses = parseCourses(data);
  return { courses, coursesTotal };
}

/**
 * Search user available courses.
 *
 */
export async function getAvailableCourses(_s: StateType, _a: any, params: any) {
  const { data, error, headers } = await request('get', '/courses/available/', params);
  if (error) {
    return createErrorState(error);
  }
  const coursesTotal = parseInt(headers['x-count'], 10);
  const courses = parseCourses(data);
  return { courses, coursesTotal };
}

/**
 * Patch course webinar polling question display times.
 *
 */
export async function patchWebinarPollingDisplayTimes(
  state: StateType,
  _a: any,
  courseId: number,
  webinarPollingQuestionDisplayTimes: string[]
) {
  const { data, error }: { data: CourseType; error: Error } = await request('patch', `/courses/${courseId}`, {
    webinarPollingQuestionDisplayTimes
  });
  if (error) {
    return createErrorState(error);
  }

  const { course } = state;

  return {
    course: {
      ...course,
      ...data
    }
  };
}

/**
 * Patch course and coure resources.
 *
 */
export async function patchCourse(state: StateType, _a: any, courseId: number, params: any, courseResources: any[]) {
  const { data, error } = await request('patch', `/courses/${courseId}`, params);
  if (error) {
    return createErrorState(error);
  }

  let resources = [];
  if (courseResources) {
    resources = await Promise.all(
      courseResources.map(courseResource => (!courseResource.id ? createCourseResource(data.id, courseResource) : courseResource))
    );
  }

  const stateChange: any = { message: PATCH_COURSE_SUCCESS };

  if (state.course) {
    const course = parseCourse({
      ...state.course,
      ...data,
      resources
    });

    stateChange.course = course;
  }

  const { courses } = state;
  let coursesCourse = courses.find(c => c.id === courseId);
  if (coursesCourse) {
    coursesCourse = Object.assign(coursesCourse, data);
    stateChange.courses = [...courses];
  }

  return stateChange;
}

/**
 * Set course series
 *
 */
export async function setCourseSeries(state: StateType, _a: any, courseSeriesId: number, courseIds: number[]) {
  const { data, error }: { data: CourseSeries; error: Error } = await request('post', '/courses/series', { courseSeriesId, courseIds });
  if (error) {
    return createErrorState(error);
  }

  let { courses } = state;
  courses = [...courses];

  courses = courses.map(c => {
    // updating state of courses that were removed from the serie
    if (data.removedCourseIds.indexOf(c.id) !== -1) {
      return {
        ...c,
        courseSeriesId: null,
        otherCoursesInSeries: []
      };
    }

    // updating state of courses that are in the serie
    if (courseIds.indexOf(c.id) !== -1) {
      const respCourse = data.courses.find(cc => cc.id === c.id);
      if (respCourse) {
        return {
          ...c,
          courseSeriesId: respCourse.courseSeriesId,
          courseSeriesPart: respCourse.courseSeriesPart,
          otherCoursesInSeries: data.courses.filter(cc => cc.id !== c.id)
        };
      }
      return c;
    }
    return c;
  });

  return {
    courses,
    seriesDialog: { open: false, courses: [] },
    message: SAVE_COURSE_SERIES_SUCCESS
  };
}

/**
 * Set course series
 */
export function openSeriesDialog(state: StateType, _a: any, seriesDialog: any) {
  return {
    seriesDialog: { ...state.seriesDialog, ...seriesDialog, open: true }
  };
}

export function closeSeriesDialog() {
  return {
    seriesDialog: { open: false, courses: [] }
  };
}

/**
 * Set the course list state so query params can be retained after unmount.
 *
 */
export function setCourseListState(_s: StateType, _a: any, courseListState: any) {
  return {
    courseListState
  };
}

/**
 * Unset course from store.
 */
export function unsetCourse() {
  return {
    course: undefined
  };
}

/**
 * Unset courses from store.
 */
export function unsetCourses() {
  return {
    courses: []
  };
}
