import React, { Component } from 'react';
import { createStyles, Grid, LinearProgress, Paper, WithStyles, withStyles } from '@material-ui/core';
import { differenceWith, isEqual } from 'lodash';
import {
  CustomPaging,
  FilteringState,
  IntegratedSelection,
  PagingState,
  SearchState,
  SelectionState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  DragDropProvider,
  Grid as DxGrid,
  PagingPanel,
  TableColumnReordering,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui';
import { WithProps } from 'react-waterfall';

import { connect, StateType } from '../../../core/store';
import { SearchButton } from '../../../shared';

import { ExportViewDialog } from './export-view-dialog';
import ArrayOfStringTypeProvider from './components/array-of-string-type-provider';
import AvatarTypeProvider from './components/avatar-type-provider';
import CategoryFilterCell from './components/category-filter-cell';
import CategoryTypeProvider from './components/category-type-provider';
import CoursePartTypeProvider from './components/course-part-type-provider';
import CreditTypeFilterCell from './components/credit-type-filter-cell';
import DateFilterCell from './components/date-filter-cell';
import DateTypeProvider from './components/date-type-provider';
import DeliveryMethodFilterCell from './components/delivery-method-filter-cell';
import FieldOfStudyFilterCell from './components/field-of-study-filter-cell';
import FieldOfStudyTypeProvider from './components/field-of-study-type-provider';
import InstructorTypeProvider from './components/instructor-type-provider';
import IrsStatusFilterCell from './components/irs-status-filter-cell';
import LevelFilterCell from './components/level-filter-cell';
import LinkValueTypeProvider from './components/link-value-type-provider';
import RoleFilterCell from './components/role-filter-cell';
import StatusFilterCell from './components/status-filter-cell';
import UrlTypeProvider from './components/url-type-provider';
import WebinarScheduledTypeProvider from './components/webinar-scheduled-type-provider';

const styles = createStyles({
  actions: {
    padding: '20px 0px'
  },
  actionsLeft: {
    '& button': {
      marginRight: 20
    },
    '& a': {
      marginRight: 20
    }
  },
  actionsRight: {
    textAlign: 'right',
    '& button': {
      marginLeft: 20
    }
  },
  disabledCell: {
    color: 'lightgray'
  },
  searchContainer: {
    display: 'inline-block',
    height: 46,
    paddingTop: 6,
    verticalAlign: 'bottom',
    width: 46
  },
  searchInput: {
    display: 'inline-block'
  }
});

const mapStateToProps = ({ categories, fieldOfStudies }: StateType) => ({
  categories,
  fieldOfStudies
});

interface Props extends WithStyles<typeof styles>, WithProps<typeof mapStateToProps> {
  actions: any;
  adminColumns: any[];
  config: any;
  exportRows: Function;
  loadRows: Function;
  model: string;
  rows: any[];
  totalCount: number;
  onSelectionChange?: Function;
  onQueryParamsChange?: Function;
}

interface State {
  columns: any[];
  columnExtensions: any[] | undefined;
  columnOrder: string[];
  currentPage: number;
  dateColumns: string[];
  hiddenColumnNames: string[];
  filters: any[];
  filteringStateColumnExtensions: any[];
  lastQueryParams: any;
  linkColumns: string[];
  loading: boolean;
  pageSize: number;
  search: string;
  searchDisabled: boolean;
  selection: any[];
  selectActions: React.ComponentType<{ selection: any[] }> | null;
  sorting: any[] | null;
  sortingStateColumnExtensions: any[] | undefined;
  totalCount: number;
  urlColumns: string[];
  urlColumnsBase: string;
}

class TableGridBase extends Component<Props, State> {
  timeout?: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      columns: [],
      columnExtensions: undefined,
      columnOrder: [],
      currentPage: 0,
      dateColumns: [],
      filters: [],
      filteringStateColumnExtensions: [],
      hiddenColumnNames: [],
      lastQueryParams: {},
      linkColumns: [],
      loading: true,
      pageSize: 10,
      search: '',
      searchDisabled: false,
      selection: [],
      selectActions: null,
      totalCount: 0,
      urlColumns: [],
      urlColumnsBase: '',
      sorting: null,
      sortingStateColumnExtensions: undefined
    };
  }

  componentDidMount(): void {
    this.getRows();
    this.configureTableColumns();
  }

  componentDidUpdate(prevProps: Props) {
    const { adminColumns } = this.props;

    if (differenceWith(adminColumns, prevProps.adminColumns, isEqual).length !== 0) {
      this.configureTableColumns();
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  configureTableColumns = () => {
    const { adminColumns, model } = this.props;
    const { columns } = this.state;
    const modelAdminColumns = adminColumns.filter(column => column.modelName === model);
    const modelColumns = columns.map((column, index) => {
      const adminColumn = modelAdminColumns.find(c => c.columnName === column.name);
      if (adminColumn) {
        return adminColumn;
      }

      return { columnName: column.name, hidden: false, priority: columns.length - index };
    });

    const columnOrder = modelColumns
      .sort((a, b) => {
        if (a.priority > b.priority) return -1;
        if (a.priority < b.priority) return 1;
        return 0;
      })
      .map(column => column.columnName);

    const hiddenColumnNames = modelColumns.filter(column => column.hidden).map(column => column.columnName);

    this.setState({ columnOrder, hiddenColumnNames });
  };

  static getDerivedStateFromProps(newProps: Props) {
    const { config } = newProps;
    return { ...config };
  }

  changeCurrentPage = (currentPage: number) => {
    this.setState({ currentPage, selection: [] }, this.getRows);
  };

  changeColumnOrder = (columnOrder: string[]) => {
    const { actions, model } = this.props;
    this.setState({ columnOrder }, () => {
      actions.orderAdminColumns(model, columnOrder);
    });
  };

  changeColumnVisibility = (hiddenColumnNames: string[]) => {
    const { actions, model: modelName } = this.props;
    const { columns } = this.state;
    this.setState({ hiddenColumnNames }, () => {
      const updatedAdminColumns = columns.map(column => {
        const columnName = column.name;
        if (hiddenColumnNames.includes(columnName)) {
          return { hidden: true, columnName, modelName };
        }

        return { hidden: false, columnName, modelName };
      });

      actions.updateAdminColumns(updatedAdminColumns);
    });
  };

  changeFilters = (filters: any[]) => {
    this.setState(
      {
        currentPage: 0,
        filters,
        searchDisabled: !!filters.length,
        search: filters.length > 0 ? '' : this.state.search,
        selection: []
      },
      this.getRows
    );
  };

  changeSearch = (searchString: string) => {
    const search = searchString.length > 0 ? searchString : '';
    this.setState({ currentPage: 0, search, selection: [] }, this.getRows);
  };

  changeSorting = (sorting: any[]) => {
    this.setState({ currentPage: 0, sorting }, this.getRows);
  };

  changeSelection = (selection: number[]) => {
    const { rows, onSelectionChange } = this.props;
    if (onSelectionChange) {
      const selectedRows = selection.map(x => rows[x]);
      onSelectionChange(selectedRows);
    }
    this.setState({ selection });
  };

  handleExportRows = (method: 'all' | 'search') => {
    const { exportRows } = this.props;
    if (method === 'search') {
      const queryParams = this.queryParams();
      exportRows(method, queryParams);
    } else {
      exportRows(method);
    }
  };

  queryParams() {
    const { currentPage, filters, search, sorting } = this.state;
    const query: any = {};

    // sorting
    if (sorting && sorting.length) {
      const columnOrder = sorting[0];
      query.order = columnOrder.columnName;
      query.direction = columnOrder.direction === 'desc' ? 'desc' : 'asc';
    }

    // filtering
    filters.forEach(({ columnName, value }) => {
      query[columnName] = value;
    });

    // searching
    if (search) {
      query.search = search;
    }

    // pagination
    query.page = currentPage + 1;

    return query;
  }

  async getRows() {
    const { onQueryParamsChange } = this.props;
    const queryParams = this.queryParams();

    if (isEqual(queryParams, this.state.lastQueryParams)) {
      return;
    }
    this.setState({
      loading: true,
      lastQueryParams: queryParams
    });

    if (onQueryParamsChange) {
      onQueryParamsChange(queryParams);
    }

    this.props.loadRows(queryParams);

    // simulate loading icon until context can be figured out
    this.timeout = window.setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }

  // TODO Refactor so parent component passes in.
  filterCell = (props: any) => {
    const { dateColumns } = this.state;
    const { name } = props.column;
    if (name === 'role') {
      return <RoleFilterCell {...props} />;
    } else if (name === 'categoryId') {
      return <CategoryFilterCell {...props} />;
    } else if (name === 'credittype') {
      return <CreditTypeFilterCell {...props} />;
    } else if (name === 'deliveryMethod') {
      return <DeliveryMethodFilterCell {...props} />;
    } else if (name === 'fieldOfStudyId') {
      return <FieldOfStudyFilterCell {...props} />;
    } else if (name === 'irsStatus') {
      return <IrsStatusFilterCell {...props} />;
    } else if (name === 'level') {
      return <LevelFilterCell {...props} />;
    } else if (name === 'status') {
      return <StatusFilterCell {...props} />;
    } else if (dateColumns.includes(name)) {
      return <DateFilterCell {...props} />;
    }

    return <TableFilterRow.Cell {...props} />;
  };

  headerCell = (props: any) => {
    const { classes } = this.props;
    let className;
    if (!props.sortingEnabled) {
      className = classes.disabledCell;
    }

    return <TableHeaderRow.Cell className={className} {...props} />;
  };

  render() {
    const { categories, classes, fieldOfStudies, rows, totalCount } = this.props;
    const {
      columns,
      columnExtensions,
      columnOrder,
      currentPage,
      dateColumns,
      hiddenColumnNames,
      filteringStateColumnExtensions,
      linkColumns,
      loading,
      pageSize,
      search,
      searchDisabled,
      selectActions: SelectActions,
      selection,
      sorting,
      sortingStateColumnExtensions,
      urlColumns,
      urlColumnsBase
    } = this.state;

    return (
      <div>
        <Grid container spacing={0} className={classes.actions}>
          <Grid item xs={12} className={classes.actionsLeft}>
            <ExportViewDialog exportRows={this.handleExportRows} />
            {SelectActions && <SelectActions selection={selection} />}
            <div className={classes.searchContainer}>
              <SearchButton disabled={searchDisabled} changeSearch={this.changeSearch} />
            </div>
          </Grid>
        </Grid>
        {loading && <LinearProgress variant="query" />}
        <Paper>
          <DxGrid rows={rows} columns={columns}>
            <FilteringState onFiltersChange={this.changeFilters} columnExtensions={filteringStateColumnExtensions} />
            <SearchState value={search} />
            <SortingState
              columnExtensions={sortingStateColumnExtensions}
              sorting={sorting == null ? undefined : sorting}
              onSortingChange={this.changeSorting}
            />
            <PagingState currentPage={currentPage} onCurrentPageChange={this.changeCurrentPage} pageSize={pageSize} />
            <SelectionState selection={selection} onSelectionChange={this.changeSelection} />

            <CustomPaging totalCount={totalCount} />

            <IntegratedSelection />

            {/* TODO Refactor so parent component passes in. */}
            <ArrayOfStringTypeProvider for={['credittype']} />
            <AvatarTypeProvider for={['avatarUrl']} />
            <DateTypeProvider for={dateColumns} />
            <CategoryTypeProvider categories={categories} for={['categoryId']} />
            <FieldOfStudyTypeProvider fieldOfStudies={fieldOfStudies} for={['fieldOfStudyId']} />
            <DragDropProvider />
            <InstructorTypeProvider for={['instructor']} />
            <LinkValueTypeProvider for={linkColumns} />
            <UrlTypeProvider for={urlColumns} base={urlColumnsBase} />
            <WebinarScheduledTypeProvider for={['webinarScheduled']} />
            <CoursePartTypeProvider for={['courseSeriesPart']} />
            <SelectionState />

            <VirtualTable columnExtensions={columnExtensions} height={600} />

            <TableSelection showSelectAll />

            <TableColumnReordering order={columnOrder} onOrderChange={this.changeColumnOrder} />

            <TableHeaderRow cellComponent={this.headerCell} showSortingControls />

            <TableFilterRow cellComponent={this.filterCell} />

            <PagingPanel />

            <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} onHiddenColumnNamesChange={this.changeColumnVisibility} />

            <Toolbar />
            <ColumnChooser />
          </DxGrid>
        </Paper>
      </div>
    );
  }
}

export const TableGrid = withStyles(styles)(connect(mapStateToProps)(TableGridBase));
