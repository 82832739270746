import React from 'react';
import { MenuItem, Select, TableCell } from '@material-ui/core';
import { WithProps } from 'react-waterfall';

import { connect, StateType } from '../../../../core/store';

const mapStateToProps = ({ categories }: StateType) => ({
  categories
});

interface Props extends WithProps<typeof mapStateToProps> {
  filter: any;
  onFilter: Function;
}

const CategoryFilterCell = ({ categories, filter, onFilter }: Props) => (
  <TableCell padding="none">
    <Select
      fullWidth={true}
      value={filter ? filter.value : ''}
      onChange={e => {
        onFilter(e.target.value ? { value: e.target.value } : null);
      }}
      inputProps={{
        name: 'categoryId',
        id: 'categoryId'
      }}>
      <MenuItem value="">
        <em>All</em>
      </MenuItem>
      {categories.map(category => (
        <MenuItem key={category.id} value={category.id}>
          {category.name}
        </MenuItem>
      ))}
    </Select>
  </TableCell>
);

export default connect(mapStateToProps)(CategoryFilterCell);
